import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Box, Button, Flex, Container, Image } from '@chakra-ui/react';
import { signOutUser } from '../redux/user/user.actions';
import './error/error.styles.scss';

const WebSocketErrorHandler = () => {
  const navigate = useNavigate();
  return (
    <Box p={4} className="error-page">
      <Container maxW="full" className="error-container">
        <div className="error-icon">
          <Image
            w="250px"
            objectFit="contain"
            src="/deadline.png"
            alt="deadline"
          />
        </div>

        <Flex
          className="error-detail"
          flexDirection="column"
          alignItems="center"
        >
          <h3>Connection Timeout</h3>
          <p>
            Your session was expired or the connection was closed. Please check
            your network connection and refresh this page.
          </p>

          <Button
            mt={6}
            className="btn-group"
            bg="#565657"
            color="#FFF"
            variant="solid"
            w={200}
            onClick={() => {
              signOutUser();
              Auth.signOut();
            }}
            _hover={{ bg: '#2e2e2f' }}
          >
            Logout
          </Button>
          <Button
            mt={6}
            className="btn-group"
            bg="#DB2F5CCC"
            color="#FFF"
            variant="solid"
            w={200}
            onClick={() => {
              navigate('/');
              navigate(0);
            }}
            _hover={{ bg: '#db2f5d99' }}
          >
            Reconnect
          </Button>
        </Flex>
      </Container>
    </Box>
  );
};

export default WebSocketErrorHandler;
