const mapStatusLabels = (status) => {
  let label;
  switch (status) {
    case 'draft':
      label = 'Created';
      break;
    case 'confirmed':
      label = 'Processing';
      break;
    case 'closed':
      label = 'Completed';
      break;
    case 'void':
      label = 'Cancelled';
      break;
    default:
      label = 'On Hold';
  }
  return label;
};

export default mapStatusLabels;
