import React, { useEffect, useState, memo } from 'react';
import {
  Box,
  Flex,
  Icon,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  TableContainer,
} from '@chakra-ui/react';
import {
  Pagination,
  usePagination,
  PaginationNext,
  PaginationPage,
  PaginationPrevious,
  PaginationContainer,
  PaginationPageGroup,
} from '@ajna/pagination';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import ModbusRTUSingleView from './ModbusRTUSingleView';
import getAttributeData from './functions/getAttributeData';
import mapModbusRTUStatus from '../../aux-device-view/mapModbusRTUStatus';
import './modbus-rtu-view.styles.scss';

const ModbusRTUView = ({ latestAUXData, AUXConfig }) => {
  const [isSingleViewOpen, setSingleViewOpen] = useState(false);
  const [currentAttribute, setCurrentAttribute] = useState(0);
  const [currentData, setCurrentData] = useState([]);
  const [attributeData, setAttributeData] = useState([]);

  const itemLimit = 5;
  const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({
    pagesCount: Math.ceil(attributeData.length / itemLimit),
    initialState: { currentPage: 1 },
  });

  useEffect(() => {
    const attributeData = getAttributeData(
      AUXConfig.read_blocks,
      latestAUXData
    );
    setAttributeData(attributeData);
    const startIndex = (currentPage - 1) * itemLimit;
    const endIndex = startIndex + itemLimit;
    const getList = () => {
      const newData = attributeData.slice(startIndex, endIndex);
      setCurrentData(newData);
    };
    getList();
  }, [currentPage, itemLimit, latestAUXData, AUXConfig]);

  const handleOpenChannel = (index) => {
    if (index !== '') {
      setSingleViewOpen(true);
      setCurrentAttribute(index);
    } else {
      setSingleViewOpen(false);
      setCurrentAttribute(0);
    }
  };

  return isSingleViewOpen ? (
    <ModbusRTUSingleView
      data={attributeData}
      handleOpenChannel={handleOpenChannel}
      selectedAttribute={currentAttribute}
    />
  ) : (
    <TableContainer className="aux-table" p={2} pt={6} overflowY="auto">
      <Table variant="simple">
        <Thead position="sticky" top={0} bgColor="#3a3a3c">
          <Tr h="40px">
            <Th>Attribute</Th>
            <Th>Value</Th>
            <Th>Status</Th>
          </Tr>
        </Thead>
        <Tbody>
          {currentData.length > 0 ? (
            currentData.map((item, index) => (
              <Tr
                key={index}
                className="channel-row"
                onClick={() =>
                  handleOpenChannel(
                    attributeData.findIndex(
                      (attr) => attr.attribute_name === item.attribute_name
                    )
                  )
                }
              >
                <Td w={40} data-testid="attribute-name">
                  {item.attribute_name}
                </Td>
                <Td
                  w={40}
                  data-testid="attribute-value"
                  className={`${
                    item.isStaledData === true ? 'staled-data' : ''
                  } device-data`}
                >
                  {item.attribute_value === null ? (
                    '--'
                  ) : (
                    <Flex className="device-data">
                      <Flex className="attr-text-value">
                        {`${item.attribute_value}`}
                      </Flex>
                      <Flex>
                        {item.data_unit === 'None' ? '' : item.data_unit}
                      </Flex>
                    </Flex>
                  )}
                </Td>

                <Td
                  w={40}
                  className={`${
                    item.isStaledData === true ? 'staled-data' : ''
                  } device-data`}
                >
                  {mapModbusRTUStatus(item.attribute_status)}
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td>Data is not available</Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      {pagesCount > 1 && (
        <Box className="table-pagination">
          <Pagination
            onPageChange={setCurrentPage}
            pagesCount={pagesCount}
            currentPage={currentPage}
          >
            <PaginationContainer>
              <PaginationPrevious>
                <Icon as={FiChevronLeft} w={4} h={4} />
              </PaginationPrevious>
              <PaginationPageGroup>
                {pages.map((page) => (
                  <PaginationPage key={`paginator_page_${page}`} page={page} />
                ))}
              </PaginationPageGroup>
              <PaginationNext>
                <Icon as={FiChevronRight} w={4} h={4} />
              </PaginationNext>
            </PaginationContainer>
          </Pagination>
        </Box>
      )}
    </TableContainer>
  );
};
export default memo(ModbusRTUView);
