const handleLatestAUXChannelData = (arr1, arr2, channels) => {
  // Combine both arrays
  const combinedArray = arr1.concat(arr2);

  // Sort the combined array based on timestamp_ms in descending order
  combinedArray.sort((a, b) => b.timestamp_ms - a.timestamp_ms);

  // Use a Set to keep track of unique channels
  const uniqueChannels = new Set();

  // Filter the array to keep only the latest objects for each channel
  const resultArray = combinedArray.filter((obj) => {
    if (!uniqueChannels.has(obj.channel)) {
      uniqueChannels.add(obj.channel);
      return true;
    }
    return false;
  });
  if (channels && channels.length > 0) {
    return resultArray.filter((item) => channels.includes(item.channel));
  }
  return resultArray;
};

export default handleLatestAUXChannelData;
