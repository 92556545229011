import React from 'react';
import { Box, HStack, useRadio, useRadioGroup } from '@chakra-ui/react';

const RadioButton = (props) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="lg"
        boxShadow="md"
        _checked={{
          bg: '#DF4661',
          color: 'white',
          borderColor: '#DF4661',
        }}
        _focus={{
          boxShadow: 'none',
        }}
        px={5}
        py={1}
        // w={150}
        // ml={2}
        mr={4}
        textAlign="center"
      >
        {props.children}
      </Box>
    </Box>
  );
};

const CustomRadioComponent = ({ options, defaultValue, value, onChange }) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    defaultValue,
    value,
    onChange,
  });

  const group = getRootProps();

  return (
    <HStack {...group} flexWrap="wrap">
      {options.map((value) => {
        const radio = getRadioProps({ value });
        return (
          <RadioButton key={value} {...radio}>
            {value}
          </RadioButton>
        );
      })}
    </HStack>
  );
};

export default CustomRadioComponent;
