import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Box, Container, Flex, Image, Icon } from '@chakra-ui/react';
import { FaPlus } from 'react-icons/fa';
import CloudAnimation from '../../components/animation/cloud';
import NoDevice from './no-device/no-device.component';
import portalHandleRequest from '../../api/portalHandleRequest';
import './devices.styles.scss';

const PORTAL_API_ENDPOINT = process.env.REACT_APP_PORTAL_API;

const Devices = ({ userAccounts }) => {
  const [activeDevices, setActiveDevices] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const getDevices = async () => {
      if (userAccounts && userAccounts.user_email) {
        const obj = {
          method: 'GET',
          url: `${PORTAL_API_ENDPOINT}/devices/user-email/${userAccounts.user_email}`,
        };

        const devices = await portalHandleRequest(obj, navigate);
        if (devices.status === 200) {
          setActiveDevices(devices.data);
        } else {
          throw new Error(devices);
        }
      }
    };

    getDevices();
  }, []);
  return (
    <Box p={4} className="devices-page">
      <Container maxW="full" p={0} className="devices-page--container">
        {activeDevices && activeDevices.length > 0 ? (
          <Flex flexDirection="column">
            <Flex
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
              mb={6}
              ml={4}
              mr={4}
            >
              <h1>Added Devices</h1>

              <Button
                className="btn-group"
                bg="#DB2F5CCC"
                color="#FFF"
                variant="solid"
                w={200}
                ml={4}
                _hover={{ bg: '#db2f5d99' }}
                onClick={() => navigate('/add-device')}
              >
                <Icon as={FaPlus} w={4} h={4} mr={4} />
                Add New Device
              </Button>
            </Flex>

            <Flex flexWrap="wrap" flex="1" className="devices-page--wrapper">
              {activeDevices.map((device) => (
                <Link
                  to={`/device/${device.thing_id}`}
                  key={device.thing_id}
                  className="device-link"
                >
                  <Box w="100%" className="device-item">
                    <Box className="overlay" />
                    <Flex
                      className="device-wrapper"
                      justifyContent="space-between"
                      flexDirection="column"
                    >
                      {device.image_url ? (
                        <Image
                          boxSize="250px"
                          objectFit="contain"
                          src={device.image_url}
                          alt="device-img"
                          mb={2}
                        />
                      ) : (
                        <Image
                          w="200px"
                          objectFit="contain"
                          src="/edgepi-placeholder.png"
                          alt="device-img"
                          mb={2}
                        />
                      )}

                      <div className="device-info">
                        <h3>{device.product_name}</h3>
                        <p>S/N: {device.serial_number}</p>
                      </div>
                    </Flex>
                  </Box>
                </Link>
              ))}
            </Flex>
          </Flex>
        ) : activeDevices && activeDevices.length === 0 ? (
          <NoDevice />
        ) : (
          <Flex
            w="100%"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <CloudAnimation />
          </Flex>
        )}
      </Container>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  userAccounts: state.user.accounts,
});

export default connect(mapStateToProps, null)(Devices);
