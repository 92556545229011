// this function is for FTX and PDX: to keep mappings value

const payloadObj = (objA, objB) => {
  const objC = { ...objA };

  for (const key in objB) {
    if (Object.prototype.hasOwnProperty.call(objB, key)) {
      if (objB[key] !== '' && objB[key] !== null && !Number.isNaN(objB[key])) {
        if (typeof objB[key] === 'object' && !Array.isArray(objB[key])) {
          objC[key] = payloadObj(objA[key], objB[key]);
        } else {
          objC[key] = objB[key];
        }
      } else if (objB[key] === null) {
        objC[key] = objB[key];
      }
    }
  }

  return objC;
};

export default payloadObj;
