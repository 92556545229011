import { useLottie } from 'lottie-react';
import cloudAnimation from '../../assets/cloud.json';

const CloudAnimation = () => {
  const options = {
    animationData: cloudAnimation,
    loop: true,
    autoplay: true,
  };

  const { View } = useLottie(options);

  return View;
};

export default CloudAnimation;
