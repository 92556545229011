import { Auth } from 'aws-amplify';

const PORTAL_API_ENDPOINT = process.env.REACT_APP_PORTAL_API;

const useAuthentication = () => {
  Auth.configure({
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    mandatorySignIn: false,

    // cookieStorage: {
    //   domain: process.env.REACT_APP_COOKIE_DOMAIN,
    //   path: '/',
    //   expires: 0.5,
    //   secure: true,
    // },

    // Cognito Hosted UI configuration
    oauth: {
      domain: process.env.REACT_APP_AUTH_DOMAIN,
      scope: [
        'email',
        'openid',
        `${PORTAL_API_ENDPOINT}/order.read`,
        `${PORTAL_API_ENDPOINT}/order.write`,
        `${PORTAL_API_ENDPOINT}/event.write`,
        `${PORTAL_API_ENDPOINT}/user.create`,
        `${PORTAL_API_ENDPOINT}/user.read`,
        `${PORTAL_API_ENDPOINT}/user.update`,
        `${PORTAL_API_ENDPOINT}/guest-user.delete`,
        `${PORTAL_API_ENDPOINT}/guest-user.read`,
        `${PORTAL_API_ENDPOINT}/subscriptions.rw`,
        `${PORTAL_API_ENDPOINT}/device.add`,
        `${PORTAL_API_ENDPOINT}/device.read`,
        `${PORTAL_API_ENDPOINT}/device.update`,
        `${PORTAL_API_ENDPOINT}/device.assign`,
        `${PORTAL_API_ENDPOINT}/shadow.update`,
        `${PORTAL_API_ENDPOINT}/shadow.read`,
        `${PORTAL_API_ENDPOINT}/plan.read`,
        `${PORTAL_API_ENDPOINT}/plan.create`,
        `${PORTAL_API_ENDPOINT}/product.read`,
        `${PORTAL_API_ENDPOINT}/provisioning.create`,
        `${PORTAL_API_ENDPOINT}/provisioning.read`,
        `${PORTAL_API_ENDPOINT}/provisioning.delete`,
        `${PORTAL_API_ENDPOINT}/thing.subscribe`,
        `${PORTAL_API_ENDPOINT}/thing.unsubscribe`,
      ],
      redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN,
      redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT,
      responseType: 'code',
    },
  });
};

export default useAuthentication;
