// Create a query string that has the selected channels
const generateAUXChannelsQueryString = (channels) => {
  // Map each channel string to the desired format
  const queryStringArray = channels.map(
    (channel) => `&var-channel=ch_${channel.toLowerCase()}`
  );
  // Join the formatted strings together
  const queryString = queryStringArray.join('');
  return queryString;
};

export default generateAUXChannelsQueryString;
