import generateAccessToken from './generateAccessToken';

const retryDelay = 1000;

const portalHandleRequest = async (obj, navigate, maxRetries = 3) => {
  const { method, data, url, contentType } = obj;
  const token = await generateAccessToken();
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  if (contentType && contentType === 'application/json') {
    myHeaders.append('Content-Type', 'application/json');
  }

  const raw = JSON.stringify(data);

  const requestOptions = {
    method,
    headers: myHeaders,
    body: raw || null,
    redirect: 'follow',
  };

  try {
    const response = await fetch(`${url}`, requestOptions);
    const json = await response.json();
    return { status: response.status, ...json };
  } catch (err) {
    console.error(err);
    if (method === 'GET' && maxRetries > 0) {
      console.log('Network error. Retrying... Retries left:', maxRetries);
      await new Promise((resolve) => setTimeout(resolve, retryDelay));
      return portalHandleRequest(obj, navigate, maxRetries - 1);
    }
    if (navigate) {
      navigate('/network-error', { state: err });
    }
    return err;
  }
};

export default portalHandleRequest;
