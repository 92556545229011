import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Flex,
  Image,
  SimpleGrid,
} from '@chakra-ui/react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/logo-text-white.svg';

import './landing.styles.scss';

const Landing = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const checkAuthUser = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        navigate('/dashboard');
      } catch (error) {
        console.error(error);
      }
    };
    checkAuthUser();
  }, []);
  return (
    <Box className="landing-page">
      <Container maxW="full" className="landing-page--container">
        <Box className="landing-page--header">
          <Logo className="logo" height={80} />
          <Flex alignItems="center" justifyContent="center" mt={4} mb={4}>
            <Button
              className="btn-group"
              bg="#DB2F5CCC"
              color="#FFF"
              variant="solid"
              w={170}
              mr={4}
              onClick={() => {
                Auth.federatedSignIn({
                  customState: window.location.pathname,
                });
              }}
              _hover={{ bg: '#db2f5d99' }}
            >
              Login
            </Button>

            <a
              href={process.env.REACT_APP_STORE_URL}
              target="_blank"
              rel="noreferrer"
            >
              <Button
                className="btn-group"
                bg="#3A3A3C"
                color="#FFF"
                variant="solid"
                w={170}
                _hover={{ bg: '#db2f5d99' }}
              >
                EdgePi Store
              </Button>
            </a>
          </Flex>
        </Box>
        <Box className="landing-page--body">
          <h1>Welcome to your EdgePi Portal .</h1>
          <h3>
            All the Features, All in one Place. Safely and Securely in the{' '}
            <span>Cloud</span> .
          </h3>
          <SimpleGrid columns={{ md: 1, xl: 3 }} spacing="20px" mt={24}>
            <Box className="feature-box">
              <Image
                boxSize="120px"
                objectFit="contain"
                src="/internet.png"
                alt="internet"
                mb={4}
              />
              <h3>Anytime, Anywhere</h3>
              <p>
                Configure each and every component of your EdgePi device
                remotely.
              </p>
            </Box>

            <Box className="feature-box">
              <Image
                boxSize="120px"
                objectFit="contain"
                src="/chart.png"
                alt="chart"
                mb={4}
              />
              <h3>Data Visualization</h3>
              <p>
                Visualize your data read from EdgePi online using our
                interactive analytics tool.
              </p>
            </Box>

            <Box className="feature-box">
              <Image
                boxSize="120px"
                objectFit="contain"
                src="/replay.png"
                alt="replay"
                mb={4}
              />
              <h3>Quick and Easy</h3>
              <p>
                Register your EdgePi, attach it to a plan, and begin configuring
                in minutes.
              </p>
            </Box>
          </SimpleGrid>
        </Box>
      </Container>
    </Box>
  );
};

export default Landing;
