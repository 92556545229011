import React, { useState, useEffect, useRef } from 'react';
import AuthCode from 'react-auth-code-input';
import { Link } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/react';
import { CustomButton } from '../../../components/custom-button/custom-button.component';
import './reg-code.styles.scss';

const RegCode = ({ onResult, resetInput, errorCode }) => {
  const [inputCode, setInputCode] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [isCodeValid, setCodeValid] = useState(null);

  // eslint-disable-next-line prettier/prettier
  const AuthInputRef = useRef(null);

  useEffect(() => {
    setInputCode('');
    setCodeValid(null);
    AuthInputRef.current?.clear();
  }, [resetInput]);
  const handleCheckCode = async () => {
    setLoading(true);
    const res = await onResult(inputCode);
    if (res) {
      setLoading(false);
      setCodeValid(true);
    } else {
      setLoading(false);
      setCodeValid(false);
    }
  };
  const handleKeyUp = (e) => {
    if (inputCode.length === 5 && e.keyCode === 13) {
      handleCheckCode();
    }
  };
  const handleInputCode = (value) => {
    setCodeValid(null);
    setInputCode(value);
  };
  return (
    <Box mt={16}>
      <div className="add-device-step-heading">
        <h2 className="step-header">Step 01</h2>
        <h2>Add a Device - Registration Code</h2>
        <p>
          Please enter the 5-character device registration code found on the
          backside of your EdgePi device.
        </p>
      </div>
      <Flex
        className="reg-code-process"
        flexDirection="column"
        justifyContent="center"
        flexWrap="wrap"
        mb={4}
      >
        <Flex alignItems="center" flexWrap="wrap" mb={4}>
          <Box
            mr={4}
            className={`reg-code-input ${
              isCodeValid ? 'reg-code-input-disabled' : ''
            }`}
            onKeyUp={handleKeyUp}
          >
            <AuthCode
              disabled={isCodeValid}
              ref={AuthInputRef}
              length={5}
              allowedCharacters="alphanumeric"
              onChange={handleInputCode}
            />
          </Box>
          {isCodeValid === true && <p className="code-status">Code is valid</p>}
          {isCodeValid === false && (
            <Flex flexDirection="column">
              <p className="code-status error">
                {errorCode === 401
                  ? 'This EdgePi has been assigned to your account already.'
                  : 'Invalid registration code'}
              </p>
              {errorCode === 401 && (
                <p className="error-info">
                  Click <Link to="/devices/">here</Link> to delete your device
                  and add it again.
                </p>
              )}
            </Flex>
          )}
        </Flex>
        {!isCodeValid && (
          <Box w={300}>
            <CustomButton
              onClick={handleCheckCode}
              disabled={inputCode === '' || inputCode.length !== 5 || isLoading}
              isLoading={isLoading}
              variant="custom-btn--red"
            >
              Check
            </CustomButton>
          </Box>
        )}
      </Flex>
    </Box>
  );
};
export default RegCode;
