import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon, Flex } from '@chakra-ui/react';
import { AiOutlineMobile } from 'react-icons/ai';
import { FaPlus } from 'react-icons/fa';
import './no-device.styles.scss';

const NoDevice = () => (
  <Flex
    w="100%"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
  >
    <Icon as={AiOutlineMobile} w={24} h={24} mb={4} />
    <h2 className="no-device-message">You don’t have any devices.</h2>

    <p>To make a purchase, visit our storefront at</p>
    <p className="store-link">
      <a
        href={`${process.env.REACT_APP_STORE_URL}/shop`}
        target="_blank"
        rel="noreferrer"
      >
        {process.env.REACT_APP_STORE_URL}/shop
      </a>
    </p>
    <p>or</p>
    <Link to="/add-device">
      <Button
        mt={6}
        className="btn-group"
        bg="#DB2F5CCC"
        color="#FFF"
        variant="solid"
        w={200}
        _hover={{ bg: '#db2f5d99' }}
      >
        <Icon as={FaPlus} w={4} h={4} mr={4} /> Add Device
      </Button>
    </Link>
  </Flex>
);

export default NoDevice;
