import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import {
  Box,
  Avatar,
  Button,
  Icon,
  Image,
  Select,
  Flex,
  SkeletonText,
} from '@chakra-ui/react';
import { AiOutlineHome } from 'react-icons/ai';
import { IoHelpCircleOutline } from 'react-icons/io5';
import { MdDataUsage } from 'react-icons/md';
import { BsReceiptCutoff } from 'react-icons/bs';
import { FaUserAlt } from 'react-icons/fa';
import { ReactComponent as Logo } from '../../assets/logo-text-white.svg';
import { setCurrentUser, signOutUser } from '../../redux/user/user.actions';
import './sidebar.styles.scss';

const Sidebar = ({
  userAccounts,
  activeDevices,
  setCurrentUser,
  signOutUser,
}) => {
  // const { toggleColorMode } = useColorMode();
  const location = useLocation();
  const currentSlug = location.pathname;
  const [menuActive, setMenuState] = useState(false);

  const handleChangeAccount = (e) => {
    userAccounts.accounts.find((account) => {
      if (account.account_id === e.target.value) {
        setCurrentUser(account);
        localStorage.setItem('currentAccount', e.target.value);
      }
      setMenuState(false);
      return null;
    });
  };
  const styles = {
    left: '0',
    top: '0',
  };
  const handleClick = () => {
    setMenuState(!menuActive);
    if (menuActive) {
      // eslint-disable-next-line no-undef
      document.body.style = 'overflow-y: scroll';
    } else {
      // eslint-disable-next-line no-undef
      document.body.style = 'overflow-y: hidden';
    }
  };
  const handleCloseMenu = () => {
    setMenuState(false);
  };
  return (
    <>
      <Box className="sidebar" style={styles}>
        <Link className="logo-container" to="/">
          <Logo className="logo" />
        </Link>
        <Flex flexDirection="column" justifyContent="space-between">
          <div className="profile-picture">
            <Avatar
              loading="lazy"
              showBorder
              icon={<FaUserAlt fontSize="2.5rem" />}
            />
            <div>
              {userAccounts && userAccounts.user_email ? (
                <h4>{userAccounts.user_email}</h4>
              ) : (
                <SkeletonText
                  mt="4"
                  noOfLines={1}
                  spacing="4"
                  skeletonHeight="4"
                  width={220}
                />
              )}

              {userAccounts &&
              userAccounts.accounts &&
              userAccounts.accounts.length > 1 ? (
                <Select
                  bg="#535355"
                  borderColor="#535355"
                  color="white"
                  defaultValue={
                    localStorage.getItem('currentAccount')
                      ? localStorage.getItem('currentAccount')
                      : null
                  }
                  className="account-selector"
                  onChange={handleChangeAccount}
                >
                  {userAccounts.accounts.map((account) => (
                    <option key={account.account_id} value={account.account_id}>
                      {account.account_name || account.account_id}
                    </option>
                  ))}
                </Select>
              ) : null}
              <Button
                className="logout"
                bg="#535355"
                color="white"
                size="xs"
                marginTop={2}
                p={4}
                onClick={() => {
                  Auth.signOut();
                  signOutUser();
                }}
              >
                Log Out
              </Button>
            </div>
          </div>
          <div className="main-menu">
            <div className="options">
              <Link
                className={`option ${
                  currentSlug.indexOf('dashboard') >= 0 ? 'active' : ''
                }`}
                to={
                  activeDevices && activeDevices.length > 0
                    ? `/dashboard/${activeDevices[0].thing_id}`
                    : `/dashboard`
                }
              >
                <Icon as={AiOutlineHome} w={8} h={8} /> Dashboard
              </Link>
              <Link
                className={`option ${
                  currentSlug === '/devices' ||
                  currentSlug.indexOf('device') >= 0
                    ? 'active'
                    : ''
                }`}
                to="/devices"
              >
                <Image
                  className="sys-chart-btn"
                  w={8}
                  h={8}
                  mr="20px"
                  objectFit="contain"
                  src="/device.svg"
                  alt="device"
                />
                Devices
              </Link>
              <Link
                className={`option ${
                  currentSlug === '/orders' || currentSlug.indexOf('order') >= 0
                    ? 'active'
                    : ''
                }`}
                to="/orders"
              >
                <Icon as={BsReceiptCutoff} w={8} h={8} /> Orders
              </Link>
              <Link
                className={`option ${currentSlug === '/plans' ? 'active' : ''}`}
                to="/plans"
              >
                <Icon as={MdDataUsage} w={8} h={8} /> Plans
              </Link>
            </div>
          </div>
        </Flex>
        <div className="bottom-section">
          <Flex marginTop={4} alignItems="center" className="help-section">
            <Icon as={IoHelpCircleOutline} w={8} h={8} />
            <div>
              <p>Have any questions using our product?</p>
              <p>
                Email us at{' '}
                <a href="mailto:support@edgepi.com">support@edgepi.com</a>
              </p>
            </div>
          </Flex>
          <p className="app-version">
            Version: {process.env.REACT_APP_VERSION}
          </p>
        </div>
      </Box>

      <Box className="mobile-menu" style={styles}>
        <Flex className="mobile-menu--top">
          <Link className="logo-container" to="/">
            <Logo className="logo" />
          </Link>
          <div className={`${menuActive ? 'nav-active' : ''}`}>
            <div
              role="presentation"
              className="nav-icon"
              onClick={() => handleClick()}
            >
              <div />
            </div>
          </div>
        </Flex>
        <Flex
          display={`${menuActive ? 'flex' : 'none'}`}
          className="mobile-menu--body"
        >
          <div className="profile-picture">
            <Avatar
              loading="lazy"
              showBorder
              icon={<FaUserAlt fontSize="2rem" />}
            />
            <div>
              {userAccounts && userAccounts.user_email ? (
                <h4>{userAccounts.user_email}</h4>
              ) : (
                <SkeletonText
                  mt="4"
                  noOfLines={1}
                  spacing="4"
                  skeletonHeight="4"
                  width={220}
                />
              )}
              {userAccounts &&
              userAccounts.accounts &&
              userAccounts.accounts.length > 1 ? (
                <Select
                  bg="#535355"
                  borderColor="#535355"
                  color="white"
                  defaultValue={
                    localStorage.getItem('currentAccount')
                      ? localStorage.getItem('currentAccount')
                      : null
                  }
                  className="account-selector"
                  onChange={handleChangeAccount}
                >
                  {userAccounts.accounts.map((account) => (
                    <option key={account.account_id} value={account.account_id}>
                      {account.account_name || account.account_id}
                    </option>
                  ))}
                </Select>
              ) : null}
              <Button
                className="logout"
                bg="#535355"
                color="white"
                size="xs"
                marginTop={2}
                onClick={() => {
                  Auth.signOut();
                  signOutUser();
                }}
              >
                Log Out
              </Button>
            </div>
          </div>
          <div className="main-menu">
            <div className="options">
              <Link
                className={`option ${
                  currentSlug.indexOf('dashboard') >= 0 ? 'active' : ''
                }`}
                onClick={() => handleCloseMenu()}
                to={
                  activeDevices && activeDevices.length > 0
                    ? `/dashboard/${activeDevices[0].thing_id}`
                    : `/dashboard`
                }
              >
                <p>Dashboard</p>
              </Link>

              <>
                <Link
                  className={`option ${
                    currentSlug === '/devices' ||
                    currentSlug.indexOf('device') >= 0
                      ? 'active'
                      : ''
                  }`}
                  onClick={() => handleCloseMenu()}
                  to="/devices"
                >
                  <p>Devices</p>
                </Link>
                <Link
                  className={`option ${
                    currentSlug === '/orders' ||
                    currentSlug.indexOf('order') >= 0
                      ? 'active'
                      : ''
                  }`}
                  onClick={() => handleCloseMenu()}
                  to="/orders"
                >
                  <p>Orders</p>
                </Link>
                <Link
                  className={`option ${
                    currentSlug === '/plans' ? 'active' : ''
                  }`}
                  onClick={() => handleCloseMenu()}
                  to="/plans"
                >
                  <p>Plans</p>
                </Link>
                {/* <Link
                    className={`option ${
                      currentSlug === '/settings' ? 'active' : ''
                    }`}
                    onClick={() => handleCloseMenu()}
                    to="/settings"
                  >
                    <p>Settings</p>
                  </Link> */}
              </>
            </div>
          </div>
          <div className="bottom-section">
            {/* <Flex alignItems="center">
              <Switch
                defaultChecked={
                  !!(
                    localStorage.getItem('colorMode') &&
                    localStorage.getItem('colorMode') === 'light'
                  )
                }
                colorScheme="teal"
                size="lg"
                onChange={(e) => handleSwitchColorMode(e)}
              />
              <p>Dark / Light Mode</p>
            </Flex> */}
            <Flex marginTop={4} alignItems="center" className="help-section">
              <Icon as={IoHelpCircleOutline} w={8} h={8} />
              <div>
                <p>Have any questions using our product?</p>
                <p>Call us at OSENSA Innovations</p>
                <p>+1-888-732-0016</p>
              </div>
            </Flex>
            <p className="app-version">
              Version: {process.env.REACT_APP_VERSION}
            </p>
          </div>
        </Flex>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  userAccounts: state.user.accounts,
  activeDevices: state.user.activeDevices,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  signOutUser: () => dispatch(signOutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
