import { useState } from 'react';
import { nameRegex } from '../../../../../../../api/nameRegex';

const useModbusRTUForm = (callback, initialState = {}) => {
  const [values, setValues] = useState(initialState);

  const onChange = (input) => (event) => {
    if (input === 'data_collection' || input === 'cloud_storage') {
      setValues({
        ...values,
        [input]: event ? 'enabled' : 'disabled',
      });
    } else if (input === 'read_enable') {
      setValues({
        ...values,
        [input]: event,
      });
    } else if (
      input === 'device_name' &&
      (!event.target.value || nameRegex.test(event.target.value))
    ) {
      setValues({
        ...values,
        [input]: event.target.value,
      });
    } else if (input === 'modbus_rtu_baud_rate') {
      setValues({
        ...values,
        [input]: event.value,
      });
    } else {
      setValues({
        ...values,
        [input]: event.target ? event.target.value : event,
      });
    }
  };

  const onReset = (keys) => {
    const tempValuesObj = { ...values };
    keys.forEach((key) => {
      tempValuesObj[key] = '';
    });
    setValues(tempValuesObj);
  };

  const onResetAll = () => {
    setValues(initialState);
  };

  const onStart = (value) => {
    setValues(value);
  };

  const onSubmit = (event) => {
    callback();
    event.preventDefault();
  };

  return {
    onChange,
    onReset,
    onResetAll,
    onStart,
    onSubmit,
    values,
  };
};
export default useModbusRTUForm;
