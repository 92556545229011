import React, { useState, useCallback, useEffect, useRef, memo } from 'react';
import {
  Box,
  Image,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Tr,
  Td,
  Icon,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { SiIcloud } from 'react-icons/si';
import isEqual from 'lodash/isEqual';
import portalHandleRequest from '../../../../api/portalHandleRequest';
import handleLatestDataObj from '../../../../api/handleLatestDataObj';
import LedsModal from './leds-modal/leds-modal.component';
import LedChannelModal from './led-channel-modal/led-channel-modal.component';
import './leds.styles.scss';

const PORTAL_API_ENDPOINT = process.env.REACT_APP_PORTAL_API;

const LedsModule = ({
  thingID,
  data,
  resetData,
  config,
  getConfig,
  getGrafanaURL,
}) => {
  const [LEDConfig, setLEDConfig] = useState();
  const [updatedChannels, setUpdatedChannels] = useState([]);
  const [latestLedsData, setLatestLedsData] = useState({});
  const [selectedLed, setSelectedLed] = useState();
  const navigate = useNavigate();
  const toast = useToast();
  const updateState = useRef(false);

  const {
    isOpen: isLEDsModalOpen,
    onOpen: onLEDsModalOpen,
    onClose: onLEDsModalClose,
  } = useDisclosure();

  const {
    isOpen: isLEDChannelModalOpen,
    onOpen: onLEDChannelModalOpen,
    onClose: onLEDChannelModalClose,
  } = useDisclosure();

  useEffect(() => {
    if (config && !updateState.current) {
      setLEDConfig(config);
    }
  }, [config]);

  useEffect(() => {
    if (data.length > 0) {
      const res = handleLatestDataObj(data, latestLedsData);
      if (res) {
        if (res.latestObj) {
          setLatestLedsData(res.latestObj);
        } else {
          setUpdatedChannels(res.latestIndex);
          setLatestLedsData(res.originalObject);
        }
      }
    } else {
      setLatestLedsData({});
    }
  }, [data]);

  useEffect(() => {
    if (resetData) {
      setLatestLedsData({});
    }
  }, [resetData]);

  const ledMode = (mode) => {
    let label;
    switch (mode) {
      case 'manual-off':
        label = 'Manual Off';
        break;
      case 'manual-on':
        label = 'Manual On';
        break;
      case 'auto-digital-in':
        label = 'Auto Digital In';
        break;
      case 'auto-digital-out':
        label = 'Auto Digital Out';
        break;
      default:
        label = 'N/A';
    }
    return label;
  };

  const handleGrafanaURL = (PANEL) => {
    getGrafanaURL({ PANEL });
  };

  const handleUpdateConfig = useCallback(async (newConfig) => {
    updateState.current = true;

    const obj = {
      method: 'PATCH',
      url: `${PORTAL_API_ENDPOINT}/shadows/${thingID}`,
      contentType: 'application/json',
      data: {
        state: {
          desired: {
            LED: newConfig,
          },
        },
      },
    };
    const res = await portalHandleRequest(obj, navigate);
    if (res.status === 200) {
      const retry = (fn, retriesLeft = 10, interval = 1000) => {
        return new Promise((resolve, reject) => {
          fn()
            .then((response) => {
              if (!response) {
                // validation failed => invalid config
                toast({
                  title:
                    'Invalid EdgePi config. Please try power cycling your EdgePi or contact tech support',
                  status: 'error',
                  position: 'top',
                  duration: 5000,
                  isClosable: true,
                });
                updateState.current = false;
                onLEDsModalClose();
                onLEDChannelModalClose();
              } else {
                const isLEDMatched = isEqual(
                  response.state.desired.LED,
                  response.state.reported.LED
                );

                if (!isLEDMatched) {
                  console.log(
                    `Retry to get new reported config ... Remaining attempts: ${retriesLeft}`
                  );
                  setTimeout(() => {
                    if (retriesLeft === 1) {
                      console.log('Maximum retries exceeded');
                      toast({
                        title: 'Failed to setup LED configuration',
                        description:
                          'Please try again later or contact us for support',
                        status: 'error',
                        position: 'top',
                        duration: 10000,
                        isClosable: true,
                      });
                      updateState.current = false;
                      onLEDsModalClose();
                      onLEDChannelModalClose();
                      return;
                    }
                    retry(fn, retriesLeft - 1, interval).then(resolve, reject);
                  }, interval);
                } else {
                  console.log('Updated successfully!');
                  toast({
                    title: 'New LED configuration has been setup successfully',
                    status: 'success',
                    position: 'top',
                    duration: 2000,
                    isClosable: true,
                  });
                  updateState.current = false;
                  onLEDsModalClose();
                  onLEDChannelModalClose();
                  resolve();
                }
              }
            })
            .catch((error) => {
              setTimeout(() => {
                if (retriesLeft === 1) {
                  // maximum retries exceeded
                  toast({
                    title: 'Failed to setup LED configuration',
                    description:
                      'Please try again later or contact us for support',
                    status: 'error',
                    position: 'top',
                    duration: 10000,
                    isClosable: true,
                  });
                  reject(error);
                  updateState.current = false;
                  onLEDsModalClose();
                  onLEDChannelModalClose();
                  return;
                }
                retry(fn, retriesLeft - 1, interval).then(resolve, reject);
              }, interval);
            });
        });
      };
      retry(() => getConfig());
    } else {
      toast({
        title: `Failed to update LEDs configuration. Status: ${res.status}`,
        description:
          res.Message || 'Please try again later or contact us for support',
        status: 'error',
        position: 'top',
        duration: 10000,
        isClosable: true,
      });
      onLEDsModalClose();
      onLEDChannelModalClose();
    }
  }, []);

  return (
    <Box className="dashboard-module">
      <div className="dashboard-module--heading">
        <h2>LED&apos;s</h2>
      </div>
      {LEDConfig ? (
        <>
          <TableContainer mt={2} className="dashboard-module--table">
            <Table size="sm">
              <Tbody>
                <Tr>
                  <Td>
                    <span className="led-channel-number">01</span> :{' '}
                    {ledMode(LEDConfig.led_1.mode)}
                  </Td>
                  <Td>
                    {LEDConfig.led_1.data_collection === 'enabled' ? (
                      latestLedsData.led_1 ? (
                        <div
                          className={`led-status ${
                            updatedChannels.length > 0 &&
                            updatedChannels.includes('led_1')
                              ? 'active'
                              : 'paused'
                          }`}
                          data-testid="test-led-1"
                        />
                      ) : (
                        <div className="led-status disabled" />
                      )
                    ) : (
                      <h5>N/A</h5>
                    )}
                  </Td>
                  <Td pr={0}>
                    <div className="led-function">
                      <Tooltip
                        hasArrow
                        label={
                          LEDConfig.led_1.cloud_storage === 'enabled'
                            ? 'Cloud Storage Enabled'
                            : 'Cloud Storage Disabled'
                        }
                        bg="#242424"
                        color="white"
                      >
                        <Box h={6}>
                          <Icon
                            as={SiIcloud}
                            color={
                              LEDConfig.led_1.cloud_storage === 'enabled'
                                ? '#dc2f5c'
                                : '#6f6f6f'
                            }
                            className="cloud-status"
                            w={6}
                            h={6}
                          />
                        </Box>
                      </Tooltip>
                      <Tooltip
                        hasArrow
                        label="Channel Settings"
                        bg="#242424"
                        color="white"
                      >
                        <Image
                          className="setting-btn"
                          boxSize="25px"
                          objectFit="contain"
                          src="/gear.png"
                          alt="settings"
                          mr={2}
                          ml={2}
                          onClick={() => {
                            setSelectedLed('led_1');
                            onLEDChannelModalOpen();
                          }}
                        />
                      </Tooltip>
                      <Tooltip
                        hasArrow
                        label="View Graphs"
                        bg="#242424"
                        color="white"
                      >
                        <Image
                          className="chart-btn"
                          boxSize="20px"
                          objectFit="contain"
                          src="/grafana.png"
                          alt="grafana-chart"
                          onClick={() => handleGrafanaURL('LED')}
                        />
                      </Tooltip>
                    </div>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <span className="led-channel-number">02</span> :{' '}
                    {ledMode(LEDConfig.led_2.mode)}
                  </Td>
                  <Td>
                    {LEDConfig.led_2.data_collection === 'enabled' ? (
                      latestLedsData.led_2 ? (
                        <div
                          className={`led-status ${
                            updatedChannels.length > 0 &&
                            updatedChannels.includes('led_2')
                              ? 'active'
                              : 'paused'
                          }`}
                        />
                      ) : (
                        <div
                          className="led-status disabled"
                          data-testid="test-led-2"
                        />
                      )
                    ) : (
                      <h5>N/A</h5>
                    )}
                  </Td>
                  <Td pr={0}>
                    <div className="led-function">
                      <Tooltip
                        hasArrow
                        label={
                          LEDConfig.led_2.cloud_storage === 'enabled'
                            ? 'Cloud Storage Enabled'
                            : 'Cloud Storage Disabled'
                        }
                        bg="#242424"
                        color="white"
                      >
                        <Box h={6}>
                          <Icon
                            as={SiIcloud}
                            color={
                              LEDConfig.led_2.cloud_storage === 'enabled'
                                ? '#dc2f5c'
                                : '#6f6f6f'
                            }
                            className="cloud-status"
                            w={6}
                            h={6}
                          />
                        </Box>
                      </Tooltip>
                      <Tooltip
                        hasArrow
                        label="Channel Settings"
                        bg="#242424"
                        color="white"
                      >
                        <Image
                          className="setting-btn"
                          boxSize="25px"
                          objectFit="contain"
                          src="/gear.png"
                          alt="settings"
                          mr={2}
                          ml={2}
                          onClick={() => {
                            setSelectedLed('led_2');
                            onLEDChannelModalOpen();
                          }}
                        />
                      </Tooltip>
                      <Tooltip
                        hasArrow
                        label="View Graphs"
                        bg="#242424"
                        color="white"
                      >
                        <Image
                          className="chart-btn"
                          boxSize="20px"
                          objectFit="contain"
                          src="/grafana.png"
                          alt="grafana-chart"
                          onClick={() => handleGrafanaURL('LED')}
                        />
                      </Tooltip>
                    </div>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <span className="led-channel-number">03</span> :{' '}
                    {ledMode(LEDConfig.led_3.mode)}
                  </Td>
                  <Td>
                    {LEDConfig.led_3.data_collection === 'enabled' ? (
                      latestLedsData.led_3 ? (
                        <div
                          className={`led-status ${
                            updatedChannels.length > 0 &&
                            updatedChannels.includes('led_3')
                              ? 'active'
                              : 'paused'
                          }`}
                        />
                      ) : (
                        <div
                          className="led-status disabled"
                          data-testid="test-led-3"
                        />
                      )
                    ) : (
                      <h5>N/A</h5>
                    )}
                  </Td>
                  <Td pr={0}>
                    <div className="led-function">
                      <Tooltip
                        hasArrow
                        label={
                          LEDConfig.led_3.cloud_storage === 'enabled'
                            ? 'Cloud Storage Enabled'
                            : 'Cloud Storage Disabled'
                        }
                        bg="#242424"
                        color="white"
                      >
                        <Box h={6}>
                          <Icon
                            as={SiIcloud}
                            color={
                              LEDConfig.led_3.cloud_storage === 'enabled'
                                ? '#dc2f5c'
                                : '#6f6f6f'
                            }
                            className="cloud-status"
                            w={6}
                            h={6}
                          />
                        </Box>
                      </Tooltip>
                      <Tooltip
                        hasArrow
                        label="Channel Settings"
                        bg="#242424"
                        color="white"
                      >
                        <Image
                          className="setting-btn"
                          boxSize="25px"
                          objectFit="contain"
                          src="/gear.png"
                          alt="settings"
                          mr={2}
                          ml={2}
                          onClick={() => {
                            setSelectedLed('led_3');
                            onLEDChannelModalOpen();
                          }}
                        />
                      </Tooltip>
                      <Tooltip
                        hasArrow
                        label="View Graphs"
                        bg="#242424"
                        color="white"
                      >
                        <Image
                          className="chart-btn"
                          boxSize="20px"
                          objectFit="contain"
                          src="/grafana.png"
                          alt="grafana-chart"
                          onClick={() => handleGrafanaURL('LED')}
                        />
                      </Tooltip>
                    </div>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <span className="led-channel-number">04</span> :{' '}
                    {ledMode(LEDConfig.led_4.mode)}
                  </Td>
                  <Td>
                    {LEDConfig.led_4.data_collection === 'enabled' ? (
                      latestLedsData.led_4 ? (
                        <div
                          className={`led-status ${
                            updatedChannels.length > 0 &&
                            updatedChannels.includes('led_4')
                              ? 'active'
                              : 'paused'
                          }`}
                        />
                      ) : (
                        <div className="led-status disabled" />
                      )
                    ) : (
                      <h5 data-testid="test-led-4">N/A</h5>
                    )}
                  </Td>
                  <Td pr={0}>
                    <div className="led-function">
                      <Tooltip
                        hasArrow
                        label={
                          LEDConfig.led_4.cloud_storage === 'enabled'
                            ? 'Cloud Storage Enabled'
                            : 'Cloud Storage Disabled'
                        }
                        bg="#242424"
                        color="white"
                      >
                        <Box h={6}>
                          <Icon
                            as={SiIcloud}
                            color={
                              LEDConfig.led_4.cloud_storage === 'enabled'
                                ? '#dc2f5c'
                                : '#6f6f6f'
                            }
                            className="cloud-status"
                            w={6}
                            h={6}
                          />
                        </Box>
                      </Tooltip>
                      <Tooltip
                        hasArrow
                        label="Channel Settings"
                        bg="#242424"
                        color="white"
                      >
                        <Image
                          className="setting-btn"
                          boxSize="25px"
                          objectFit="contain"
                          src="/gear.png"
                          alt="settings"
                          mr={2}
                          ml={2}
                          onClick={() => {
                            setSelectedLed('led_4');
                            onLEDChannelModalOpen();
                          }}
                        />
                      </Tooltip>
                      <Tooltip
                        hasArrow
                        label="View Graphs"
                        bg="#242424"
                        color="white"
                      >
                        <Image
                          className="chart-btn"
                          boxSize="20px"
                          objectFit="contain"
                          src="/grafana.png"
                          alt="grafana-chart"
                          onClick={() => handleGrafanaURL('LED')}
                        />
                      </Tooltip>
                    </div>
                  </Td>
                </Tr>

                <Tr>
                  <Td>
                    <span className="led-channel-number">05</span> :{' '}
                    {ledMode(LEDConfig.led_5.mode)}
                  </Td>
                  <Td>
                    {LEDConfig.led_5.data_collection === 'enabled' ? (
                      latestLedsData.led_5 ? (
                        <div
                          className={`led-status ${
                            updatedChannels.length > 0 &&
                            updatedChannels.includes('led_5')
                              ? 'active'
                              : 'paused'
                          }`}
                        />
                      ) : (
                        <div className="led-status disabled" />
                      )
                    ) : (
                      <h5>N/A</h5>
                    )}
                  </Td>
                  <Td pr={0}>
                    <div className="led-function">
                      <Tooltip
                        hasArrow
                        label={
                          LEDConfig.led_5.cloud_storage === 'enabled'
                            ? 'Cloud Storage Enabled'
                            : 'Cloud Storage Disabled'
                        }
                        bg="#242424"
                        color="white"
                      >
                        <Box h={6}>
                          <Icon
                            as={SiIcloud}
                            color={
                              LEDConfig.led_5.cloud_storage === 'enabled'
                                ? '#dc2f5c'
                                : '#6f6f6f'
                            }
                            className="cloud-status"
                            w={6}
                            h={6}
                          />
                        </Box>
                      </Tooltip>
                      <Tooltip
                        hasArrow
                        label="Channel Settings"
                        bg="#242424"
                        color="white"
                      >
                        <Image
                          className="setting-btn"
                          boxSize="25px"
                          objectFit="contain"
                          src="/gear.png"
                          alt="settings"
                          mr={2}
                          ml={2}
                          onClick={() => {
                            setSelectedLed('led_5');
                            onLEDChannelModalOpen();
                          }}
                        />
                      </Tooltip>
                      <Tooltip
                        hasArrow
                        label="View Graphs"
                        bg="#242424"
                        color="white"
                      >
                        <Image
                          className="chart-btn"
                          boxSize="20px"
                          objectFit="contain"
                          src="/grafana.png"
                          alt="grafana-chart"
                          onClick={() => handleGrafanaURL('LED')}
                        />
                      </Tooltip>
                    </div>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <span className="led-channel-number">06</span> :{' '}
                    {ledMode(LEDConfig.led_6.mode)}
                  </Td>
                  <Td>
                    {LEDConfig.led_6.data_collection === 'enabled' ? (
                      latestLedsData.led_6 ? (
                        <div
                          className={`led-status ${
                            updatedChannels.length > 0 &&
                            updatedChannels.includes('led_6')
                              ? 'active'
                              : 'paused'
                          }`}
                        />
                      ) : (
                        <div className="led-status disabled" />
                      )
                    ) : (
                      <h5>N/A</h5>
                    )}
                  </Td>
                  <Td pr={0}>
                    <div className="led-function">
                      <Tooltip
                        hasArrow
                        label={
                          LEDConfig.led_6.cloud_storage === 'enabled'
                            ? 'Cloud Storage Enabled'
                            : 'Cloud Storage Disabled'
                        }
                        bg="#242424"
                        color="white"
                      >
                        <Box h={6}>
                          <Icon
                            as={SiIcloud}
                            color={
                              LEDConfig.led_6.cloud_storage === 'enabled'
                                ? '#dc2f5c'
                                : '#6f6f6f'
                            }
                            className="cloud-status"
                            w={6}
                            h={6}
                          />
                        </Box>
                      </Tooltip>
                      <Tooltip
                        hasArrow
                        label="Channel Settings"
                        bg="#242424"
                        color="white"
                      >
                        <Image
                          className="setting-btn"
                          boxSize="25px"
                          objectFit="contain"
                          src="/gear.png"
                          alt="settings"
                          mr={2}
                          ml={2}
                          onClick={() => {
                            setSelectedLed('led_6');
                            onLEDChannelModalOpen();
                          }}
                        />
                      </Tooltip>
                      <Tooltip
                        hasArrow
                        label="View Graphs"
                        bg="#242424"
                        color="white"
                      >
                        <Image
                          className="chart-btn"
                          boxSize="20px"
                          objectFit="contain"
                          src="/grafana.png"
                          alt="grafana-chart"
                          onClick={() => handleGrafanaURL('LED')}
                        />
                      </Tooltip>
                    </div>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <span className="led-channel-number">07</span> :{' '}
                    {ledMode(LEDConfig.led_7.mode)}
                  </Td>
                  <Td>
                    {LEDConfig.led_7.data_collection === 'enabled' ? (
                      latestLedsData.led_7 ? (
                        <div
                          className={`led-status ${
                            updatedChannels.length > 0 &&
                            updatedChannels.includes('led_7')
                              ? 'active'
                              : 'paused'
                          }`}
                        />
                      ) : (
                        <div className="led-status disabled" />
                      )
                    ) : (
                      <h5>N/A</h5>
                    )}
                  </Td>
                  <Td pr={0}>
                    <div className="led-function">
                      <Tooltip
                        hasArrow
                        label={
                          LEDConfig.led_7.cloud_storage === 'enabled'
                            ? 'Cloud Storage Enabled'
                            : 'Cloud Storage Disabled'
                        }
                        bg="#242424"
                        color="white"
                      >
                        <Box h={6}>
                          <Icon
                            as={SiIcloud}
                            color={
                              LEDConfig.led_7.cloud_storage === 'enabled'
                                ? '#dc2f5c'
                                : '#6f6f6f'
                            }
                            className="cloud-status"
                            w={6}
                            h={6}
                          />
                        </Box>
                      </Tooltip>
                      <Tooltip
                        hasArrow
                        label="Channel Settings"
                        bg="#242424"
                        color="white"
                      >
                        <Image
                          className="setting-btn"
                          boxSize="25px"
                          objectFit="contain"
                          src="/gear.png"
                          alt="settings"
                          mr={2}
                          ml={2}
                          onClick={() => {
                            setSelectedLed('led_7');
                            onLEDChannelModalOpen();
                          }}
                        />
                      </Tooltip>
                      <Tooltip
                        hasArrow
                        label="View Graphs"
                        bg="#242424"
                        color="white"
                      >
                        <Image
                          className="chart-btn"
                          boxSize="20px"
                          objectFit="contain"
                          src="/grafana.png"
                          alt="grafana-chart"
                          onClick={() => handleGrafanaURL('LED')}
                        />
                      </Tooltip>
                    </div>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <span className="led-channel-number">08</span> :{' '}
                    {ledMode(LEDConfig.led_8.mode)}
                  </Td>
                  <Td>
                    {LEDConfig.led_8.data_collection === 'enabled' ? (
                      latestLedsData.led_8 ? (
                        <div
                          className={`led-status ${
                            updatedChannels.length > 0 &&
                            updatedChannels.includes('led_8')
                              ? 'active'
                              : 'paused'
                          }`}
                        />
                      ) : (
                        <div className="led-status disabled" />
                      )
                    ) : (
                      <h5>N/A</h5>
                    )}
                  </Td>
                  <Td pr={0}>
                    <div className="led-function">
                      <Tooltip
                        hasArrow
                        label={
                          LEDConfig.led_8.cloud_storage === 'enabled'
                            ? 'Cloud Storage Enabled'
                            : 'Cloud Storage Disabled'
                        }
                        bg="#242424"
                        color="white"
                      >
                        <Box h={6}>
                          <Icon
                            as={SiIcloud}
                            color={
                              LEDConfig.led_8.cloud_storage === 'enabled'
                                ? '#dc2f5c'
                                : '#6f6f6f'
                            }
                            className="cloud-status"
                            w={6}
                            h={6}
                          />
                        </Box>
                      </Tooltip>
                      <Tooltip
                        hasArrow
                        label="Channel Settings"
                        bg="#242424"
                        color="white"
                      >
                        <Image
                          className="setting-btn"
                          boxSize="25px"
                          objectFit="contain"
                          src="/gear.png"
                          alt="settings"
                          mr={2}
                          ml={2}
                          onClick={() => {
                            setSelectedLed('led_8');
                            onLEDChannelModalOpen();
                          }}
                        />
                      </Tooltip>
                      <Tooltip
                        hasArrow
                        label="View Graphs"
                        bg="#242424"
                        color="white"
                      >
                        <Image
                          className="chart-btn"
                          boxSize="20px"
                          objectFit="contain"
                          src="/grafana.png"
                          alt="grafana-chart"
                          onClick={() => handleGrafanaURL('LED')}
                        />
                      </Tooltip>
                    </div>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>

          <Flex className="dashboard-module--footer" w="100%">
            <Flex p={3} w="100%" alignItems="center" justifyContent="flex-end">
              <Tooltip
                hasArrow
                label="Module Settings"
                bg="#242424"
                color="white"
              >
                <Image
                  boxSize="30px"
                  objectFit="contain"
                  src="/gear.png"
                  alt="settings"
                  className="setting-btn"
                  onClick={() => {
                    onLEDsModalOpen();
                  }}
                />
              </Tooltip>
            </Flex>
          </Flex>
        </>
      ) : (
        <Flex className="dashboard-module--na" mt={8}>
          <h4>Module is not available</h4>
        </Flex>
      )}

      <LedsModal
        isOpen={isLEDsModalOpen}
        onClose={onLEDsModalClose}
        LED={LEDConfig || ''}
        handleUpdateLEDsConfig={handleUpdateConfig}
      />

      <LedChannelModal
        isOpen={isLEDChannelModalOpen}
        onClose={onLEDChannelModalClose}
        setting={LEDConfig || ''}
        selectedLed={selectedLed}
        handleUpdateConfig={handleUpdateConfig}
      />
    </Box>
  );
};

export default memo(LedsModule);
