import React, { useState, useCallback, useEffect, useRef, memo } from 'react';
import {
  Box,
  Flex,
  Image,
  Icon,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { SiIcloud } from 'react-icons/si';
import isEqual from 'lodash/isEqual';
import getObjectWithLatestTimestamp from '../../../../api/getLatestObjWithTimestamp';
import temperatureConverter from '../../../../api/temperatureConverter';
import ThermocoupleModal from './thermocouple-modal/thermocouple-modal.component';
import portalHandleRequest from '../../../../api/portalHandleRequest';
import './thermocouple.styles.scss';

const PORTAL_API_ENDPOINT = process.env.REACT_APP_PORTAL_API;

const Thermocouple = ({
  thingID,
  data,
  resetData,
  config,
  getConfig,
  getGrafanaURL,
}) => {
  const [TCConfig, setTCConfig] = useState();
  const [latestTCData, setLatestTCData] = useState({});
  const [currentScale, setCurrentScale] = useState('C');
  const [currentTemp, setCurrentTemp] = useState('N/A');
  const [isDataStaled, setDataStaled] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const updateState = useRef(false);

  const {
    isOpen: isTCModalOpen,
    onOpen: onTCModalOpen,
    onClose: onTCModalClose,
  } = useDisclosure();

  useEffect(() => {
    if (config && !updateState.current) {
      setTCConfig(config);
    }
  }, [config]);

  useEffect(() => {
    if (data.length > 0) {
      if (Object.keys(latestTCData).length !== 0) {
        // add the current tc data sample to the upcoming data samples array
        data.push(latestTCData);
      }
      // get the sample which has the latest timestamp
      const latestObject = getObjectWithLatestTimestamp(data);

      // set staled data
      if (latestObject.timestamp_ms === latestTCData.timestamp_ms) {
        setDataStaled(true);
      } else {
        setDataStaled(false);
      }
      setLatestTCData(latestObject);

      // convert temp from C to current scale
      const newTemp = temperatureConverter(
        latestTCData.linear_temperature_C,
        currentScale
      );
      if (TCConfig && TCConfig.data_collection === 'enabled') {
        setCurrentTemp(newTemp);
      } else {
        setCurrentTemp('N/A');
      }
    } else if (resetData) {
      setLatestTCData({});
      setCurrentTemp('');
    }
  }, [data]);

  const handleChangeTempScale = (scale) => {
    setCurrentTemp(
      temperatureConverter(latestTCData.linear_temperature_C, scale)
    );
    setCurrentScale(scale);
  };

  const handleUpdateTCConfig = useCallback(async (newConfig) => {
    updateState.current = true;

    const obj = {
      method: 'PATCH',
      url: `${PORTAL_API_ENDPOINT}/shadows/${thingID}`,
      contentType: 'application/json',
      data: {
        state: {
          desired: {
            TC: newConfig,
          },
        },
      },
    };
    const res = await portalHandleRequest(obj, navigate);

    if (res.status === 200) {
      const retry = (fn, retriesLeft = 10, interval = 1000) => {
        return new Promise((resolve, reject) => {
          fn()
            .then((response) => {
              if (!response) {
                // validation failed => invalid config
                toast({
                  title:
                    'Invalid EdgePi config. Please try power cycling your EdgePi or contact tech support',
                  status: 'error',
                  position: 'top',
                  duration: 5000,
                  isClosable: true,
                });
                updateState.current = false;
                onTCModalClose();
              } else {
                const check2ConfigObj = !isEqual(
                  response.state.desired.TC,
                  response.state.reported.TC
                );

                if (check2ConfigObj) {
                  console.log(
                    `Retry to get new reported config ... Remaining attempts: ${retriesLeft}`
                  );
                  setTimeout(() => {
                    if (retriesLeft === 1) {
                      console.log('Maximum retries exceeded');
                      toast({
                        title: 'Failed to setup Thermocouple configuration',
                        description:
                          'Please try again later or contact us for support',
                        status: 'error',
                        position: 'top',
                        duration: 10000,
                        isClosable: true,
                      });
                      updateState.current = false;
                      onTCModalClose();
                      return;
                    }
                    retry(fn, retriesLeft - 1, interval).then(resolve, reject);
                  }, interval);
                } else {
                  console.log('Updated successfully!');
                  toast({
                    title:
                      'New Thermocouple configuration has been setup successfully',
                    status: 'success',
                    position: 'top',
                    duration: 2000,
                    isClosable: true,
                  });
                  updateState.current = false;
                  onTCModalClose();
                  resolve();
                }
              }
            })
            .catch((error) => {
              setTimeout(() => {
                if (retriesLeft === 1) {
                  // maximum retries exceeded
                  toast({
                    title: 'Failed to setup Thermocouple configuration',
                    description:
                      'Please try again later or contact us for support',
                    status: 'error',
                    position: 'top',
                    duration: 10000,
                    isClosable: true,
                  });
                  reject(error);
                  updateState.current = false;
                  onTCModalClose();
                  return;
                }
                retry(fn, retriesLeft - 1, interval).then(resolve, reject);
              }, interval);
            });
        });
      };
      retry(() => getConfig());
    } else {
      toast({
        title: `Failed to update TC configuration. Status: ${res.status}`,
        description:
          res.Message || 'Please try again later or contact us for support',
        status: 'error',
        position: 'top',
        duration: 10000,
        isClosable: true,
      });
      onTCModalClose();
    }
  }, []);

  const handleGrafanaURL = (PANEL) => {
    getGrafanaURL({ PANEL });
  };

  const mapTCTypeValue = (type) => {
    let value;
    switch (type) {
      case 'B':
        value = '250°C to 1820°C';
        break;
      case 'E':
        value = '-200°C to 1000°C';
        break;
      case 'J':
        value = '-210°C to 1200°C';
        break;
      case 'K':
        value = '-200°C to 1372°C';
        break;
      case 'N':
        value = '-200°C to 1300°C';
        break;
      case 'R':
        value = '-50°C to 1768°C';
        break;
      case 'S':
        value = '-50°C to 1768°C';
        break;
      case 'T':
        value = '-200°C to 400°C';
        break;
      default:
        value = 'N/A';
    }
    return value;
  };

  return (
    <Box className="dashboard-module thermocouple-view">
      <div className="dashboard-module--heading">
        <h2>Thermocouple</h2>
        <Icon
          as={SiIcloud}
          color="#dc2f5c"
          className="cloud-status"
          ml={2}
          w={8}
          h={8}
        />
      </div>
      {TCConfig ? (
        <>
          <div className="dashboard-module--content">
            {data && data.length === 0 ? (
              <h1>N/A</h1>
            ) : latestTCData.linear_temperature_C &&
              latestTCData.linear_temperature_C > -273.15 &&
              latestTCData.linear_temperature_C < 2000 ? (
              <>
                <h1
                  className={`tc-data ${isDataStaled ? 'staled-data' : ''}`}
                  data-testid="test-tc-value"
                >
                  {currentTemp}
                  {currentScale !== 'K' ? '°' : ' '}
                  {currentScale}
                </h1>
                <div className="thermo-opts">
                  <div
                    className={`scale-opt ${
                      currentScale === 'C' && 'thermo-opt-active'
                    }`}
                    type="button"
                    onClick={() => handleChangeTempScale('C')}
                  >
                    °C
                  </div>
                  <div
                    className={`scale-opt ${
                      currentScale === 'F' && 'thermo-opt-active'
                    }`}
                    type="button"
                    onClick={() => handleChangeTempScale('F')}
                  >
                    °F
                  </div>
                  <div
                    className={`scale-opt ${
                      currentScale === 'K' && 'thermo-opt-active'
                    }`}
                    type="button"
                    onClick={() => handleChangeTempScale('K')}
                  >
                    K
                  </div>
                </div>
              </>
            ) : (
              <h1>No Probe</h1>
            )}
          </div>

          <Flex className="dashboard-module--footer">
            {TCConfig && TCConfig.probe_type && (
              <Flex p={3} w="80%" alignItems="center">
                <p className="thermal-probe-type" data-testid="test-probe-type">
                  Type {TCConfig.probe_type} :{' '}
                  {mapTCTypeValue(TCConfig.probe_type)}
                </p>
                {/* <p>Cold Junction : ON</p> */}
              </Flex>
            )}

            <Flex p={3} w="20%" alignItems="center" justifyContent="flex-end">
              <Tooltip
                hasArrow
                label="Module Settings"
                bg="#242424"
                color="white"
              >
                <Image
                  className="setting-btn"
                  boxSize="30px"
                  objectFit="contain"
                  src="/gear.png"
                  alt="settings"
                  mr={2}
                  ml={2}
                  onClick={() => onTCModalOpen()}
                />
              </Tooltip>
              <Tooltip hasArrow label="View Graphs" bg="#242424" color="white">
                <Image
                  className="chart-btn"
                  boxSize="25px"
                  objectFit="contain"
                  src="/grafana.png"
                  alt="grafana-chart"
                  onClick={() => handleGrafanaURL('TC')}
                />
              </Tooltip>
            </Flex>
          </Flex>
        </>
      ) : (
        <Flex className="dashboard-module--na" mt={8}>
          <h4>Module is not available</h4>
        </Flex>
      )}

      <ThermocoupleModal
        isOpen={isTCModalOpen}
        onClose={onTCModalClose}
        setting={TCConfig || ''}
        handleUpdateConfig={handleUpdateTCConfig}
      />
    </Box>
  );
};

export default memo(Thermocouple);
