import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Button,
  Flex,
  Container,
  Icon,
  Image,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Stack,
  Spinner,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { BsCheckCircleFill, BsFillXCircleFill } from 'react-icons/bs';
import { IoArrowBackCircleOutline, IoClose } from 'react-icons/io5';
// import QRCodeScanner from './qr-code-scanner/qr-code-scanner.component';
import RegCode from './reg-code/reg-code.component';
import portalHandleRequest from '../../api/portalHandleRequest';
import { refreshProductData } from '../../redux/product/product.actions';
import { CustomButton } from '../../components/custom-button/custom-button.component';
import './add-device.styles.scss';

const PORTAL_API_ENDPOINT = process.env.REACT_APP_PORTAL_API;

const AddDevice = ({
  activeDevices,
  userAccounts,
  refreshProductData,
  refreshData,
}) => {
  const [activePlans, setActivePlans] = useState('');
  const [attachedPlan, setAttachedPlan] = useState('');
  const [attachedPlanName, setAttachedPlanName] = useState('');
  const [selectedDevice, setSelectedDevice] = useState('');
  const [currentRegCode, setRegCode] = useState('');
  const [isPlanComponentOpen, setPlanComponent] = useState(false);
  const [isSummaryComponentOpen, setSummaryComponent] = useState(false);

  const [isAddingDeviceCompleted, setAddingDeviceCompleted] = useState(null);
  const [isAttachToPlanCompleted, setAttachToPlanCompleted] = useState(null);
  const [provisionStatus, setProvisionStatus] = useState('');
  const [isProvisioningCompleted, setProvisioningCompleted] = useState(null);

  const [resetRegInput, setResetRegInput] = useState(false);
  const [regCodeError, setRegCodeError] = useState('');

  const { id } = useParams();

  const [data, setData] = useState({});

  const [isConfirmButtonLoading, setConfirmButtonLoading] = useState(false);
  const [isPlansButtonLoading, setPlansButtonLoading] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const step3 = useRef(null);
  const step4 = useRef(null);
  const pageBottom = useRef(null);

  const scrollToStep3 = () => step3.current.scrollIntoView();
  const scrollToStep4 = () => step4.current.scrollIntoView();
  const scrollToBottom = () => pageBottom.current.scrollIntoView();

  const handlePlan = (plan) => {
    if (plan.assigned_devices.length < plan.max_allowed_devices) {
      setAttachedPlan(plan.plan_id);
      setAttachedPlanName(plan.name);
    } else {
      toast({
        title: 'Can not assign your device to this plan!',
        description: 'No available slot',
        status: 'error',
        position: 'top',
        duration: 10000,
        isClosable: true,
      });
    }
  };
  const handleResult = async (code) => {
    setRegCode(code.toLowerCase());
    setRegCodeError('');
    setSelectedDevice('');
    // Call api to lookup the product info
    const obj = {
      method: 'GET',
      url: `${PORTAL_API_ENDPOINT}/devices/reg-code/${code.toLowerCase()}`,
      contentType: 'application/json',
    };
    const response = await portalHandleRequest(obj, navigate);

    if (response.status === 200) {
      const isAssigned = activeDevices.find(
        (device) => device.reg_code === code
      );
      if (isAssigned) {
        toast({
          title: 'This EdgePi has been assigned to your account already.',
          status: 'error',
          position: 'top',
          duration: 10000,
          isClosable: true,
        });
        setRegCodeError(401);
        return false;
      }
      toast({
        title: 'An EdgePi has been found.',
        status: 'success',
        position: 'top',
        duration: 5000,
        isClosable: true,
      });
      setData(response.data); // save product info to state
      setSelectedDevice(response.data.thing_id);
      setTimeout(() => {
        scrollToBottom();
      }, 200);
      return true;
    }

    if (response.status === 403) {
      toast({
        title: 'Invalid registration code.',
        status: 'error',
        position: 'top',
        duration: 5000,
        isClosable: true,
      });
      setRegCodeError('Invalid registration code.');
      return false;
    }

    toast({
      title: `Failed to get device information.`,
      description: 'Please contact us for support',
      status: 'error',
      position: 'top',
      duration: 10000,
      isClosable: true,
    });
    setRegCodeError('Failed to get device information.');
    return false;
  };

  const onCloseProduct = () => {
    setData('');
    setResetRegInput(!resetRegInput);
  };

  const onPlansContainerOpen = async () => {
    // get all available plans
    if (userAccounts && userAccounts.user_email) {
      setPlansButtonLoading(true);
      setButtonDisabled(true);
      const obj = {
        method: 'GET',
        url: `${PORTAL_API_ENDPOINT}/plans/user-email/${userAccounts.user_email}`,
      };

      const plans = await portalHandleRequest(obj, navigate);
      if (plans.status === 200) {
        if (plans.data.length > 0) {
          setActivePlans(plans.data);

          // check if the device has attached to any plan already
          const attachedPlan = plans.data.find(
            (plan) => plan.plan_id === data.plan_id
          );

          if (attachedPlan) {
            setAttachedPlan(attachedPlan.plan_id);
            setAttachedPlanName(attachedPlan.name);
          } else {
            // set FREE plan as default if device has not attached to any plan yet
            const freePlan = plans.data.find(
              (plan) => plan.name.toLowerCase() === 'free'
            );
            if (
              freePlan &&
              freePlan.assigned_devices.length < freePlan.max_allowed_devices
            ) {
              setAttachedPlan(freePlan.plan_id);
              setAttachedPlanName(freePlan.name);
            }
          }
          setPlanComponent(true);
          setTimeout(() => {
            scrollToStep3();
          }, 500);
        } else {
          toast({
            title: `Your account doesn't have any available plans.`,
            status: 'error',
            position: 'top',
            duration: 10000,
            isClosable: true,
          });
        }
        setPlansButtonLoading(false);
        setButtonDisabled(false);
      } else {
        toast({
          title: `Failed to get plans information.`,
          status: 'error',
          position: 'top',
          duration: 10000,
          isClosable: true,
        });
        console.error(plans);
        setPlansButtonLoading(false);
        setButtonDisabled(false);
      }
    }
  };
  const onSummaryContainerOpen = () => {
    setSummaryComponent(true);
  };

  const handleCreateProvision = async () => {
    setConfirmButtonLoading(true);

    const obj = {
      method: 'POST',
      url: `${PORTAL_API_ENDPOINT}/prov/${userAccounts.user_email}`,
      contentType: 'application/json',
      data: {
        thing_id: data.thing_id,
      },
    };

    const response = await portalHandleRequest(obj, navigate);
    if (response.status === 200) {
      setProvisionStatus('Request to get provisioning sent');
      return true;
    }
    console.error(response);

    return false;
  };

  const getDeviceProvisioning = async () => {
    setProvisionStatus('Getting device provisioning ...');
    const obj = {
      method: 'GET',
      url: `${PORTAL_API_ENDPOINT}/prov/${userAccounts.user_email}`,
    };

    const response = await portalHandleRequest(obj, navigate);

    if (response.status === 200 && response.provisioning_completed) {
      setProvisionStatus('Getting device provision successfully');
      setProvisioningCompleted(true);
      refreshProductData(!refreshData);
      setConfirmButtonLoading(false);
      setButtonDisabled(false);
      setTimeout(() => {
        setProvisionStatus('');
      }, 300);
    } else if (response.status === 200 && !response.provisioning_completed) {
      toast({
        title: 'Failed to get provision status!',
        description: 'Provisioning was not completed',
        status: 'error',
        position: 'top',
        duration: 10000,
        isClosable: true,
      });
      setProvisionStatus('Failed to get provision status');
      setProvisioningCompleted(false);
      refreshProductData(!refreshData);
      setConfirmButtonLoading(false);
      setButtonDisabled(false);
    } else if (response.status === 404) {
      // User does not have device provision => create
      setProvisionStatus('Create device provisioning ...');
      const createProvision = await handleCreateProvision();
      if (createProvision) {
        getDeviceProvisioning(); // start get function again
      } else {
        setProvisionStatus('Service provisioning failed');
        toast({
          title: 'Service provisioning failed',
          status: 'error',
          position: 'top',
          duration: 10000,
          isClosable: true,
        });
        setProvisioningCompleted(false);
        refreshProductData(!refreshData);
        setConfirmButtonLoading(false);
        setButtonDisabled(false);
      }
    } else {
      console.error(response);
      setProvisionStatus('Failed to get provision status');
      toast({
        title: `Failed to get provision status! Status: ${response.status}`,
        status: 'error',
        position: 'top',
        duration: 10000,
        isClosable: true,
      });
      setProvisioningCompleted(false);
      refreshProductData(!refreshData);
      setConfirmButtonLoading(false);
      setButtonDisabled(false);
    }
  };

  const handleAddNewDevice = async () => {
    setConfirmButtonLoading(true);
    setButtonDisabled(true);

    // Check if the device is already in the selected plan
    if (data.plan_id === attachedPlan) {
      toast({
        title: 'Can not add this device to this plan!',
        description: 'You device is already with the selected plan',
        status: 'error',
        position: 'top',
        duration: 10000,
        isClosable: true,
      });
      setConfirmButtonLoading(false);
      setButtonDisabled(false);
      return false;
    }

    // Open Provisioning Modal
    onOpen();

    // check if the device is already assigned to the user
    const isAssigned = activeDevices.find(
      (device) => device.reg_code === currentRegCode
    );

    if (isAssigned) {
      toast({
        title: 'This device has been assigned to your account already!',
        status: 'error',
        position: 'top',
        duration: 10000,
        isClosable: true,
      });
      setAddingDeviceCompleted(false);
      setProvisioningCompleted(false);
      setConfirmButtonLoading(false);
      setButtonDisabled(false);
      return false;
    }

    const deviceObj = {
      user_email: userAccounts.user_email,
      plan_id: attachedPlan,
    };

    const addDeviceOptions = {
      method: 'PATCH',
      url: `${PORTAL_API_ENDPOINT}/devices/${data.thing_id}`,
      contentType: 'application/json',
      data: deviceObj,
    };

    const response = await portalHandleRequest(addDeviceOptions);

    if (response.status === 200) {
      setAddingDeviceCompleted(true);
      setAttachToPlanCompleted(true);
      getDeviceProvisioning(); // run device provisioning process
    } else {
      toast({
        title: 'Failed to add new device!',
        description: 'Please contact us for support',
        status: 'error',
        position: 'top',
        duration: 10000,
        isClosable: true,
      });
      setAddingDeviceCompleted(false);
      setAttachToPlanCompleted(false);
      setProvisioningCompleted(false);
      setConfirmButtonLoading(false);
      setButtonDisabled(false);
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (id) {
      handleResult(id);
    }
  }, [id]);

  return (
    <Box className="add-device-page">
      <Container maxW="full">
        <Flex className="page-header" flexDirection="column">
          <Flex alignItems="center" className="page-header--back-btn">
            <Icon
              as={IoArrowBackCircleOutline}
              w={10}
              h={10}
              mr={4}
              className="add-device-back-btn"
              onClick={() => navigate('/dashboard')}
            />
            <h2>Set up your device and pick your plan.</h2>
          </Flex>
          <p className="page-description">
            To get started, simply add your EdgePi Device using Registration
            Code and then select a plan to attach to your EdgePi.
          </p>
        </Flex>

        <hr className="page-divider" />
        <RegCode
          onResult={handleResult}
          resetInput={resetRegInput}
          errorCode={regCodeError}
        />

        {Object.keys(data).length > 0 && (
          <>
            <div
              onClick={() => {
                setSelectedDevice(data.thing_id);
              }}
              className={`product-info-container ${selectedDevice && 'active'}`}
            >
              <Icon
                onClick={onCloseProduct}
                className="product-info-container--close-btn"
                as={IoClose}
                w={6}
                h={6}
              />
              <Flex alignItems="flex-start" flexDirection="column">
                <Flex alignItems="center">
                  <h2>{data.product_name}</h2>
                  {selectedDevice && (
                    <Icon
                      color="#db2f5c"
                      backgroundColor="#FFFFFF"
                      borderRadius="50%"
                      as={BsCheckCircleFill}
                      w={8}
                      h={8}
                      ml={6}
                    />
                  )}
                </Flex>
                <p className="product-id">ID : {data.thing_id}</p>
                {data.plan_id && <p>Plan ID : {data.plan_id}</p>}
                <p>Serial number : {data.serial_number}</p>
                {/* {data.activation_date && (
                  <p>
                    Activation date :{' '}
                    {new Intl.DateTimeFormat('en-US', {
                      dateStyle: 'long',
                      timeStyle: undefined,
                      timeZone:
                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                    }).format(new Date(data.activation_date * 1000))}
                  </p>
                )} */}
              </Flex>

              <Image
                boxSize="200px"
                objectFit="contain"
                src="/edgepi.png"
                alt="edgepi"
                mt={2}
                mb={2}
              />
            </div>

            <CustomButton
              width="270px"
              variant="custom-btn--red"
              disabled={!selectedDevice || isButtonDisabled}
              isLoading={isPlansButtonLoading}
              onClick={() => {
                onPlansContainerOpen();
              }}
            >
              Continue to Plans
            </CustomButton>
          </>
        )}

        {isPlanComponentOpen && (
          <>
            <hr className="page-divider" />
            <Flex ref={step3} flexDirection="column" mt={12}>
              <div className="add-device-step-heading">
                <h2 className="step-header">Step 02</h2>
                <h2>Attach a Plan</h2>
                <p>Attach the EdgePi to an already purchased plan</p>
              </div>

              {activePlans && activePlans.length > 0 ? (
                activePlans.map((plan) => (
                  <div
                    key={plan.plan_id}
                    className={`add-device-form-options ${
                      attachedPlan === plan.plan_id && 'active'
                    }`}
                    onClick={() => {
                      handlePlan(plan);
                    }}
                  >
                    <div className="add-device-form-options--textbox">
                      <h2>
                        {plan.name}
                        {attachedPlan === plan.plan_id && (
                          <Icon
                            color="#db2f5c"
                            backgroundColor="#FFFFFF"
                            borderRadius="50%"
                            as={BsCheckCircleFill}
                            w={8}
                            h={8}
                            ml={4}
                          />
                        )}
                      </h2>
                      <p className="device-id">
                        <span>ID:</span> {plan.plan_id}
                      </p>

                      <p>
                        Number of assigned devices :{' '}
                        {plan.assigned_devices.length}
                      </p>
                      <p>Max allowed devices : {plan.max_allowed_devices}</p>
                    </div>
                    <Flex flexDirection="column" alignItems="center">
                      <Image
                        w="200px"
                        objectFit="contain"
                        src="/edgepi.svg"
                        alt="edgepi-icon"
                        m={2}
                      />
                    </Flex>
                  </div>
                ))
              ) : (
                <h4>
                  You do not have any active plans. Please subscribe a plan at
                  the{' '}
                  <a
                    href={`${process.env.REACT_APP_STORE_URL}/plans`}
                    className="store-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    store
                  </a>{' '}
                  website
                </h4>
              )}
              <Box mt={8}>
                <CustomButton
                  width="270px"
                  variant="custom-btn--red"
                  disabled={!attachedPlan}
                  onClick={() => {
                    onSummaryContainerOpen();
                    setTimeout(() => {
                      scrollToStep4();
                    }, 500);
                  }}
                >
                  Continue to Summary
                </CustomButton>
              </Box>
            </Flex>
          </>
        )}

        {isSummaryComponentOpen && (
          <>
            <hr className="page-divider" />
            <Flex
              ref={step4}
              className="summary-container"
              flexDirection="column"
              mt={12}
            >
              <div className="add-device-step-heading">
                <h2 className="step-header">Step 03</h2>
                <h2>Summary</h2>
                <p>Please Review your Selections.</p>
              </div>
              <Stack direction={['column']} spacing="20px">
                <Box mb={2}>
                  <h3>Device Added :</h3>
                  <Flex alignItems="center">
                    <Image
                      boxSize="120px"
                      objectFit="contain"
                      src="/edgepi.png"
                      alt="edgepi"
                      marginRight={2}
                      mt={6}
                    />
                    <Flex flexDirection="column">
                      <h4>{data.product_name}</h4>
                      <p>Serial number : {data.serial_number}</p>
                    </Flex>
                  </Flex>
                </Box>

                <Box mt={2}>
                  <h3>Added to Plan :</h3>
                  <Flex alignItems="center">
                    <Image
                      boxSize="120px"
                      objectFit="contain"
                      src="/edgepi.svg"
                      alt="edgepi-icon"
                      marginRight={2}
                    />
                    <Flex flexDirection="column">
                      <h4>{attachedPlanName}</h4>
                      <p>ID: {attachedPlan}</p>
                    </Flex>
                  </Flex>
                </Box>
              </Stack>
              <Flex justifyContent="center" mt={4} mb={8}>
                <CustomButton
                  width="270px"
                  variant="custom-btn--red"
                  disabled={!attachedPlan}
                  isLoading={isConfirmButtonLoading}
                  onClick={handleAddNewDevice}
                >
                  Confirm
                </CustomButton>
              </Flex>
            </Flex>
          </>
        )}
        <div ref={pageBottom} />
      </Container>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        onCloseComplete={() => {
          setAddingDeviceCompleted('loading'); // reset UI
          setAttachToPlanCompleted('loading');
          setProvisioningCompleted('loading');
        }}
        size="3xl"
        className="provision-modal"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Provisioning</ModalHeader>
          <ModalBody p={6}>
            <Stack>
              <Flex alignItems="center">
                <p className="provisioning-step">Adding Device ... </p>
                {isAddingDeviceCompleted === null ? (
                  <Spinner ml={4} color="#db2f5c" />
                ) : isAddingDeviceCompleted ? (
                  <Icon
                    color="#db2f5c"
                    backgroundColor="#FFFFFF"
                    borderRadius="50%"
                    as={BsCheckCircleFill}
                    w={6}
                    h={6}
                    ml={4}
                  />
                ) : (
                  <Icon as={BsFillXCircleFill} w={6} h={6} ml={4} />
                )}
              </Flex>
              {isAddingDeviceCompleted && (
                <Flex alignItems="center">
                  <p className="provisioning-step">Attaching plan ... </p>
                  {isAttachToPlanCompleted === null ? (
                    <Spinner ml={4} color="#db2f5c" />
                  ) : isAttachToPlanCompleted ? (
                    <Icon
                      color="#db2f5c"
                      backgroundColor="#FFFFFF"
                      borderRadius="50%"
                      as={BsCheckCircleFill}
                      w={6}
                      h={6}
                      ml={4}
                    />
                  ) : (
                    <Icon as={BsFillXCircleFill} w={6} h={6} ml={4} />
                  )}
                </Flex>
              )}

              {isAttachToPlanCompleted && (
                <Flex flexDirection="column">
                  <Flex alignItems="center">
                    <p className="provisioning-step">
                      Provisioning Cloud Services ...
                    </p>
                    {isProvisioningCompleted === null ? (
                      <Spinner ml={4} color="#db2f5c" />
                    ) : isProvisioningCompleted ? (
                      <Icon
                        color="#db2f5c"
                        backgroundColor="#FFFFFF"
                        borderRadius="50%"
                        as={BsCheckCircleFill}
                        w={6}
                        h={6}
                        ml={4}
                      />
                    ) : (
                      <Icon as={BsFillXCircleFill} w={6} h={6} ml={4} />
                    )}
                  </Flex>
                  <p className="provisioning-status">{provisionStatus}</p>
                </Flex>
              )}
              {isProvisioningCompleted && (
                <Flex flexDirection="column">
                  <h2 className="provisioning-completed">
                    Device added to account 🎉
                  </h2>
                  <p className="provisioning-status">
                    Return to Dashboard to view your newly added device.
                  </p>
                  <p className="provisioning-status">
                    Device ID : <span>{data.thing_id}</span>
                  </p>
                </Flex>
              )}
            </Stack>
          </ModalBody>
          <ModalFooter>
            {isProvisioningCompleted && (
              <Flex justifyContent="center" flexWrap="wrap">
                <Button
                  className="btn-group"
                  bg="#535355"
                  color="#FFF"
                  boxShadow="0px 15px 30px rgba(0, 0, 0, 0.15)"
                  variant="solid"
                  w={270}
                  mr={2}
                  isDisabled={isButtonDisabled}
                  onClick={() => {
                    onClose();
                    navigate(0);
                  }}
                  _hover={{ bg: '#242424' }}
                >
                  Add another Device
                </Button>

                <Button
                  className="btn-group"
                  bg="#db2f5c"
                  color="#FFF"
                  boxShadow="0px 15px 30px rgba(0, 0, 0, 0.15)"
                  variant="solid"
                  w={270}
                  isDisabled={isButtonDisabled}
                  onClick={() => {
                    onClose();
                    navigate(`/dashboard/${data.thing_id}`);
                  }}
                  _hover={{ bg: '#bd214b' }}
                >
                  View Device on Dashboard
                </Button>
              </Flex>
            )}

            {isProvisioningCompleted === false && (
              <Flex
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <h2 className="provisioning-completed">
                  Failed to add this device. Please contact us for support.
                </h2>
                <Button
                  className="btn-group"
                  bg="#535355"
                  color="#FFF"
                  boxShadow="0px 15px 30px rgba(0, 0, 0, 0.15)"
                  variant="solid"
                  w={270}
                  mt={4}
                  isDisabled={isButtonDisabled}
                  onClick={() => {
                    onClose();
                    navigate(0);
                  }}
                  _hover={{ bg: '#242424' }}
                >
                  Close
                </Button>
              </Flex>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  currentSubscriptions: state.user.currentSubscriptions,
  userAccounts: state.user.accounts,
  activeDevices: state.user.activeDevices,
  refreshData: state.product.refreshData,
});

const mapDispatchToProps = (dispatch) => ({
  refreshProductData: (bool) => dispatch(refreshProductData(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddDevice);
