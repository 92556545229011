import React, { useEffect, useState, memo } from 'react';
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
} from '@chakra-ui/react';
import CustomSwitch from '../../../../../components/custom-switch/custom-switch.component';

const SystemPanelModal = ({ isOpen, onClose, SYS, handleUpdateSYSConfig }) => {
  const [SYSConfig, setSYSConfig] = useState({});
  const [isButtonLoading, setButtonLoading] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    if (SYS) {
      setSYSConfig(SYS);
    }
  }, [SYS]);

  const handleSYSConfig = (prop, value) => {
    const updatedSYSConfig = structuredClone(SYSConfig);
    if (typeof value === 'boolean') {
      updatedSYSConfig[prop] = value ? 'enabled' : 'disabled';
    } else {
      updatedSYSConfig[prop] = value;
    }
    setSYSConfig(updatedSYSConfig);
  };

  const handleConfirmConfig = () => {
    setButtonLoading(true);
    setButtonDisabled(true);
    handleUpdateSYSConfig(SYSConfig);
  };

  const handleOnCloseModal = () => {
    setSYSConfig(SYS); // Reset all on cancel;
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
      className="system-modal"
      onCloseComplete={() => {
        setButtonLoading(false);
        setButtonDisabled(false);
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Systems Panel</ModalHeader>
        <ModalCloseButton onClick={handleOnCloseModal} />
        <ModalBody p={6} className="dashboard-setting-modal">
          <Box className="adc-modal--box">
            <Flex alignItems="center" mb={2} flexWrap="wrap">
              <h3>Data Collection :</h3>
              <CustomSwitch
                id="system-bar"
                variant="lite"
                onSwitchChange={(e) => handleSYSConfig('data_collection', e)}
                isChecked={
                  Object.keys(SYSConfig).length > 0 &&
                  SYSConfig.data_collection === 'enabled'
                }
              />
            </Flex>
            <p>
              Data collection begins the collection of data from this module.
              Its reading will be subsequently shown on your Dashboard.
            </p>
          </Box>
          <Box className="adc-modal--box">
            <Flex alignItems="center" mb={2} flexWrap="wrap">
              <h3>Read Interval :</h3>
              <Select
                bg="#535355"
                borderColor="#535355"
                color="white"
                w={200}
                ml={2}
                defaultValue={
                  Object.keys(SYSConfig).length > 0 &&
                  SYSConfig.read_interval_ms
                }
                onChange={(e) =>
                  handleSYSConfig(
                    'read_interval_ms',
                    parseInt(e.target.value, 10)
                  )
                }
              >
                <option value={1000}>1 second</option>
                <option value={2000}>2 seconds</option>
                <option value={3000}>3 seconds</option>
                <option value={5000}>5 seconds</option>
                <option value={10000}>10 seconds</option>
                <option value={15000}>15 seconds</option>
                <option value={30000}>30 seconds</option>
                <option value={60000}>60 seconds</option>
              </Select>
            </Flex>
            <p>Data collection read interval for System Panel</p>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button
            className="btn-group"
            bg="#535355"
            color="#FFF"
            boxShadow="0px 15px 30px rgba(0, 0, 0, 0.15)"
            variant="solid"
            w={150}
            mr={2}
            onClick={handleOnCloseModal}
            _hover={{ bg: '#242424' }}
            isDisabled={isButtonDisabled}
          >
            Cancel
          </Button>

          <Button
            className="btn-group"
            bg="#DB2F5CCC"
            color="#FFF"
            variant="solid"
            w={150}
            onClick={handleConfirmConfig}
            isLoading={isButtonLoading}
            _hover={{ bg: '#db2f5d99' }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default memo(SystemPanelModal);
