import React, { useState, useEffect, memo } from 'react';
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
} from '@chakra-ui/react';
import CustomSwitch from '../../../../../components/custom-switch/custom-switch.component';

const RelayModal = ({ isOpen, onClose, setting, handleUpdateConfig }) => {
  const [RelayConfig, setRelayConfig] = useState({});
  const [isButtonLoading, setButtonLoading] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    if (setting) {
      setRelayConfig(setting);
    }
  }, [setting]);

  const handleRelayConfig = (prop, value) => {
    const updatedRelayConfig = structuredClone(RelayConfig);
    if (typeof value === 'boolean') {
      updatedRelayConfig[prop] = value ? 'enabled' : 'disabled';
    } else {
      updatedRelayConfig[prop] = value;
    }
    setRelayConfig(updatedRelayConfig);
  };

  const handleConfirmConfig = () => {
    setButtonLoading(true);
    setButtonDisabled(true);
    handleUpdateConfig(RelayConfig);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
      className="thermocouple-modal"
      closeOnOverlayClick={false}
      onCloseComplete={() => {
        setButtonLoading(false);
        setButtonDisabled(false);
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Relay</ModalHeader>
        <ModalCloseButton isDisabled={isButtonLoading} />

        <ModalBody p={6}>
          <Box className="operating-menu">
            <Box mb={4}>
              <Flex alignItems="center" className="switch-box" flexWrap="wrap">
                <h4>Data Collection : </h4>

                <CustomSwitch
                  id="relay-data-collection"
                  onSwitchChange={(e) =>
                    handleRelayConfig('data_collection', e)
                  }
                  variant="lite"
                  isChecked={
                    Object.keys(RelayConfig).length > 0 &&
                    RelayConfig.data_collection === 'enabled'
                  }
                  isDisabled={isButtonLoading}
                />
              </Flex>
              <p>
                Data collection begins the collection of data from this module.
                Its reading will be subsequently shown on your Dashboard.
              </p>
            </Box>
            <Box mb={4}>
              <Flex alignItems="center" className="switch-box" flexWrap="wrap">
                <h4>Cloud Storage : </h4>
                <CustomSwitch
                  id="relay-cloud-storage"
                  onSwitchChange={(e) => handleRelayConfig('cloud_storage', e)}
                  variant="lite"
                  isChecked={
                    Object.keys(RelayConfig).length > 0 &&
                    RelayConfig.cloud_storage === 'enabled'
                  }
                  isDisabled
                />
              </Flex>
              <p>
                Cloud Storage will begin the storing of data. This data will be
                made available to you through our data visualization tool.
              </p>
            </Box>

            <Box mb={4}>
              <h4>Read Interval :</h4>
              <p>
                Data collection read interval for <strong>Relay</strong>.
              </p>
              <Select
                bg="#535355"
                borderColor="#535355"
                color="white"
                w={200}
                mt={2}
                defaultValue={
                  Object.keys(RelayConfig).length > 0 &&
                  RelayConfig.read_interval_ms
                }
                onChange={(e) =>
                  handleRelayConfig(
                    'read_interval_ms',
                    parseInt(e.target.value, 10)
                  )
                }
                isDisabled={isButtonLoading}
              >
                <option value={1000}>1 second</option>
                <option value={2000}>2 seconds</option>
                <option value={3000}>3 seconds</option>
                <option value={5000}>5 seconds</option>
                <option value={10000}>10 seconds</option>
                <option value={15000}>15 seconds</option>
                <option value={30000}>30 seconds</option>
                <option value={60000}>60 seconds</option>
              </Select>
            </Box>

            <Box mb={4}>
              <h4>State :</h4>
              <Select
                bg="#535355"
                borderColor="#535355"
                color="white"
                w={200}
                mt={2}
                defaultValue={
                  Object.keys(RelayConfig).length > 0 && RelayConfig.state
                }
                onChange={(e) => handleRelayConfig('state', e.target.value)}
                isDisabled={isButtonLoading}
              >
                <option value="open">Open</option>
                <option value="closed">Closed</option>
              </Select>
            </Box>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button
            className="btn-group"
            bg="#535355"
            color="#FFF"
            boxShadow="0px 15px 30px rgba(0, 0, 0, 0.15)"
            variant="solid"
            w={150}
            mr={2}
            isDisabled={isButtonDisabled}
            onClick={onClose}
            _hover={{ bg: '#242424' }}
          >
            Cancel
          </Button>

          <Button
            className="btn-group"
            bg="#DB2F5CCC"
            color="#FFF"
            variant="solid"
            w={150}
            isLoading={isButtonLoading}
            isDisabled={isButtonDisabled}
            onClick={handleConfirmConfig}
            _hover={{ bg: '#db2f5d99' }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default memo(RelayModal);
