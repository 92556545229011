import React, { useState, memo } from 'react';
import {
  Box,
  Icon,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react';
import {
  Pagination,
  usePagination,
  PaginationNext,
  PaginationPage,
  PaginationPrevious,
  PaginationContainer,
  PaginationPageGroup,
} from '@ajna/pagination';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import FTXChannelList from './FTXChannelList';
import FTXSingleChannelView from './FTXSingleChannelView';
import mapDeviceName from '../../aux-device-view/mapDeviceName';

const FTXView = ({ latestAUXData, AUXConfig, ModbusConfig }) => {
  const [isChannelBoxOpen, setChannelBoxOpen] = useState(false);
  const [currentChannel, setCurrentChannel] = useState(0);
  const itemLimit = 5;
  const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({
    pagesCount: Math.ceil(latestAUXData.length / itemLimit),
    initialState: { currentPage: 1 },
  });
  const handleOpenChannel = (channel, index) => {
    if (channel) {
      setChannelBoxOpen(true);
      setCurrentChannel(index);
    } else {
      setChannelBoxOpen(false);
      setCurrentChannel(0);
    }
  };
  return isChannelBoxOpen ? (
    <FTXSingleChannelView
      data={latestAUXData}
      handleOpenChannel={handleOpenChannel}
      mappingList={AUXConfig.mappings}
      selectedChannel={currentChannel}
    />
  ) : (
    <TableContainer
      className={`aux-table ${isChannelBoxOpen && 'aux-table-hide'}`}
      p={2}
      pt={6}
      overflowY="auto"
    >
      <Table variant="simple">
        <Thead position="sticky" top={0} bgColor="#3a3a3c">
          <Tr h="40px">
            <Th>Channel</Th>
            <Th>Temp</Th>
            <Th>LED PWR</Th>
            <Th>Status</Th>
          </Tr>
        </Thead>
        <Tbody>
          {latestAUXData.length > 0 ? (
            <FTXChannelList
              currentPage={currentPage}
              data={latestAUXData}
              itemLimit={itemLimit}
              handleOpenChannel={handleOpenChannel}
              mappingList={AUXConfig.mappings}
              ModbusConfig={ModbusConfig}
            />
          ) : (
            AUXConfig &&
            ModbusConfig.channels.map((channel, index) => (
              <Tr
                key={index}
                className="channel-row"
                onClick={() => handleOpenChannel(channel, index)}
              >
                <Td w={40}>{mapDeviceName(channel, AUXConfig.mappings)}</Td>
                <Td w={40} className="device-data">
                  N/A
                </Td>
                <Td w={40} className="device-data">
                  N/A
                </Td>
                <Td w={40} className="device-data">
                  N/A
                </Td>
              </Tr>
            ))
          )}
        </Tbody>
      </Table>
      {pagesCount > 1 && (
        <Box className="table-pagination">
          <Pagination
            onPageChange={setCurrentPage}
            pagesCount={pagesCount}
            currentPage={currentPage}
          >
            <PaginationContainer>
              <PaginationPrevious>
                <Icon as={FiChevronLeft} w={4} h={4} />
              </PaginationPrevious>
              <PaginationPageGroup>
                {pages.map((page) => (
                  <PaginationPage key={`paginator_page_${page}`} page={page} />
                ))}
              </PaginationPageGroup>
              <PaginationNext>
                <Icon as={FiChevronRight} w={4} h={4} />
              </PaginationNext>
            </PaginationContainer>
          </Pagination>
        </Box>
      )}
    </TableContainer>
  );
};

export default memo(FTXView);
