import React, { useState, useEffect, memo } from 'react';
import { Tr, Td } from '@chakra-ui/react';
import mapFTXStatus from '../../aux-device-view/mapFTXStatus';
import mapDeviceName from '../../aux-device-view/mapDeviceName';
import formatDecimal from '../../aux-device-view/formatDecimal';

const FTXChannelList = ({
  data,
  currentPage,
  mappingList,
  itemLimit,
  handleOpenChannel,
  ModbusConfig,
}) => {
  const [currentData, setCurrentData] = useState([]);
  const [isDataStaled, setDataStaled] = useState(false);

  useEffect(() => {
    const startIndex = (currentPage - 1) * itemLimit;
    const endIndex = startIndex + itemLimit;

    const getList = () => {
      const newData = data.slice(startIndex, endIndex);
      if (
        currentData.length > 0 &&
        currentData[0].timestamp_ms > newData[0].timestamp_ms
      ) {
        setDataStaled(true);
      } else {
        setDataStaled(false);
      }
      // Filter data from the selected channels
      const filteredArray = newData.filter((item) => {
        const channelSuffix = item.channel.split('_').pop();
        return ModbusConfig.channels.includes(channelSuffix);
      });

      setCurrentData(filteredArray);
    };

    getList();
  }, [currentPage, itemLimit, data, ModbusConfig]);

  return currentData.map((item, index) => (
    <Tr
      className="channel-row"
      key={index}
      onClick={() => handleOpenChannel(item.channel, index)}
    >
      <Td>{mapDeviceName(item.channel, mappingList)}</Td>
      <Td className={`device-data ${isDataStaled ? 'staled-data' : ''}`}>
        {item.temperature_c
          ? `${formatDecimal(item.temperature_c, 2)}°C`
          : '--'}
      </Td>
      <Td className={`device-data ${isDataStaled ? 'staled-data' : ''}`}>
        {item.led_power_perc ? `${item.led_power_perc}%` : '--'}
      </Td>
      <Td className={`device-data ${isDataStaled ? 'staled-data' : ''}`}>
        {mapFTXStatus(item.status) ?? '--'}
      </Td>
    </Tr>
  ));
};

export default memo(FTXChannelList);
