const temperatureConverter = (temp, toUnit) => {
  let convertedTemp;
  if (!temp) {
    return 'N/A';
  }

  if (toUnit === 'C') {
    return temp.toFixed(2);
  }

  if (toUnit === 'F') {
    // Celsius to Fahrenheit conversion
    convertedTemp = (temp * 9) / 5 + 32;

    return convertedTemp.toFixed(2);
  }
  if (toUnit === 'K') {
    // Celsius to Kelvin conversion
    convertedTemp = temp + 273.15;
    return convertedTemp.toFixed(2);
  }
  return console.error('Invalid unit.', temp, toUnit);
};

export default temperatureConverter;
