import React from 'react';
import './custom-switch.styles.scss';

const CustomSwitch = ({
  id,
  onSwitchChange,
  variant,
  isChecked,
  defaultChecked,
  isDisabled,
}) => {
  const handleOnChange = (val) => {
    onSwitchChange(val.target.checked);
  };
  return (
    <div className="custom-toggle modal-switch">
      <input
        checked={isChecked}
        defaultChecked={defaultChecked}
        id={id}
        type="checkbox"
        onChange={handleOnChange}
        disabled={isDisabled}
      />
      <label htmlFor={id}>
        <div
          className="custom-toggle__switch"
          data-checked={variant === 'lite' ? 'On' : 'Enabled'}
          data-unchecked={variant === 'lite' ? 'Off' : 'Disabled'}
        />
      </label>
    </div>
  );
};
export default CustomSwitch;
