import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, Container, Flex } from '@chakra-ui/react';
import PlanCard from './plan-card.component';
import NoPlan from './no-plan/no-plan.component';
import './plans.styles.scss';

const Plans = ({ plans }) => (
  <Box p={4} className="plans-page">
    <Container maxW="full" p={0} className="plans-page--container">
      {plans && plans.length > 0 ? (
        <Flex flexDirection="column" alignItems="flex-start">
          <h1 className="plans-page--heading">Plans</h1>
          <Flex flexWrap="wrap" flex="1" className="plans-page--wrapper">
            {plans.map((plan) => (
              <Link
                to={`/plan/${plan.plan_id}`}
                key={plan.plan_id}
                className="plan-link"
              >
                <PlanCard key={plan.plan_id} data={plan} />
              </Link>
            ))}
          </Flex>
        </Flex>
      ) : (
        <NoPlan />
      )}
    </Container>
  </Box>
);

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  plans: state.product.activePlans,
});

export default connect(mapStateToProps, null)(Plans);
