const getAvailableModules = (obj) => {
  const nonEmptyKeys = [];

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      nonEmptyKeys.push(key);
    }
  }
  return nonEmptyKeys;
};

export default getAvailableModules;
