import { useIdleTimer } from 'react-idle-timer';

const useIdleTimeOut = ({ onIdle, idleTime }) => {
  const idleTimeout = 1000 * idleTime;

  const idleTimer = useIdleTimer({
    timeout: idleTimeout,
    promptBeforeIdle: 1000 * 30, // A modal will appear 30 seconds before entering an idle state.
    onPrompt: onIdle,
    throttle: 500,
  });

  let isUserIdle = false;

  if (idleTimer.isIdle) {
    isUserIdle = true;
  }
  return {
    idleTimer,
    isUserIdle,
  };
};

export default useIdleTimeOut;
