const mapPDXStatus = (statusCode) => {
  let status;
  switch (statusCode) {
    case 1:
      status = 'GOOD';
      break;
    case 2:
      status = 'RF FAILURE';
      break;
    case 3:
      status = 'SYNC ERROR';
      break;
    case 4:
      status = 'OTHER';
      break;
    default:
      status = 'INVALID';
  }
  return status;
};

export default mapPDXStatus;
