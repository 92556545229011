const checkAUXDesiredConfigSize = (currentAUXDesiredConfig, newAUXObj) => {
  // Convert the current aux desired shadow and new aux object to a JSON string to measure their byte size
  const currentAUXDesiredString = JSON.stringify(currentAUXDesiredConfig);
  const newAUXString = JSON.stringify(newAUXObj);

  // Calculate the size of the current aux desired obj
  const currentAUXDesiredObjByteSize = new Blob([currentAUXDesiredString]).size;

  // Calculate the byte size of the new aux string
  const newAUXByteSize = new Blob([newAUXString]).size;

  // Calculate 45% of max config size
  const maxAUXDesiredObjSize =
    0.45 * parseInt(process.env.REACT_APP_MAX_CONFIG_SIZE, 10);

  // Check if the total byte size of the current desired object and the new aux object is less than 45% of max config size
  return currentAUXDesiredObjByteSize + newAUXByteSize <= maxAUXDesiredObjSize;
};

export default checkAUXDesiredConfigSize;
