/* eslint-disable no-unreachable */
import React, { useState, useCallback, useEffect, memo } from 'react';
import {
  Box,
  Button,
  Flex,
  Image,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';

import { SiIcloud } from 'react-icons/si';
import { useNavigate } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import portalHandleRequest from '../../../../../api/portalHandleRequest';
import handleLatestAUXChannelData from '../../../../../api/handleLatestAUXChannelData';
import getLatestAttributeValue from '../protocol/modbus-rtu/functions/getLatestAttributeValue';
import FTXView from '../protocol/modbus-ftx/FTXView';
import PDXView from '../protocol/modbus-pdx/PDXView';
import ModbusRTUView from '../protocol/modbus-rtu/ModbusRTUView';
import FTXConfigModal from '../protocol/modbus-ftx/FTXConfigModal';
import PDXConfigModal from '../protocol/modbus-pdx/PDXConfigModal';
import ModbusRTUConfigModal from '../protocol/modbus-rtu/ModbusRTUConfigModal';
import './aux-device-view.styles.scss';

const PORTAL_API_ENDPOINT = process.env.REACT_APP_PORTAL_API;

const AUXDeviceView = ({
  thingID,
  deviceID,
  config,
  data,
  getConfig,
  getGrafanaURL,
}) => {
  const [latestAUXData, setLatestAUXData] = useState([]);
  const [AUXConfig, setAUXConfig] = useState();
  const [ModbusConfig, setModbusConfig] = useState({});
  const [isLoading, setButtonLoading] = useState(false);
  const [isDisabled, setButtonDisabled] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  const {
    isOpen: isAUXDeviceModalOpen,
    onOpen: onAUXDeviceModalOpen,
    onClose: onAUXDeviceModalClose,
  } = useDisclosure();

  const {
    isOpen: isDeleteAUXDeviceModalOpen,
    onOpen: onDeleteAUXDeviceModalOpen,
    onClose: onDeleteAUXDeviceModalClose,
  } = useDisclosure();

  useEffect(() => {
    // Setting Modbus and AUX configurations
    if (config) {
      if (config.protocol === 'modbus_rtu') {
        setModbusConfig(config.modbus_params);
      } else {
        setModbusConfig(config[config.protocol]);
      }

      setAUXConfig(config);
    }
  }, [config]);

  useEffect(() => {
    const updateLatestAUXData = (samples) => {
      if (samples && samples.length > 0) {
        const latestSamples = handleLatestAUXChannelData(
          latestAUXData,
          samples,
          ModbusConfig.channels
        );
        setLatestAUXData(latestSamples);
      }
    };

    // Handling different protocols and updating AUX data
    if (config.protocol === 'modbus_ftx') {
      updateLatestAUXData(data?.ftx_samples);
    } else if (config.protocol === 'modbus_pdx') {
      updateLatestAUXData(data?.pdx_samples);
    } else if (config.protocol === 'modbus_rtu') {
      if (data) {
        const latestSamples = getLatestAttributeValue(
          latestAUXData,
          data.generic_read_properties
        );
        latestSamples.sort((a, b) =>
          a.property_name.localeCompare(b.property_name)
        );
        setLatestAUXData(latestSamples);
      }
    }
  }, [data]);

  const getDashboardType = (protocol) => {
    if (protocol === 'modbus_ftx') {
      return 'edgepi_ftx';
    }
    if (protocol === 'modbus_pdx') {
      return 'edgepi_pdx';
    }
    if (protocol === 'modbus_rtu') {
      return 'edgepi_generic';
    }
    return null;
  };
  const handleUpdateConfig = useCallback(async (AUXDeviceID, newConfig) => {
    setButtonLoading(true);
    setButtonDisabled(true);
    const obj = {
      method: 'PATCH',
      url: `${PORTAL_API_ENDPOINT}/shadows/${thingID}`,
      contentType: 'application/json',
      data: {
        state: {
          desired: {
            AUX: {
              [AUXDeviceID]: newConfig,
            },
          },
        },
      },
    };
    const res = await portalHandleRequest(obj, navigate);
    if (res.status === 200) {
      const retry = (fn, retriesLeft = 10, interval = 1000) => {
        return new Promise((resolve, reject) => {
          fn()
            .then((response) => {
              if (!response) {
                // validation failed => insvalid config
                toast({
                  title:
                    'Invalid EdgePi config. Please try power cycling your EdgePi or contact tech support',
                  status: 'error',
                  position: 'top',
                  duration: 5000,
                  isClosable: true,
                });
                setButtonLoading(false);
                setButtonDisabled(false);
                onAUXDeviceModalClose();
              } else {
                const isAUXMatched = isEqual(
                  response.state.desired.AUX,
                  response.state.reported.AUX
                );

                if (!isAUXMatched) {
                  console.log(
                    `Retry to get new reported config ... Remaining attempts: ${retriesLeft}`
                  );
                  setTimeout(() => {
                    if (retriesLeft === 1) {
                      console.log('Maximum retries exceeded');
                      if (!newConfig) {
                        toast({
                          title: 'Failed to delete AUX this device',
                          description:
                            'Please try again later or contact us for support',
                          status: 'error',
                          position: 'top',
                          duration: 10000,
                          isClosable: true,
                        });
                      } else {
                        toast({
                          title: 'Failed to setup AUX configuration',
                          description:
                            'Please try again later or contact us for support',
                          status: 'error',
                          position: 'top',
                          duration: 10000,
                          isClosable: true,
                        });
                      }

                      setButtonLoading(false);
                      setButtonDisabled(false);
                      onAUXDeviceModalClose();
                      return;
                    }
                    retry(fn, retriesLeft - 1, interval).then(resolve, reject);
                  }, interval);
                } else {
                  if (!newConfig) {
                    toast({
                      title: 'AUX device deleted successfully',
                      status: 'success',
                      position: 'top',
                      duration: 2000,
                      isClosable: true,
                    });
                  } else {
                    toast({
                      title:
                        'New AUX configuration has been setup successfully',
                      status: 'success',
                      position: 'top',
                      duration: 2000,
                      isClosable: true,
                    });
                  }

                  setButtonLoading(false);
                  setButtonDisabled(false);
                  onAUXDeviceModalClose();
                  resolve();
                }
              }
            })
            .catch((error) => {
              console.error(error);

              setTimeout(() => {
                if (retriesLeft === 1) {
                  // maximum retries exceeded

                  if (!newConfig) {
                    toast({
                      title: 'Failed to delete this AUX device',
                      description:
                        'Please try again later or contact us for support',
                      status: 'error',
                      position: 'top',
                      duration: 10000,
                      isClosable: true,
                    });
                  } else {
                    toast({
                      title: 'Failed to setup AUX configuration',
                      description:
                        'Please try again later or contact us for support',
                      status: 'error',
                      position: 'top',
                      duration: 10000,
                      isClosable: true,
                    });
                  }

                  reject(error);
                  setButtonLoading(false);
                  setButtonDisabled(false);
                  onAUXDeviceModalClose();
                  return;
                }

                retry(fn, retriesLeft - 1, interval).then(resolve, reject);
              }, interval);
            });
        });
      };
      retry(() => getConfig());
    } else {
      if (!newConfig) {
        toast({
          title: `Failed to delete AUX this device. Status: ${res.status}`,
          description:
            res.Message || 'Please try again later or contact us for support',
          status: 'error',
          position: 'top',
          duration: 10000,
          isClosable: true,
        });
      } else {
        toast({
          title: `Failed to update AUX configuration. Status: ${res.status}`,
          description:
            res.Message || 'Please try again later or contact us for support',
          status: 'error',
          position: 'top',
          duration: 10000,
          isClosable: true,
        });
      }
      onAUXDeviceModalClose();
    }
  }, []);

  return (
    <Box className="dashboard-module">
      <div className="dashboard-module--heading">
        <Flex flexDirection="column">
          <Flex alignItems="center">
            <h2 data-testid="test-aux-device-name">
              {AUXConfig && AUXConfig.device_name}
            </h2>
            <Icon
              as={SiIcloud}
              color="#dc2f5c"
              className="cloud-status"
              ml={2}
              w={8}
              h={8}
            />
          </Flex>
        </Flex>
      </div>

      {AUXConfig && AUXConfig.protocol === 'modbus_ftx' && (
        <>
          <FTXView
            latestAUXData={latestAUXData}
            AUXConfig={AUXConfig}
            ModbusConfig={ModbusConfig}
          />
          <FTXConfigModal
            isOpen={isAUXDeviceModalOpen}
            onClose={onAUXDeviceModalClose}
            config={AUXConfig}
            handleUpdateFTXConfig={handleUpdateConfig}
            AUXDeviceID={deviceID}
          />
        </>
      )}

      {AUXConfig && AUXConfig.protocol === 'modbus_pdx' && (
        <>
          <PDXView
            latestAUXData={latestAUXData}
            AUXConfig={AUXConfig}
            ModbusConfig={ModbusConfig}
          />
          <PDXConfigModal
            isOpen={isAUXDeviceModalOpen}
            onClose={onAUXDeviceModalClose}
            config={AUXConfig}
            handleUpdatePDXConfig={handleUpdateConfig}
            AUXDeviceID={deviceID}
          />
        </>
      )}

      {AUXConfig && AUXConfig.protocol === 'modbus_rtu' && (
        <>
          <ModbusRTUView latestAUXData={latestAUXData} AUXConfig={AUXConfig} />
          <ModbusRTUConfigModal
            isOpen={isAUXDeviceModalOpen}
            onClose={onAUXDeviceModalClose}
            config={AUXConfig}
            handleUpdateModbusRTUConfig={handleUpdateConfig}
            AUXDeviceID={deviceID}
          />
        </>
      )}

      <Flex className="dashboard-module--footer">
        <Flex p={3} w="100%" alignItems="center" justifyContent="flex-end">
          <Image
            className="setting-btn"
            boxSize="30px"
            objectFit="contain"
            src="/delete.png"
            onClick={() => onDeleteAUXDeviceModalOpen()}
            alt="delete"
          />
          <Image
            className="setting-btn"
            boxSize="30px"
            objectFit="contain"
            src="/gear.png"
            alt="settings"
            mr={2}
            ml={2}
            onClick={() => {
              getConfig();
              onAUXDeviceModalOpen();
            }}
          />
          <Image
            className="chart-btn"
            boxSize="25px"
            objectFit="contain"
            src="/grafana.png"
            alt="grafana-chart"
            onClick={() =>
              getGrafanaURL({
                protocol: getDashboardType(AUXConfig.protocol),
                deviceID,
                channels: ModbusConfig.channels,
              })
            }
          />
        </Flex>
      </Flex>

      <Modal
        closeOnOverlayClick={false}
        isOpen={isDeleteAUXDeviceModalOpen}
        onClose={onDeleteAUXDeviceModalClose}
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete AUX Device</ModalHeader>
          <ModalCloseButton isDisabled={isDisabled} />
          <ModalBody className="delete-device-modal">
            <p>Are you sure you want to delete this device? </p>{' '}
            <p>
              You will lose your configuration, and data will no longer be
              published to device{' '}
              <span className="device-id">
                {AUXConfig && AUXConfig.device_name}
              </span>
              .
            </p>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-group"
              bg="#535355"
              color="#FFF"
              boxShadow="0px 15px 30px rgba(0, 0, 0, 0.15)"
              variant="solid"
              w={150}
              mr={2}
              isDisabled={isDisabled}
              onClick={onDeleteAUXDeviceModalClose}
              _hover={{ bg: '#242424' }}
            >
              Cancel
            </Button>
            <Button
              className="btn-group"
              bg="#DB2F5CCC"
              color="#FFF"
              variant="solid"
              w={150}
              isLoading={isLoading}
              isDisabled={isDisabled}
              onClick={() => {
                handleUpdateConfig(deviceID, null);
              }}
              _hover={{ bg: '#db2f5d99' }}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default memo(AUXDeviceView);
