import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Accordion,
  AccordionItem,
  AccordionIcon,
  AccordionButton,
  AccordionPanel,
  Alert,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { refreshProductData } from '../../redux/product/product.actions';
import portalHandleRequest from '../../api/portalHandleRequest';
import './admin.styles.scss';

const PORTAL_API_ENDPOINT = process.env.REACT_APP_PORTAL_API;

const Admin = ({ userAccounts, refreshProductData, refreshData }) => {
  const [isLoading, setButtonLoading] = useState(false);
  const [isDisabled, setButtonDisabled] = useState(false);
  const [provisionStatus, setProvisionStatus] = useState('');
  const [updateResponse, setUpdateResponse] = useState({});
  const [responseMessage, setResponseMessage] = useState('');
  const [addMissingDashboards, setAddMissingDashboards] = useState(true);
  const navigate = useNavigate();
  const toast = useToast();

  const {
    isOpen: isDeleteProvModalOpen,
    onOpen: onDeleteProvModalOpen,
    onClose: onDeleteProvModalClose,
  } = useDisclosure();

  const {
    isOpen: isCreateProvModalOpen,
    onOpen: onCreateProvModalOpen,
    onClose: onCreateProvModalClose,
  } = useDisclosure();

  const {
    isOpen: isUpdateDashboardsModalOpen,
    onOpen: onUpdateDashboardsModalOpen,
    onClose: onUpdateDashboardsModalClose,
  } = useDisclosure();

  const handleDeleteProv = async () => {
    setButtonLoading(true);
    setButtonDisabled(true);
    const obj = {
      method: 'DELETE',
      url: `${PORTAL_API_ENDPOINT}/prov/${userAccounts.user_email}`,
      contentType: 'application/json',
    };
    const response = await portalHandleRequest(obj, navigate);
    if (response.status === 200) {
      toast({
        title: 'Provisioning removed!',
        status: 'success',
        position: 'top',
        duration: 5000,
        isClosable: true,
      });
      setButtonLoading(false);
      setButtonDisabled(false);
      onDeleteProvModalClose();
    } else {
      toast({
        title: 'Failed to delete provisioning!',
        description: 'Please contact us for support',
        status: 'error',
        position: 'top',
        duration: 10000,
        isClosable: true,
      });
      setButtonLoading(false);
      setButtonDisabled(false);
    }
  };

  const handleCreateProvision = async () => {
    const obj = {
      method: 'POST',
      url: `${PORTAL_API_ENDPOINT}/prov/${userAccounts.user_email}`,
      contentType: 'application/json',
      data: {},
    };

    const response = await portalHandleRequest(obj, navigate);
    if (response.status === 200) {
      setProvisionStatus('Request to get provisioning sent');
      return true;
    }
    console.error(response);

    return false;
  };

  const handleDeviceProvisioning = async () => {
    setProvisionStatus('Getting device provisioning ...');
    const obj = {
      method: 'GET',
      url: `${PORTAL_API_ENDPOINT}/prov/${userAccounts.user_email}`,
    };

    const response = await portalHandleRequest(obj, navigate);

    if (response.status === 200 && response.provisioning_completed) {
      setProvisionStatus('Getting device provision successfully');
      setButtonDisabled(false);
      setTimeout(() => {
        setProvisionStatus('');
        toast({
          title: 'Provisioning exist!',
          status: 'success',
          position: 'top',
          duration: 5000,
          isClosable: true,
        });
      }, 1000);
      onCreateProvModalClose();
    } else if (response.status === 200 && !response.provisioning_completed) {
      toast({
        title: 'Failed to get provision status!',
        description: 'Provisioning was not completed',
        status: 'error',
        position: 'top',
        duration: 10000,
        isClosable: true,
      });
      setProvisionStatus('Failed to get provision status');
      setButtonDisabled(false);
    } else if (response.status === 404) {
      // User does not have device provision => create
      setProvisionStatus('Create device provisioning ...');
      const createProvision = await handleCreateProvision();
      if (createProvision) {
        handleDeviceProvisioning(); // start get function again
      } else {
        setProvisionStatus('Failed to create device provision');
        toast({
          title: 'Failed to create device provision!',
          status: 'error',
          position: 'top',
          duration: 10000,
          isClosable: true,
        });
        setButtonDisabled(false);
      }
    } else {
      console.error(response);
      setProvisionStatus('Failed to get provision status');
      toast({
        title: `Failed to get provision status! Status: ${response.status}`,
        status: 'error',
        position: 'top',
        duration: 10000,
        isClosable: true,
      });
      setButtonDisabled(false);
    }
  };

  const handleUpdateDashboards = async () => {
    setUpdateResponse({});
    setResponseMessage('');
    setButtonLoading(true);
    setButtonDisabled(true);

    const obj = {
      method: 'PATCH',
      url: `${PORTAL_API_ENDPOINT}/users/${userAccounts.user_email}/dashboards?add-missing=${addMissingDashboards}`, // true : update all current dashboards and add missing dashboards ( if any ). false or other value means only update all current dashboards
      contentType: 'application/json',
      data: {},
    };
    try {
      const response = await portalHandleRequest(obj, navigate);
      setUpdateResponse(response);

      let responseMessage = '';
      if (response.status === 200) {
        responseMessage = 'All dashboards have been updated or added';
        refreshProductData(!refreshData);
      } else if (response.status === 400 || response.status === 404) {
        responseMessage = 'Provisioning not found or not completed';
      } else {
        responseMessage = 'There is an issue with one of the dashboards';
      }

      setResponseMessage(responseMessage);
    } catch (error) {
      console.error(error);
      toast({
        title: `Failed to update Grafana dashboards!`,
        status: 'error',
        position: 'top',
        duration: 10000,
        isClosable: true,
      });
      onUpdateDashboardsModalClose();
    } finally {
      setButtonLoading(false);
      setButtonDisabled(false);
    }
  };

  return (
    <Box p={4} className="admin-page">
      <Container maxW="full" p={0} className="admin-page--container">
        <Flex flexDirection="column" mb={4}>
          <h3>1. Create provision</h3>
          <Button
            bg="transparent"
            color="#FFF"
            boxShadow="0px 15px 30px rgba(0, 0, 0, 0.15)"
            border="1px solid #FFFFFF"
            variant="solid"
            borderRadius="full"
            w={270}
            m={2}
            onClick={() => {
              onCreateProvModalOpen();
            }}
            _hover={{ bg: '#535355' }}
          >
            Create Provision
          </Button>
        </Flex>
        <Flex flexDirection="column" mb={4}>
          <h3>2. Delete provision</h3>
          <Button
            bg="transparent"
            color="#FFF"
            boxShadow="0px 15px 30px rgba(0, 0, 0, 0.15)"
            border="1px solid #FFFFFF"
            variant="solid"
            borderRadius="full"
            w={270}
            m={2}
            onClick={() => {
              onDeleteProvModalOpen();
            }}
            _hover={{ bg: '#535355' }}
          >
            Delete Provision
          </Button>
        </Flex>
        <Flex flexDirection="column">
          <h3>3. Update all Grafana Dashboards</h3>
          <Button
            bg="transparent"
            color="#FFF"
            boxShadow="0px 15px 30px rgba(0, 0, 0, 0.15)"
            border="1px solid #FFFFFF"
            variant="solid"
            borderRadius="full"
            w={270}
            m={2}
            onClick={() => {
              onUpdateDashboardsModalOpen();
            }}
            _hover={{ bg: '#535355' }}
          >
            Update Dashboards
          </Button>
        </Flex>
      </Container>

      <Modal
        size="2xl"
        isOpen={isCreateProvModalOpen}
        onClose={onCreateProvModalClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Provisioning</ModalHeader>
          <ModalCloseButton isDisabled={isDisabled} />
          <ModalBody className="delete-device-modal">
            <h4>Are you sure to create provisioning?</h4>
            <p>{provisionStatus}</p>
          </ModalBody>
          <ModalFooter>
            <Button
              isLoading={isLoading}
              isDisabled={isDisabled}
              mr={3}
              onClick={() => {
                handleDeviceProvisioning();
              }}
            >
              Create Provision
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        size="2xl"
        isOpen={isDeleteProvModalOpen}
        onClose={onDeleteProvModalClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Provisioning</ModalHeader>
          <ModalCloseButton isDisabled={isDisabled} />
          <ModalBody className="delete-device-modal">
            <h4>Are you sure to delete provisioning?</h4>
          </ModalBody>
          <ModalFooter>
            <Button
              isLoading={isLoading}
              isDisabled={isDisabled}
              mr={3}
              onClick={() => {
                handleDeleteProv();
              }}
            >
              Delete Provision
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        size="2xl"
        isOpen={isUpdateDashboardsModalOpen}
        onClose={onUpdateDashboardsModalClose}
        closeOnOverlayClick={false}
        onCloseComplete={() => {
          setResponseMessage('');
          setUpdateResponse({});
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Dashboards</ModalHeader>
          <ModalCloseButton isDisabled={isDisabled} />
          <ModalBody className="update-device-modal">
            {Object.keys(updateResponse).length > 0 ? (
              <div>
                <h5>
                  {responseMessage} ({updateResponse.status}).
                </h5>

                <Accordion allowMultiple>
                  {updateResponse.summary.map((item, index) => (
                    <AccordionItem key={index}>
                      <AccordionButton>
                        <Alert
                          mt={2}
                          mb={2}
                          status={
                            item.status === 'success'
                              ? 'success'
                              : item.status === 'failed'
                              ? 'error'
                              : 'warning'
                          }
                        >
                          <AlertIcon />
                          <h5>{item.dashboard_type}</h5>
                          <AccordionIcon marginLeft="auto" />
                        </Alert>
                      </AccordionButton>

                      <AccordionPanel pb={4}>
                        <Flex flexDirection="column" w="100%">
                          <p>Operation : {item.operation}</p>
                          <p>Status : {item.status}</p>
                          <p className="error-message">{item.error}</p>
                        </Flex>
                      </AccordionPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              </div>
            ) : (
              <>
                <h4>Are you sure to update all Grafana Dashboards?</h4>
                <Checkbox
                  mt={2}
                  defaultChecked
                  onChange={() =>
                    setAddMissingDashboards(!addMissingDashboards)
                  }
                >
                  Add missing dashboards
                </Checkbox>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            {responseMessage ? (
              <Flex>
                <Button
                  isLoading={isLoading}
                  isDisabled={isDisabled}
                  mr={3}
                  onClick={() => {
                    handleUpdateDashboards();
                  }}
                >
                  Retry
                </Button>
                <Button
                  isLoading={isLoading}
                  isDisabled={isDisabled}
                  onClick={() => {
                    onUpdateDashboardsModalClose();
                  }}
                  bg="#DB2F5CCC"
                  color="#FFF"
                >
                  Done
                </Button>
              </Flex>
            ) : (
              <Button
                isLoading={isLoading}
                isDisabled={isDisabled}
                mr={3}
                onClick={() => {
                  handleUpdateDashboards();
                }}
                bg="#DB2F5CCC"
              >
                Update Dashboards
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  userAccounts: state.user.accounts,
  refreshData: state.product.refreshData,
});

const mapDispatchToProps = (dispatch) => ({
  refreshProductData: (bool) => dispatch(refreshProductData(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
