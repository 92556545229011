import React, { useEffect, useCallback, useState, useRef, memo } from 'react';
import {
  Box,
  Flex,
  Image,
  SimpleGrid,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import portalHandleRequest from '../../../../api/portalHandleRequest';
import SystemPanelModal from './system-panel-modal/system-panel-modal.component';
import './system-panel.styles.scss';

const PORTAL_API_ENDPOINT = process.env.REACT_APP_PORTAL_API;

const SystemPanel = ({
  data,
  config,
  resetData,
  getConfig,
  thingID,
  getGrafanaURL,
}) => {
  const [latestSYSData, setLatestSYSData] = useState({});
  const [SYSConfig, setSYSConfig] = useState({});
  const [staledKeys, setStaledKeys] = useState([]);
  const navigate = useNavigate();
  const toast = useToast();
  const updateState = useRef(false);

  const {
    isOpen: isSystemPanelModalOpen,
    onOpen: onSystemPanelModalOpen,
    onClose: onSystemPanelModalClose,
  } = useDisclosure();

  const convertMemorySize = (mb) => {
    const gb = mb / 1024;
    if (Math.floor(gb) === 0) {
      return `${mb?.toFixed(2)} MB`;
    }
    return `${gb?.toFixed(2)} GB`; // Return GB value rounded to 2 decimal places
  };

  const convertUpTime = (seconds) => {
    const secondsPerHour = 3600;
    const secondsPerDay = 86400;

    const days = Math.floor(seconds / secondsPerDay);
    const hours = Math.floor(seconds / secondsPerHour);
    const minutes = Math.floor(seconds / 60);

    if (minutes === 0) {
      return `${seconds} Seconds`;
    }
    if (hours === 0) {
      return `${minutes} Minutes`;
    }
    if (days === 0) {
      return `${hours} Hr., ${minutes - hours * 60} Minutes`;
    }

    return `${days} Day(s), ${hours - days * 24} Hr.`;
  };

  const getObjectWithLatestTimestamp = (obj) => {
    return obj.reduce((prev, current) => {
      return prev.timestamp_ms > current.timestamp_ms ? prev : current;
    });
  };

  const keysWithNullValues = (obj) => {
    // Check the obj => return an array of keys that have null value
    const keysWithNull = [];
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key] === null) {
        keysWithNull.push(key);
      }
    }
    return keysWithNull;
  };

  const mergeObjectsWithNull = (newObj, oldObj) => {
    // Check 2 objs => return a merged obj that made from the new obj , but will check and get the old value of a key if the new value of that key is null
    const mergedObj = {};
    for (const key in newObj) {
      if (Object.prototype.hasOwnProperty.call(newObj, key)) {
        mergedObj[key] = newObj[key] !== null ? newObj[key] : oldObj[key];
      }
    }
    return mergedObj;
  };

  useEffect(() => {
    if (config && !updateState.current) {
      setSYSConfig(config);
    }
  }, [config]);

  useEffect(() => {
    // Check the incoming data is not empty
    if (data.length > 0) {
      // check if there is any available latestSYSData
      if (Object.keys(latestSYSData).length !== 0) {
        const originalObject = structuredClone(latestSYSData);

        let latestObject = {};

        // compare all the samples in the incoming data => get the newest one
        const newestObject = data.reduce((prev, current) => {
          return new Date(current.timestamp) > new Date(prev.timestamp)
            ? current
            : prev;
        });

        // compare the previous result with the current originalObject
        if (newestObject.timestamp_ms > originalObject.timestamp_ms) {
          latestObject = mergeObjectsWithNull(newestObject, originalObject);
        } else {
          latestObject = mergeObjectsWithNull(originalObject, newestObject);
        }

        const staledKeys = keysWithNullValues(newestObject);
        setLatestSYSData(latestObject);
        setStaledKeys(staledKeys);
      } else {
        // get the sample which has the latest timestamp. This occurs at the first time receiving data
        const latestObj = getObjectWithLatestTimestamp(data);
        setLatestSYSData(latestObj);
      }
    } else if (resetData) {
      setLatestSYSData({});
    } else {
      setStaledKeys([
        'cpu_load_1_min',
        'cpu_load_5_min',
        'cpu_load_15_min',
        'cpu_temp_C',
        'fan_speed_RPM',
        'localtime_s',
        'memory_total_MB',
        'memory_used_MB',
        'rtc_utc_s',
        'time_zone',
        'timestamp_ms',
        'uptime_s',
      ]);
    }
  }, [data]);

  const handleUpdateSYSConfig = useCallback(async (newConfig) => {
    updateState.current = true;

    const obj = {
      method: 'PATCH',
      url: `${PORTAL_API_ENDPOINT}/shadows/${thingID}`,
      contentType: 'application/json',
      data: {
        state: {
          desired: {
            SYS: newConfig,
          },
        },
      },
    };
    const res = await portalHandleRequest(obj, navigate);

    if (res.status === 200) {
      const retry = (fn, retriesLeft = 10, interval = 1000) => {
        return new Promise((resolve, reject) => {
          fn()
            .then((response) => {
              if (!response) {
                // validation failed => invalid config
                toast({
                  title:
                    'Invalid EdgePi config. Please try power cycling your EdgePi or contact tech support',
                  status: 'error',
                  position: 'top',
                  duration: 5000,
                  isClosable: true,
                });
                updateState.current = false;
                onSystemPanelModalClose();
              } else {
                const check2ConfigObj = !isEqual(
                  response.state.desired.SYS,
                  response.state.reported.SYS
                );

                if (check2ConfigObj) {
                  console.log(
                    `Retry to get new reported config ... Remaining attempts: ${retriesLeft}`
                  );
                  setTimeout(() => {
                    if (retriesLeft === 1) {
                      console.log('Maximum retries exceeded');
                      toast({
                        title: 'Failed to setup System Panel configuration',
                        description:
                          'Please try again later or contact us for support',
                        status: 'error',
                        position: 'top',
                        duration: 10000,
                        isClosable: true,
                      });
                      updateState.current = false;
                      onSystemPanelModalClose();
                      return;
                    }
                    retry(fn, retriesLeft - 1, interval).then(resolve, reject);
                  }, interval);
                } else {
                  console.log('Updated successfully!');
                  toast({
                    title:
                      'New System Panel configuration has been setup successfully',
                    status: 'success',
                    position: 'top',
                    duration: 2000,
                    isClosable: true,
                  });
                  updateState.current = false;
                  onSystemPanelModalClose();
                  resolve();
                }
              }
            })
            .catch((error) => {
              setTimeout(() => {
                if (retriesLeft === 1) {
                  // maximum retries exceeded
                  toast({
                    title: 'Failed to setup System Panel configuration',
                    description:
                      'Please try again later or contact us for support',
                    status: 'error',
                    position: 'top',
                    duration: 10000,
                    isClosable: true,
                  });
                  reject(error);
                  updateState.current = false;
                  onSystemPanelModalClose();
                  return;
                }
                retry(fn, retriesLeft - 1, interval).then(resolve, reject);
              }, interval);
            });
        });
      };
      retry(() => getConfig());
    } else {
      toast({
        title: `Failed to update SYSTEM configuration. Status: ${res.status}`,
        description:
          res.Message || 'Please try again later or contact us for support',
        status: 'error',
        position: 'top',
        duration: 10000,
        isClosable: true,
      });
      onSystemPanelModalClose();
    }
  }, []);

  const handleGrafanaURL = (PANEL) => {
    getGrafanaURL({ PANEL });
  };

  return (
    <Flex flexWrap="wrap" className="dashboard-stats">
      {config ? (
        <>
          <Box className="dashboard-stats--item">
            <h3>CPU Load</h3>
            {config.data_collection === 'enabled' ? (
              <SimpleGrid columns={3} mt={6} spacing="10px">
                <Flex flexDirection="column" alignItems="center">
                  <h4
                    className={`${
                      staledKeys.includes('cpu_load_1_min')
                        ? 'staled-data'
                        : 'sys-data'
                    }`}
                    data-testid="test-1-min"
                  >
                    {latestSYSData.cpu_load_1_min?.toFixed(2) || '--'}
                  </h4>
                  <p>1 min.</p>
                </Flex>
                <Flex flexDirection="column" alignItems="center">
                  <h4
                    className={`${
                      staledKeys.includes('cpu_load_5_min')
                        ? 'staled-data'
                        : 'sys-data'
                    }`}
                    data-testid="test-5-min"
                  >
                    {latestSYSData.cpu_load_5_min?.toFixed(2) || '--'}
                  </h4>
                  <p>5 min.</p>
                </Flex>
                <Flex flexDirection="column" alignItems="center">
                  <h4
                    className={`${
                      staledKeys.includes('cpu_load_15_min')
                        ? 'staled-data'
                        : 'sys-data'
                    }`}
                    data-testid="test-15-min"
                  >
                    {latestSYSData.cpu_load_15_min?.toFixed(2) || '--'}
                  </h4>
                  <p>15 min.</p>
                </Flex>
              </SimpleGrid>
            ) : (
              <h4 className="sys-data">N/A</h4>
            )}

            <Tooltip hasArrow label="View Graphs" bg="#242424" color="white">
              <Image
                className="sys-chart-btn"
                boxSize="25px"
                objectFit="contain"
                src="/grafana.png"
                alt="grafana-chart"
                onClick={() => handleGrafanaURL('SYS.CPU_LOAD')}
              />
            </Tooltip>
          </Box>

          <Box className="dashboard-stats--item">
            <h3>CPU Temp.</h3>
            {config.data_collection === 'enabled' ? (
              <Box mt={6}>
                <h1
                  className={`${
                    staledKeys.includes('cpu_temp_C')
                      ? 'staled-data'
                      : 'sys-data'
                  }`}
                  data-testid="test-cpu-temp"
                >
                  {latestSYSData.cpu_temp_C?.toFixed(2)}°C
                </h1>
              </Box>
            ) : (
              <h4 className="sys-data">N/A</h4>
            )}
            <Tooltip hasArrow label="View Graphs" bg="#242424" color="white">
              <Image
                className="sys-chart-btn"
                boxSize="25px"
                objectFit="contain"
                src="/grafana.png"
                alt="grafana-chart"
                onClick={() => handleGrafanaURL('SYS.CPU_TEMP')}
              />
            </Tooltip>
          </Box>

          <Box className="dashboard-stats--item">
            <h3>Memory Usage</h3>
            {config.data_collection === 'enabled' ? (
              <Flex mt={6} flexDirection="column" alignItems="center">
                <h4
                  className={`${
                    staledKeys.includes('memory_used_MB') ||
                    staledKeys.includes('memory_total_MB')
                      ? 'staled-data'
                      : 'sys-data'
                  }`}
                >
                  {convertMemorySize(latestSYSData.memory_used_MB)} /{' '}
                  {convertMemorySize(latestSYSData.memory_total_MB)}
                </h4>
                <p className="memory-usage-percentage">
                  ({' '}
                  {(
                    (latestSYSData.memory_used_MB /
                      latestSYSData.memory_total_MB) *
                    100
                  )?.toFixed(2) || '--'}
                  % )
                </p>
              </Flex>
            ) : (
              <h4 className="sys-data">N/A</h4>
            )}
            <Tooltip hasArrow label="View Graphs" bg="#242424" color="white">
              <Image
                className="sys-chart-btn"
                boxSize="25px"
                objectFit="contain"
                src="/grafana.png"
                alt="grafana-chart"
                onClick={() => handleGrafanaURL('SYS.MEM_USG')}
              />
            </Tooltip>
          </Box>

          <Box className="dashboard-stats--item">
            <h3>Up Time</h3>
            {config.data_collection === 'enabled' ? (
              <Box mt={6}>
                <h4
                  className={`${
                    staledKeys.includes('uptime_s') ? 'staled-data' : 'sys-data'
                  }`}
                  data-testid="test-up-time"
                >
                  {convertUpTime(latestSYSData.uptime_s)}
                </h4>
                <Tooltip
                  hasArrow
                  label="View Graphs"
                  bg="#242424"
                  color="white"
                >
                  <Image
                    className="sys-chart-btn"
                    boxSize="25px"
                    objectFit="contain"
                    src="/grafana.png"
                    alt="grafana-chart"
                    onClick={() => handleGrafanaURL('SYS.UPTIME')}
                  />
                </Tooltip>
              </Box>
            ) : (
              <h4 className="sys-data">N/A</h4>
            )}
          </Box>

          {/* <Box className="dashboard-stats--item">
        <h3>Clock</h3>
        <h3>{latestSYSData.localtime_s}</h3>
        <p>{latestSYSData.time_zone}</p>
      </Box> */}
        </>
      ) : (
        <Box className="dashboard-stats--item">
          <h4>Module is not available</h4>
        </Box>
      )}

      <div className="dashboard-stats--setting">
        <Tooltip hasArrow label="Module Settings" bg="#242424" color="white">
          <Image
            boxSize="25px"
            objectFit="contain"
            src="/gear.png"
            alt="settings"
            onClick={() => {
              onSystemPanelModalOpen();
            }}
          />
        </Tooltip>
      </div>

      <SystemPanelModal
        isOpen={isSystemPanelModalOpen}
        onClose={onSystemPanelModalClose}
        SYS={SYSConfig || ''}
        handleUpdateSYSConfig={handleUpdateSYSConfig}
      />
    </Flex>
  );
};

export default memo(SystemPanel);
