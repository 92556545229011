import moment from 'moment';

const isNewBuild = (latestDate, localDate) => {
  const momLatestDateTime = moment(latestDate);
  const momLocalDateTime = moment(localDate);

  if (momLatestDateTime.isAfter(momLocalDateTime)) {
    return true;
  }
  return false;
};

export default isNewBuild;
