/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect, memo } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionIcon,
  AccordionButton,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Icon,
  Input,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ListItem,
  ListIcon,
  List,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Tooltip,
  Select,
  SimpleGrid,
  useToast,
} from '@chakra-ui/react';
import CreatableSelect from 'react-select/creatable';
import { GoPlus } from 'react-icons/go';
import { BsMemory } from 'react-icons/bs';
import { GrFormAttachment } from 'react-icons/gr';
import { nameRegex } from '../../../../../../api/nameRegex';
import validateModbusRTUConfig from './functions/validateModbusRTUConfig';
import useModbusRTUForm from './functions/useModbusRTUForm';
import ModbusRTUFormat from './format/ModbusRTUFormat';
import ModbusRTUAttribute from './attribute/ModbusRTUAttribute';
import ModbusRTUReadBlock from './read-block/ModbusRTUReadBlock';
import CustomSwitch from '../../../../../../components/custom-switch/custom-switch.component';
import generateShortID from './functions/generateShortID';
import removeUnusedFormatKeys from './functions/removeUnusedFormatKeys';
import './modbus-rtu-modal.styles.scss';

const ModbusRTUConfigModal = ({
  isOpen,
  onClose,
  config,
  AUXDeviceID,
  handleUpdateModbusRTUConfig,
}) => {
  const createOption = (label, value) => ({
    label,
    value,
  });

  const { onChange, onSubmit, onResetAll, values } = useModbusRTUForm(
    updateInfoCallback,
    {
      protocol: 'modbus_rtu',
      port: config ? config.port : '',
      device_name: config ? config.device_name : 'aux_modbus_rtu_',
      data_collection: config ? config.data_collection : 'enabled',
      cloud_storage: config ? config.cloud_storage : 'enabled',
      read_interval_ms: config ? config.read_interval_ms : 1000,

      modbus_rtu_id: config ? config.modbus_params.modbus_id : '',
      modbus_rtu_baud_rate: config ? config.modbus_params.baud_rate : 9600,
      modbus_rtu_parity: config ? config.modbus_params.parity : 'N',
      modbus_rtu_stopbits: config ? config.modbus_params.stop_bits : 1,
      modbus_rtu_byte_size: config ? config.modbus_params.byte_size : 8,
    }
  );

  async function updateInfoCallback() {
    handleConfirmConfig();
  }

  const baudRateOptions = [
    createOption(9600, 9600),
    createOption(19200, 19200),
    createOption(38400, 38400),
    createOption(57600, 57600),
    createOption(115200, 115200),
  ];

  const unitOptions = [
    createOption('None', 'None'),
    createOption('Celsius (°C)', '°C'),
    createOption('Fahrenheit (°F)', '°F'),
    createOption('Kelvin (K)', 'K'),
    createOption('Ampere (A)', 'A'),
    createOption('Volt (V)', 'V'),
    createOption('Hertz (Hz)', 'Hz'),
    createOption('Watt (W)', 'W'),
    createOption('Coulomb (C)', 'C'),
    createOption('Candela (cd)', 'cd'),
    createOption('Meter (m)', 'm'),
    createOption('Kilogram (kg)', 'kg'),
    createOption('Second (s)', 's'),
    createOption('Mole (mol)', 'mol'),
    createOption('Liter (L)', 'L'),
  ];

  const defaultStyles = {
    control: (baseStyles) => ({
      ...baseStyles,
      cursor: 'pointer',
    }),
    input: (baseStyles) => ({
      ...baseStyles,
      color: 'white',
    }),
    singleValue: (baseStyles) => ({
      ...baseStyles,
      color: 'white',
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: state.isFocused ? '#dc2f5c' : '',
      cursor: 'pointer',
    }),
  };

  const [ModbusRTUConfig, setModbusRTUConfig] = useState();
  const [tabIndex, setTabIndex] = useState(0);
  const [isButtonLoading, setButtonLoading] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [baudRate, setBaudRateOptions] = useState(baudRateOptions);
  const [checkValidField, setCheckValidField] = useState(false);
  const [readBlockIndex, setReadBlockIndex] = useState(0);
  const [readBlockList, setReadBlockList] = useState({});
  const [activeReadBlock, setActiveReadBlock] = useState(''); // current selected Read block
  const [activeAttribute, setActiveAttribute] = useState('');
  const [unit, setUnitOptions] = useState(unitOptions);
  const [accordionIndex, setAccordionIndex] = useState(0);
  const [selectedPreset, setSelectedPreset] = useState(''); // selected format preset
  const [currentFormat, setCurrentFormat] = useState(''); // current format key
  const [formatList, setFormatList] = useState({}); // saved format list
  const toast = useToast();

  useEffect(() => {
    if (config) {
      setModbusRTUConfig(config);
      setReadBlockList(config.read_blocks);
      setActiveReadBlock(Object.keys(config.read_blocks)[0]);
      setReadBlockIndex(1);
      setFormatList(config.formats);
    }
  }, [config]);

  const checkUniqueName = (inputValue, input, refKey, obj) => {
    for (const key in obj) {
      // ignore the current item to check other items
      if (key !== refKey) {
        if (obj[key][input] === inputValue) {
          return false; // Found a match, return false
        }
      }
    }
    return true;
  };

  const handleCreateOption = (inputValue, name) => {
    if (name === 'baud_rate') {
      // check valid value
      const newValue = parseInt(inputValue, 10);
      if (Number.isInteger(newValue) && newValue > 0) {
        setTimeout(() => {
          const newOption = createOption(inputValue, inputValue);
          if (name === 'baud_rate') {
            setBaudRateOptions((prev) => [...prev, newOption]);
          }
        }, 1000);
      } else {
        toast({
          title: 'Invalid value',
          description: 'Must be a number and more than 0',
          status: 'error',
          position: 'top',
          duration: 10000,
          isClosable: true,
        });
      }
    } else if (name === 'unit') {
      const newOption = createOption(inputValue, inputValue);
      setUnitOptions((prev) => [...prev, newOption]);
      const tempBlock = structuredClone(readBlockList);
      tempBlock[activeReadBlock].read_attributes[activeAttribute].unit =
        inputValue;
      setReadBlockList(tempBlock);
    }
  };
  const handleCreateReadBlock = () => {
    const tempBlock = structuredClone(readBlockList);
    const newBlockKey = generateShortID();

    setReadBlockIndex(1);
    const newReadBlockName = `block_${newBlockKey}`;
    const newReadBlock = {
      block_name: newReadBlockName,
      read_enabled: true,
      read_params: {
        func_code: 3,
        address: '0x00',
        reg_count: 1,
      },
      read_attributes: {},
    };

    // Save to the state
    tempBlock[newBlockKey] = newReadBlock;

    setReadBlockList(tempBlock);
    setActiveReadBlock(newBlockKey);
    setAccordionIndex(Object.keys(tempBlock).length - 1);
  };

  const handleSelectReadBlock = (readBlockKey) => {
    setActiveReadBlock(readBlockKey);
    setReadBlockIndex(1);
  };

  const handleUpdateReadBlock = (blockName, input, key) => (event) => {
    const tempBlock = structuredClone(readBlockList);
    if (input === 'read_enabled') {
      tempBlock[blockName][input] = event;
    } else if (input === 'block_name') {
      if (event.target.value) {
        if (nameRegex.test(event.target.value)) {
          // Check block name uniqueness
          const isNameUnique = checkUniqueName(
            event.target.value,
            'block_name',
            blockName,
            tempBlock
          );
          if (isNameUnique) {
            tempBlock[blockName].block_name = event.target.value;
          }
        }
      } else {
        tempBlock[blockName].block_name = '';
      }
    } else if (key) {
      if (key === 'func_code' || key === 'reg_count') {
        if (!Number.isNaN(event.target.value)) {
          tempBlock[blockName][input][key] = parseInt(event.target.value, 10);
        }
      } else {
        tempBlock[blockName][input][key] = event.target.value;
      }
    } else {
      tempBlock[blockName][input] = event.target ? event.target.value : event;
    }
    setReadBlockList(tempBlock);
  };

  const handleDeleteReadBlock = () => {
    const tempReadBlock = structuredClone(readBlockList);
    if (
      AUXDeviceID &&
      Object.prototype.hasOwnProperty.call(config.read_blocks, activeReadBlock)
    ) {
      // Delete an exist read block from a device => set to null
      tempReadBlock[activeReadBlock] = null;
    } else {
      // Delete a read block when setting new device => remove from state
      delete tempReadBlock[activeReadBlock];
    }
    setActiveReadBlock('');
    setAccordionIndex('');
    setReadBlockList(tempReadBlock);
  };

  const handleCreateAttribute = () => {
    const tempBlock = structuredClone(readBlockList);
    const newAttributeKey = generateShortID();
    const newAttributeName = `attr_${newAttributeKey}`;

    const newAttribute = {
      attribute_name: newAttributeName,
      unit: '',
      format_id: '',
      address: '0x00',
      reg_count: 1,
    };

    tempBlock[activeReadBlock].read_attributes[newAttributeKey] = newAttribute;
    setReadBlockList(tempBlock);
    setActiveAttribute(newAttributeKey);
    setReadBlockIndex(2);
  };

  const handleSelectAttribute = (attributeName) => {
    setActiveAttribute(attributeName);
    setReadBlockIndex(2);
  };

  const handleUpdateAttribute = (input) => (event) => {
    const tempBlock = structuredClone(readBlockList);

    if (input === 'attribute_name') {
      if (event.target.value) {
        if (nameRegex.test(event.target.value)) {
          // Check attribute name uniqueness
          const isNameUnique = checkUniqueName(
            event.target.value,
            'attribute_name',
            activeAttribute,
            tempBlock[activeReadBlock].read_attributes
          );
          if (isNameUnique) {
            tempBlock[activeReadBlock].read_attributes[
              activeAttribute
            ].attribute_name = event.target.value;
          }
        }
      } else {
        tempBlock[activeReadBlock].read_attributes[
          activeAttribute
        ].attribute_name = '';
      }
    } else if (input === 'reg_count') {
      tempBlock[activeReadBlock].read_attributes[activeAttribute].reg_count =
        parseInt(event.target.value, 10);
    } else if (input === 'unit') {
      tempBlock[activeReadBlock].read_attributes[activeAttribute].unit =
        event.value;
    } else {
      tempBlock[activeReadBlock].read_attributes[activeAttribute][input] =
        event.target ? event.target.value : event;
    }
    setReadBlockList(tempBlock);
  };

  const handleDeleteAttribute = () => {
    const tempReadBlock = structuredClone(readBlockList);

    if (
      AUXDeviceID &&
      Object.prototype.hasOwnProperty.call(
        config.read_blocks[activeReadBlock].read_attributes,
        activeAttribute
      )
    ) {
      // Delete an exist read attribute from a device => set to null
      tempReadBlock[activeReadBlock].read_attributes[activeAttribute] = null;
    } else {
      // Delete an attribute when setting new device => remove from state
      delete tempReadBlock[activeReadBlock].read_attributes[activeAttribute];
    }
    setActiveAttribute('');
    setReadBlockIndex('');
    setReadBlockList(tempReadBlock);
  };

  const handleSaveFormat = () => {
    // Fields validation
    setCheckValidField(true);

    // Check empty fields
    for (const key in formatList[currentFormat]) {
      if (
        Object.prototype.hasOwnProperty.call(formatList[currentFormat], key)
      ) {
        if (formatList[currentFormat][key] === '') {
          toast({
            title: 'Missing Format data',
            description: 'Please finish the Format configuration first.',
            status: 'error',
            position: 'top',
            duration: 10000,
            isClosable: true,
          });
          return;
        }
      }
    }

    setReadBlockIndex(2);
    const tempReadBlock = structuredClone(readBlockList);
    tempReadBlock[activeReadBlock].read_attributes[activeAttribute].format_id =
      currentFormat;
    setReadBlockList(tempReadBlock);
    setCheckValidField(false);
  };

  const handleFormat = (event) => {
    const tempBlock = structuredClone(readBlockList);
    if (event.target.value === 'create') {
      setReadBlockIndex(3);
      const newFormatKey = generateShortID();
      const newFormatName = `format_${newFormatKey}`;

      const newFormat = {
        format_name: newFormatName,
        data_type: '',
        byte_order: '',
        word_order: '',
        rounding: '',
        offset: '',
        scale: '',
      };
      const updatedFormatList = structuredClone(formatList);
      updatedFormatList[newFormatKey] = newFormat;
      setFormatList(updatedFormatList);
      setCurrentFormat(newFormatKey);
      setSelectedPreset('');
    } else {
      // set format_id value for attribute
      tempBlock[activeReadBlock].read_attributes[activeAttribute].format_id =
        event.target.value;
    }
    setReadBlockList(tempBlock);
  };

  const handleEditFormat = () => {
    // View/Edit format from Attribute
    setReadBlockIndex(3);
    setActiveAttribute('');
    setCurrentFormat(
      readBlockList[activeReadBlock].read_attributes[activeAttribute].format_id
    );
  };

  const handleUpdateFormat = (input) => (e) => {
    const tempFormat = structuredClone(formatList);

    if (input === 'format_name') {
      if (e.target.value && !nameRegex.test(e.target.value)) {
        return;
      }
      // Check format name uniqueness
      const isNameUnique = checkUniqueName(
        e.target.value,
        'format_name',
        currentFormat,
        formatList
      );
      if (!isNameUnique) {
        return;
      }
    }

    if (input === 'data_type') {
      let byteOrderValue = '';
      let wordOrderValue = '';
      let scaleValue = '';
      let offsetValue = '';
      let roundingValue = '';

      if (e.target.value === 'bool') {
        byteOrderValue = 'None';
        wordOrderValue = 'None';
        scaleValue = 'None';
        roundingValue = 'None';
        offsetValue = 'None';
      }

      if (
        e.target.value !== 'int_32' &&
        e.target.value !== 'uint_32' &&
        e.target.value !== 'float_32'
      ) {
        wordOrderValue = 'None';
      }

      if (e.target.value === 'ascii_16' || e.target.value === 'ascii_32') {
        scaleValue = 'None';
        roundingValue = 'None';
        offsetValue = 'None';
      }
      tempFormat[currentFormat].data_type = e.target.value;
      tempFormat[currentFormat].byte_order = byteOrderValue;
      tempFormat[currentFormat].word_order = wordOrderValue;
      tempFormat[currentFormat].scale = scaleValue;
      tempFormat[currentFormat].offset = offsetValue;
      tempFormat[currentFormat].rounding = roundingValue;
    } else if (input === 'offset' || input === 'scale') {
      tempFormat[currentFormat][input] = e.target.value
        ? parseFloat(e.target.value)
        : e.target.value;
    } else if (input === 'rounding') {
      tempFormat[currentFormat][input] = e.target.value
        ? parseInt(e.target.value, 10)
        : e.target.value;
    } else {
      tempFormat[currentFormat][input] = e.target.value;
    }

    setFormatList(tempFormat);
  };

  const handleLoadPreset = (e) => {
    setSelectedPreset(e.target.value);
    if (e.target.value) {
      let preset;
      if (e.target.value === 'PS1') {
        preset = {
          format_name: 'Signed Int-16 BE',
          data_type: 'int_16',
          byte_order: 'BE',
          word_order: 'None',
          rounding: 2,
          offset: 0,
          scale: 1,
        };
      } else if (e.target.value === 'PS2') {
        preset = {
          format_name: 'Unsigned Int-16 BE',
          data_type: 'uint_16',
          byte_order: 'BE',
          word_order: 'None',
          rounding: 2,
          offset: 0,
          scale: 1,
        };
      } else if (e.target.value === 'PS3') {
        preset = {
          format_name: 'Signed Int-32 BE',
          data_type: 'int_32',
          byte_order: 'BE',
          word_order: 'BE',
          rounding: 2,
          offset: 0,
          scale: 1,
        };
      }
      preset.format_name = formatList[currentFormat].format_name;
      const updatedFormatList = structuredClone(formatList);
      updatedFormatList[currentFormat] = preset;
      setFormatList(updatedFormatList);
    }
  };

  const replaceStringInObject = (obj, target) => {
    for (const key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        replaceStringInObject(obj[key], target);
      } else if (obj[key] === target) {
        obj[key] = '';
      }
    }
  };

  const handleDeleteFormat = () => {
    const tempReadBlockList = structuredClone(readBlockList);
    replaceStringInObject(tempReadBlockList, currentFormat);
    setReadBlockList(tempReadBlockList);
    const tempFormatList = structuredClone(formatList);
    if (AUXDeviceID) {
      tempFormatList[currentFormat] = null;
    } else {
      delete tempFormatList[currentFormat];
    }
    setFormatList(tempFormatList);
    setReadBlockIndex('');
    setCurrentFormat('');
  };
  const handleConfirmConfig = () => {
    setButtonLoading(true);
    setButtonDisabled(true);

    if (Object.keys(readBlockList).length === 0) {
      toast({
        title: 'Missing Read Blocks data',
        description:
          'Please continue setting up the Read Blocks configuration first.',
        status: 'error',
        position: 'top',
        duration: 10000,
        isClosable: true,
      });
      setButtonLoading(false);
      setButtonDisabled(false);
      return;
    }

    if (Object.keys(formatList).length === 0) {
      toast({
        title: 'Missing Format data',
        description:
          'Please continue setting up the Format for each Read Block Attribute.',
        status: 'error',
        position: 'top',
        duration: 10000,
        isClosable: true,
      });
      setButtonLoading(false);
      setButtonDisabled(false);
      return;
    }

    // Fields validation
    setCheckValidField(true);

    // manipulate desired Modbus RTU config
    const config = {
      protocol: values.protocol,
      port: values.port,
      device_name:
        values.device_name === 'aux_modbus_rtu_'
          ? `aux_modbus_rtu_${values.modbus_rtu_id}`
          : values.device_name,
      data_collection: values.data_collection,
      cloud_storage: values.cloud_storage,
      read_interval_ms: parseInt(values.read_interval_ms, 10),
      modbus_params: {
        modbus_id: parseInt(values.modbus_rtu_id, 10),
        baud_rate: parseInt(values.modbus_rtu_baud_rate, 10),
        parity: values.modbus_rtu_parity,
        stop_bits: parseFloat(values.modbus_rtu_stopbits),
        byte_size: parseInt(values.modbus_rtu_byte_size, 10),
      },
      read_blocks: readBlockList,
      formats: formatList,
    };

    // Validate Modbus RTU config
    const isConfigValid = validateModbusRTUConfig(config);
    if (isConfigValid.isValid) {
      // Remove unused format keys in the formatList
      const updatedPayload = removeUnusedFormatKeys(AUXDeviceID, config);
      handleUpdateModbusRTUConfig(AUXDeviceID, updatedPayload, onResetAll);
      setCheckValidField(false);
    } else {
      toast({
        title: 'Failed to setup Modbus RTU configuration',
        description: isConfigValid.message,
        status: 'error',
        position: 'top',
        duration: 10000,
        isClosable: true,
      });
      setButtonLoading(false);
      setButtonDisabled(false);
    }
  };
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      onCloseComplete={() => {
        setTabIndex(0);
        setCheckValidField(false);
        setButtonLoading(false);
        setButtonDisabled(false);
        setReadBlockIndex(0);
        setAccordionIndex(0);
        setReadBlockList({});
        setFormatList({});
      }}
      size="6xl"
      className="adc-modal"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          AUX Device Configuration
          {AUXDeviceID && (
            <h4>
              ID : <span>{AUXDeviceID}</span>
            </h4>
          )}
        </ModalHeader>
        <ModalCloseButton
          isDisabled={isButtonDisabled}
          onClick={() => {
            onResetAll();
            onClose();
          }}
        />
        <ModalBody p={6}>
          <Tabs
            isLazy
            isFitted
            flexWrap="wrap"
            index={tabIndex}
            onChange={(index) => setTabIndex(index)}
          >
            <TabList>
              <Tab>
                <h2>Modbus RTU</h2>
              </Tab>
              <Tab>
                <h2>Read Blocks</h2>
              </Tab>
              <Tab>
                <h2>General</h2>
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <Box className="adc-modal--item">
                  <Flex alignItems="center">
                    <h4>Port :</h4>
                  </Flex>
                  <p>Select a Port you would like to connect to</p>
                  <Select
                    w={270}
                    mt={2}
                    variant="filled"
                    placeholder="Select a Port ..."
                    onChange={onChange('port')}
                    value={values.port}
                    isInvalid={checkValidField && values.port === ''}
                  >
                    <option value="rs485_1">RS-485-1</option>
                    <option value="rs485_2">RS-485-2</option>
                    <option value="usb_1">USB-1</option>
                    <option value="usb_2">USB-2</option>
                  </Select>
                </Box>

                <Box className="adc-modal--item">
                  <Box className="adc-modal--item-details">
                    <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="20px">
                      <Flex flexDirection="column">
                        <h4>Baud Rate :</h4>
                        <p>Rate at which data is sent/received</p>
                        <CreatableSelect
                          className={`custom-select ${
                            checkValidField &&
                            (ModbusRTUConfig
                              ? ModbusRTUConfig.modbus_params.baud_rate === ''
                              : values.modbus_rtu_baud_rate === '')
                              ? 'invalid-custom-select'
                              : ''
                          }`}
                          styles={defaultStyles}
                          onChange={onChange('modbus_rtu_baud_rate')}
                          onCreateOption={(e) =>
                            handleCreateOption(e, 'baud_rate')
                          }
                          options={baudRate}
                          value={{
                            label: values.modbus_rtu_baud_rate,
                            value: values.modbus_rtu_baud_rate,
                          }}
                          placeholder="Custom Value ..."
                        />
                      </Flex>

                      <Flex flexDirection="column" mt={4}>
                        <h4>Parity : </h4>
                        <Select
                          w={250}
                          mt={2}
                          variant="filled"
                          onChange={onChange('modbus_rtu_parity')}
                          value={values.modbus_rtu_parity}
                          placeholder="Select an option ..."
                          isInvalid={
                            checkValidField &&
                            (ModbusRTUConfig
                              ? ModbusRTUConfig.modbus_params.parity === ''
                              : values.modbus_rtu_parity === '')
                          }
                        >
                          <option value="E">Even Parity</option>
                          <option value="O">Odd Parity</option>
                          <option value="N">No Parity</option>
                        </Select>
                      </Flex>

                      <Flex flexDirection="column" mt={4}>
                        <h4>Stop Bits : </h4>
                        <Select
                          w={250}
                          mt={2}
                          variant="filled"
                          onChange={onChange('modbus_rtu_stopbits')}
                          value={values.modbus_rtu_stopbits}
                          placeholder="Select an option ..."
                          isInvalid={
                            checkValidField &&
                            (ModbusRTUConfig
                              ? ModbusRTUConfig.modbus_params.stopbits === ''
                              : values.modbus_rtu_stopbits === '')
                          }
                        >
                          <option value={1}>1</option>
                          <option value={1.5}>1.5</option>
                          <option value={2}>2</option>
                        </Select>
                      </Flex>

                      <Flex flexDirection="column" mt={4}>
                        <h4>Byte Size : </h4>
                        <Select
                          w={250}
                          mt={2}
                          variant="filled"
                          onChange={onChange('modbus_rtu_byte_size')}
                          value={values.modbus_rtu_byte_size}
                          placeholder="Select an option ..."
                          isInvalid={
                            checkValidField &&
                            (ModbusRTUConfig
                              ? ModbusRTUConfig.modbus_params.byte_size === ''
                              : values.modbus_rtu_byte_size === '')
                          }
                        >
                          <option value={7}>7</option>
                          <option value={8}>8</option>
                        </Select>
                      </Flex>

                      <Flex
                        justifyContent="center"
                        flexDirection="column"
                        mt={4}
                      >
                        <h4>Modbus ID :</h4>
                        <Tooltip
                          hasArrow
                          label="Input Device Modbus ID"
                          placement="right"
                          bg="#dc2f5c"
                          color="#fff"
                          isOpen={
                            ModbusRTUConfig
                              ? ModbusRTUConfig.modbus_params.modbus_id === ''
                              : values.modbus_rtu_modbus_id === ''
                          }
                        >
                          <Input
                            width={250}
                            type="number"
                            onChange={onChange('modbus_rtu_id')}
                            placeholder="[1-247]"
                            value={values.modbus_rtu_id}
                            isInvalid={
                              checkValidField && values.modbus_rtu_id === ''
                            }
                          />
                        </Tooltip>
                      </Flex>
                    </SimpleGrid>
                  </Box>
                </Box>
              </TabPanel>

              <TabPanel>
                <Flex flexWrap="wrap">
                  <Flex
                    flexDirection="column"
                    className="adc-modal--item"
                    w={300}
                    mr={10}
                  >
                    <h4>Read Blocks :</h4>
                    <Accordion
                      mt={10}
                      allowToggle
                      index={accordionIndex}
                      onChange={setAccordionIndex}
                    >
                      {Object.keys(readBlockList).map(
                        (readblock) =>
                          readBlockList[readblock] && (
                            <AccordionItem key={readblock}>
                              <AccordionButton
                                justifyContent="space-between"
                                onClick={() => handleSelectReadBlock(readblock)}
                                className={`${
                                  activeReadBlock === readblock &&
                                  readBlockIndex === 1
                                    ? 'active-readblock'
                                    : ''
                                }`}
                              >
                                <div className="modbus-rtu-readblock">
                                  {readBlockList[readblock].block_name}
                                </div>
                                <AccordionIcon />
                              </AccordionButton>

                              <AccordionPanel>
                                <Flex flexDirection="column" ml={4}>
                                  <List>
                                    {activeReadBlock &&
                                      readBlockList[activeReadBlock] !== null &&
                                      Object.keys(
                                        readBlockList[activeReadBlock]
                                          .read_attributes
                                      ).map(
                                        (attribute, index) =>
                                          readBlockList[activeReadBlock]
                                            .read_attributes[attribute] !==
                                            null && (
                                            <ListItem
                                              key={index}
                                              className={`${
                                                activeAttribute === attribute &&
                                                readBlockIndex === 2
                                                  ? 'active-attribute'
                                                  : ''
                                              }`}
                                            >
                                              <div
                                                className="modbus-rtu-attribute"
                                                onClick={() =>
                                                  handleSelectAttribute(
                                                    attribute
                                                  )
                                                }
                                              >
                                                <ListIcon
                                                  as={BsMemory}
                                                  color="#56d5dc"
                                                />
                                                {
                                                  readBlockList[activeReadBlock]
                                                    .read_attributes[attribute]
                                                    .attribute_name
                                                }
                                              </div>
                                            </ListItem>
                                          )
                                      )}

                                    <ListItem>
                                      <div
                                        className="modbus-rtu-attribute"
                                        onClick={handleCreateAttribute}
                                      >
                                        <ListIcon as={GoPlus} color="#56d5dc" />
                                        Add Attribute
                                      </div>
                                    </ListItem>
                                  </List>
                                </Flex>
                              </AccordionPanel>
                            </AccordionItem>
                          )
                      )}
                    </Accordion>
                    <Tooltip
                      hasArrow
                      label="Create a read block to continue"
                      placement="top"
                      bg="#dc2f5c"
                      color="#fff"
                      isOpen={Object.keys(readBlockList).length === 0}
                    >
                      <Button
                        mt={4}
                        leftIcon={<GoPlus />}
                        colorScheme="#db2f5c"
                        variant="outline"
                        onClick={handleCreateReadBlock}
                      >
                        Add Read Block
                      </Button>
                    </Tooltip>
                    {Object.keys(formatList).length > 0 && (
                      <List spacing={2} mt={4}>
                        <h4>Format :</h4>
                        {Object.keys(formatList).map(
                          (item, index) =>
                            formatList[item] && (
                              <ListItem
                                key={index}
                                className={`${
                                  currentFormat === item && readBlockIndex === 3
                                    ? 'active-format'
                                    : ''
                                }`}
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                cursor="pointer"
                                p={1}
                                _hover={{
                                  bgColor: '#dc2f5c',
                                }}
                                onClick={() => {
                                  setReadBlockIndex(3);
                                  setActiveAttribute('');
                                  setCurrentFormat(item);
                                }}
                              >
                                <Flex display="flex" alignItems="center">
                                  <Icon as={GrFormAttachment} boxSize={6} />
                                  <h5>
                                    {formatList[item] &&
                                      formatList[item].format_name}
                                  </h5>
                                </Flex>
                              </ListItem>
                            )
                        )}
                        <Button
                          mt={4}
                          w="100%"
                          leftIcon={<GoPlus />}
                          colorScheme="#db2f5c"
                          variant="outline"
                          value="create"
                          onClick={(e) => {
                            setActiveAttribute('');
                            handleFormat(e);
                          }}
                        >
                          Add New Format
                        </Button>
                      </List>
                    )}
                  </Flex>

                  {readBlockIndex === 1 && activeReadBlock && (
                    <ModbusRTUReadBlock
                      readBlockList={readBlockList}
                      activeReadBlock={activeReadBlock}
                      handleDeleteReadBlock={handleDeleteReadBlock}
                      handleUpdateReadBlock={handleUpdateReadBlock}
                    />
                  )}

                  {readBlockIndex === 2 && activeAttribute && (
                    <ModbusRTUAttribute
                      checkValidField={checkValidField}
                      defaultStyles={defaultStyles}
                      formatList={formatList}
                      readBlockList={readBlockList}
                      activeReadBlock={activeReadBlock}
                      activeAttribute={activeAttribute}
                      unit={unit}
                      setReadBlockIndex={setReadBlockIndex}
                      handleCreateOption={handleCreateOption}
                      handleFormat={handleFormat}
                      handleEditFormat={handleEditFormat}
                      handleUpdateAttribute={handleUpdateAttribute}
                      handleDeleteAttribute={handleDeleteAttribute}
                    />
                  )}

                  {readBlockIndex === 3 && currentFormat && (
                    <ModbusRTUFormat
                      checkValidField={checkValidField}
                      formatList={formatList}
                      currentFormat={currentFormat}
                      selectedPreset={selectedPreset}
                      activeAttribute={activeAttribute}
                      handleSaveFormat={handleSaveFormat}
                      handleLoadPreset={handleLoadPreset}
                      setReadBlockIndex={setReadBlockIndex}
                      handleUpdateFormat={handleUpdateFormat}
                      handleDeleteFormat={handleDeleteFormat}
                    />
                  )}
                </Flex>
              </TabPanel>

              <TabPanel>
                <Box className="adc-modal--item">
                  <Box mb={6}>
                    <Flex justifyContent="center" flexDirection="column">
                      <h4>Name of Device :</h4>
                      <p>Name your connected device</p>
                      <Input
                        width={250}
                        mt={1}
                        type="text"
                        maxLength={20}
                        onChange={onChange('device_name')}
                        value={values.device_name}
                        isInvalid={values.device_name === ''}
                      />
                    </Flex>

                    <Flex alignItems="center" className="switch-box" mt={6}>
                      <h4>Data Collection :</h4>

                      <CustomSwitch
                        id="aux-data-collection"
                        onSwitchChange={onChange('data_collection')}
                        variant="lite"
                        isChecked={values.data_collection === 'enabled'}
                        isDisabled={isButtonLoading}
                      />
                    </Flex>
                    <p>
                      Data collection begins the collection of data from this
                      module. Its reading will be subsequently shown on your
                      Dashboard.
                    </p>
                  </Box>

                  <Box className="adc-modal--box" mb={6}>
                    <h4>Read Interval :</h4>
                    <p>Data collection read interval</p>
                    <Select
                      bg="#535355"
                      borderColor="#535355"
                      color="white"
                      w={220}
                      mt={2}
                      onChange={onChange('read_interval_ms')}
                      placeholder="Select an option ..."
                      value={values.read_interval_ms}
                      isInvalid={
                        checkValidField &&
                        (ModbusRTUConfig
                          ? ModbusRTUConfig.read_interval_ms === ''
                          : values.read_interval_ms === '')
                      }
                    >
                      <option value={1000}>1 second</option>
                      <option value={2000}>2 seconds</option>
                      <option value={3000}>3 seconds</option>
                      <option value={5000}>5 seconds</option>
                      <option value={10000}>10 seconds</option>
                      <option value={15000}>15 seconds</option>
                      <option value={30000}>30 seconds</option>
                      <option value={60000}>60 seconds</option>
                    </Select>
                  </Box>

                  <Box mb={4}>
                    <Flex alignItems="center" className="switch-box">
                      <h4>Cloud Storage :</h4>
                      <CustomSwitch
                        id="aux-cloud-storage"
                        onSwitchChange={onChange('cloud_storage')}
                        variant="lite"
                        isChecked={values.cloud_storage === 'enabled'}
                        isDisabled
                      />
                    </Flex>
                    <p>
                      Cloud Storage will begin the storing of data. This data
                      will be made available to you through our data
                      visualization tool.
                    </p>
                  </Box>
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn-group"
            bg="#535355"
            color="#FFF"
            boxShadow="0px 15px 30px rgba(0, 0, 0, 0.15)"
            variant="solid"
            w={150}
            mr={2}
            isDisabled={isButtonDisabled}
            onClick={() => {
              onResetAll();
              onClose();
            }}
            _hover={{ bg: '#242424' }}
          >
            Cancel
          </Button>

          <Button
            className="btn-group"
            bg="#DB2F5CCC"
            color="#FFF"
            variant="solid"
            w={150}
            isLoading={isButtonLoading}
            onClick={onSubmit}
            _hover={{ bg: '#db2f5d99' }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default memo(ModbusRTUConfigModal);
