const hasOwnProperty = (obj, key) =>
  Object.prototype.hasOwnProperty.call(obj, key);

function checkKeyGroup(keyGroup, mandatoryKeys, optionalKeys, path) {
  let allKeysPresent = true;

  mandatoryKeys.forEach((key) => {
    if (!hasOwnProperty(keyGroup, key)) {
      allKeysPresent = false;
      console.log(`'${path}/${key}' is missing.`);
    }
  });

  optionalKeys.forEach((key) => {
    if (!hasOwnProperty(keyGroup, key)) {
      console.log(
        `'${path}/${key}' is missing (optional). Rendering dashboard without this module.`
      );
    }
  });

  return allKeysPresent;
}

function checkKeys(jsonData, mandatoryKeys, optionalKeys) {
  if (jsonData.state && jsonData.state.reported && jsonData.state.desired) {
    const desiredKeys = jsonData.state.desired;
    const reportedKeys = jsonData.state.reported;

    const desiredKeysValid = checkKeyGroup(
      desiredKeys,
      mandatoryKeys,
      optionalKeys,
      'desired'
    );
    const reportedKeysValid = checkKeyGroup(
      reportedKeys,
      mandatoryKeys,
      optionalKeys,
      'reported'
    );

    return desiredKeysValid && reportedKeysValid;
  }

  console.log("Major keys 'reported' and 'desired' are not present.");
  return false;
}

const validateConfig = (jsonConfig) => {
  const mandatoryKeys = [
    'ADC',
    'DAC',
    'LED',
    'TC',
    'DIN',
    'DOUT',
    'PWM',
    'RLY',
    'AUD',
    'SYS',
    'BUZZ',
    'INPUTS',
    'OUTPUTS',
  ];
  const optionalKeys = process.env.REACT_APP_OPTIONAL_MODULES.split(',');

  return checkKeys(jsonConfig, mandatoryKeys, optionalKeys);
};

export default validateConfig;
