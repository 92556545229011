import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Box,
  Button,
  Container,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Image,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';
import PlansModal from './plans-modal/plans-modal.component';
import { refreshProductData } from '../../redux/product/product.actions';
import validateConfig from '../../api/validateConfig';
import portalHandleRequest from '../../api/portalHandleRequest';
import CloudAnimation from '../../components/animation/cloud';
import './device.styles.scss';

const PORTAL_API_ENDPOINT = process.env.REACT_APP_PORTAL_API;

const Device = ({
  activeDevices,
  activePlans,
  refreshProductData,
  userAccounts,
  refreshData,
}) => {
  const [device, setDevice] = useState({});
  const [assignedPlan, setAssignedPlan] = useState({});
  const [isLoading, setButtonLoading] = useState(false);
  const [isDisabled, setButtonDisabled] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const {
    isOpen: isDeleteDeviceModalOpen,
    onOpen: onDeleteDeviceModalOpen,
    onClose: onDeleteDeviceModalClose,
  } = useDisclosure();

  const {
    isOpen: isUnAttachPlanModalOpen,
    onOpen: onUnAttachPlanModalOpen,
    onClose: onUnAttachPlanModalClose,
  } = useDisclosure();

  const {
    isOpen: isAttachAPlanModalOpen,
    onOpen: onAttachAPlanModalOpen,
    onClose: onAttachAPlanModalClose,
  } = useDisclosure();

  const {
    isOpen: isResetConfigModalOpen,
    onOpen: onResetConfigModalOpen,
    onClose: onResetConfigModalClose,
  } = useDisclosure();

  useEffect(() => {
    const getDeviceData = async () => {
      const currentDevice = activeDevices.find(
        (device) => device.thing_id === id
      );
      if (currentDevice) {
        setDevice(currentDevice);
        const assignedPlan = activePlans.find((plan) =>
          plan.assigned_devices.includes(id)
        );
        if (assignedPlan) {
          setAssignedPlan(assignedPlan);
        }
      }
    };
    getDeviceData();
  }, [id, activeDevices, activePlans]);

  const getConfig = async () => {
    const obj = {
      method: 'GET',
      url: `${PORTAL_API_ENDPOINT}/shadows/${id}`,
      contentType: 'application/json',
    };
    const res = await portalHandleRequest(obj);
    if (res.status === 200) {
      if (process.env.REACT_APP_DEV_FEATURES === 'true') {
        console.log('Config:', res);
      }
      if (res.message) {
        toast({
          title: 'Could not get the device configuration',
          description: res.message,
          status: 'info',
          position: 'top',
          duration: 5000,
          isClosable: true,
        });
      } else {
        // validate config
        const isConfigValid = validateConfig(res.shadow);
        if (isConfigValid) {
          return res.shadow;
        }
        toast({
          title: 'Could not get the device configuration',
          description:
            'Invalid EdgePi config. Please try power cycling your EdgePi or contact tech support',
          status: 'error',
          position: 'top',
          duration: 10000,
          isClosable: true,
        });
      }
    }
    return false;
  };

  const handleDeleteDevice = async () => {
    setButtonLoading(true);
    setButtonDisabled(true);

    const deviceObj = {
      user_email: '',
      plan_id: '',
    };

    const activeCloudOptions = {
      method: 'PATCH',
      url: `${PORTAL_API_ENDPOINT}/devices/${id}`,
      contentType: 'application/json',
      data: deviceObj,
    };

    const res = await portalHandleRequest(activeCloudOptions);

    if (res.status === 200) {
      toast({
        title: 'This device has been removed from your account',
        status: 'success',
        position: 'top',
        duration: 5000,
        isClosable: true,
      });
      setButtonLoading(false);
      setButtonDisabled(false);
      refreshProductData(!refreshData);
      onDeleteDeviceModalClose();
      navigate('/devices');
    } else {
      toast({
        title: 'Failed to remove this device from your account',
        description: 'Please contact us for support',
        status: 'error',
        position: 'top',
        duration: 10000,
        isClosable: true,
      });
      setButtonLoading(false);
      setButtonDisabled(false);
    }
    return null;
  };

  const handleUnAttachPlan = async () => {
    setButtonLoading(true);
    setButtonDisabled(true);

    const deviceObj = {
      plan_id: '',
    };

    const obj = {
      method: 'PATCH',
      url: `${PORTAL_API_ENDPOINT}/devices/${id}`,
      contentType: 'application/json',
      data: deviceObj,
    };
    const response = await portalHandleRequest(obj, navigate);

    if (response.status === 200) {
      toast({
        title: 'This device has been removed from your plan',
        status: 'success',
        position: 'top',
        duration: 5000,
        isClosable: true,
      });
      setButtonLoading(false);
      setButtonDisabled(false);
      refreshProductData(!refreshData);
      navigate(0);
      onUnAttachPlanModalClose();
    } else {
      toast({
        title: 'Failed to remove the device from this plan!',
        description: 'Please contact us for support',
        status: 'error',
        position: 'top',
        duration: 10000,
        isClosable: true,
      });
      setButtonLoading(false);
      setButtonDisabled(false);
    }
  };

  const handleResetConfig = async () => {
    setButtonLoading(true);
    setButtonDisabled(true);

    const resetConfigOptions = {
      method: 'PATCH',
      url: `${PORTAL_API_ENDPOINT}/shadows/${id}`,
      contentType: 'application/json',
      data: null,
    };

    const res = await portalHandleRequest(resetConfigOptions);

    if (res.status === 200) {
      const retry = (fn, retriesLeft = 10, interval = 3000) => {
        return new Promise((resolve, reject) => {
          fn()
            .then((response) => {
              if (
                Object.prototype.hasOwnProperty.call(response.state, 'delta')
              ) {
                console.log(
                  `Retry to get new config ... Remaining attempts: ${retriesLeft}`
                );
                setTimeout(() => {
                  if (retriesLeft === 1) {
                    console.log('Maximum retries exceeded');
                    toast({
                      title: 'Failed to reset device configuration completely',
                      description:
                        'Please try again later or contact us for support',
                      status: 'error',
                      position: 'top',
                      duration: 10000,
                      isClosable: true,
                    });
                    setButtonLoading(false);
                    setButtonDisabled(false);
                    onResetConfigModalClose();
                    return;
                  }
                  retry(fn, retriesLeft - 1, interval).then(resolve, reject);
                }, interval);
              } else {
                toast({
                  title: 'Device config has been reset successfully',
                  status: 'success',
                  position: 'top',
                  duration: 2000,
                  isClosable: true,
                });
                setButtonLoading(false);
                setButtonDisabled(false);
                onResetConfigModalClose();
                resolve();
              }
            })
            .catch((error) => {
              setTimeout(() => {
                if (retriesLeft === 1) {
                  // maximum retries exceeded
                  toast({
                    title: 'Failed to reset device configuration completely',
                    description:
                      'Please try again later or contact us for support',
                    status: 'error',
                    position: 'top',
                    duration: 10000,
                    isClosable: true,
                  });
                  reject(error);
                  setButtonLoading(false);
                  setButtonDisabled(false);
                  onResetConfigModalClose();
                  return;
                }
                retry(fn, retriesLeft - 1, interval).then(resolve, reject);
              }, interval);
            });
        });
      };
      retry(() => getConfig());
    } else {
      toast({
        title: 'Failed to reset device configuration completely',
        description: 'Please contact us for support',
        status: 'error',
        position: 'top',
        duration: 10000,
        isClosable: true,
      });
      setButtonLoading(false);
      setButtonDisabled(false);
    }
    return null;
  };

  return (
    <Box className="device-page">
      {Object.keys(device).length !== 0 ? (
        <Container p={0} maxW="full" className="device-page--container">
          <Flex
            wrap="wrap"
            justifyContent="center"
            className="device-page--details"
          >
            <Box className="device-container">
              <h1>{device.product_name}</h1>
              <p className="device-id">ID: {device.thing_id}</p>
              <p className="device-id">S/N: {device.serial_number}</p>

              <p className="device-activation-date">
                Device Add Date :{' '}
                <span>
                  {new Intl.DateTimeFormat('en-US', {
                    dateStyle: 'long',
                    timeStyle: undefined,
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                  }).format(new Date(device.activation_date * 1000))}
                </span>
              </p>

              <Flex flexWrap="wrap" mt={6}>
                <Button
                  bg="transparent"
                  color="#FFF"
                  boxShadow="0px 15px 30px rgba(0, 0, 0, 0.15)"
                  border="1px solid #FFFFFF"
                  variant="solid"
                  borderRadius="full"
                  w={270}
                  m={2}
                  onClick={() => {
                    onDeleteDeviceModalOpen();
                  }}
                  _hover={{ bg: '#535355' }}
                >
                  Delete Device
                </Button>

                <Button
                  bg="transparent"
                  color="#FFF"
                  boxShadow="0px 15px 30px rgba(0, 0, 0, 0.15)"
                  border="1px solid #FFFFFF"
                  variant="solid"
                  borderRadius="full"
                  w={270}
                  m={2}
                  onClick={() => {
                    navigate(`/dashboard/${device.thing_id}`);
                  }}
                  _hover={{ bg: '#535355' }}
                >
                  Go to Device Dashboard
                </Button>
              </Flex>
              <Flex>
                <Button
                  bg="transparent"
                  color="#FFF"
                  boxShadow="0px 15px 30px rgba(0, 0, 0, 0.15)"
                  border="1px solid #FFFFFF"
                  variant="solid"
                  borderRadius="full"
                  w={270}
                  m={2}
                  onClick={() => {
                    onResetConfigModalOpen();
                  }}
                  _hover={{ bg: '#535355' }}
                >
                  Reset Device Config
                </Button>
              </Flex>
            </Box>

            <Box className="device-container">
              {device.image_url ? (
                <Image
                  boxSize="350px"
                  objectFit="contain"
                  src={device.image_url}
                  alt="device-img"
                  mb={2}
                />
              ) : (
                <Image
                  boxSize="350px"
                  objectFit="contain"
                  src="/edgepi-placeholder.png"
                  alt="device-img"
                  mb={2}
                />
              )}
            </Box>
          </Flex>
          {assignedPlan && Object.keys(assignedPlan).length !== 0 ? (
            <Flex
              wrap="wrap"
              justifyContent="center"
              alignItems="flex-start"
              className="device-page--plans"
            >
              <Box className="device-container">
                <h3 className="plan-heading">Plan</h3>
                <Flex alignItems="center">
                  <h2>{assignedPlan.name}</h2>
                  <Image
                    boxSize="50px"
                    objectFit="contain"
                    src="/edgepi.svg"
                    alt="edgepi-icon"
                    ml={4}
                  />
                </Flex>

                <p>ID: {assignedPlan.plan_id}</p>

                <Flex flexWrap="wrap" mt={6}>
                  <Button
                    bg="transparent"
                    color="#FFF"
                    boxShadow="0px 15px 30px rgba(0, 0, 0, 0.15)"
                    border="1px solid #FFFFFF"
                    variant="solid"
                    borderRadius="full"
                    w={270}
                    m={2}
                    onClick={() => {
                      onUnAttachPlanModalOpen();
                    }}
                    _hover={{ bg: '#535355' }}
                  >
                    Unattach Plan
                  </Button>

                  <Button
                    bg="transparent"
                    color="#FFF"
                    boxShadow="0px 15px 30px rgba(0, 0, 0, 0.15)"
                    border="1px solid #FFFFFF"
                    variant="solid"
                    borderRadius="full"
                    w={270}
                    m={2}
                    onClick={() => {
                      navigate(`/plan/${assignedPlan.plan_id}`);
                    }}
                    _hover={{ bg: '#535355' }}
                  >
                    Go to Plan
                  </Button>
                </Flex>
              </Box>

              <Box className="device-container">
                <h3 className="plan-heading">Device Data Usage</h3>
                {process.env.REACT_APP_DEV_FEATURES === 'true' ? (
                  <Flex justifyContent="center" flexWrap="wrap">
                    <Flex m={4} flexDirection="column" alignItems="center">
                      <h4>Data Traffic</h4>
                      <CircularProgress
                        mt={4}
                        value={40}
                        size="120px"
                        color="#dc2f5c"
                      >
                        <CircularProgressLabel>40%</CircularProgressLabel>
                      </CircularProgress>
                      <h4>13GB Remaining</h4>
                      <p>7GB / 20 GB</p>
                    </Flex>

                    <Flex m={4} flexDirection="column" alignItems="center">
                      <h4>Data Storage</h4>
                      <CircularProgress
                        mt={4}
                        value={40}
                        size="120px"
                        color="#dc2f5c"
                      >
                        <CircularProgressLabel>40%</CircularProgressLabel>
                      </CircularProgress>
                      <h4>13GB Remaining</h4>
                      <p>7GB / 20 GB</p>
                    </Flex>
                  </Flex>
                ) : (
                  <h4>COMING SOON</h4>
                )}
              </Box>
            </Flex>
          ) : (
            <Flex
              mt={12}
              justifyContent="center"
              className="device-page--details"
            >
              <Box className="device-container">
                <Flex alignItems="center" flexDirection="column">
                  <h2 className="no-plan">
                    This device has not assigned to any plan
                  </h2>
                  <Button
                    className="btn-group"
                    bg="#DB2F5CCC"
                    borderRadius="10px"
                    color="#FFF"
                    variant="solid"
                    w={200}
                    onClick={onAttachAPlanModalOpen}
                    _hover={{ bg: '#db2f5d99' }}
                  >
                    Attach a Plan
                  </Button>
                </Flex>
              </Box>
            </Flex>
          )}

          <Modal
            closeOnOverlayClick={false}
            isOpen={isDeleteDeviceModalOpen}
            onClose={onDeleteDeviceModalClose}
            size="2xl"
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Delete Device</ModalHeader>
              <ModalCloseButton isDisabled={isDisabled} />
              <ModalBody className="delete-device-modal">
                <p>Are you sure to delete this device?</p>
                <p className="device-id">
                  Serial Number: <span>{device.serial_number}</span>
                </p>
              </ModalBody>
              <ModalFooter>
                <Button
                  className="btn-group"
                  bg="#535355"
                  color="#FFF"
                  boxShadow="0px 15px 30px rgba(0, 0, 0, 0.15)"
                  variant="solid"
                  w={150}
                  mr={2}
                  isDisabled={isDisabled}
                  onClick={() => onDeleteDeviceModalClose()}
                  _hover={{ bg: '#242424' }}
                >
                  Cancel
                </Button>
                <Button
                  className="btn-group"
                  bg="#DB2F5CCC"
                  color="#FFF"
                  variant="solid"
                  w={150}
                  isLoading={isLoading}
                  isDisabled={isDisabled}
                  onClick={() => {
                    handleDeleteDevice(device.thing_id);
                  }}
                  _hover={{ bg: '#db2f5d99' }}
                >
                  Confirm
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          <Modal
            closeOnOverlayClick={false}
            isOpen={isResetConfigModalOpen}
            onClose={onResetConfigModalClose}
            size="2xl"
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Reset Configuration</ModalHeader>
              <ModalCloseButton isDisabled={isDisabled} />
              <ModalBody className="delete-device-modal">
                <p>
                  Are you sure you want to reset your EdgePi config? All the
                  INPUTS, OUTPUTS, TC, ... configurations will be reset to the
                  default values
                </p>
                <p className="device-id">
                  Serial Number: <span>{device.serial_number}</span>
                </p>
              </ModalBody>
              <ModalFooter>
                <Button
                  className="btn-group"
                  bg="#535355"
                  color="#FFF"
                  boxShadow="0px 15px 30px rgba(0, 0, 0, 0.15)"
                  variant="solid"
                  w={150}
                  mr={2}
                  isDisabled={isDisabled}
                  onClick={() => onResetConfigModalClose()}
                  _hover={{ bg: '#242424' }}
                >
                  Cancel
                </Button>
                <Button
                  className="btn-group"
                  bg="#DB2F5CCC"
                  color="#FFF"
                  variant="solid"
                  w={150}
                  isLoading={isLoading}
                  isDisabled={isDisabled}
                  onClick={() => {
                    handleResetConfig();
                  }}
                  _hover={{ bg: '#db2f5d99' }}
                >
                  Confirm
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          <Modal
            closeOnOverlayClick={false}
            isOpen={isUnAttachPlanModalOpen}
            onClose={onUnAttachPlanModalClose}
            size="2xl"
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Unattach Plan</ModalHeader>
              <ModalCloseButton isDisabled={isDisabled} />
              <ModalBody className="delete-device-modal">
                <p>Are you sure to unattach this plan?</p>
                <p className="device-id">
                  Plan ID: <span>{assignedPlan.plan_id}</span>
                </p>
              </ModalBody>
              <ModalFooter>
                <Button
                  className="btn-group"
                  bg="#535355"
                  color="#FFF"
                  boxShadow="0px 15px 30px rgba(0, 0, 0, 0.15)"
                  variant="solid"
                  w={150}
                  mr={2}
                  isDisabled={isDisabled}
                  onClick={() => onUnAttachPlanModalClose()}
                  _hover={{ bg: '#242424' }}
                >
                  Cancel
                </Button>
                <Button
                  className="btn-group"
                  bg="#DB2F5CCC"
                  color="#FFF"
                  variant="solid"
                  w={150}
                  isLoading={isLoading}
                  isDisabled={isDisabled}
                  onClick={() => {
                    handleUnAttachPlan();
                  }}
                  _hover={{ bg: '#db2f5d99' }}
                >
                  Confirm
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Container>
      ) : (
        <Container className="device-page--container-center">
          <CloudAnimation />
        </Container>
      )}
      <PlansModal
        isOpen={isAttachAPlanModalOpen}
        onClose={onAttachAPlanModalClose}
        plans={activePlans}
        userEmail={userAccounts.user_email}
        thingID={device.thing_id}
        refreshData={refreshData}
        refreshProductData={refreshProductData}
      />
    </Box>
  );
};

const mapStateToProps = (state) => ({
  activeDevices: state.user.activeDevices,
  activePlans: state.product.activePlans,
  userAccounts: state.user.accounts,
  refreshData: state.product.refreshData,
});

const mapDispatchToProps = (dispatch) => ({
  refreshProductData: (bool) => dispatch(refreshProductData(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Device);
