import { Auth } from 'aws-amplify';
import isNewBuild from './checkNewBuild';
import packageJson from '../../package.json';

const useCheckNewBuild = () => {
  const refreshCacheAndReload = () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then((names) => {
        for (const name of names) {
          caches.delete(name);
        }
      });
    }
    // sign out, delete browser cache and hard reload
    console.log('New build detected!');
    Auth.signOut();
  };

  fetch('/meta.json')
    .then((response) => response.json())
    .then((meta) => {
      if (!localStorage.getItem('buildDate')) {
        localStorage.setItem('buildDate', packageJson.buildDate);
      }

      const latestVersionDate = meta.buildDate;
      const localVersionDate =
        parseInt(localStorage.getItem('buildDate'), 10) ||
        packageJson.buildDate;

      const shouldForceRefresh = isNewBuild(
        latestVersionDate,
        localVersionDate
      );

      if (shouldForceRefresh) {
        localStorage.removeItem('buildDate');
        refreshCacheAndReload();
      }
    });
};

export default useCheckNewBuild;
