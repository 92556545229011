function getExponentValue(pC) {
  // Convert the number to a string to count the digits before the decimal point
  const numString = pC.toString();
  const parts = numString.split('.');

  // Count the digits before the decimal point
  const digitCount = parts[0].length;

  // If the number is less than 1000, the exponent is 0
  if (digitCount < 4) {
    return 0;
  }

  // Calculate the exponent as the largest multiple of 3 less than the digit count
  return digitCount - (digitCount % 3 === 0 ? 3 : digitCount % 3);
}

function pdMetricConversion(exponent) {
  let prefix;
  if (exponent === 3) {
    prefix = 'nC'; // NANO
  } else if (exponent === 6) {
    prefix = 'μC'; // MICRO
  } else if (exponent === 9) {
    prefix = 'mC'; // MILLI
  } else if (exponent === 12) {
    prefix = 'C'; // NONE
  } else if (exponent === 15) {
    prefix = 'kC'; // KILO
  } else if (exponent === 18) {
    prefix = 'MC'; // MEGA
  } else if (exponent === 21) {
    prefix = 'GC'; // GIGA
  } else if (exponent === 24) {
    prefix = 'TC'; // TERA
  } else {
    prefix = 'pC'; // DEFAULT
  }
  return prefix;
}

const pdConversion = (pCValue) => {
  const e = getExponentValue(pCValue);
  // write the value in scientific notation
  let a = e >= 3 ? pCValue / 10 ** e : pCValue;

  if (!Number.isInteger(a)) {
    // if the value is not an integer, round it to 2 decimal places
    a = a.toFixed(2);
  }
  // get the pc metric prefix from the exponent value
  const b = pdMetricConversion(e);

  return a + b;
};

export default pdConversion;
