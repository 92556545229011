import React, { useState, memo } from 'react';
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
} from '@chakra-ui/react';

const AUXConfigModal = ({ isOpen, onClose, handleSelectProtocol }) => {
  const [protocol, setProtocol] = useState('');

  const handleProtocol = () => {
    handleSelectProtocol(protocol);
    onClose();
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      onCloseComplete={() => {
        setProtocol('');
      }}
      size="4xl"
      className="adc-modal"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Auxiliary</ModalHeader>
        <ModalCloseButton />
        <ModalBody p={6}>
          <Box className="adc-modal--protocol">
            <Flex alignItems="center">
              <h4>Protocol :</h4>
            </Flex>
            <p>Select the protocol you would like to use</p>
            <Select
              w={270}
              mt={2}
              variant="filled"
              placeholder="Select a Protocol ..."
              onChange={(e) => setProtocol(e.target.value)}
              value={protocol}
            >
              <option value="modbus_ftx">Modbus - OSENSA FTX</option>
              <option value="modbus_pdx">Modbus - OSENSA PDX</option>
              <option value="modbus_rtu">Modbus RTU</option>

              <option value="modbus_ascii" disabled>
                Modbus ASCII (Coming soon ... )
              </option>
              <option value="modbus_tcp" disabled>
                Modbus TCP (Coming soon ... )
              </option>
            </Select>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn-group"
            bg="#535355"
            color="#FFF"
            boxShadow="0px 15px 30px rgba(0, 0, 0, 0.15)"
            variant="solid"
            w={150}
            mr={2}
            onClick={onClose}
            _hover={{ bg: '#242424' }}
          >
            Cancel
          </Button>

          <Button
            className="btn-group"
            bg="#DB2F5CCC"
            color="#FFF"
            variant="solid"
            w={150}
            isDisabled={protocol === ''}
            onClick={handleProtocol}
            _hover={{ bg: '#db2f5d99' }}
          >
            Next
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default memo(AUXConfigModal);
