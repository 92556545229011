import React, { useState, useCallback, useEffect, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import {
  Box,
  Flex,
  Image,
  Icon,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { SiIcloud } from 'react-icons/si';
import DACModal from './dac-modal/dac-modal.component';
import OutputChannelModal from './output-channel-modal/output-channel-modal.component';
import portalHandleRequest from '../../../../api/portalHandleRequest';
import handleLatestDataObj from '../../../../api/handleLatestDataObj';
import './outputs.styles.scss';

const PORTAL_API_ENDPOINT = process.env.REACT_APP_PORTAL_API;

const Outputs = ({
  thingID,
  data,
  resetData,
  config,
  DOUTConfig,
  DOUTData,
  PWMConfig,
  PWMData,
  OUTPUTSSelection,
  getConfig,
  getGrafanaURL,
}) => {
  const [latestDACData, setLatestDACData] = useState({});
  const [latestDOUTData, setLatestDOUTData] = useState({});
  const [latestPWMData, setLatestPWMData] = useState({});
  const [DACConfig, setDACConfig] = useState({});
  const [updatedChannels, setUpdatedChannels] = useState([]);
  const [updatedDOUTChannels, setUpdatedDOUTChannels] = useState([]);
  const [updatedPWMChannels, setUpdatedPWMChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState();
  const navigate = useNavigate();
  const toast = useToast();

  const {
    isOpen: isOutputChannelModalOpen,
    onOpen: onOutputChannelModalOpen,
    onClose: onOutputChannelModalClose,
  } = useDisclosure();

  const {
    isOpen: isDACModalOpen,
    onOpen: onDACModalOpen,
    onClose: onDACModalClose,
  } = useDisclosure();

  useEffect(() => {
    if (config) {
      setDACConfig(config);
    }
  }, [config]);

  useEffect(() => {
    if (resetData) {
      setLatestDACData({});
    }
  }, [resetData]);

  useEffect(() => {
    if (data.length > 0) {
      const res = handleLatestDataObj(data, latestDACData);
      if (res) {
        if (res.latestObj) {
          setLatestDACData(res.latestObj);
        } else {
          setUpdatedChannels(res.latestIndex);
          setLatestDACData(res.originalObject);
        }
      }
    } else {
      setLatestDACData({});
    }
  }, [data]);

  useEffect(() => {
    if (DOUTData.length > 0) {
      const res = handleLatestDataObj(DOUTData, latestDOUTData);
      if (res) {
        if (res.latestObj) {
          setLatestDOUTData(res.latestObj);
        } else {
          setUpdatedDOUTChannels(res.latestIndex);
          setLatestDOUTData(res.originalObject);
        }
      } else {
        setUpdatedDOUTChannels([]);
      }
    } else {
      setLatestDOUTData({});
    }
  }, [DOUTData]);

  useEffect(() => {
    const res = handleLatestDataObj(PWMData, latestPWMData);
    if (res) {
      if (res.latestObj) {
        setLatestPWMData(res.latestObj);
      } else {
        setUpdatedPWMChannels(res.latestIndex);
        setLatestPWMData(res.originalObject);
      }
    } else {
      setUpdatedPWMChannels([]);
    }
  }, [PWMData]);

  const handleUpdateConfig = useCallback(
    async (newDACConfig, newDOUTConfig, newOUTPUTSConfig) => {
      const desired = {};

      desired.DAC = newDACConfig;
      desired.DOUT = newDOUTConfig;
      desired.OUTPUTS = newOUTPUTSConfig;

      const obj = {
        method: 'PATCH',
        url: `${PORTAL_API_ENDPOINT}/shadows/${thingID}`,
        contentType: 'application/json',
        data: {
          state: {
            desired,
          },
        },
      };
      const res = await portalHandleRequest(obj, navigate);

      if (res.status === 200) {
        const retry = (fn, retriesLeft = 10, interval = 1000) => {
          return new Promise((resolve, reject) => {
            fn()
              .then((response) => {
                if (!response) {
                  // validation failed => invalid config
                  toast({
                    title:
                      'Invalid EdgePi config. Please try power cycling your EdgePi or contact tech support',
                    status: 'error',
                    position: 'top',
                    duration: 5000,
                    isClosable: true,
                  });
                  onOutputChannelModalClose();
                  onDACModalClose();
                } else {
                  const isOUTPUTSMatched = isEqual(
                    response.state.desired.OUTPUTS,
                    response.state.reported.OUTPUTS
                  );

                  const isDACConfigMatched = isEqual(
                    response.state.desired.DAC,
                    response.state.reported.DAC
                  );
                  const isDOUTConfigMatched = isEqual(
                    response.state.desired.DOUT,
                    response.state.reported.DOUT
                  );

                  if (
                    !isDACConfigMatched ||
                    !isDOUTConfigMatched ||
                    !isOUTPUTSMatched
                  ) {
                    console.log(
                      `Retry to get new reported config ... Remaining attempts: ${retriesLeft}`
                    );
                    setTimeout(() => {
                      if (retriesLeft === 1) {
                        console.log('Maximum retries exceeded');
                        toast({
                          title: 'Failed to setup Outputs configuration',
                          description:
                            'Please try again later or contact us for support',
                          status: 'error',
                          position: 'top',
                          duration: 10000,
                          isClosable: true,
                        });
                        onOutputChannelModalClose();
                        onDACModalClose();
                        return;
                      }
                      retry(fn, retriesLeft - 1, interval).then(
                        resolve,
                        reject
                      );
                    }, interval);
                  } else {
                    console.log('Updated successfully!');
                    toast({
                      title:
                        'New Outputs configuration has been setup successfully',
                      status: 'success',
                      position: 'top',
                      duration: 2000,
                      isClosable: true,
                    });
                    onOutputChannelModalClose();
                    onDACModalClose();
                    resolve();
                  }
                }
              })
              .catch((error) => {
                setTimeout(() => {
                  if (retriesLeft === 1) {
                    // maximum retries exceeded
                    toast({
                      title: 'Failed to setup Outputs configuration',
                      description:
                        'Please try again later or contact us for support',
                      status: 'error',
                      position: 'top',
                      duration: 10000,
                      isClosable: true,
                    });
                    reject(error);
                    onDACModalClose();
                    onOutputChannelModalClose();
                    return;
                  }
                  retry(fn, retriesLeft - 1, interval).then(resolve, reject);
                }, interval);
              });
          });
        };
        retry(() => getConfig());
      } else {
        toast({
          title: `Failed to update Outputs configuration. Status: ${res.status}`,
          description:
            res.Message || 'Please try again later or contact us for support',
          status: 'error',
          position: 'top',
          duration: 10000,
          isClosable: true,
        });
        onDACModalClose();
        onOutputChannelModalClose();
      }
    },
    []
  );

  const handleGrafanaURL = (PANEL) => {
    getGrafanaURL({ PANEL });
  };

  return (
    <Box className="dashboard-module" mt={2}>
      <div className="dashboard-module--heading">
        <h2>Outputs</h2>
      </div>
      {config ? (
        <>
          <Flex className="dashboard-module--dac" mt={8}>
            <Flex className="channel-orders" w={4}>
              <div className="channel">1</div>
              <div className="channel">2</div>
              <div className="channel">4</div>
              <div className="channel">5</div>
            </Flex>
            <Flex className="dac-type" flexDirection="column">
              <Flex
                className="dac-type--data"
                alignItems="center"
                flexWrap="wrap"
              >
                <div className="channel-mobile">1</div>
                <Flex
                  className="dac-type--channel"
                  alignItems="center"
                  flexWrap="wrap"
                >
                  {OUTPUTSSelection && OUTPUTSSelection.out_1 === 'dout' ? (
                    <h2>
                      <span className="channel-number">D-OUT 1</span> :
                      {Object.keys(DOUTConfig).length > 0 &&
                      DOUTConfig.dout_1.data_collection === 'enabled' ? (
                        <span
                          className={`${
                            updatedDOUTChannels.length > 0 &&
                            updatedDOUTChannels.includes('digital_out_1')
                              ? 'dac-value-updated'
                              : 'dac-value'
                          }`}
                          data-testid="test-dout-1"
                        >
                          {Object.keys(latestDOUTData).length > 0 &&
                          latestDOUTData.digital_out_1 !== null ? (
                            `${
                              latestDOUTData.digital_out_1 === 1
                                ? 'HIGH'
                                : latestDOUTData.digital_out_1 === 0
                                ? 'LOW'
                                : 'Hi-Z'
                            }`
                          ) : (
                            <span className="dac-value">--</span>
                          )}
                        </span>
                      ) : (
                        <span className="dac-value">N/A</span>
                      )}
                    </h2>
                  ) : OUTPUTSSelection && OUTPUTSSelection.out_1 === 'pwm' ? (
                    <h2>
                      <span className="channel-number">PWM 1</span> :
                      {Object.keys(PWMConfig).length > 0 &&
                      PWMConfig.pwm_1.data_collection === 'enabled' ? (
                        <span
                          className={`${
                            updatedPWMChannels.length > 0 &&
                            updatedPWMChannels.includes('pwm_1')
                              ? 'dac-value-updated'
                              : 'dac-value'
                          }`}
                        >
                          {latestPWMData &&
                          latestPWMData.pwm_1 !== null &&
                          latestPWMData.pwm_1 !== undefined ? (
                            `${latestPWMData.pwm_1.toFixed(4)}V`
                          ) : (
                            <span className="dac-value">--</span>
                          )}
                        </span>
                      ) : (
                        <span className="dac-value">N/A</span>
                      )}
                    </h2>
                  ) : (
                    <h2>
                      <span className="channel-number">A-OUT 1</span> :
                      {Object.keys(DACConfig).length > 0 &&
                      DACConfig.ch_1.data_collection === 'enabled' ? (
                        <span
                          className={`${
                            updatedChannels.length > 0 &&
                            updatedChannels.includes('ch_1_V')
                              ? 'dac-value-updated'
                              : 'dac-value'
                          }`}
                        >
                          {latestDACData &&
                          latestDACData.ch_1_V !== null &&
                          latestDACData.ch_1_V !== undefined ? (
                            `${latestDACData.ch_1_V.toFixed(4)}V`
                          ) : (
                            <span className="dac-value">--</span>
                          )}
                        </span>
                      ) : (
                        <span className="dac-value">N/A</span>
                      )}
                    </h2>
                  )}

                  <Flex className="dac-status">
                    <Tooltip
                      hasArrow
                      label={
                        Object.keys(DACConfig).length > 0 &&
                        DACConfig.ch_1.cloud_storage === 'enabled'
                          ? 'Cloud Storage Enabled'
                          : 'Cloud Storage Disabled'
                      }
                      bg="#242424"
                      color="white"
                    >
                      <Box h={6}>
                        <Icon
                          as={SiIcloud}
                          color={
                            Object.keys(DACConfig).length > 0 &&
                            DACConfig.ch_1.cloud_storage === 'enabled'
                              ? '#dc2f5c'
                              : '#6f6f6f'
                          }
                          className="cloud-status"
                          w={6}
                          h={6}
                        />
                      </Box>
                    </Tooltip>
                    <Tooltip
                      hasArrow
                      label="Channel Settings"
                      bg="#242424"
                      color="white"
                    >
                      <Image
                        boxSize="25px"
                        objectFit="contain"
                        src="/gear.png"
                        alt="settings"
                        className="setting-btn"
                        mr={2}
                        ml={2}
                        onClick={() => {
                          setSelectedChannel('ch_1');
                          onOutputChannelModalOpen();
                          getConfig();
                        }}
                      />
                    </Tooltip>
                    <Tooltip
                      hasArrow
                      label="View Graphs"
                      bg="#242424"
                      color="white"
                    >
                      <Image
                        className="chart-btn"
                        boxSize="20px"
                        objectFit="contain"
                        src="/grafana.png"
                        alt="grafana-chart"
                        onClick={() =>
                          handleGrafanaURL(
                            OUTPUTSSelection &&
                              OUTPUTSSelection.out_1 === 'dout'
                              ? 'DOUT'
                              : 'DAC'
                          )
                        }
                      />
                    </Tooltip>
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                className="dac-type--data"
                alignItems="center"
                flexWrap="wrap"
              >
                <div className="channel-mobile">2</div>

                <Flex
                  className="dac-type--channel"
                  alignItems="center"
                  flexWrap="wrap"
                >
                  {OUTPUTSSelection && OUTPUTSSelection.out_2 === 'dout' ? (
                    <h2>
                      <span className="channel-number">D-OUT 2</span> :
                      {Object.keys(DOUTConfig).length > 0 &&
                      DOUTConfig.dout_2.data_collection === 'enabled' ? (
                        <span
                          className={`${
                            updatedDOUTChannels.length > 0 &&
                            updatedDOUTChannels.includes('digital_out_2')
                              ? 'dac-value-updated'
                              : 'dac-value'
                          }`}
                        >
                          {Object.keys(latestDOUTData).length > 0 &&
                          latestDOUTData.digital_out_2 !== null ? (
                            `${
                              latestDOUTData.digital_out_2 === 1
                                ? 'HIGH'
                                : latestDOUTData.digital_out_2 === 0
                                ? 'LOW'
                                : 'Hi-Z'
                            }`
                          ) : (
                            <span className="dac-value">--</span>
                          )}
                        </span>
                      ) : (
                        <span className="dac-value">N/A</span>
                      )}
                    </h2>
                  ) : OUTPUTSSelection && OUTPUTSSelection.out_2 === 'pwm' ? (
                    <h2>
                      <span className="channel-number">PWM 2</span> :
                      {Object.keys(PWMConfig).length > 0 &&
                      PWMConfig.pwm_2.data_collection === 'enabled' ? (
                        <span
                          className={`${
                            updatedPWMChannels.length > 0 &&
                            updatedPWMChannels.includes('pwm_2')
                              ? 'dac-value-updated'
                              : 'dac-value'
                          }`}
                        >
                          {latestPWMData &&
                          latestPWMData.pwm_2 !== null &&
                          latestPWMData.pwm_2 !== undefined ? (
                            `${latestPWMData.pwm_2.toFixed(4)}`
                          ) : (
                            <span className="dac-value">--</span>
                          )}
                        </span>
                      ) : (
                        <span className="dac-value">N/A</span>
                      )}
                    </h2>
                  ) : (
                    <h2>
                      <span className="channel-number">A-OUT 2</span> :
                      {Object.keys(DACConfig).length > 0 &&
                      DACConfig.ch_2.data_collection === 'enabled' ? (
                        <span
                          className={`${
                            updatedChannels.length > 0 &&
                            updatedChannels.includes('ch_2_V')
                              ? 'dac-value-updated'
                              : 'dac-value'
                          }`}
                        >
                          {latestDACData &&
                          latestDACData.ch_2_V !== null &&
                          latestDACData.ch_2_V !== undefined ? (
                            `${latestDACData.ch_2_V.toFixed(4)}V`
                          ) : (
                            <span className="dac-value">--</span>
                          )}
                        </span>
                      ) : (
                        <span className="dac-value">N/A</span>
                      )}
                    </h2>
                  )}
                  <Flex className="dac-status">
                    <Tooltip
                      hasArrow
                      label={
                        Object.keys(DACConfig).length > 0 &&
                        DACConfig.ch_2.cloud_storage === 'enabled'
                          ? 'Cloud Storage Enabled'
                          : 'Cloud Storage Disabled'
                      }
                      bg="#242424"
                      color="white"
                    >
                      <Box h={6}>
                        <Icon
                          as={SiIcloud}
                          color={
                            Object.keys(DACConfig).length > 0 &&
                            DACConfig.ch_2.cloud_storage === 'enabled'
                              ? '#dc2f5c'
                              : '#6f6f6f'
                          }
                          className="cloud-status"
                          w={6}
                          h={6}
                        />
                      </Box>
                    </Tooltip>
                    <Tooltip
                      hasArrow
                      label="Channel Settings"
                      bg="#242424"
                      color="white"
                    >
                      <Image
                        boxSize="25px"
                        objectFit="contain"
                        src="/gear.png"
                        alt="settings"
                        className="setting-btn"
                        mr={2}
                        ml={2}
                        onClick={() => {
                          setSelectedChannel('ch_2');
                          onOutputChannelModalOpen();
                          getConfig();
                        }}
                      />
                    </Tooltip>
                    <Tooltip
                      hasArrow
                      label="View Graphs"
                      bg="#242424"
                      color="white"
                    >
                      <Image
                        className="chart-btn"
                        boxSize="20px"
                        objectFit="contain"
                        src="/grafana.png"
                        alt="grafana-chart"
                        onClick={() =>
                          handleGrafanaURL(
                            OUTPUTSSelection &&
                              OUTPUTSSelection.out_2 === 'dout'
                              ? 'DOUT'
                              : 'DAC'
                          )
                        }
                      />
                    </Tooltip>
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                className="dac-type--data"
                alignItems="center"
                flexWrap="wrap"
              >
                <div className="channel-mobile">3</div>
                <Flex
                  className="dac-type--channel"
                  alignItems="center"
                  flexWrap="wrap"
                >
                  {OUTPUTSSelection && OUTPUTSSelection.out_3 === 'dout' ? (
                    <h2>
                      <span className="channel-number">D-OUT 3</span> :
                      {Object.keys(DOUTConfig).length > 0 &&
                      DOUTConfig.dout_3.data_collection === 'enabled' ? (
                        <span
                          className={`${
                            updatedDOUTChannels.length > 0 &&
                            updatedDOUTChannels.includes('digital_out_3')
                              ? 'dac-value-updated'
                              : 'dac-value'
                          }`}
                        >
                          {Object.keys(latestDOUTData).length > 0 &&
                          latestDOUTData.digital_out_3 !== null ? (
                            `${
                              latestDOUTData.digital_out_3 === 1
                                ? 'HIGH'
                                : latestDOUTData.digital_out_3 === 0
                                ? 'LOW'
                                : 'Hi-Z'
                            }`
                          ) : (
                            <span className="dac-value">--</span>
                          )}
                        </span>
                      ) : (
                        <span className="dac-value">N/A</span>
                      )}
                    </h2>
                  ) : (
                    <h2>
                      <span className="channel-number">A-OUT 3</span> :
                      {Object.keys(DACConfig).length > 0 &&
                      DACConfig.ch_3.data_collection === 'enabled' ? (
                        <span
                          className={`${
                            updatedChannels.length > 0 &&
                            updatedChannels.includes('ch_3_V')
                              ? 'dac-value-updated'
                              : 'dac-value'
                          }`}
                        >
                          {latestDACData &&
                          latestDACData.ch_3_V !== null &&
                          latestDACData.ch_3_V !== undefined ? (
                            `${latestDACData.ch_3_V.toFixed(4)}V`
                          ) : (
                            <span className="dac-value">--</span>
                          )}
                        </span>
                      ) : (
                        <span className="dac-value">N/A</span>
                      )}
                    </h2>
                  )}

                  <Flex className="dac-status">
                    <Tooltip
                      hasArrow
                      label={
                        Object.keys(DACConfig).length > 0 &&
                        DACConfig.ch_3.cloud_storage === 'enabled'
                          ? 'Cloud Storage Enabled'
                          : 'Cloud Storage Disabled'
                      }
                      bg="#242424"
                      color="white"
                    >
                      <Box h={6}>
                        <Icon
                          as={SiIcloud}
                          color={
                            Object.keys(DACConfig).length > 0 &&
                            DACConfig.ch_3.cloud_storage === 'enabled'
                              ? '#dc2f5c'
                              : '#6f6f6f'
                          }
                          className="cloud-status"
                          w={6}
                          h={6}
                        />
                      </Box>
                    </Tooltip>
                    <Tooltip
                      hasArrow
                      label="Channel Settings"
                      bg="#242424"
                      color="white"
                    >
                      <Image
                        boxSize="25px"
                        objectFit="contain"
                        src="/gear.png"
                        alt="settings"
                        className="setting-btn"
                        mr={2}
                        ml={2}
                        onClick={() => {
                          setSelectedChannel('ch_3');
                          onOutputChannelModalOpen();
                          getConfig();
                        }}
                      />
                    </Tooltip>
                    <Tooltip
                      hasArrow
                      label="View Graphs"
                      bg="#242424"
                      color="white"
                    >
                      <Image
                        className="chart-btn"
                        boxSize="20px"
                        objectFit="contain"
                        src="/grafana.png"
                        alt="grafana-chart"
                        onClick={() =>
                          handleGrafanaURL(
                            OUTPUTSSelection &&
                              OUTPUTSSelection.out_3 === 'dout'
                              ? 'DOUT'
                              : 'DAC'
                          )
                        }
                      />
                    </Tooltip>
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                className="dac-type--data"
                alignItems="center"
                flexWrap="wrap"
              >
                <div className="channel-mobile">4</div>
                <Flex
                  className="dac-type--channel"
                  alignItems="center"
                  flexWrap="wrap"
                >
                  {OUTPUTSSelection && OUTPUTSSelection.out_4 === 'dout' ? (
                    <h2>
                      <span className="channel-number">D-OUT 4</span> :
                      {Object.keys(DOUTConfig).length > 0 &&
                      DOUTConfig.dout_4.data_collection === 'enabled' ? (
                        <span
                          className={`${
                            updatedDOUTChannels.length > 0 &&
                            updatedDOUTChannels.includes('digital_out_4')
                              ? 'dac-value-updated'
                              : 'dac-value'
                          }`}
                        >
                          {Object.keys(latestDOUTData).length > 0 &&
                          latestDOUTData.digital_out_4 !== null ? (
                            `${
                              latestDOUTData.digital_out_4 === 1
                                ? 'HIGH'
                                : latestDOUTData.digital_out_4 === 0
                                ? 'LOW'
                                : 'Hi-Z'
                            }`
                          ) : (
                            <span className="dac-value">--</span>
                          )}
                        </span>
                      ) : (
                        <span className="dac-value">N/A</span>
                      )}
                    </h2>
                  ) : (
                    <h2>
                      <span className="channel-number">A-OUT 4</span> :
                      {Object.keys(DACConfig).length > 0 &&
                      DACConfig.ch_4.data_collection === 'enabled' ? (
                        <span
                          className={`${
                            updatedChannels.length > 0 &&
                            updatedChannels.includes('ch_4_V')
                              ? 'dac-value-updated'
                              : 'dac-value'
                          }`}
                        >
                          {latestDACData &&
                          latestDACData.ch_4_V !== null &&
                          latestDACData.ch_4_V !== undefined ? (
                            `${latestDACData.ch_4_V.toFixed(4)}V`
                          ) : (
                            <span className="dac-value">--</span>
                          )}
                        </span>
                      ) : (
                        <span className="dac-value">N/A</span>
                      )}
                    </h2>
                  )}

                  <Flex className="dac-status">
                    <Tooltip
                      hasArrow
                      label={
                        Object.keys(DACConfig).length > 0 &&
                        DACConfig.ch_4.cloud_storage === 'enabled'
                          ? 'Cloud Storage Enabled'
                          : 'Cloud Storage Disabled'
                      }
                      bg="#242424"
                      color="white"
                    >
                      <Box h={6}>
                        <Icon
                          as={SiIcloud}
                          color={
                            Object.keys(DACConfig).length > 0 &&
                            DACConfig.ch_4.cloud_storage === 'enabled'
                              ? '#dc2f5c'
                              : '#6f6f6f'
                          }
                          className="cloud-status"
                          w={6}
                          h={6}
                        />
                      </Box>
                    </Tooltip>
                    <Tooltip
                      hasArrow
                      label="Channel Settings"
                      bg="#242424"
                      color="white"
                    >
                      <Image
                        boxSize="25px"
                        objectFit="contain"
                        src="/gear.png"
                        alt="settings"
                        className="setting-btn"
                        mr={2}
                        ml={2}
                        onClick={() => {
                          setSelectedChannel('ch_4');
                          onOutputChannelModalOpen();
                          getConfig();
                        }}
                      />
                    </Tooltip>
                    <Tooltip
                      hasArrow
                      label="View Graphs"
                      bg="#242424"
                      color="white"
                    >
                      <Image
                        className="chart-btn"
                        boxSize="20px"
                        objectFit="contain"
                        src="/grafana.png"
                        alt="grafana-chart"
                        onClick={() =>
                          handleGrafanaURL(
                            OUTPUTSSelection &&
                              OUTPUTSSelection.out_4 === 'dout'
                              ? 'DOUT'
                              : 'DAC'
                          )
                        }
                      />
                    </Tooltip>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          <Flex className="dashboard-module--dac">
            <Flex className="channel-orders" w={4}>
              <div className="channel">6</div>
              <div className="channel">7</div>
              <div className="channel">9</div>
              <div className="channel">10</div>
            </Flex>
            <Flex className="dac-type" flexDirection="column">
              <Flex
                className="dac-type--data"
                alignItems="center"
                flexWrap="wrap"
              >
                <div className="channel-mobile">5</div>
                <Flex
                  className="dac-type--channel"
                  alignItems="center"
                  flexWrap="wrap"
                >
                  {OUTPUTSSelection && OUTPUTSSelection.out_5 === 'dout' ? (
                    <h2>
                      <span className="channel-number">D-OUT 5</span> :
                      {Object.keys(DOUTConfig).length > 0 &&
                      DOUTConfig.dout_5.data_collection === 'enabled' ? (
                        <span
                          className={`${
                            updatedDOUTChannels.length > 0 &&
                            updatedDOUTChannels.includes('digital_out_5')
                              ? 'dac-value-updated'
                              : 'dac-value'
                          }`}
                        >
                          {Object.keys(latestDOUTData).length > 0 &&
                          latestDOUTData.digital_out_5 !== null ? (
                            `${
                              latestDOUTData.digital_out_5 === 1
                                ? 'HIGH'
                                : latestDOUTData.digital_out_5 === 0
                                ? 'LOW'
                                : 'Hi-Z'
                            }`
                          ) : (
                            <span className="dac-value">--</span>
                          )}
                        </span>
                      ) : (
                        <span className="dac-value">N/A</span>
                      )}
                    </h2>
                  ) : (
                    <h2>
                      <span className="channel-number">A-OUT 5</span> :
                      {Object.keys(DACConfig).length > 0 &&
                      DACConfig.ch_5.data_collection === 'enabled' ? (
                        <span
                          className={`${
                            updatedChannels.length > 0 &&
                            updatedChannels.includes('ch_5_V')
                              ? 'dac-value-updated'
                              : 'dac-value'
                          }`}
                          data-testid="test-aout-5"
                        >
                          {latestDACData &&
                          latestDACData.ch_5_V !== null &&
                          latestDACData.ch_5_V !== undefined ? (
                            `${latestDACData.ch_5_V.toFixed(4)}V`
                          ) : (
                            <span className="dac-value">--</span>
                          )}
                        </span>
                      ) : (
                        <span className="dac-value">N/A</span>
                      )}
                    </h2>
                  )}

                  <Flex className="dac-status">
                    <Tooltip
                      hasArrow
                      label={
                        Object.keys(DACConfig).length > 0 &&
                        DACConfig.ch_5.cloud_storage === 'enabled'
                          ? 'Cloud Storage Enabled'
                          : 'Cloud Storage Disabled'
                      }
                      bg="#242424"
                      color="white"
                    >
                      <Box h={6}>
                        <Icon
                          as={SiIcloud}
                          color={
                            Object.keys(DACConfig).length > 0 &&
                            DACConfig.ch_5.cloud_storage === 'enabled'
                              ? '#dc2f5c'
                              : '#6f6f6f'
                          }
                          className="cloud-status"
                          w={6}
                          h={6}
                        />
                      </Box>
                    </Tooltip>
                    <Tooltip
                      hasArrow
                      label="Channel Settings"
                      bg="#242424"
                      color="white"
                    >
                      <Image
                        boxSize="25px"
                        objectFit="contain"
                        src="/gear.png"
                        alt="settings"
                        className="setting-btn"
                        mr={2}
                        ml={2}
                        onClick={() => {
                          setSelectedChannel('ch_5');
                          onOutputChannelModalOpen();
                          getConfig();
                        }}
                      />
                    </Tooltip>
                    <Tooltip
                      hasArrow
                      label="View Graphs"
                      bg="#242424"
                      color="white"
                    >
                      <Image
                        className="chart-btn"
                        boxSize="20px"
                        objectFit="contain"
                        src="/grafana.png"
                        alt="grafana-chart"
                        onClick={() =>
                          handleGrafanaURL(
                            OUTPUTSSelection &&
                              OUTPUTSSelection.out_5 === 'dout'
                              ? 'DOUT'
                              : 'DAC'
                          )
                        }
                      />
                    </Tooltip>
                  </Flex>
                </Flex>
              </Flex>

              <Flex
                className="dac-type--data"
                alignItems="center"
                flexWrap="wrap"
              >
                <div className="channel-mobile">6</div>

                <Flex
                  className="dac-type--channel"
                  alignItems="center"
                  flexWrap="wrap"
                >
                  {OUTPUTSSelection && OUTPUTSSelection.out_6 === 'dout' ? (
                    <h2>
                      <span className="channel-number">D-OUT 6</span> :
                      {Object.keys(DOUTConfig).length > 0 &&
                      DOUTConfig.dout_6.data_collection === 'enabled' ? (
                        <span
                          className={`${
                            updatedDOUTChannels.length > 0 &&
                            updatedDOUTChannels.includes('digital_out_6')
                              ? 'dac-value-updated'
                              : 'dac-value'
                          }`}
                        >
                          {Object.keys(latestDOUTData).length > 0 &&
                          latestDOUTData.digital_out_6 !== null ? (
                            `${
                              latestDOUTData.digital_out_6 === 1
                                ? 'HIGH'
                                : latestDOUTData.digital_out_6 === 0
                                ? 'LOW'
                                : 'Hi-Z'
                            }`
                          ) : (
                            <span className="dac-value">--</span>
                          )}
                        </span>
                      ) : (
                        <span className="dac-value">N/A</span>
                      )}
                    </h2>
                  ) : (
                    <h2 data-testid="test-aout-6">
                      <span className="channel-number">A-OUT 6</span> :
                      {Object.keys(DACConfig).length > 0 &&
                      DACConfig.ch_6.data_collection === 'enabled' ? (
                        <span
                          className={`${
                            updatedChannels.length > 0 &&
                            updatedChannels.includes('ch_6_V')
                              ? 'dac-value-updated'
                              : 'dac-value'
                          }`}
                        >
                          {latestDACData &&
                          latestDACData.ch_6_V !== null &&
                          latestDACData.ch_6_V !== undefined ? (
                            `${latestDACData.ch_6_V.toFixed(4)}V`
                          ) : (
                            <span className="dac-value">--</span>
                          )}
                        </span>
                      ) : (
                        <span className="dac-value">N/A</span>
                      )}
                    </h2>
                  )}
                  <Flex className="dac-status">
                    <Tooltip
                      hasArrow
                      label={
                        Object.keys(DACConfig).length > 0 &&
                        DACConfig.ch_6.cloud_storage === 'enabled'
                          ? 'Cloud Storage Enabled'
                          : 'Cloud Storage Disabled'
                      }
                      bg="#242424"
                      color="white"
                    >
                      <Box h={6}>
                        <Icon
                          as={SiIcloud}
                          color={
                            Object.keys(DACConfig).length > 0 &&
                            DACConfig.ch_6.cloud_storage === 'enabled'
                              ? '#dc2f5c'
                              : '#6f6f6f'
                          }
                          className="cloud-status"
                          w={6}
                          h={6}
                        />
                      </Box>
                    </Tooltip>
                    <Tooltip
                      hasArrow
                      label="Channel Settings"
                      bg="#242424"
                      color="white"
                    >
                      <Image
                        boxSize="25px"
                        objectFit="contain"
                        src="/gear.png"
                        alt="settings"
                        className="setting-btn"
                        mr={2}
                        ml={2}
                        onClick={() => {
                          setSelectedChannel('ch_6');
                          onOutputChannelModalOpen();
                          getConfig();
                        }}
                      />
                    </Tooltip>
                    <Tooltip
                      hasArrow
                      label="View Graphs"
                      bg="#242424"
                      color="white"
                    >
                      <Image
                        className="chart-btn"
                        boxSize="20px"
                        objectFit="contain"
                        src="/grafana.png"
                        alt="grafana-chart"
                        onClick={() =>
                          handleGrafanaURL(
                            OUTPUTSSelection &&
                              OUTPUTSSelection.out_6 === 'dout'
                              ? 'DOUT'
                              : 'DAC'
                          )
                        }
                      />
                    </Tooltip>
                  </Flex>{' '}
                </Flex>
              </Flex>

              <Flex
                className="dac-type--data"
                alignItems="center"
                flexWrap="wrap"
              >
                <div className="channel-mobile">7</div>
                <Flex
                  className="dac-type--channel"
                  alignItems="center"
                  flexWrap="wrap"
                >
                  {OUTPUTSSelection && OUTPUTSSelection.out_7 === 'dout' ? (
                    <h2>
                      <span className="channel-number">D-OUT 7</span> :
                      {Object.keys(DOUTConfig).length > 0 &&
                      DOUTConfig.dout_7.data_collection === 'enabled' ? (
                        <span
                          className={`${
                            updatedDOUTChannels.length > 0 &&
                            updatedDOUTChannels.includes('digital_out_7')
                              ? 'dac-value-updated'
                              : 'dac-value'
                          }`}
                        >
                          {Object.keys(latestDOUTData).length > 0 &&
                          latestDOUTData.digital_out_7 !== null ? (
                            `${
                              latestDOUTData.digital_out_7 === 1
                                ? 'HIGH'
                                : latestDOUTData.digital_out_7 === 0
                                ? 'LOW'
                                : 'Hi-Z'
                            }`
                          ) : (
                            <span className="dac-value">--</span>
                          )}
                        </span>
                      ) : (
                        <span className="dac-value">N/A</span>
                      )}
                    </h2>
                  ) : (
                    <h2>
                      <span className="channel-number">A-OUT 7</span> :
                      {Object.keys(DACConfig).length > 0 &&
                      DACConfig.ch_7.data_collection === 'enabled' ? (
                        <span
                          className={`${
                            updatedChannels.length > 0 &&
                            updatedChannels.includes('ch_7_V')
                              ? 'dac-value-updated'
                              : 'dac-value'
                          }`}
                        >
                          {latestDACData &&
                          latestDACData.ch_7_V !== null &&
                          latestDACData.ch_7_V !== undefined ? (
                            `${latestDACData.ch_7_V.toFixed(4)}V`
                          ) : (
                            <span className="dac-value">--</span>
                          )}
                        </span>
                      ) : (
                        <span className="dac-value">N/A</span>
                      )}
                    </h2>
                  )}

                  <Flex className="dac-status">
                    <Tooltip
                      hasArrow
                      label={
                        Object.keys(DACConfig).length > 0 &&
                        DACConfig.ch_7.cloud_storage === 'enabled'
                          ? 'Cloud Storage Enabled'
                          : 'Cloud Storage Disabled'
                      }
                      bg="#242424"
                      color="white"
                    >
                      <Box h={6}>
                        <Icon
                          as={SiIcloud}
                          color={
                            Object.keys(DACConfig).length > 0 &&
                            DACConfig.ch_7.cloud_storage === 'enabled'
                              ? '#dc2f5c'
                              : '#6f6f6f'
                          }
                          className="cloud-status"
                          w={6}
                          h={6}
                        />
                      </Box>
                    </Tooltip>
                    <Tooltip
                      hasArrow
                      label="Channel Settings"
                      bg="#242424"
                      color="white"
                    >
                      <Image
                        boxSize="25px"
                        objectFit="contain"
                        src="/gear.png"
                        alt="settings"
                        className="setting-btn"
                        mr={2}
                        ml={2}
                        onClick={() => {
                          setSelectedChannel('ch_7');
                          onOutputChannelModalOpen();
                          getConfig();
                        }}
                      />
                    </Tooltip>
                    <Tooltip
                      hasArrow
                      label="View Graphs"
                      bg="#242424"
                      color="white"
                    >
                      <Image
                        className="chart-btn"
                        boxSize="20px"
                        objectFit="contain"
                        src="/grafana.png"
                        alt="grafana-chart"
                        onClick={() =>
                          handleGrafanaURL(
                            OUTPUTSSelection &&
                              OUTPUTSSelection.out_7 === 'dout'
                              ? 'DOUT'
                              : 'DAC'
                          )
                        }
                      />
                    </Tooltip>
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                className="dac-type--data"
                alignItems="center"
                flexWrap="wrap"
              >
                <div className="channel-mobile">8</div>
                <Flex
                  className="dac-type--channel"
                  alignItems="center"
                  flexWrap="wrap"
                >
                  {OUTPUTSSelection && OUTPUTSSelection.out_8 === 'dout' ? (
                    <h2>
                      <span className="channel-number">D-OUT 8</span> :
                      {Object.keys(DOUTConfig).length > 0 &&
                      DOUTConfig.dout_8.data_collection === 'enabled' ? (
                        <span
                          className={`${
                            updatedDOUTChannels.length > 0 &&
                            updatedDOUTChannels.includes('digital_out_8')
                              ? 'dac-value-updated'
                              : 'dac-value'
                          }`}
                        >
                          {Object.keys(latestDOUTData).length > 0 &&
                          latestDOUTData.digital_out_8 !== null ? (
                            `${
                              latestDOUTData.digital_out_8 === 1
                                ? 'HIGH'
                                : latestDOUTData.digital_out_8 === 0
                                ? 'LOW'
                                : 'Hi-Z'
                            }`
                          ) : (
                            <span className="dac-value">--</span>
                          )}
                        </span>
                      ) : (
                        <span className="dac-value">N/A</span>
                      )}
                    </h2>
                  ) : (
                    <h2>
                      <span className="channel-number">A-OUT 8</span> :
                      {Object.keys(DACConfig).length > 0 &&
                      DACConfig.ch_8.data_collection === 'enabled' ? (
                        <span
                          className={`${
                            updatedChannels.length > 0 &&
                            updatedChannels.includes('ch_8_V')
                              ? 'dac-value-updated'
                              : 'dac-value'
                          }`}
                          data-testid="test-aout-8"
                        >
                          {latestDACData &&
                          latestDACData.ch_8_V !== null &&
                          latestDACData.ch_8_V !== undefined ? (
                            `${latestDACData.ch_8_V.toFixed(4)}V`
                          ) : (
                            <span className="dac-value">--</span>
                          )}
                        </span>
                      ) : (
                        <span className="dac-value">N/A</span>
                      )}
                    </h2>
                  )}

                  <Flex className="dac-status">
                    <Tooltip
                      hasArrow
                      label={
                        Object.keys(DACConfig).length > 0 &&
                        DACConfig.ch_8.cloud_storage === 'enabled'
                          ? 'Cloud Storage Enabled'
                          : 'Cloud Storage Disabled'
                      }
                      bg="#242424"
                      color="white"
                    >
                      <Box h={6}>
                        <Icon
                          as={SiIcloud}
                          color={
                            Object.keys(DACConfig).length > 0 &&
                            DACConfig.ch_8.cloud_storage === 'enabled'
                              ? '#dc2f5c'
                              : '#6f6f6f'
                          }
                          className="cloud-status"
                          w={6}
                          h={6}
                        />
                      </Box>
                    </Tooltip>
                    <Tooltip
                      hasArrow
                      label="Channel Settings"
                      bg="#242424"
                      color="white"
                    >
                      <Image
                        boxSize="25px"
                        objectFit="contain"
                        src="/gear.png"
                        alt="settings"
                        className="setting-btn"
                        mr={2}
                        ml={2}
                        onClick={() => {
                          setSelectedChannel('ch_8');
                          onOutputChannelModalOpen();
                          getConfig();
                        }}
                      />
                    </Tooltip>
                    <Tooltip
                      hasArrow
                      label="View Graphs"
                      bg="#242424"
                      color="white"
                    >
                      <Image
                        className="chart-btn"
                        boxSize="20px"
                        objectFit="contain"
                        src="/grafana.png"
                        alt="grafana-chart"
                        onClick={() =>
                          handleGrafanaURL(
                            OUTPUTSSelection &&
                              OUTPUTSSelection.out_8 === 'dout'
                              ? 'DOUT'
                              : 'DAC'
                          )
                        }
                      />
                    </Tooltip>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex className="dashboard-module--footer" w="100%">
            <Flex p={3} w="100%" alignItems="center" justifyContent="flex-end">
              <Tooltip
                hasArrow
                label="Module Settings"
                bg="#242424"
                color="white"
              >
                <Image
                  boxSize="30px"
                  objectFit="contain"
                  src="/gear.png"
                  alt="settings"
                  className="setting-btn"
                  onClick={() => {
                    onDACModalOpen();
                  }}
                />
              </Tooltip>
            </Flex>
          </Flex>
        </>
      ) : (
        <Flex className="dashboard-module--na" mt={8}>
          <h4>Module is not available</h4>
        </Flex>
      )}

      <DACModal
        isOpen={isDACModalOpen}
        handleUpdateModuleConfig={handleUpdateConfig}
        onClose={onDACModalClose}
        DAC={config || ''}
        DOUT={DOUTConfig || ''}
        OUTPUTS={OUTPUTSSelection || ''}
      />
      <OutputChannelModal
        isOpen={isOutputChannelModalOpen}
        onClose={onOutputChannelModalClose}
        selectedChannel={selectedChannel}
        DAC={config || ''}
        DOUT={DOUTConfig || ''}
        PWM={PWMConfig || ''}
        OUTPUTS={OUTPUTSSelection || ''}
        handleUpdateChannelConfig={handleUpdateConfig}
      />
    </Box>
  );
};

export default memo(Outputs);
