import React, { useEffect, memo } from 'react';
import {
  Box,
  Flex,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react';
import useForm from '../../../../../api/useForm';

const PDXMappingList = ({ list, handleUpdateList, selectedModel }) => {
  const { onStart, onChange, values } = useForm(null, {});

  useEffect(() => {
    const listOptions = {
      CH_A: 'CH_A',
      CH_B: 'CH_B',
      CH_C: 'CH_C',
      CH_D: 'CH_D',
      CH_E: 'CH_E',
      CH_F: 'CH_F',
      CH_G: 'CH_G',
      CH_H: 'CH_H',
    };

    for (const key in list) {
      if (
        Object.prototype.hasOwnProperty.call(list, key) &&
        Object.prototype.hasOwnProperty.call(listOptions, key) &&
        list[key] !== ''
      ) {
        listOptions[key] = list[key];
      }
    }
    onStart(listOptions);
  }, [selectedModel]);

  useEffect(() => {
    handleUpdateList(values);
  }, [values]);

  return (
    <>
      <Flex flexWrap="wrap" alignItems="flex-end" />
      <Box className="mapping-container">
        <Box>
          <TableContainer className="mapping-list">
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th w={40}>Channel</Th>
                  <Th>Name</Th>
                </Tr>
              </Thead>

              <Tbody>
                {Object.entries(values).map(([channel, name]) => (
                  <Tr key={channel}>
                    <Td w={40}>{channel}</Td>
                    <Td>
                      <Input
                        width={320}
                        mt={1}
                        type="text"
                        isInvalid={!name}
                        maxLength={20}
                        placeholder="e.g. Living Room"
                        value={name}
                        onChange={onChange(channel)}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
};
export default memo(PDXMappingList);
