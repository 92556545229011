import React from 'react';
import { Container, Icon } from '@chakra-ui/react';
import { FaShoppingCart } from 'react-icons/fa';
import './no-order.styles.scss';

const NoOrder = () => (
  <Container maxW="full" className="error-container">
    <Icon as={FaShoppingCart} w={24} h={24} mb={4} />
    <h2 className="no-order-message">You haven’t placed any orders yet.</h2>
    <p>
      The EdgePi Portal will be made available to you once an order has been
      placed.
    </p>
    <p>To make a purchase, visit our storefront at</p>
    <p className="store-link">
      <a
        href={`${process.env.REACT_APP_STORE_URL}/shop`}
        target="_blank"
        rel="noreferrer"
      >
        {process.env.REACT_APP_STORE_URL}/shop
      </a>
    </p>
  </Container>
);

export default NoOrder;
