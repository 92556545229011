import { Auth } from 'aws-amplify';

const checkAuthentication = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return user;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export default checkAuthentication;
