/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
const removeUnusedFormatKeys = (AUXDeviceID, config) => {
  const usedFormatKeys = new Set();

  // Collect all used format_ids
  for (const blockKey in config.read_blocks) {
    if (
      Object.prototype.hasOwnProperty.call(config.read_blocks, blockKey) &&
      config.read_blocks[blockKey] !== null
    ) {
      const block = config.read_blocks[blockKey];
      const attributes = block.read_attributes;
      for (const attributeKey in attributes) {
        if (
          Object.prototype.hasOwnProperty.call(attributes, attributeKey) &&
          attributes[attributeKey] !== null
        ) {
          const attribute = attributes[attributeKey];
          usedFormatKeys.add(attribute.format_id);
        }
      }
    }
  }

  // Remove unused format keys

  for (const formatKey in config.formats) {
    if (!usedFormatKeys.has(formatKey)) {
      if (AUXDeviceID) {
        config.formats[formatKey] = null;
      } else {
        delete config.formats[formatKey];
      }
    }
  }

  return config;
};

export default removeUnusedFormatKeys;
