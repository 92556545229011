import React, { memo } from 'react';
import {
  Box,
  Button,
  Flex,
  Input,
  Select,
  SimpleGrid,
  Stack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/react';
import { GoArrowLeft, GoTrash } from 'react-icons/go';

const ModbusRTUFormat = ({
  checkValidField,
  formatList,
  currentFormat,
  selectedPreset,
  activeAttribute,
  handleLoadPreset,
  handleDeleteFormat,
  handleSaveFormat,
  setReadBlockIndex,
  handleUpdateFormat,
}) => (
  <Flex className="adc-modal--item" flexDirection="column" flex="1">
    <Flex justifyContent="center" flexDirection="column">
      <Stack direction="row" justifyContent="space-between" spacing={4} mb={4}>
        <Button
          leftIcon={<GoArrowLeft />}
          colorScheme="white"
          variant="outline"
          w={100}
          onClick={() => setReadBlockIndex(2)}
        >
          Back
        </Button>

        <Popover>
          <PopoverTrigger>
            <Button
              colorScheme="red"
              variant="outline"
              w={180}
              mr={2}
              leftIcon={<GoTrash />}
            >
              Delete Format
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>Confirmation!</PopoverHeader>
            <PopoverBody>
              Are you sure you want to delete this format{' '}
              {Object.keys(formatList).length > 0 && (
                <span className="item_name">
                  {formatList[currentFormat].format_name} ?
                </span>
              )}
              <h5>
                You need to reassign another format for any attributes currently
                using this format.
              </h5>
            </PopoverBody>
            <PopoverFooter>
              <Button colorScheme="blue" onClick={handleDeleteFormat}>
                Yes, delete it
              </Button>
            </PopoverFooter>
          </PopoverContent>
        </Popover>
      </Stack>
      <Flex flexDirection="column" mb={8}>
        <h4>Load Preset :</h4>
        <Select
          bg="#535355"
          borderColor="#535355"
          color="white"
          w={280}
          onChange={handleLoadPreset}
          placeholder="Select an option ..."
          value={selectedPreset}
        >
          <>
            <option value="PS1">Signed Int-16 BE</option>
            <option value="PS2">Unsigned Int-16 BE</option>
            <option value="PS3">Signed Int-32 BE</option>
          </>
        </Select>
      </Flex>
      <hr />
      <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="20px" mb={6}>
        <Flex justifyContent="center" flexDirection="column" mb={4} mt={2}>
          <h4>Format Name :</h4>
          <p>Unique name for this format</p>
          <Input
            w={280}
            mt={2}
            type="text"
            maxLength={20}
            onChange={handleUpdateFormat('format_name')}
            value={formatList[currentFormat].format_name}
            isInvalid={
              checkValidField && formatList[currentFormat].format_name === ''
            }
          />
        </Flex>
      </SimpleGrid>

      <SimpleGrid columns={{ md: 1, lg: 2 }} spacing="20px">
        <Box>
          <h4>Data Type :</h4>
          <p>Read register data type</p>

          <Select
            w={280}
            mt={2}
            variant="filled"
            placeholder="Parse Reading As ..."
            onChange={handleUpdateFormat('data_type')}
            value={formatList[currentFormat].data_type}
            isInvalid={
              checkValidField && formatList[currentFormat].data_type === ''
            }
          >
            <option value="int_16">16-bit integer</option>
            <option value="uint_16">16-bit unsigned integer</option>
            <option value="int_32">32-bit integer</option>
            <option value="uint_32">32-bit unsigned integer</option>
            <option value="float_32">32-bit float</option>
            <option value="ascii_16">Two character ASCII string</option>
            <option value="ascii_32">Four character ASCII string</option>
            <option value="bool">Boolean</option>
          </Select>
        </Box>
        <Box>
          <h4>Byte Order :</h4>
          <p>How to arrange 8-bit bytes in a 16-bit register</p>
          <Select
            bg="#535355"
            borderColor="#535355"
            color="white"
            w={280}
            mt={2}
            isDisabled={formatList[currentFormat].data_type === 'bool'}
            placeholder="Select an option ..."
            onChange={handleUpdateFormat('byte_order')}
            value={formatList[currentFormat].byte_order}
            isInvalid={
              checkValidField && formatList[currentFormat].byte_order === ''
            }
          >
            <option value="BE">Big Endian</option>
            <option value="LE">Small Endian</option>
            {formatList[currentFormat].data_type === 'bool' && (
              <option value="None">None</option>
            )}
          </Select>
        </Box>

        <Box mt={6}>
          <h4>Word Order :</h4>
          <p>How to arrange 16-bit registers in a 32-bit word</p>
          <Select
            bg="#535355"
            borderColor="#535355"
            color="white"
            w={280}
            mt={2}
            placeholder="Select an option ..."
            isDisabled={[
              'bool',
              'int_16',
              'uint_16',
              'ascii_16',
              'ascii_32',
            ].includes(formatList[currentFormat].data_type)}
            onChange={handleUpdateFormat('word_order')}
            value={formatList[currentFormat].word_order}
            isInvalid={
              checkValidField && formatList[currentFormat].word_order === ''
            }
          >
            <option value="BE">Big Endian</option>
            <option value="LE">Small Endian</option>
            {['bool', 'int_16', 'uint_16', 'ascii_16', 'ascii_32'].includes(
              formatList[currentFormat].data_type
            ) && <option value="None">None</option>}
          </Select>
        </Box>

        <Box mt={6}>
          <h4>Rounding :</h4>
          <p>Decimal places for rounding data values</p>
          <Input
            w={280}
            mt={2}
            isDisabled={['bool', 'ascii_16', 'ascii_32'].includes(
              formatList[currentFormat].data_type
            )}
            type={
              ['bool', 'ascii_16', 'ascii_32'].includes(
                formatList[currentFormat].data_type
              )
                ? 'text'
                : 'number'
            }
            onChange={handleUpdateFormat('rounding')}
            value={formatList[currentFormat].rounding}
            isInvalid={
              checkValidField && formatList[currentFormat].rounding === ''
            }
          />
        </Box>

        <Flex justifyContent="center" flexDirection="column" mt={6}>
          <h4>Offset :</h4>
          <p>Offset to apply to reading values</p>
          <Input
            w={280}
            mt={1}
            isDisabled={['bool', 'ascii_16', 'ascii_32'].includes(
              formatList[currentFormat].data_type
            )}
            type={
              ['bool', 'ascii_16', 'ascii_32'].includes(
                formatList[currentFormat].data_type
              )
                ? 'text'
                : 'number'
            }
            onChange={handleUpdateFormat('offset')}
            value={formatList[currentFormat].offset}
            isInvalid={
              checkValidField && formatList[currentFormat].offset === ''
            }
          />
        </Flex>

        <Flex justifyContent="center" flexDirection="column" mt={6}>
          <h4>Scale :</h4>
          <p>Scale factor to apply to reading values</p>
          <Input
            w={280}
            mt={1}
            isDisabled={['bool', 'ascii_16', 'ascii_32'].includes(
              formatList[currentFormat].data_type
            )}
            type={
              ['bool', 'ascii_16', 'ascii_32'].includes(
                formatList[currentFormat].data_type
              )
                ? 'text'
                : 'number'
            }
            onChange={handleUpdateFormat('scale')}
            value={formatList[currentFormat].scale}
            isInvalid={
              checkValidField && formatList[currentFormat].scale === ''
            }
          />
        </Flex>
      </SimpleGrid>
      {activeAttribute && (
        <Button
          colorScheme="blue"
          variant="outline"
          w={100}
          mt={8}
          onClick={handleSaveFormat}
        >
          Done
        </Button>
      )}
    </Flex>
  </Flex>
);

export default memo(ModbusRTUFormat);
