import React from 'react';
import { Container, Icon } from '@chakra-ui/react';
import { IoReceipt } from 'react-icons/io5';

const NoPlan = () => (
  <Container maxW="full" className="error-container">
    <Icon as={IoReceipt} w={24} h={24} mb={4} />
    <h2 className="no-order-message">
      You currently have no active subscriptions.
    </h2>
    <p>When you do, details of the data plan will appear here.</p>
    <p>To subscribe, visit our storefront at</p>
    <p className="store-link">
      <a
        href={`${process.env.REACT_APP_STORE_URL}/plans`}
        target="_blank"
        rel="noreferrer"
      >
        {process.env.REACT_APP_STORE_URL}/plans
      </a>
    </p>
  </Container>
);

export default NoPlan;
