const mapFTXStatus = (statusCode) => {
  let status;
  switch (statusCode) {
    case 1:
      status = 'GOOD';
      break;
    case 129:
      status = 'CONVERGING';
      break;
    case 130:
      status = 'NO PROBE';
      break;
    case 131:
      status = 'MAX LED';
      break;
    case 132:
      status = 'LOW LIGHT';
      break;
    case 3:
      status = 'NO INFO';
      break;
    case 0:
      status = 'NO COM';
      break;
    default:
      status = 'INVALID';
  }
  return status;
};

export default mapFTXStatus;
