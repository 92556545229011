/* eslint-disable no-unreachable */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect, memo } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  Input,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Select,
  SimpleGrid,
  Stack,
  useToast,
} from '@chakra-ui/react';
import CreatableSelect from 'react-select/creatable';
import auxValidation from '../../aux-validation/aux-validation';
import FTXMappingList from '../../aux-mapping-list/ftx-mapping-list.component';
import useForm from '../../../../../../api/useForm';
import updateChannelList from '../../functions/updateChannelList';
import CustomSwitch from '../../../../../../components/custom-switch/custom-switch.component';

const FTXConfigModal = ({
  isOpen,
  onClose,
  config,
  AUXDeviceID,
  handleUpdateFTXConfig,
}) => {
  const createOption = (label, value) => ({
    label,
    value,
  });

  const { onChange, onSubmit, onResetAll, values } = useForm(
    updateInfoCallback,
    {
      protocol: 'modbus_ftx',
      port: config ? config.port : '',
      device_name: config ? config.device_name : 'aux_ftx_',
      data_collection: config ? config.data_collection : 'enabled',
      cloud_storage: config ? config.cloud_storage : 'enabled',
      read_interval_ms: config ? config.read_interval_ms : 1000,

      ftx_model: config ? config.modbus_ftx.ftx_model : '',
      ftx_modbus_id: config ? config.modbus_ftx.modbus_id : '',
      ftx_baud_rate: config ? config.modbus_ftx.baud_rate : 9600,
      ftx_channels: config ? config.modbus_ftx.channels : [],
      ftx_read_attributes: 'Temperature',

      data_type: 'int_16',
      byte_order: 'BE',
      word_order: 'None',
      units: 'C',
      offset: config ? config.format.offset : 0,
      scale: 0.01,

      mappings: {},
    }
  );

  async function updateInfoCallback() {
    handleConfirmConfig();
  }

  const baudRateOptions = [
    createOption(9600, 9600),
    createOption(19200, 19200),
    createOption(38400, 38400),
    createOption(57600, 57600),
    createOption(115200, 115200),
  ];

  const defaultStyles = {
    control: (baseStyles) => ({
      ...baseStyles,
      cursor: 'pointer',
    }),
    input: (baseStyles) => ({
      ...baseStyles,
      color: 'white',
    }),
    singleValue: (baseStyles) => ({
      ...baseStyles,
      color: 'white',
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: state.isFocused ? '#dc2f5c' : '',
      cursor: 'pointer',
    }),
  };

  const [AUXConfig, setAUXConfig] = useState();
  const [tabIndex, setTabIndex] = useState(0);
  const [isButtonLoading, setButtonLoading] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [checkValidField, setCheckValidField] = useState(false);
  const [baudRate, setBaudRateOptions] = useState(baudRateOptions);

  const toast = useToast();

  useEffect(() => {
    if (config) {
      setAUXConfig(config);
    }
  }, [config]);

  const handleCreateOption = (inputValue, name) => {
    // check valid value
    const newValue = parseInt(inputValue, 10);
    if (Number.isInteger(newValue) && newValue > 0) {
      setTimeout(() => {
        const newOption = createOption(inputValue, inputValue);
        if (name === 'baud_rate') {
          setBaudRateOptions((prev) => [...prev, newOption]);
        }
      }, 1000);
    } else {
      toast({
        title: 'Invalid value',
        description: 'Must be a number and more than 0',
        status: 'error',
        position: 'top',
        duration: 10000,
        isClosable: true,
      });
    }
  };

  const handleConfirmConfig = () => {
    setButtonLoading(true);
    setButtonDisabled(true);
    setCheckValidField(true);

    // manipulate desired AUX config
    const config = {
      protocol: 'modbus_ftx',
      port: values.port,
      device_name:
        values.device_name === 'aux_ftx_'
          ? `aux_ftx_${values.ftx_modbus_id}`
          : values.device_name,
      data_collection: values.data_collection,
      cloud_storage: values.cloud_storage,
      read_interval_ms: parseInt(values.read_interval_ms, 10),
      modbus_ftx: {
        modbus_id: parseInt(values.ftx_modbus_id, 10),
        baud_rate: parseInt(values.ftx_baud_rate, 10),
        ftx_model: values.ftx_model,
        channels: updateChannelList(
          values.ftx_model,
          values.ftx_channels.sort()
        ),
        read_attributes: [values.ftx_read_attributes],
      },
      format: {
        data_type: values.data_type,
        byte_order: values.byte_order,
        word_order: values.word_order,
        units: values.units,
        offset: parseFloat(values.offset),
        scale: parseFloat(values.scale),
      },
      mappings: values.mappings,
    };

    // Validate AUX config
    const isConfigValid = auxValidation(config);

    if (isConfigValid.isValid) {
      handleUpdateFTXConfig(AUXDeviceID, config, onResetAll);
      setCheckValidField(false);
    } else {
      toast({
        title: 'Failed to setup FTX configuration',
        description: isConfigValid.message,
        status: 'error',
        position: 'top',
        duration: 10000,
        isClosable: true,
      });
      setButtonLoading(false);
      setButtonDisabled(false);
    }
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      onCloseComplete={() => {
        setTabIndex(0);
        setCheckValidField(false);
        setButtonLoading(false);
        setButtonDisabled(false);
      }}
      size="4xl"
      className="adc-modal"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {AUXConfig ? AUXConfig.device_name : 'AUX Device Configuration'}
        </ModalHeader>
        <ModalCloseButton
          isDisabled={isButtonDisabled}
          onClick={() => {
            onResetAll();
            onClose();
          }}
        />
        <ModalBody p={6}>
          <Tabs
            isLazy
            isFitted
            flexWrap="wrap"
            index={tabIndex}
            onChange={(index) => setTabIndex(index)}
          >
            <TabList>
              <Tab>
                <h2>Modbus FTX</h2>
              </Tab>
              <Tab>
                <h2>General</h2>
              </Tab>
              <Tab>
                <h2>Format</h2>
              </Tab>
              <Tab>
                <h2>Mapping</h2>
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <Box className="adc-modal--item">
                  <Flex alignItems="center">
                    <h4>Port :</h4>
                  </Flex>
                  <p>Select a Port you would like to connect to</p>
                  <Select
                    w={270}
                    mt={2}
                    variant="filled"
                    placeholder="Select a Port ..."
                    onChange={onChange('port')}
                    value={values.port}
                    isInvalid={checkValidField && values.port === ''}
                  >
                    <option value="rs485_1">RS-485-1</option>
                    <option value="rs485_2">RS-485-2</option>
                    <option value="usb_1">USB-1</option>
                    <option value="usb_2">USB-2</option>
                  </Select>
                </Box>

                <Box className="adc-modal--item">
                  <Box className="adc-modal--item-details">
                    <Flex alignItems="center">
                      <h4>FTX Device Model : </h4>
                    </Flex>

                    <Select
                      w={250}
                      mt={2}
                      variant="filled"
                      onChange={onChange('ftx_model')}
                      placeholder="Select an option ..."
                      value={values.ftx_model}
                      isInvalid={checkValidField && values.ftx_model === ''}
                    >
                      <option value="1_ch">1-channel</option>
                      <option value="3_ch">3-channel</option>
                      <option value="9_ch">9-channel</option>
                    </Select>

                    <Box mt={4}>
                      <h4>Baud Rate :</h4>
                      <p>Rate at which data is sent/received</p>

                      <CreatableSelect
                        className={`custom-select ${
                          checkValidField && values.ftx_baud_rate === ''
                            ? 'invalid-custom-select'
                            : ''
                        }`}
                        styles={defaultStyles}
                        onChange={onChange('ftx_baud_rate')}
                        onCreateOption={(e) =>
                          handleCreateOption(e, 'baud_rate')
                        }
                        options={baudRate}
                        value={{
                          label: values.ftx_baud_rate,
                          value: values.ftx_baud_rate,
                        }}
                        placeholder="Custom Value ..."
                      />
                    </Box>
                    <Flex justifyContent="center" flexDirection="column" mt={4}>
                      <h4>Modbus ID :</h4>
                      <Input
                        width={250}
                        type="number"
                        placeholder="[1-247]"
                        onChange={onChange('ftx_modbus_id')}
                        value={values.ftx_modbus_id}
                        isInvalid={
                          checkValidField && values.ftx_modbus_id === ''
                        }
                      />
                    </Flex>

                    <Flex alignItems="center" mt={4}>
                      <h4>Read Attributes : </h4>
                    </Flex>
                    <p>Specify what gets read</p>
                    <Select
                      w={250}
                      mt={2}
                      variant="filled"
                      placeholder="Select an option ..."
                      value="Temperature"
                      disabled
                    >
                      <option value="Temperature">Temperature</option>
                    </Select>

                    <Flex justifyContent="center" flexDirection="column" mt={4}>
                      <h4>Channels :</h4>
                      <p>FTX Channels</p>
                      <Box
                        p={2}
                        className={`ftx-channels ${
                          checkValidField && values.ftx_channels.length === 0
                            ? 'invalid-channels'
                            : ''
                        }`}
                      >
                        <CheckboxGroup
                          mt={4}
                          onChange={onChange('ftx_channels')}
                          value={values.ftx_channels}
                        >
                          {values.ftx_model === '1_ch' ||
                          (!values.ftx_model &&
                            AUXConfig &&
                            AUXConfig.modbus_ftx.ftx_model === '1_ch') ? (
                            <Stack
                              spacing={[1, 5]}
                              direction={['column', 'row']}
                            >
                              <Checkbox value="A">A</Checkbox>
                            </Stack>
                          ) : values.ftx_model === '3_ch' ||
                            (!values.ftx_model &&
                              AUXConfig &&
                              AUXConfig.modbus_ftx.ftx_model === '3_ch') ? (
                            <Stack
                              spacing={[1, 5]}
                              direction={['column', 'row']}
                            >
                              <Checkbox value="A">A</Checkbox>
                              <Checkbox value="B">B</Checkbox>
                              <Checkbox value="C">C</Checkbox>
                            </Stack>
                          ) : (
                            <SimpleGrid columns={3}>
                              <Checkbox value="A" w={10}>
                                A
                              </Checkbox>
                              <Checkbox value="D" w={10}>
                                D
                              </Checkbox>
                              <Checkbox value="G" w={10}>
                                G
                              </Checkbox>
                              <Checkbox value="B" w={10}>
                                B
                              </Checkbox>
                              <Checkbox value="E" w={10}>
                                E
                              </Checkbox>
                              <Checkbox value="H" w={10}>
                                H
                              </Checkbox>
                              <Checkbox value="C" w={10}>
                                C
                              </Checkbox>
                              <Checkbox value="F" w={10}>
                                F
                              </Checkbox>
                              <Checkbox value="I" w={10}>
                                I
                              </Checkbox>
                            </SimpleGrid>
                          )}
                        </CheckboxGroup>
                      </Box>
                    </Flex>
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel>
                <Box className="adc-modal--item">
                  <Box mb={6}>
                    {AUXDeviceID && (
                      <Flex
                        mb={6}
                        justifyContent="center"
                        flexDirection="column"
                      >
                        <h4>
                          ID :{' '}
                          <span className="aux-device-id">{AUXDeviceID}</span>
                        </h4>
                      </Flex>
                    )}
                    <Flex justifyContent="center" flexDirection="column">
                      <h4>Name of Device :</h4>
                      <p>Name your connected device</p>
                      <Input
                        width={250}
                        mt={1}
                        type="text"
                        required
                        maxLength={20}
                        onChange={onChange('device_name')}
                        value={values.device_name}
                        isInvalid={checkValidField && values.device_name === ''}
                      />
                    </Flex>

                    <Flex alignItems="center" className="switch-box" mt={6}>
                      <h4>Data Collection :</h4>

                      <CustomSwitch
                        id="aux-data-collection"
                        onSwitchChange={onChange('data_collection')}
                        variant="lite"
                        isChecked={values.data_collection === 'enabled'}
                        isDisabled={isButtonLoading}
                      />
                    </Flex>
                    <p>
                      Data collection begins the collection of data from this
                      module. Its reading will be subsequently shown on your
                      Dashboard.
                    </p>
                  </Box>

                  <Box className="adc-modal--box" mb={6}>
                    <h4>Read Interval :</h4>
                    <p>Data collection read interval</p>

                    <Select
                      bg="#535355"
                      borderColor="#535355"
                      color="white"
                      w={220}
                      mt={2}
                      onChange={onChange('read_interval_ms')}
                      placeholder="Select an option ..."
                      value={values.read_interval_ms}
                      isInvalid={
                        checkValidField && values.read_interval_ms === ''
                      }
                    >
                      <option value={1000}>1 second</option>
                      <option value={2000}>2 seconds</option>
                      <option value={3000}>3 seconds</option>
                      <option value={5000}>5 seconds</option>
                      <option value={10000}>10 seconds</option>
                      <option value={15000}>15 seconds</option>
                      <option value={30000}>30 seconds</option>
                      <option value={60000}>60 seconds</option>
                    </Select>
                  </Box>

                  <Box mb={4}>
                    <Flex alignItems="center" className="switch-box">
                      <h4>Cloud Storage :</h4>
                      <CustomSwitch
                        id="aux-cloud-storage"
                        onSwitchChange={onChange('cloud_storage')}
                        variant="lite"
                        isChecked={values.cloud_storage === 'enabled'}
                        isDisabled
                      />
                    </Flex>
                    <p>
                      Cloud Storage will begin the storing of data. This data
                      will be made available to you through our data
                      visualization tool.
                    </p>
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel>
                <SimpleGrid
                  columns={{ sm: 1, md: 2 }}
                  spacing="20px"
                  className="adc-modal--item"
                >
                  <Flex justifyContent="center" flexDirection="column" mt={6}>
                    <h4>Offset :</h4>
                    <p>Offset to apply to reading values</p>
                    <Input
                      width={250}
                      mt={1}
                      type="number"
                      onChange={onChange('offset')}
                      value={values.offset}
                      isInvalid={checkValidField && values.offset === ''}
                    />
                  </Flex>
                </SimpleGrid>
              </TabPanel>
              <TabPanel>
                <Box className="adc-modal--item">
                  <Flex justifyContent="center" flexDirection="column" mb={2}>
                    <h4>Mapping</h4>
                    <p className="tab-details">Map channels to custom names</p>
                  </Flex>
                  <FTXMappingList
                    list={AUXConfig ? AUXConfig.mappings : {}}
                    handleUpdateList={onChange('mappings')}
                    selectedModel={
                      values.ftx_model
                        ? values.ftx_model
                        : AUXConfig
                        ? AUXConfig.modbus_ftx.ftx_model
                        : ''
                    }
                  />
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn-group"
            bg="#535355"
            color="#FFF"
            boxShadow="0px 15px 30px rgba(0, 0, 0, 0.15)"
            variant="solid"
            w={150}
            mr={2}
            isDisabled={isButtonDisabled}
            onClick={() => {
              onResetAll();
              onClose();
            }}
            _hover={{ bg: '#242424' }}
          >
            Cancel
          </Button>

          <Button
            className="btn-group"
            bg="#DB2F5CCC"
            color="#FFF"
            variant="solid"
            w={150}
            isLoading={isButtonLoading}
            onClick={onSubmit}
            _hover={{ bg: '#db2f5d99' }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default memo(FTXConfigModal);
