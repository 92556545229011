import React, { useState, useEffect, memo } from 'react';
import { Icon, Box, Tag, SimpleGrid } from '@chakra-ui/react';
import { BsArrowsAngleContract } from 'react-icons/bs';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import mapDeviceName from '../../aux-device-view/mapDeviceName';
import pdConversion from '../../aux-device-view/pdConversion';
import mapPDXStatus from '../../aux-device-view/mapPDXStatus';
import formatDecimal from '../../aux-device-view/formatDecimal';

const PDXSingleChannelView = ({
  data,
  handleOpenChannel,
  mappingList,
  selectedChannel,
  ModbusConfig,
}) => {
  const [channels, setChannels] = useState([]);
  const [isDataStaled, setDataStaled] = useState(false);
  useEffect(() => {
    if (data && data.length !== 0) {
      if (channels.length === 0) {
        setChannels(data);
      } else if (data[0].timestamp_ms < channels[0].timestamp_ms) {
        setDataStaled(true);
      } else {
        setDataStaled(false);
        setChannels(data);
      }
    }
  }, [data]);

  return channels.length !== 0 ? (
    <Box className="single-channel-view">
      <Icon
        className="zoom-btn"
        as={BsArrowsAngleContract}
        w={6}
        h={6}
        onClick={() => handleOpenChannel()}
        zIndex={10}
      />
      <Box className="channel-carousel">
        <Carousel
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          selectedItem={selectedChannel}
          // infiniteLoop // enable this prop will cause a resizing issue on small screen
        >
          {channels.map((curr, index) => (
            <Box key={index} className="channel-wrapper">
              <div className="channel-name">
                <Tag size="sm" borderRadius="full" variant="solid">
                  {mapDeviceName(curr.channel, mappingList)}
                </Tag>
              </div>

              <SimpleGrid mt={8} columns={{ sm: 1, md: 2 }} spacing={2}>
                {ModbusConfig.read_attributes.includes('pd_peak') && (
                  <Box className="channel-item">
                    <h3>Peak PD</h3>
                    <h1
                      className={`device-data ${
                        isDataStaled ? 'staled-data' : ''
                      }`}
                    >
                      {curr.pd_peak_pc
                        ? `${pdConversion(Number(curr.pd_peak_pc))}`
                        : '--'}
                    </h1>
                  </Box>
                )}
                {ModbusConfig.read_attributes.includes('pd_total') && (
                  <Box className="channel-item">
                    <h3>Total PD</h3>
                    <h1
                      className={`device-data ${
                        isDataStaled ? 'staled-data' : ''
                      }`}
                    >
                      {curr.pd_total_pc
                        ? `${pdConversion(Number(curr.pd_total_pc))}`
                        : '--'}
                    </h1>
                  </Box>
                )}
                {ModbusConfig.read_attributes.includes('humidity') && (
                  <Box className="channel-item">
                    <h3>Humidity</h3>
                    <h1
                      className={`device-data ${
                        isDataStaled ? 'staled-data' : ''
                      }`}
                    >
                      {curr.humidity_perc ? `${curr.humidity_perc}%` : '--'}
                    </h1>
                  </Box>
                )}
                {ModbusConfig.read_attributes.includes('temperature') && (
                  <Box className="channel-item">
                    <h3>Temp</h3>
                    <h1
                      className={`device-data ${
                        isDataStaled ? 'staled-data' : ''
                      }`}
                    >
                      {curr.temperature_c
                        ? `${formatDecimal(curr.temperature_c, 2)}°C `
                        : '--'}
                    </h1>
                  </Box>
                )}
                {ModbusConfig.read_attributes.includes('status') && (
                  <Box className="channel-item">
                    <h3>Status</h3>
                    <h1
                      className={`device-data ${
                        isDataStaled ? 'staled-data' : ''
                      }`}
                    >
                      {mapPDXStatus(curr.status) ?? '--'}
                    </h1>
                  </Box>
                )}
              </SimpleGrid>
            </Box>
          ))}
        </Carousel>
      </Box>
    </Box>
  ) : (
    <Box className="single-channel-view">
      <Icon
        className="zoom-btn"
        as={BsArrowsAngleContract}
        w={6}
        h={6}
        onClick={() => handleOpenChannel()}
        zIndex={10}
      />
      <Box m={5}>
        <h4>Data is not available</h4>
      </Box>
    </Box>
  );
};

export default memo(PDXSingleChannelView);
