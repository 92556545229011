import React from 'react';
import { Spinner } from '@chakra-ui/react';
import './custom-button.styles.scss';

const CustomButton = ({
  children,
  variant,
  width,
  isLoading,
  disabled,
  ...otherProps
}) => (
  <button
    type="button"
    className={`custom-btn ${variant} ${disabled ? 'custom-disabled' : ''}`}
    style={{ width }}
    {...otherProps}
    disabled={disabled}
  >
    {isLoading ? <Spinner size="sm" color="#FFF" /> : children}
  </button>
);

export { CustomButton };
