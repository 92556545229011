import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Box, SimpleGrid, Container, Flex } from '@chakra-ui/react';
import OrderItem from './order-item/order-item.component';
import CloudAnimation from '../../components/animation/cloud';
import portalHandleRequest from '../../api/portalHandleRequest';
import mapStatusLabels from '../../api/mapStatusLabels';
import NoOrder from './no-order/no-order.component';
import './orders.styles.scss';

const PORTAL_API_ENDPOINT = process.env.REACT_APP_PORTAL_API;

const Orders = ({ currentUser }) => {
  const [orders, setOrders] = useState([]);
  const [noOrder, setNoOrder] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const contactId =
      currentUser && currentUser.zoho_contact_id
        ? currentUser.zoho_contact_id
        : '';

    const getOrderData = async () => {
      const obj = {
        method: 'GET',
        url: `${PORTAL_API_ENDPOINT}/contacts/${contactId}/orders`,
      };

      const response = await portalHandleRequest(obj, navigate);
      const updatedOrders = [];
      if (!response.salesorders || response.salesorders.length === 0) {
        // This may happen when delete orders in Zoho
        setNoOrder(true);
      } else {
        response.salesorders.forEach(async (order) => {
          const obj = {
            method: 'GET',
            url: `${PORTAL_API_ENDPOINT}/orders/${order.salesorder_id}`,
          };

          const response = await portalHandleRequest(obj, navigate);
          if (response.status === 200) {
            updatedOrders.push(response.salesorder);
            setOrders([...orders, ...updatedOrders]);
          } else {
            console.error(response.message);
          }
        });
      }

      return null;
    };

    if (contactId) {
      getOrderData();
    } else {
      console.log('Contact ID is missing.');
      setNoOrder(true); // no zoho contact id => user haven't made any purchase
    }
  }, []);
  return (
    <Box p={4} className="orders-page">
      <Container maxW="full" p={0} className="orders-page--container">
        {orders && orders.length > 0 ? (
          <Flex flexWrap="wrap" flex="1" className="orders-page--wrapper">
            {orders
              .sort((a, b) => {
                return a.created_time < b.created_time
                  ? 1
                  : a.created_time > b.created_time
                  ? -1
                  : 0;
              })
              .map((order) => (
                <Link
                  to={`/order/${order.salesorder_id}`}
                  key={order.salesorder_id}
                  className="order-link"
                >
                  <Box className="order-item">
                    <Box className="overlay" />
                    <Flex
                      justifyContent="space-between"
                      flexDirection="column"
                      minH="60px"
                      mb={4}
                    >
                      <h2>Order #{order.salesorder_number}</h2>
                      <p>
                        {new Intl.DateTimeFormat('en-US', {
                          dateStyle: 'long',
                          timeStyle: undefined,
                          timeZone:
                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                        }).format(new Date(order.created_time))}
                      </p>
                      <Flex>
                        <h4
                          className={`order-status ${
                            order.order_status === 'draft'
                              ? 'draft'
                              : order.order_status === 'confirmed'
                              ? 'confirmed'
                              : order.order_status === 'void'
                              ? 'void'
                              : order.order_status === 'closed'
                              ? 'closed'
                              : order.order_status === 'onhold'
                              ? 'onhold'
                              : 'default'
                          }`}
                        >
                          {mapStatusLabels(order.order_status)}
                        </h4>
                        {order.custom_field_hash.cf_pre_order_unformatted && (
                          <h3 className="order-status pre-order">Pre-Order</h3>
                        )}
                      </Flex>
                    </Flex>

                    <SimpleGrid
                      columns={order.line_items.length === 1 ? 1 : [1, null, 2]}
                      spacing="20px"
                      className="items-container"
                    >
                      {order.line_items.map((item) => (
                        <OrderItem
                          key={item.image_name}
                          imageURL={item.image_name}
                          name={JSON.parse(item.description).product_name}
                          quantity={item.quantity}
                        />
                      ))}
                    </SimpleGrid>

                    <p className="sub-total">
                      ${order.total.toFixed(2)} {order.currency_code}
                    </p>
                  </Box>
                </Link>
              ))}
          </Flex>
        ) : noOrder ? (
          <NoOrder />
        ) : (
          <CloudAnimation />
        )}
      </Container>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps, null)(Orders);
