import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, Button, Container, Image, Icon, Flex } from '@chakra-ui/react';
import { FaPlus } from 'react-icons/fa';
import generateAccessToken from '../../api/generateAccessToken';
import DashBoardView from './db-view/db-view.component';
import CloudAnimation from '../../components/animation/cloud';
import './dashboard.styles.scss';

const DashBoard = ({ activeDevices }) => {
  const [token, setToken] = useState('');

  useEffect(() => {
    const getJWTToken = async () => {
      const jwtToken = await generateAccessToken();
      setToken(jwtToken);
    };
    getJWTToken();
  }, []);

  return (
    <Box p={4} className="dashboard-page">
      {activeDevices && activeDevices.length > 0 && token ? (
        <DashBoardView token={token} activeDevices={activeDevices} />
      ) : activeDevices && activeDevices.length === 0 ? (
        <Container maxW="full" className="dashboard-page--no-devices">
          <Image
            boxSize="120px"
            objectFit="contain"
            src="/edgepi-placeholder.png"
            alt="edgepi-placeholder"
          />
          <h2 className="no-order-message">
            You have no devices attached to your portal.
          </h2>
          <p>
            Add your EdgePi now to begin exploring it’s full cloud capabilities
            !
          </p>

          <Link to="/add-device">
            <Button
              mt={6}
              className="btn-group"
              bg="#DB2F5CCC"
              color="#FFF"
              variant="solid"
              w={200}
              _hover={{ bg: '#db2f5d99' }}
            >
              <Icon as={FaPlus} w={4} h={4} mr={4} /> Add Device
            </Button>
          </Link>
        </Container>
      ) : (
        <Flex
          w="100%"
          h="100vh"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <CloudAnimation />
        </Flex>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  activeDevices: state.user.activeDevices,
});

export default connect(mapStateToProps, null)(DashBoard);
