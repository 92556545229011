// This function will take the config object and the data array and the return an array of objects (which contain `attribute_name`, `attribute_value`, `attribute_status` and `data_unit`)

const getAttributeData = (config, data) => {
  const attributeData = [];
  for (const blockKey in config) {
    if (Object.prototype.hasOwnProperty.call(config, blockKey)) {
      const block = config[blockKey];
      const readAttributes = block.read_attributes;

      for (const attributeKey in readAttributes) {
        if (
          Object.prototype.hasOwnProperty.call(readAttributes, attributeKey)
        ) {
          const attribute = readAttributes[attributeKey];
          const attributeName = attribute.attribute_name;
          const propertyMatch = data.find(
            (item) => item.property_name === attributeName
          );

          if (propertyMatch) {
            if (block.read_enabled) {
              const attributeStatus = propertyMatch.generic_samples[0].status;
              const attributeValue =
                propertyMatch.generic_samples.length > 0
                  ? propertyMatch.generic_samples.reduce((acc, sample) => {
                      const foundKey = Object.keys(sample).find((key) =>
                        key.startsWith('value')
                      );
                      return foundKey ? sample[foundKey] : acc;
                    }, null)
                  : 'NO DATA'; // empty generic_samples

              const isStaledData =
                propertyMatch.generic_samples.length > 0
                  ? propertyMatch.generic_samples[0].isStaledData
                  : '';
              const dataUnit = propertyMatch.data_unit || '';
              attributeData.push({
                attribute_status: attributeStatus,
                attribute_name: attributeName,
                attribute_value: attributeValue,
                data_unit:
                  attributeValue === true || attributeValue === false
                    ? ''
                    : dataUnit,
                isStaledData,
              });
            } else {
              // Read_enabled is FALSE
              attributeData.push({
                attribute_status: 'N/A',
                attribute_name: attributeName,
                attribute_value: 'N/A',
                data_unit: '',
              });
            }
          } else {
            // No match => No Data
            attributeData.push({
              attribute_status: 'NO DATA',
              attribute_name: attributeName,
              attribute_value: 'NO DATA',
              data_unit: '',
            });
          }
        }
      }
    }
  }
  return attributeData;
};

export default getAttributeData;
