import React, { memo } from 'react';
import {
  Button,
  Flex,
  Input,
  Icon,
  Select,
  Stack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  Tooltip,
} from '@chakra-ui/react';
import CreatableSelect from 'react-select/creatable';
import { GoArrowLeft, GoTrash, GoPencil } from 'react-icons/go';

const ModbusRTUAttribute = ({
  checkValidField,
  defaultStyles,
  formatList,
  readBlockList,
  activeReadBlock,
  activeAttribute,
  unit,
  setReadBlockIndex,
  handleCreateOption,
  handleFormat,
  handleEditFormat,
  handleDeleteAttribute,
  handleUpdateAttribute,
}) => {
  return (
    <Flex className="adc-modal--item" flexDirection="column" flex="1">
      <Flex justifyContent="center" flexDirection="column" mb={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={4}
          mb={4}
        >
          <Button
            leftIcon={<GoArrowLeft />}
            colorScheme="white"
            variant="outline"
            w={100}
            onClick={() => setReadBlockIndex(1)}
          >
            Back
          </Button>
          <Stack direction="row" spacing={4}>
            <Popover>
              <PopoverTrigger>
                <Button
                  colorScheme="red"
                  variant="outline"
                  w={180}
                  mr={2}
                  leftIcon={<GoTrash />}
                >
                  Delete Attribute
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>Confirmation!</PopoverHeader>
                <PopoverBody>
                  Are you sure you want to delete this attribute{' '}
                  <span className="item_name">
                    {
                      readBlockList[activeReadBlock].read_attributes[
                        activeAttribute
                      ].attribute_name
                    }
                  </span>
                  ?
                </PopoverBody>
                <PopoverFooter>
                  <Button colorScheme="blue" onClick={handleDeleteAttribute}>
                    Yes, delete it
                  </Button>
                </PopoverFooter>
              </PopoverContent>
            </Popover>
          </Stack>
        </Stack>
        <hr />
        <Stack direction="row" spacing={20} mt={2}>
          <Flex justifyContent="center" flexDirection="column">
            <h4>Attribute Name :</h4>
            <p>Name this attribute</p>
            <Input
              width={250}
              mt={2}
              type="text"
              maxLength={20}
              onChange={handleUpdateAttribute('attribute_name')}
              value={
                readBlockList[activeReadBlock].read_attributes[activeAttribute]
                  .attribute_name
              }
              isInvalid={
                readBlockList[activeReadBlock].read_attributes[activeAttribute]
                  .attribute_name === ''
              }
            />
          </Flex>
          <Tooltip
            hasArrow
            label="Create or Select a measurement unit"
            placement="right"
            bg="#dc2f5c"
            color="#fff"
            isOpen={
              readBlockList[activeReadBlock].read_attributes[activeAttribute]
                .unit === ''
            }
          >
            <Flex flexDirection="column">
              <h4>Unit :</h4>
              <p>Attribute measurement unit</p>

              <CreatableSelect
                className={`custom-select ${
                  checkValidField &&
                  readBlockList[activeReadBlock].read_attributes[
                    activeAttribute
                  ].unit === ''
                    ? 'invalid-custom-select'
                    : ''
                }`}
                styles={defaultStyles}
                onChange={handleUpdateAttribute('unit')}
                onCreateOption={(e) => handleCreateOption(e, 'unit')}
                options={unit}
                placeholder="Custom Value ..."
                value={{
                  label:
                    readBlockList[activeReadBlock].read_attributes[
                      activeAttribute
                    ].unit,
                  value:
                    readBlockList[activeReadBlock].read_attributes[
                      activeAttribute
                    ].unit,
                }}
              />
            </Flex>
          </Tooltip>
        </Stack>
        <Stack mt={4} direction="row" spacing={20}>
          <Flex justifyContent="center" flexDirection="column">
            <h4>Starting Address :</h4>
            <p>Where to begin reading</p>
            <Input
              width={250}
              mt={2}
              type="text"
              onChange={handleUpdateAttribute('address')}
              value={
                readBlockList[activeReadBlock].read_attributes[activeAttribute]
                  .address
              }
            />
          </Flex>
          <Flex justifyContent="center" flexDirection="column">
            <Flex alignItems="center">
              <h4>Register Count</h4>
            </Flex>
            <p>How many registers to be read</p>
            <Select
              bg="#535355"
              borderColor="#535355"
              color="white"
              w={250}
              mt={2}
              onChange={handleUpdateAttribute('reg_count')}
              placeholder="Select an option ..."
              value={
                readBlockList[activeReadBlock].read_attributes[activeAttribute]
                  .reg_count
              }
            >
              <option value={1}>1</option>
              <option value={2}>2</option>
            </Select>
          </Flex>
        </Stack>

        <Flex flexDirection="column" mt={4}>
          <h4>Format :</h4>
          <p>Attribute format</p>
          <Flex alignItems="center">
            <Tooltip
              hasArrow
              label="Create or select format"
              placement="right"
              bg="#dc2f5c"
              color="#fff"
              isOpen={
                readBlockList[activeReadBlock].read_attributes[activeAttribute]
                  .format_id === ''
              }
            >
              <Select
                bg="#535355"
                borderColor="#535355"
                color="white"
                w={220}
                mt={2}
                onChange={handleFormat}
                placeholder="Select an option ..."
                value={
                  readBlockList[activeReadBlock].read_attributes[
                    activeAttribute
                  ].format_id
                }
              >
                {Object.keys(formatList).length > 0 &&
                  Object.keys(formatList).map(
                    (item, index) =>
                      formatList[item] !== null && (
                        <option key={index} value={item}>
                          {formatList[item].format_name}
                        </option>
                      )
                  )}
                <option value="create">Create Format</option>
              </Select>
            </Tooltip>
            <Button
              mt={2}
              ml={2}
              onClick={handleEditFormat}
              isDisabled={
                readBlockList[activeReadBlock].read_attributes[activeAttribute]
                  .format_id === ''
              }
            >
              <Icon as={GoPencil} boxSize={6} />
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default memo(ModbusRTUAttribute);
