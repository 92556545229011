import React, { useEffect, useState, useRef, memo } from 'react';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Select,
  useToast,
} from '@chakra-ui/react';
import CustomSwitch from '../../../../../components/custom-switch/custom-switch.component';

const OutputChannelModal = ({
  isOpen,
  onClose,
  selectedChannel,
  DAC,
  DOUT,
  OUTPUTS,
  PWM,
  handleUpdateChannelConfig,
}) => {
  const [DACConfig, setDACConfig] = useState({}); // DAC
  const [OUTPUTSSelection, setOUTPUTSSelection] = useState({}); // OUTPUTS
  const [DOUTConfig, setDOUTConfig] = useState({}); // DOUT
  const [PWMConfig, setPWMConfig] = useState({});
  const [selectedOM, setSelectedOM] = useState('');
  const [voltage, setVoltage] = useState('');
  // const [duty, setDuty] = useState('');
  // const [frequency, setFrequency] = useState('');
  const [isButtonLoading, setButtonLoading] = useState(false);
  const toast = useToast();
  const updateState = useRef(false);

  useEffect(() => {
    if (DAC && !updateState.current) {
      setDACConfig(DAC);
    }
  }, [DAC]);

  useEffect(() => {
    if (DOUT && !updateState.current) {
      setDOUTConfig(DOUT);
    }
  }, [DOUT]);

  useEffect(() => {
    if (PWM && !updateState.current) {
      setPWMConfig(PWM);
    }
  }, [PWM]);

  useEffect(() => {
    if (OUTPUTS && !updateState.current) {
      setOUTPUTSSelection(OUTPUTS);
    }
  }, [OUTPUTS]);

  useEffect(() => {
    if (OUTPUTSSelection && selectedChannel) {
      setSelectedOM(OUTPUTSSelection[`out_${selectedChannel.at(-1)}`]);
      setVoltage(DAC[selectedChannel].voltage);
    }
  }, [selectedChannel]);

  const handleChangeOM = (e) => {
    const newOUTPUTSSelection = structuredClone(OUTPUTS);
    newOUTPUTSSelection[`out_${selectedChannel.at(-1)}`] = e.target.value;
    setSelectedOM(e.target.value);
    setOUTPUTSSelection(newOUTPUTSSelection);
  };

  const handleUpdateConfig = (name, e) => {
    let newConfig = {};
    if (selectedOM === 'dout') {
      newConfig = structuredClone(DOUTConfig);
      if (name === 'state') {
        newConfig[`dout_${selectedChannel.at(-1)}`][name] = e;
      } else if (name === 'data_collection' && e) {
        newConfig[`dout_${selectedChannel.at(-1)}`][name] = e
          ? 'enabled'
          : 'disabled';
        newConfig.data_collection = 'enabled';
      } else {
        newConfig[`dout_${selectedChannel.at(-1)}`][name] = e
          ? 'enabled'
          : 'disabled';
      }
      setDOUTConfig(newConfig);
    } else {
      newConfig = structuredClone(DACConfig);
      if (name === 'data_collection' && e) {
        newConfig[selectedChannel][name] = e ? 'enabled' : 'disabled';
        newConfig.data_collection = 'enabled';
      } else {
        newConfig[selectedChannel][name] = e ? 'enabled' : 'disabled';
      }

      setDACConfig(newConfig);
    }
  };

  const handleChangeVoltage = (value) => {
    setVoltage(value);
  };

  const handleChangeDuty = (value) => {
    const newConfig = structuredClone(PWMConfig);
    newConfig[`pwm_${selectedChannel.at(-1)}`].duty_cycle = value;
    // setDuty(value);
    setPWMConfig(newConfig);
  };

  const handleChangeFrequency = (value) => {
    const newConfig = structuredClone(PWMConfig);
    newConfig[`pwm_${selectedChannel.at(-1)}`].frequency = value;
    // setFrequency(value);
    setPWMConfig(newConfig);
  };

  const handleConfirmConfig = async () => {
    updateState.current = true;
    setButtonLoading(true);

    if (selectedOM === 'dout') {
      handleUpdateChannelConfig(
        DACConfig,
        DOUTConfig,
        OUTPUTSSelection,
        'dout'
      );
    }

    if (selectedOM === 'aout') {
      // validate voltage
      if (
        voltage > (DACConfig.gain ? 10 : 5) ||
        voltage < 0 ||
        voltage === '' ||
        Number.isNaN(voltage)
      ) {
        toast({
          title: 'Invalid Voltage value!',
          description: `Please try a valid voltage. Max: ${
            DACConfig && DACConfig.gain ? 10 : 5
          }V. Min: 0V.`,
          status: 'error',
          position: 'top',
          duration: 5000,
          isClosable: true,
        });
        setButtonLoading(false);
      } else {
        const updateDACConfig = structuredClone(DACConfig);
        // add voltage value
        updateDACConfig[selectedChannel].voltage = parseFloat(voltage, 10);
        setDACConfig(updateDACConfig);
        handleUpdateChannelConfig(
          updateDACConfig,
          DOUTConfig,
          OUTPUTSSelection,
          'dac'
        );
      }
    }

    // TODO: selectedOM === 'pwm'
  };

  const handleOnCloseModal = () => {
    // revert changes if not applying
    setDACConfig(DAC);
    setOUTPUTSSelection(OUTPUTS);
    setDOUTConfig(DOUT);
    setSelectedOM(OUTPUTS[`out_${selectedChannel.at(-1)}`]);
    onClose();
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={handleOnCloseModal}
      onCloseComplete={() => {
        updateState.current = false;
        setButtonLoading(false);
      }}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Output{' '}
          {selectedChannel &&
            parseInt(selectedChannel[selectedChannel.length - 1], 10)}
        </ModalHeader>
        <ModalCloseButton isDisabled={isButtonLoading} />

        <ModalBody pt={0}>
          {isButtonLoading && <div className="modal-disabled" />}
          <Flex alignItems="center" flexWrap="wrap">
            <h4>Operating Mode :</h4>
            <Select
              bg="#535355"
              borderColor="#535355"
              color="white"
              w={200}
              ml={2}
              defaultValue={
                OUTPUTS &&
                selectedChannel &&
                OUTPUTS[`out_${selectedChannel.at(-1)}`]
              }
              onChange={handleChangeOM}
              isDisabled={isButtonLoading}
            >
              <option value="aout">Analog Output</option>
              <option value="dout">Digital Output</option>
              {selectedChannel &&
                parseInt(selectedChannel[selectedChannel.length - 1], 10) <
                  3 && (
                  <option value="pwm" disabled>
                    PWM Output
                  </option>
                )}
            </Select>

            {((selectedOM === 'aout' && DAC.data_collection === 'disabled') ||
              (selectedOM === 'dout' &&
                DOUT.data_collection === 'disabled')) && (
              <Alert status="warning" mt={2} borderRadius="10px">
                <AlertIcon />
                Data Collection is disabled at the module level.
              </Alert>
            )}
          </Flex>
          <Box className="operating-menu">
            {selectedOM === 'aout' &&
              DACConfig &&
              DACConfig.control === 'sdk' && (
                <h4 className="sdk-enabled">SDK is being enabled</h4>
              )}

            {selectedOM === 'dout' &&
              Object.keys(DOUTConfig).length > 0 &&
              DOUTConfig.control === 'sdk' && (
                <h4 className="sdk-enabled">SDK is being enabled</h4>
              )}

            <Box mb={4}>
              <Flex alignItems="center" className="switch-box" flexWrap="wrap">
                <h4>Data Collection:</h4>
                {selectedOM === 'aout' && (
                  <CustomSwitch
                    id={`dac-${selectedChannel}-data-collection`}
                    onSwitchChange={(e) =>
                      handleUpdateConfig('data_collection', e)
                    }
                    variant="lite"
                    isChecked={
                      Object.keys(DACConfig).length > 0 &&
                      selectedChannel &&
                      DACConfig[selectedChannel].data_collection === 'enabled'
                    }
                    isDisabled={DACConfig && DACConfig.control === 'sdk'}
                  />
                )}
                {selectedOM === 'dout' && (
                  <CustomSwitch
                    id={`dout-${selectedChannel}-data-collection`}
                    onSwitchChange={(e) =>
                      handleUpdateConfig('data_collection', e)
                    }
                    variant="lite"
                    isChecked={
                      Object.keys(DOUTConfig).length > 0 &&
                      selectedChannel &&
                      DOUTConfig[`dout_${selectedChannel.at(-1)}`]
                        .data_collection === 'enabled'
                    }
                    isDisabled={
                      Object.keys(DOUTConfig).length > 0 &&
                      DOUTConfig.control === 'sdk'
                    }
                  />
                )}

                {selectedOM === 'pwm' && (
                  <CustomSwitch
                    id={`pwm-${selectedChannel}-data-collection`}
                    onSwitchChange={(e) =>
                      handleUpdateConfig('data_collection', e)
                    }
                    variant="lite"
                    isChecked={
                      PWMConfig &&
                      selectedChannel &&
                      PWMConfig[`pwm_${selectedChannel.at(-1)}`]
                        .data_collection === 'enabled'
                    }
                    isDisabled={isButtonLoading}
                  />
                )}
              </Flex>
              <p>
                Data collection begins the collection of data from this port.
                Its reading will be subsequently shown on your Dashboard.
              </p>
            </Box>
            <Box mb={4}>
              <Flex alignItems="center" className="switch-box" flexWrap="wrap">
                <h4>Cloud Storage: </h4>

                {selectedOM === 'aout' && (
                  <CustomSwitch
                    id={`${selectedChannel}-cloud-storage`}
                    onSwitchChange={(e) =>
                      handleUpdateConfig('cloud_storage', e)
                    }
                    variant="lite"
                    isChecked={
                      DACConfig &&
                      DACConfig[selectedChannel].cloud_storage === 'enabled'
                    }
                    isDisabled
                  />
                )}

                {selectedOM === 'dout' && (
                  <CustomSwitch
                    id={`${selectedChannel}-cloud-storage`}
                    onSwitchChange={(e) =>
                      handleUpdateConfig('cloud_storage', e)
                    }
                    variant="lite"
                    isChecked={
                      DOUTConfig &&
                      selectedChannel &&
                      DOUTConfig[`dout_${selectedChannel.at(-1)}`]
                        .cloud_storage === 'enabled'
                    }
                    isDisabled
                  />
                )}

                {selectedOM === 'pwm' && (
                  <CustomSwitch
                    id={`pwm-${selectedChannel}`}
                    onSwitchChange={(e) =>
                      handleUpdateConfig('cloud_storage', e)
                    }
                    variant="lite"
                    isChecked={
                      PWM &&
                      selectedChannel &&
                      PWM[`pwm_${selectedChannel.at(-1)}`].cloud_storage ===
                        'enabled'
                    }
                    isDisabled
                  />
                )}
              </Flex>
              <p>
                Cloud Storage will begin the storing of data. This data will be
                made available to you through our data visualization tool.
              </p>
            </Box>
          </Box>
          {selectedOM === 'aout' && (
            <Box className="operating-menu">
              <Flex
                mb={4}
                justifyContent="center"
                flexDirection="column"
                className="switch-box"
              >
                <Flex alignItems="center" flexWrap="wrap">
                  <h4>Voltage (V): </h4>
                  <Input
                    htmlSize={5}
                    width="auto"
                    textAlign="right"
                    isRequired
                    defaultValue={`${
                      DAC[selectedChannel] ? DAC[selectedChannel].voltage : ''
                    }`}
                    isInvalid={DACConfig.gain ? voltage > 10 : voltage > 5}
                    isDisabled={DACConfig && DACConfig.control === 'sdk'}
                    onChange={(e) => {
                      handleChangeVoltage(e.target.value);
                    }}
                  />
                </Flex>
                <p>Minimum Voltage: 0.</p>
                <p>Maximum Voltage: {DACConfig.gain ? 10 : 5}.</p>
              </Flex>
            </Box>
          )}

          {selectedOM === 'dout' && (
            <Box className="operating-menu">
              <Flex
                mb={4}
                justifyContent="center"
                flexDirection="column"
                className="switch-box"
              >
                <Flex alignItems="center" flexWrap="wrap">
                  <h4>State : </h4>
                  <Select
                    bg="#535355"
                    borderColor="#535355"
                    color="white"
                    w={200}
                    defaultValue={
                      DOUT &&
                      selectedChannel &&
                      DOUT[`dout_${selectedChannel.at(-1)}`].state
                    }
                    isDisabled={DOUTConfig && DOUTConfig.control === 'sdk'}
                    onChange={(e) =>
                      handleUpdateConfig('state', e.target.value)
                    }
                  >
                    <option value="hi_z">Hi-Z</option>
                    <option value="low">Low</option>
                    <option value="high">High</option>
                  </Select>
                </Flex>
              </Flex>
            </Box>
          )}

          {selectedOM === 'pwm' && (
            <Box className="operating-menu">
              <Flex
                mb={4}
                justifyContent="center"
                flexDirection="column"
                className="switch-box"
              >
                <Flex alignItems="center" mb={2} flexWrap="wrap">
                  <h4>Duty Cycle: </h4>
                  <Input
                    htmlSize={5}
                    width="auto"
                    textAlign="right"
                    defaultValue={`${
                      PWM[`pwm_${selectedChannel.at(-1)}`]
                        ? PWM[`pwm_${selectedChannel.at(-1)}`].duty_cycle
                        : ''
                    }`}
                    isDisabled={PWMConfig && PWMConfig.control === 'sdk'}
                    onChange={(e) => {
                      handleChangeDuty(e.target.value);
                    }}
                  />
                </Flex>

                <Flex alignItems="center" flexWrap="wrap">
                  <h4>Frequency (Hz):</h4>
                  <Input
                    htmlSize={5}
                    width="auto"
                    textAlign="right"
                    defaultValue={`${
                      PWM[`pwm_${selectedChannel.at(-1)}`]
                        ? PWM[`pwm_${selectedChannel.at(-1)}`].frequency
                        : ''
                    }`}
                    isDisabled={PWMConfig && PWMConfig.control === 'sdk'}
                    onChange={(e) => {
                      handleChangeFrequency(e.target.value);
                    }}
                  />
                </Flex>

                <Flex alignItems="center" mt={2} flexWrap="wrap">
                  <h4>Polarity :</h4>
                  <Select
                    bg="#535355"
                    borderColor="#535355"
                    color="white"
                    w={120}
                    ml={2}
                    defaultValue={
                      PWM && PWM[`pwm_${selectedChannel.at(-1)}`].polarity
                    }
                    onChange={(e) =>
                      handleUpdateConfig('polarity', e.target.value)
                    }
                  >
                    <option value="normal">Normal</option>
                    <option value="inversed">Inversed</option>
                  </Select>
                </Flex>
              </Flex>
            </Box>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            className="btn-group"
            bg="#535355"
            color="#FFF"
            boxShadow="0px 15px 30px rgba(0, 0, 0, 0.15)"
            variant="solid"
            w={150}
            mr={2}
            onClick={handleOnCloseModal}
            isDisabled={isButtonLoading}
            _hover={{ bg: '#242424' }}
          >
            Cancel
          </Button>

          <Button
            className="btn-group"
            bg="#DB2F5CCC"
            color="#FFF"
            variant="solid"
            w={150}
            onClick={handleConfirmConfig}
            isLoading={isButtonLoading}
            _hover={{ bg: '#db2f5d99' }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default memo(OutputChannelModal);
