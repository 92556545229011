// For FTX only, when user changes the FTX model between : 1_ch, 3_ch and 9_ch : Need to update the available channels in the modbus_ftx.channels before sending to BackEnd
const updateChannelList = (model, channels) => {
  const n = parseInt(model.charAt(0), 10);
  if (n === 1) {
    return channels.filter((item) => ['A'].includes(item));
  }
  if (n === 3) {
    return channels.filter((item) => ['A', 'B', 'C'].includes(item));
  }
  return channels.filter((item) =>
    ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'].includes(item)
  );
};
export default updateChannelList;
