import { useState } from 'react';

const useADCModal = (callback, initialState = {}) => {
  const [values, setValues] = useState(initialState);

  const convertToNumber = (value) => {
    const numericValue = Number(value);
    return !Number.isNaN(numericValue) ? numericValue : value;
  };

  const onChange = (objectKey, keyToUpdate) => (event) => {
    if (objectKey) {
      if (
        keyToUpdate === 'data_collection' ||
        keyToUpdate === 'cloud_storage'
      ) {
        if (objectKey === 'diff_3' || objectKey === 'diff_4') {
          // disable RTD if activate DIFF 3 or DIFF 4
          setValues((prevValues) => ({
            ...prevValues,
            [objectKey]: {
              ...prevValues[objectKey],
              [keyToUpdate]: event ? 'enabled' : 'disabled',
            },
            rtd: {
              ...prevValues.rtd,
              data_collection: 'disabled',
            },
          }));
        } else if (objectKey === 'rtd') {
          // disable data_collection for DIFF 3 or DIFF 4
          setValues((prevValues) => ({
            ...prevValues,
            [objectKey]: {
              ...prevValues[objectKey],
              [keyToUpdate]: event ? 'enabled' : 'disabled',
            },
            diff_3: {
              ...prevValues.diff_3,
              data_collection: 'disabled',
            },
            diff_4: {
              ...prevValues.diff_4,
              data_collection: 'disabled',
            },
          }));
        } else {
          setValues((prevValues) => ({
            ...prevValues,
            [objectKey]: {
              ...prevValues[objectKey],
              [keyToUpdate]: event ? 'enabled' : 'disabled',
            },
          }));
        }
      } else {
        setValues((prevValues) => ({
          ...prevValues,
          [objectKey]: {
            ...prevValues[objectKey],
            [keyToUpdate]: event.target
              ? convertToNumber(event.target.value)
              : event,
          },
        }));
      }
    } else if (
      keyToUpdate === 'data_collection' ||
      keyToUpdate === 'cloud_storage'
    ) {
      setValues((prevValues) => ({
        ...prevValues,
        [keyToUpdate]: event ? 'enabled' : 'disabled',
      }));
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        [keyToUpdate]: event.target
          ? convertToNumber(event.target.value)
          : event,
      }));
    }
  };

  const onStart = (config) => {
    setValues(config);
  };

  const onResetAll = () => {
    setValues(initialState);
  };

  const onSubmit = (event) => {
    callback();
    event.preventDefault();
  };

  return {
    onStart,
    onChange,
    onSubmit,
    onResetAll,
    values,
  };
};
export default useADCModal;
