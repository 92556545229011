import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Stack,
  Radio,
  RadioGroup,
  useToast,
} from '@chakra-ui/react';
import portalHandleRequest from '../../../api/portalHandleRequest';

const PORTAL_API_ENDPOINT = process.env.REACT_APP_PORTAL_API;

const PlansModal = ({
  isOpen,
  onClose,
  plans,
  userEmail,
  thingID,
  refreshData,
  refreshProductData,
}) => {
  const [freePlanID, setFreePlanID] = useState('');
  const [selectedPlanID, setSelectedPlanID] = useState();
  const [isButtonLoading, setButtonLoading] = useState(false);
  const toast = useToast();
  useEffect(() => {
    const freePlan = plans.find((plan) => plan.name === 'Free');
    if (freePlan) {
      setFreePlanID(freePlan.plan_id);
      setSelectedPlanID(freePlan.plan_id);
    }
  }, [plans]);

  const handleSelectPlan = (e) => {
    setSelectedPlanID(e);
  };
  const handleAttachAPlan = async () => {
    setButtonLoading(true);
    const deviceObj = {
      user_email: userEmail,
      plan_id: selectedPlanID,
    };

    const addDeviceOptions = {
      method: 'PATCH',
      url: `${PORTAL_API_ENDPOINT}/devices/${thingID}`,
      contentType: 'application/json',
      data: deviceObj,
    };

    const response = await portalHandleRequest(addDeviceOptions);

    if (response.status === 200) {
      toast({
        title: 'Plan attached successfully!',
        status: 'success',
        position: 'top',
        duration: 5000,
        isClosable: true,
      });
      setButtonLoading(false);
      refreshProductData(!refreshData);
      onClose();
    } else {
      toast({
        title: 'Failed to add new device!',
        description: 'Please contact us for support',
        status: 'error',
        position: 'top',
        duration: 10000,
        isClosable: true,
      });
      setButtonLoading(false);
    }
  };
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Attach a Plan</ModalHeader>

        <ModalBody>
          <RadioGroup defaultValue={freePlanID} onChange={handleSelectPlan}>
            <Stack>
              {plans.map((plan) => (
                <Radio
                  key={plan.plan_id}
                  size="lg"
                  value={plan.plan_id}
                  colorScheme="orange"
                >
                  {plan.name}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        </ModalBody>

        <ModalFooter>
          <Button
            className="btn-group"
            bg="#535355"
            color="#FFF"
            boxShadow="0px 15px 30px rgba(0, 0, 0, 0.15)"
            variant="solid"
            w={150}
            mr={2}
            onClick={onClose}
            isDisabled={isButtonLoading}
            _hover={{ bg: '#242424' }}
          >
            Cancel
          </Button>

          <Button
            className="btn-group"
            bg="#DB2F5CCC"
            color="#FFF"
            variant="solid"
            w={150}
            onClick={handleAttachAPlan}
            isLoading={isButtonLoading}
            _hover={{ bg: '#db2f5d99' }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PlansModal;
