import React from 'react';
import { Image } from '@chakra-ui/react';

const OrderItem = ({ imageURL, name, quantity }) => (
  <>
    <div className="product-image">
      <Image
        objectFit="contain"
        src={!imageURL || imageURL === 'null' ? '/edgepi.png' : imageURL}
        alt={name}
        m={2}
      />
      <div className="order-info">
        <h4 className="product-name">{name}</h4>
        <p className="quantity">QTY: {quantity}</p>
      </div>
    </div>
  </>
);

export default OrderItem;
