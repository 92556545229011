import React, { memo } from 'react';
import { Icon, Box } from '@chakra-ui/react';
import { BsArrowsAngleContract } from 'react-icons/bs';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import mapModbusRTUStatus from '../../aux-device-view/mapModbusRTUStatus';

const ModbusRTUSingleView = ({
  data,
  handleOpenChannel,
  selectedAttribute,
}) => {
  return data.length !== 0 ? (
    <Box className="single-attribute-view">
      <Icon
        className="zoom-btn"
        as={BsArrowsAngleContract}
        w={6}
        h={6}
        onClick={() => handleOpenChannel('')}
        zIndex={10}
      />
      <Box className="attribute-carousel">
        <Carousel
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          selectedItem={selectedAttribute}
        >
          {data.map((attr, index) => (
            <Box key={index} className="modbus-rtu-attribute-wrapper">
              <Box className="modbus-rtu-attribute-data">
                <h3>{attr.attribute_name}</h3>
                <h1
                  className={`device-data ${
                    attr.isDataStaled === true ? 'staled-data' : ''
                  }`}
                >
                  <strong className="attr-text-value">{`${attr.attribute_value}`}</strong>
                  {attr.data_unit === 'None' ? '' : attr.data_unit}
                </h1>
              </Box>
              <Box className="modbus-rtu-attribute-data">
                <h3>Status</h3>
                <h1
                  className={`device-data ${
                    attr.isDataStaled === true ? 'staled-data' : ''
                  }`}
                >
                  {mapModbusRTUStatus(attr.attribute_status)}
                </h1>
              </Box>
            </Box>
          ))}
        </Carousel>
      </Box>
    </Box>
  ) : (
    <Box className="single-channel-view">
      <Icon
        className="zoom-btn"
        as={BsArrowsAngleContract}
        w={6}
        h={6}
        onClick={() => handleOpenChannel()}
        zIndex={10}
      />
      <Box m={5}>
        <h4>Data is not available</h4>
      </Box>
    </Box>
  );
};

export default memo(ModbusRTUSingleView);
