import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Box,
  Container,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Image,
  Progress,
} from '@chakra-ui/react';
import CloudAnimation from '../../components/animation/cloud';
import './plan.styles.scss';

const Plan = ({ activeDevices, activePlans }) => {
  const [plan, setPlan] = useState({});
  const [assignedDevices, setAssignedDevices] = useState({});
  const { id } = useParams();

  useEffect(() => {
    const getPlanData = async () => {
      const currentPlan = activePlans.find((plan) => plan.plan_id === id);
      if (currentPlan) {
        const filteredArray = activeDevices.filter((obj) =>
          currentPlan.assigned_devices.includes(obj.thing_id)
        );
        setPlan(currentPlan);
        setAssignedDevices(filteredArray);
      }
    };

    getPlanData();
  }, [id, activeDevices, activePlans]);

  return (
    <Box className="plan-page">
      {Object.keys(plan).length !== 0 ? (
        <Container p={0} maxW="full" className="plan-page--container">
          <Flex
            wrap="wrap"
            justifyContent="center"
            className="plan-page--details"
          >
            <Box className="plan-container">
              <h1 className="plan-name">{plan.name}</h1>

              <p>
                <strong>Next Billing Date</strong> :{' '}
                {plan.billing_date || 'N/A'}
              </p>
              <p>
                <strong>Monthly Charge</strong> : {plan.monthly_charge || 'N/A'}
              </p>
              <p className="plan-start-date">
                Plan Start Date : <strong>{plan.start_date || 'N/A'}</strong>
              </p>
            </Box>

            <Box className="plan-container">
              {Object.keys(plan).length > 0 && (
                <Image
                  boxSize="350px"
                  objectFit="contain"
                  src={`/${plan.name.replace(/\s+/g, '').toLowerCase()}.svg`}
                  alt="plan-img"
                  mb={2}
                />
              )}
            </Box>
          </Flex>

          <Flex
            wrap="wrap"
            justifyContent="center"
            className="plan-page--details"
          >
            <Box className="plan-container">
              <h3>Devices Attached</h3>
              <Progress
                className="plan-usage-bar"
                mt={4}
                value={
                  (plan.assigned_devices.length / plan.max_allowed_devices) *
                  100
                }
                w="100%"
                h="20px"
                borderRadius="10px"
              />
              <p className="plan-usage">
                Usage :{' '}
                {(
                  plan.assigned_devices.length / plan.max_allowed_devices
                ).toFixed(2) * 100}
                % ( {plan.assigned_devices.length} Devices of{' '}
                {plan.max_allowed_devices} )
              </p>
              {plan.assigned_devices.length > 0 && (
                <Flex className="device-list" mt={8} flexDirection="column">
                  {assignedDevices && assignedDevices.length > 0
                    ? assignedDevices.map((device) => (
                        <Link
                          key={device.thing_id}
                          to={`/device/${device.thing_id}`}
                        >
                          <Flex
                            m={2}
                            alignItems="center"
                            className="device-info"
                          >
                            <Image
                              boxSize="100px"
                              objectFit="contain"
                              src="/edgepi-placeholder.png"
                              alt="settings"
                              mr={6}
                            />
                            <Flex flexDirection="column">
                              <h4 className="device-name">
                                {device.product_name}
                              </h4>
                              <p className="device-id">
                                ID : {device.thing_id}
                              </p>
                            </Flex>
                          </Flex>
                        </Link>
                      ))
                    : ''}
                </Flex>
              )}
            </Box>

            <Box className="plan-container">
              <h3>Plan Data Usage</h3>
              {process.env.REACT_APP_DEV_FEATURES === 'true' ? (
                <Flex justifyContent="center" flexWrap="wrap">
                  <Flex m={4} flexDirection="column" alignItems="center">
                    <h4>Data Traffic</h4>
                    <CircularProgress
                      mt={4}
                      value={40}
                      size="120px"
                      color="#dc2f5c"
                    >
                      <CircularProgressLabel>40%</CircularProgressLabel>
                    </CircularProgress>
                    <h4>13GB Remaining</h4>
                    <p>7GB / 20 GB</p>
                  </Flex>

                  <Flex m={4} flexDirection="column" alignItems="center">
                    <h4>Data Storage</h4>
                    <CircularProgress
                      mt={4}
                      value={40}
                      size="120px"
                      color="#dc2f5c"
                    >
                      <CircularProgressLabel>40%</CircularProgressLabel>
                    </CircularProgress>
                    <h4>13GB Remaining</h4>
                    <p>7GB / 20 GB</p>
                  </Flex>
                </Flex>
              ) : (
                <h4>COMING SOON</h4>
              )}
            </Box>
          </Flex>
        </Container>
      ) : (
        <Container maxW="full" className="devices-page--container">
          <CloudAnimation />
        </Container>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  activeDevices: state.user.activeDevices,
  activePlans: state.product.activePlans,
});

export default connect(mapStateToProps, null)(Plan);
