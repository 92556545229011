import React, { useState, useEffect, memo } from 'react';
import { Tr, Td } from '@chakra-ui/react';
import pdConversion from '../../aux-device-view/pdConversion';
import mapPDXStatus from '../../aux-device-view/mapPDXStatus';
import formatDecimal from '../../aux-device-view/formatDecimal';

const PDXChannelList = ({
  data,
  currentPage,
  itemLimit,
  handleOpenChannel,
  ModbusConfig,
}) => {
  const [currentData, setCurrentData] = useState([]);
  const [isDataStaled, setDataStaled] = useState(false);

  useEffect(() => {
    const startIndex = (currentPage - 1) * itemLimit;
    const endIndex = startIndex + itemLimit;

    const getList = () => {
      const newData = data.slice(startIndex, endIndex); // get channels in current page

      if (
        currentData.length > 0 &&
        currentData[0].timestamp_ms > newData[0].timestamp_ms
      ) {
        setDataStaled(true);
      } else {
        setDataStaled(false);
      }
      // Filter data from the selected channels
      const filteredArray = newData.filter((item) => {
        const channelSuffix = item.channel.split('_').pop();
        return ModbusConfig.channels.includes(channelSuffix);
      });

      setCurrentData(filteredArray);
    };

    getList();
  }, [currentPage, itemLimit, data, ModbusConfig]);

  return currentData.map((item, index) => (
    <Tr
      className="channel-row"
      key={index}
      onClick={() => handleOpenChannel(item.channel, index)}
    >
      <Td>{item.channel_name}</Td>

      {ModbusConfig.read_attributes.includes('pd_peak') && (
        <Td className={`device-data ${isDataStaled ? 'staled-data' : ''}`}>
          {item.pd_peak_pc ? `${pdConversion(Number(item.pd_peak_pc))}` : '--'}
        </Td>
      )}

      {ModbusConfig.read_attributes.includes('pd_total') && (
        <Td className={`device-data ${isDataStaled ? 'staled-data' : ''}`}>
          {item.pd_total_pc
            ? `${pdConversion(Number(item.pd_total_pc))}`
            : '--'}
        </Td>
      )}
      {ModbusConfig.read_attributes.includes('humidity') && (
        <Td className={`device-data ${isDataStaled ? 'staled-data' : ''}`}>
          {item.humidity_perc ? `${item.humidity_perc}%` : '--'}
        </Td>
      )}

      {ModbusConfig.read_attributes.includes('temperature') && (
        <Td className={`device-data ${isDataStaled ? 'staled-data' : ''}`}>
          {item.temperature_c
            ? `${formatDecimal(item.temperature_c, 2)}°C`
            : '--'}
        </Td>
      )}
      {ModbusConfig.read_attributes.includes('status') && (
        <Td className={`device-data ${isDataStaled ? 'staled-data' : ''}`}>
          {mapPDXStatus(item.status) ?? '--'}
        </Td>
      )}
    </Tr>
  ));
};

export default memo(PDXChannelList);
