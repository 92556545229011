function getKeysWithEmptyValues(obj) {
  const emptyKeys = [];

  function checkEmpty(value) {
    return value === '' || value === undefined || Number.isNaN(value);
  }

  function checkObject(obj) {
    for (const key in obj) {
      if (
        Object.prototype.hasOwnProperty.call(obj, key) &&
        key !== 'mappings'
      ) {
        const value = obj[key];
        if (Array.isArray(value) && value.length === 0) {
          emptyKeys.push(key);
        } else if (typeof value === 'object') {
          checkObject(value);
        } else if (checkEmpty(value)) {
          emptyKeys.push(key);
        }
      }
    }
  }
  checkObject(obj);
  return emptyKeys;
}

function checkValidValue(protocol) {
  if (
    Number.isInteger(protocol.modbus_id) &&
    protocol.modbus_id >= 1 &&
    protocol.modbus_id <= 247
  ) {
    console.log('Modbus ID is valid!');
  } else {
    return {
      isValid: false,
      message: 'Invalid Modbus ID. Valid Range: 1 to 247.',
    };
  }

  if (Number.isInteger(protocol.baud_rate) && protocol.baud_rate > 0) {
    console.log('Baud Rate is valid!');
  } else {
    return {
      isValid: false,
      message: 'Invalid Baud Rate. Valid range: greater than 0',
    };
  }
  return {
    isValid: true,
    message: 'The AUX config is valid',
  };
}

const auxValidation = (config) => {
  // Validation: check any empty values
  const emptyKeys = getKeysWithEmptyValues(config);
  if (emptyKeys.length > 0) {
    console.error('Config object has empty values in keys:', emptyKeys);
    return {
      isValid: false,
      message: `Some values are missing. Please check and try again.`,
    };
  }
  return checkValidValue(config[config.protocol]);
};

export default auxValidation;
