import { ProductActionTypes } from './product.types';

export const setActivePlans = (plans) => ({
  type: ProductActionTypes.SET_ACTIVE_PLANS,
  payload: plans,
});
export const setGrafanaData = (data) => ({
  type: ProductActionTypes.SET_GRAFANA_DATA,
  payload: data,
});
export const refreshProductData = (bool) => ({
  type: ProductActionTypes.REFRESH_PRODUCT_DATA,
  payload: bool,
});
