import { ProductActionTypes } from './product.types';

const INITIAL_STATE = {
  activePlans: [],
  grafanaData: {},
  refreshData: false,
};

const productReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ProductActionTypes.SET_ACTIVE_PLANS:
      return {
        ...state,
        activePlans: action.payload,
      };
    case ProductActionTypes.SET_GRAFANA_DATA:
      return {
        ...state,
        grafanaData: action.payload,
      };
    case ProductActionTypes.REFRESH_PRODUCT_DATA:
      return {
        ...state,
        refreshData: action.payload,
      };
    default:
      return state;
  }
};

export default productReducer;
