import React from 'react';
import { Flex, Image } from '@chakra-ui/react';
import './plan-card.styles.scss';

const PlanCard = ({ data }) => {
  return (
    <div className="plan-card">
      <div className="plan-card--info">
        {Object.keys(data).length > 0 && (
          <Image
            boxSize="350px"
            objectFit="contain"
            src={`/${data.name.replace(/\s+/g, '').toLowerCase()}.svg`}
            alt="plan-img"
            p={8}
            mb={2}
          />
        )}
      </div>
      <div className="plan-card--stats">
        <h4>{data.name}</h4>
        <Flex alignItems="center" className="stats-info">
          <p>Next Billing Date :</p>
          <span className="status">{data.billing_date || 'N/A'}</span>
        </Flex>
        <Flex alignItems="center" className="stats-info">
          <p>Monthly Charge:</p>
          <span className="status">{data.monthly_charge || 'N/A'}</span>
        </Flex>
      </div>
    </div>
  );
};

export default PlanCard;
