import React, { useCallback, memo } from 'react';
import { Box, Button, Icon, useDisclosure, useToast } from '@chakra-ui/react';
import { SiIcloud } from 'react-icons/si';
import { PiCloudWarningLight } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import AUXConfigModal from './aux-modal/aux-config-modal.component';
import FTXConfigModal from './protocol/modbus-ftx/FTXConfigModal';
import PDXConfigModal from './protocol/modbus-pdx/PDXConfigModal';
import ModbusRTUConfigModal from './protocol/modbus-rtu/ModbusRTUConfigModal';
import portalHandleRequest from '../../../../api/portalHandleRequest';
import generateID from '../../../../api/generateID';
import checkAUXDesiredConfigSize from './functions/checkAUXDesiredConfigSize';
import './aux.styles.scss';

const PORTAL_API_ENDPOINT = process.env.REACT_APP_PORTAL_API;

const AUX = ({ deviceShadow, thingID, getConfig, isAvailable }) => {
  const {
    isOpen: isAUXConfigModalOpen,
    onOpen: onAUXConfigModalOpen,
    onClose: onAUXConfigModalClose,
  } = useDisclosure();

  const {
    isOpen: isModbusRTUModalOpen,
    onOpen: onModbusRTUModalOpen,
    onClose: onModbusRTUModalClose,
  } = useDisclosure();

  const {
    isOpen: isPDXModalOpen,
    onOpen: onPDXModalOpen,
    onClose: onPDXModalClose,
  } = useDisclosure();

  const {
    isOpen: isFTXModalOpen,
    onOpen: onFTXModalOpen,
    onClose: onFTXModalClose,
  } = useDisclosure();

  const navigate = useNavigate();
  const toast = useToast();

  const handleCloseModals = () => {
    onModbusRTUModalClose();
    onFTXModalClose();
    onPDXModalClose();
  };
  const handleSelectProtocol = (e) => {
    if (e === 'modbus_ftx') {
      onFTXModalOpen();
    } else if (e === 'modbus_rtu') {
      onModbusRTUModalOpen();
    } else if (e === 'modbus_pdx') {
      onPDXModalOpen();
    }
  };
  const handleUpdateAUXConfig = useCallback(
    async (AUXDeviceID, config, callback) => {
      const isAUXDesiredSizeValid = checkAUXDesiredConfigSize(
        deviceShadow.state.desired.AUX,
        config
      );
      if (isAUXDesiredSizeValid) {
        // Generate ID
        let uniqueAUXDeviceId;
        if (AUXDeviceID) {
          uniqueAUXDeviceId = AUXDeviceID;
        } else {
          uniqueAUXDeviceId = generateID('aux');
        }
        const obj = {
          method: 'PATCH',
          url: `${PORTAL_API_ENDPOINT}/shadows/${thingID}`,
          contentType: 'application/json',
          data: {
            state: {
              desired: {
                AUX: {
                  [uniqueAUXDeviceId]: config,
                },
              },
            },
          },
        };

        const res = await portalHandleRequest(obj, navigate);

        if (res.status === 200) {
          const retry = (fn, retriesLeft = 10, interval = 1000) => {
            return new Promise((resolve, reject) => {
              fn()
                .then((response) => {
                  if (!response) {
                    // is_valid is false in getConfig() call
                    setTimeout(() => {
                      if (retriesLeft === 1) {
                        console.log('Maximum retries exceeded');
                        toast({
                          title: 'Failed to setup AUX configuration',
                          description:
                            'Invalid EdgePi config. Please try power cycling your EdgePi or contact tech support',
                          status: 'error',
                          position: 'top',
                          duration: 10000,
                          isClosable: true,
                        });
                        handleCloseModals();
                        return;
                      }
                      retry(fn, retriesLeft - 1, interval).then(
                        resolve,
                        reject
                      );
                    }, interval);
                  } else {
                    const check2ConfigObj = !isEqual(
                      response.state.desired.AUX,
                      response.state.reported.AUX
                    );

                    if (check2ConfigObj) {
                      console.log(
                        `Retry to get new reported config ... Remaining attempts: ${retriesLeft}`
                      );
                      setTimeout(() => {
                        if (retriesLeft === 1) {
                          console.log('Maximum retries exceeded');
                          toast({
                            title: 'Failed to setup AUX configuration',
                            description:
                              'Please try again later or contact us for support',
                            status: 'error',
                            position: 'top',
                            duration: 10000,
                            isClosable: true,
                          });
                          handleCloseModals();
                          return;
                        }
                        retry(fn, retriesLeft - 1, interval).then(
                          resolve,
                          reject
                        );
                      }, interval);
                    } else {
                      console.log('Updated successfully!');
                      toast({
                        title:
                          'New AUX configuration has been setup successfully',
                        status: 'success',
                        position: 'top',
                        duration: 2000,
                        isClosable: true,
                      });
                      handleCloseModals();
                      callback();
                      resolve();
                    }
                  }
                })
                .catch((error) => {
                  setTimeout(() => {
                    if (retriesLeft === 1) {
                      // maximum retries exceeded
                      toast({
                        title: 'Failed to setup AUX configuration',
                        description:
                          'Please try again later or contact us for support',
                        status: 'error',
                        position: 'top',
                        duration: 10000,
                        isClosable: true,
                      });
                      reject(error);
                      handleCloseModals();
                      return;
                    }
                    retry(fn, retriesLeft - 1, interval).then(resolve, reject);
                  }, interval);
                });
            });
          };
          retry(() => getConfig());
        } else if (res.status === 400) {
          // Backend validation error logs
          toast({
            title:
              'Invalid EdgePi config. Please try power cycling your EdgePi or reset config',
            status: 'error',
            position: 'top',
            duration: 5000,
            isClosable: true,
          });
          handleCloseModals();
          console.error(JSON.parse(res.validation_errors));
        } else {
          toast({
            title: `Failed to setup AUX configuration. Status: ${res.status}`,
            description:
              'Please try power cycling your EdgePi or contact tech support',
            status: 'error',
            position: 'top',
            duration: 10000,
            isClosable: true,
          });
          handleCloseModals();
        }
      } else {
        toast({
          title: 'Failed to setup AUX configuration',
          description:
            'The AUX configuration size exceeds the limit. Please reduce your AUX config.',
          status: 'error',
          position: 'top',
          duration: 10000,
          isClosable: true,
        });
        handleCloseModals();
      }
    },
    []
  );
  return (
    <Box className="dashboard-module">
      <div className="dashboard-module--heading">
        <h2>Auxiliary</h2>
        <Icon
          as={SiIcloud}
          color="#dc2f5c"
          className="cloud-status"
          ml={2}
          w={8}
          h={8}
        />
      </div>
      <div className="dashboard-module--content">
        {!isAvailable && (
          <div className="dashboard-module--content-disabled">
            <Icon as={PiCloudWarningLight} color="#dc2f5c" w={24} h={24} />
            <h4>
              Please upgrade your EdgePi to use this feature by following the
              guide provided{' '}
              <a
                href="https://wiki.edgepi.com/en/maintenance/edgepi_upgrade"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              .
            </h4>
          </div>
        )}

        <p style={{ marginTop: 0 }}>
          Connect your other devices to this EdgePi and view their readings from
          your Dashboard.
        </p>

        <Button
          className="btn-group"
          bg="#DB2F5CCC"
          borderRadius="10px"
          color="#FFF"
          variant="solid"
          w={200}
          onClick={onAUXConfigModalOpen}
          _hover={{ bg: '#db2f5d99' }}
        >
          Begin Configuring
        </Button>
      </div>

      <AUXConfigModal
        isOpen={isAUXConfigModalOpen}
        onClose={onAUXConfigModalClose}
        handleSelectProtocol={handleSelectProtocol}
      />

      <FTXConfigModal
        isOpen={isFTXModalOpen}
        onClose={onFTXModalClose}
        handleUpdateFTXConfig={handleUpdateAUXConfig}
      />
      <PDXConfigModal
        isOpen={isPDXModalOpen}
        onClose={onPDXModalClose}
        handleUpdatePDXConfig={handleUpdateAUXConfig}
      />
      <ModbusRTUConfigModal
        isOpen={isModbusRTUModalOpen}
        onClose={onModbusRTUModalClose}
        handleUpdateModbusRTUConfig={handleUpdateAUXConfig}
      />
    </Box>
  );
};
export default memo(AUX);
