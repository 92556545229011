import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Button,
  Flex,
  Container,
} from '@chakra-ui/react';
import { Auth } from 'aws-amplify';
import { signOutUser } from '../../redux/user/user.actions';
import { ReactComponent as ErrorIcon } from '../../assets/cloud-error.svg';
import ErrorHandler from '../ErrorHandler';
import './error.styles.scss';

const ErrorPage = ({ signOutUser }) => {
  const location = useLocation();
  const navigate = useNavigate();

  if (!location.state) {
    return <ErrorHandler />;
  }

  return (
    <Box p={4} className="error-page">
      <Container maxW="full" className="error-container">
        <div className="error-icon">
          <ErrorIcon />
        </div>

        <Flex
          className="error-detail"
          flexDirection="column"
          alignItems="center"
        >
          <h1>{location.state.status}</h1>
          <h3>Something went wrong</h3>
          <p>
            We are unable to process your request. Please try again or contact
            us for support.
          </p>
        </Flex>
        <Flex flexDirection="column" alignItems="center" w="100%">
          <Accordion mt={4} allowToggle w="100%">
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="center">
                    Details
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="error-details">
                <h3>{location.state.name}</h3>
                <p>{location.state.message}</p>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>

          <Button
            mt={6}
            className="btn-group"
            bg="#565657"
            color="#FFF"
            variant="solid"
            w={200}
            onClick={() => {
              signOutUser();
              Auth.signOut();
            }}
            _hover={{ bg: '#2e2e2f' }}
          >
            Logout
          </Button>
          <Button
            mt={6}
            className="btn-group"
            bg="#DB2F5CCC"
            color="#FFF"
            variant="solid"
            w={200}
            onClick={() => {
              navigate('/');
            }}
            _hover={{ bg: '#db2f5d99' }}
          >
            Back to Homepage
          </Button>
        </Flex>
      </Container>
    </Box>
  );
};

const mapDispatchToProps = (dispatch) => ({
  signOutUser: () => dispatch(signOutUser()),
});

export default connect(null, mapDispatchToProps)(ErrorPage);
