const mapDeviceName = (channel, mappingList) => {
  let channelName;
  if (Object.keys(mappingList).length > 0) {
    if (mappingList[`CH_${channel}`]) {
      channelName = mappingList[`CH_${channel}`];
    } else {
      channelName = `CH_${channel}`;
    }
  } else {
    channelName = `CH_${channel}`;
  }

  return channelName;
};

export default mapDeviceName;
