/* eslint-disable no-use-before-define */
import React, { useState, useEffect, memo } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  Input,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Select,
  SimpleGrid,
  Stack,
  useToast,
} from '@chakra-ui/react';
import CreatableSelect from 'react-select/creatable';
import auxValidation from '../../aux-validation/aux-validation';
import PDXMappingList from '../../aux-mapping-list/pdx-mapping-list.component';
import useForm from '../../../../../../api/useForm';
import CustomSwitch from '../../../../../../components/custom-switch/custom-switch.component';

const PDXConfigModal = ({
  isOpen,
  onClose,
  config,
  AUXDeviceID,
  handleUpdatePDXConfig,
}) => {
  const createOption = (label, value) => ({
    label,
    value,
  });

  const { onChange, onSubmit, onResetAll, values } = useForm(
    updateInfoCallback,
    {
      protocol: 'modbus_pdx',
      port: config ? config.port : '',
      device_name: config ? config.device_name : 'aux_pdx_',
      data_collection: config ? config.data_collection : 'enabled',
      cloud_storage: config ? config.cloud_storage : 'enabled',
      read_interval_ms: config ? config.read_interval_ms : 1000,

      pdx_modbus_id: config ? config.modbus_pdx.modbus_id : '',
      pdx_baud_rate: config ? config.modbus_pdx.baud_rate : 9600,
      pdx_channels: config ? config.modbus_pdx.channels : [],
      pdx_read_attributes: config ? config.modbus_pdx.read_attributes : [],

      mappings: {},
    }
  );

  async function updateInfoCallback() {
    handleConfirmConfig();
  }

  const baudRateOptions = [
    createOption(9600, 9600),
    createOption(19200, 19200),
    createOption(38400, 38400),
    createOption(57600, 57600),
    createOption(115200, 115200),
  ];

  const defaultStyles = {
    control: (baseStyles) => ({
      ...baseStyles,
      cursor: 'pointer',
    }),
    input: (baseStyles) => ({
      ...baseStyles,
      color: 'white',
    }),
    singleValue: (baseStyles) => ({
      ...baseStyles,
      color: 'white',
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: state.isFocused ? '#dc2f5c' : '',
      cursor: 'pointer',
    }),
  };

  const [AUXConfig, setAUXConfig] = useState();
  const [tabIndex, setTabIndex] = useState(0);
  const [isButtonLoading, setButtonLoading] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [checkValidField, setCheckValidField] = useState(false);
  const [baudRate, setBaudRateOptions] = useState(baudRateOptions);

  const toast = useToast();

  useEffect(() => {
    if (config) {
      setAUXConfig(config);
    }
  }, [config]);

  const handleCreateOption = (inputValue, name) => {
    // check valid value
    const newValue = parseInt(inputValue, 10);
    if (Number.isInteger(newValue) && newValue > 0) {
      setTimeout(() => {
        const newOption = createOption(inputValue, inputValue);
        if (name === 'baud_rate') {
          setBaudRateOptions((prev) => [...prev, newOption]);
        }
      }, 1000);
    } else {
      toast({
        title: 'Invalid value',
        description: 'Must be a number and more than 0',
        status: 'error',
        position: 'top',
        duration: 10000,
        isClosable: true,
      });
    }
  };

  const handleConfirmConfig = () => {
    setButtonLoading(true);
    setButtonDisabled(true);
    setCheckValidField(true);

    // manipulate desired AUX config
    const config = {
      protocol: 'modbus_pdx',
      port: values.port,
      device_name:
        values.device_name === 'aux_pdx_'
          ? `aux_pdx_${values.pdx_modbus_id}`
          : values.device_name,
      data_collection: values.data_collection,
      cloud_storage: values.cloud_storage,
      read_interval_ms: parseInt(values.read_interval_ms, 10),
      modbus_pdx: {
        modbus_id: parseInt(values.pdx_modbus_id, 10),
        baud_rate: parseInt(values.pdx_baud_rate, 10),
        channels: values.pdx_channels.sort(),
        read_attributes: values.pdx_read_attributes.sort(),
      },
      mappings: values.mappings,
    };

    // Validate AUX config
    const isConfigValid = auxValidation(config);

    if (isConfigValid.isValid) {
      handleUpdatePDXConfig(AUXDeviceID, config, onResetAll);
      setCheckValidField(false);
    } else {
      toast({
        title: 'Failed to setup PDX configuration',
        description: isConfigValid.message,
        status: 'error',
        position: 'top',
        duration: 10000,
        isClosable: true,
      });
      setButtonLoading(false);
      setButtonDisabled(false);
    }
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      onCloseComplete={() => {
        setTabIndex(0);
        setCheckValidField(false);
        setButtonLoading(false);
        setButtonDisabled(false);
      }}
      size="4xl"
      className="adc-modal"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {AUXConfig ? AUXConfig.device_name : 'AUX Device Configuration'}
        </ModalHeader>
        <ModalCloseButton
          isDisabled={isButtonDisabled}
          onClick={() => {
            onResetAll();
            onClose();
          }}
        />
        <ModalBody p={6}>
          <Tabs
            isLazy
            isFitted
            flexWrap="wrap"
            index={tabIndex}
            onChange={(index) => setTabIndex(index)}
          >
            <TabList>
              <Tab>
                <h2>Modbus PDX</h2>
              </Tab>
              <Tab>
                <h2>General</h2>
              </Tab>
              <Tab>
                <h2>Mapping</h2>
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <Box className="adc-modal--item">
                  <Flex alignItems="center">
                    <h4>Port :</h4>
                  </Flex>
                  <p>Select a Port you would like to connect to</p>
                  <Select
                    w={270}
                    mt={2}
                    variant="filled"
                    placeholder="Select a Port ..."
                    onChange={onChange('port')}
                    value={values.port}
                    isInvalid={checkValidField && values.port === ''}
                  >
                    <option value="rs485_1">RS-485-1</option>
                    <option value="rs485_2">RS-485-2</option>
                    <option value="usb_1">USB-1</option>
                    <option value="usb_2">USB-2</option>
                  </Select>
                </Box>

                <Box className="adc-modal--item">
                  <Box className="adc-modal--item-details">
                    <Box mt={4}>
                      <h4>Baud Rate :</h4>
                      <p>Rate at which data is sent/received</p>

                      <CreatableSelect
                        className={`custom-select ${
                          checkValidField && values.pdx_baud_rate === ''
                            ? 'invalid-custom-select'
                            : ''
                        }`}
                        styles={defaultStyles}
                        onChange={onChange('pdx_baud_rate')}
                        onCreateOption={(e) =>
                          handleCreateOption(e, 'baud_rate')
                        }
                        options={baudRate}
                        value={{
                          label: values.pdx_baud_rate,
                          value: values.pdx_baud_rate,
                        }}
                        placeholder="Custom Value ..."
                      />
                    </Box>
                    <Flex justifyContent="center" flexDirection="column" mt={4}>
                      <h4>Modbus ID :</h4>
                      <Input
                        width={250}
                        type="number"
                        placeholder="[1-247]"
                        onChange={onChange('pdx_modbus_id')}
                        value={values.pdx_modbus_id}
                        isInvalid={
                          checkValidField && values.pdx_modbus_id === ''
                        }
                      />
                    </Flex>

                    <Flex alignItems="center" mt={4}>
                      <h4>Read Attributes : </h4>
                    </Flex>
                    <p>Specify what gets read</p>
                    <Box
                      p={2}
                      className={`pdx-channels ${
                        checkValidField &&
                        values.pdx_read_attributes.length === 0
                          ? 'invalid-channels'
                          : ''
                      }`}
                    >
                      <CheckboxGroup
                        mt={4}
                        onChange={onChange('pdx_read_attributes')}
                        value={values.pdx_read_attributes}
                      >
                        <Stack spacing={[1, 5]} direction={['column', 'row']}>
                          <Checkbox value="pd_peak">PD Peak</Checkbox>
                          <Checkbox value="pd_total">PD Total</Checkbox>
                          <Checkbox value="temperature">Temperature</Checkbox>
                          <Checkbox value="humidity">Humidity</Checkbox>
                          <Checkbox value="status">Status</Checkbox>
                        </Stack>
                      </CheckboxGroup>
                    </Box>

                    <Flex justifyContent="center" flexDirection="column" mt={4}>
                      <h4>Channels :</h4>
                      <p>PDX Channels</p>
                      <Box
                        p={2}
                        className={`pdx-channels ${
                          checkValidField && values.pdx_channels.length === 0
                            ? 'invalid-channels'
                            : ''
                        }`}
                      >
                        <CheckboxGroup
                          mt={4}
                          onChange={onChange('pdx_channels')}
                          value={values.pdx_channels}
                        >
                          <SimpleGrid columns={3}>
                            <Checkbox value="A" w={10}>
                              A
                            </Checkbox>
                            <Checkbox value="D" w={10}>
                              D
                            </Checkbox>
                            <Checkbox value="G" w={10}>
                              G
                            </Checkbox>
                            <Checkbox value="B" w={10}>
                              B
                            </Checkbox>
                            <Checkbox value="E" w={10}>
                              E
                            </Checkbox>
                            <Checkbox value="H" w={10}>
                              H
                            </Checkbox>
                            <Checkbox value="C" w={10}>
                              C
                            </Checkbox>
                            <Checkbox value="F" w={10}>
                              F
                            </Checkbox>
                          </SimpleGrid>
                        </CheckboxGroup>
                      </Box>
                    </Flex>
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel>
                <Box className="adc-modal--item">
                  <Box mb={6}>
                    {AUXDeviceID && (
                      <Flex
                        mb={6}
                        justifyContent="center"
                        flexDirection="column"
                      >
                        <h4>
                          ID :{' '}
                          <span className="aux-device-id">{AUXDeviceID}</span>
                        </h4>
                      </Flex>
                    )}
                    <Flex justifyContent="center" flexDirection="column">
                      <h4>Name of Device :</h4>
                      <p>Name your connected device</p>
                      <Input
                        width={250}
                        mt={1}
                        type="text"
                        maxLength={20}
                        onChange={onChange('device_name')}
                        value={values.device_name}
                        isInvalid={checkValidField && values.device_name === ''}
                      />
                    </Flex>

                    <Flex alignItems="center" className="switch-box" mt={6}>
                      <h4>Data Collection :</h4>

                      <CustomSwitch
                        id="aux-data-collection"
                        onSwitchChange={onChange('data_collection')}
                        variant="lite"
                        isChecked={values.data_collection === 'enabled'}
                        isDisabled={isButtonLoading}
                      />
                    </Flex>
                    <p>
                      Data collection begins the collection of data from this
                      module. Its reading will be subsequently shown on your
                      Dashboard.
                    </p>
                  </Box>

                  <Box className="adc-modal--box" mb={6}>
                    <h4>Read Interval :</h4>
                    <p>Data collection read interval</p>

                    <Select
                      bg="#535355"
                      borderColor="#535355"
                      color="white"
                      w={220}
                      mt={2}
                      onChange={onChange('read_interval_ms')}
                      placeholder="Select an option ..."
                      value={values.read_interval_ms}
                      isInvalid={
                        checkValidField && values.read_interval_ms === ''
                      }
                    >
                      <option value={1000}>1 second</option>
                      <option value={2000}>2 seconds</option>
                      <option value={3000}>3 seconds</option>
                      <option value={5000}>5 seconds</option>
                      <option value={10000}>10 seconds</option>
                      <option value={15000}>15 seconds</option>
                      <option value={30000}>30 seconds</option>
                      <option value={60000}>60 seconds</option>
                    </Select>
                  </Box>

                  <Box mb={4}>
                    <Flex alignItems="center" className="switch-box">
                      <h4>Cloud Storage :</h4>
                      <CustomSwitch
                        id="aux-cloud-storage"
                        onSwitchChange={onChange('cloud_storage')}
                        variant="lite"
                        isChecked={values.cloud_storage === 'enabled'}
                        isDisabled
                      />
                    </Flex>
                    <p>
                      Cloud Storage will begin the storing of data. This data
                      will be made available to you through our data
                      visualization tool.
                    </p>
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel>
                <Box className="adc-modal--item">
                  <Flex justifyContent="center" flexDirection="column" mb={2}>
                    <h4>Mapping</h4>
                    <p className="tab-details">Map channels to custom names</p>
                  </Flex>
                  <PDXMappingList
                    list={AUXConfig ? AUXConfig.mappings : {}}
                    handleUpdateList={onChange('mappings')}
                  />
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn-group"
            bg="#535355"
            color="#FFF"
            boxShadow="0px 15px 30px rgba(0, 0, 0, 0.15)"
            variant="solid"
            w={150}
            mr={2}
            isDisabled={isButtonDisabled}
            onClick={() => {
              onResetAll();
              onClose();
            }}
            _hover={{ bg: '#242424' }}
          >
            Cancel
          </Button>

          <Button
            className="btn-group"
            bg="#DB2F5CCC"
            color="#FFF"
            variant="solid"
            w={150}
            isLoading={isButtonLoading}
            onClick={onSubmit}
            _hover={{ bg: '#db2f5d99' }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default memo(PDXConfigModal);
