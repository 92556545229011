// EXPLAINATION: This function will receive 2 parameters : 1 is the incomming data array (A), and 1 is the current applied data obj (B). The idea is we will check each sample obj (C) in (A) : if the any key (which is the channel name) in (C) has the valid value (not null) , we will compare the timestamp of that key with the timestamp of the available equivalent key in an obj name latestValues (D). If (D) does not have that key, we will add the channel name with the new value and the timestamp to (D) => Then we update the value in (B) with the available equivalent items in (D) (if applicable), also, we record the indexes in (D) to show the updated channels in the UI. At the end of the function, the function will return an updated clone of (B) and the updated indexes array.

import getObjectWithLatestTimestamp from './getLatestObjWithTimestamp';

const handleLatestDataObj = (data, latestData) => {
  // (A) and (B)

  if (data && data.length > 0) {
    if (latestData && Object.keys(latestData).length !== 0) {
      // deep copy
      const originalObject = JSON.parse(JSON.stringify(latestData)); // <= This is a clone of (B)

      const latestValues = {}; // <= This is (D)

      const latestIndex = [];

      for (let i = 0; i < data.length; i += 1) {
        // loop through all samples
        const currentObject = data[i]; // This is (C)

        for (const key in currentObject) {
          // loop through all the channels
          if (key === 'timestamp_ms') {
            continue; // skip timestamp key because we are trying to compare the values only
          }
          if (currentObject[key] !== null) {
            if (
              !Object.prototype.hasOwnProperty.call(latestValues, key) ||
              currentObject.timestamp_ms > latestValues[key].timestamp_ms
            ) {
              // record the channel name with the new available value and timestamp to latestValues
              latestValues[key] = {
                value: currentObject[key],
                timestamp_ms: currentObject.timestamp_ms,
              };
            }
          }
        }
      }

      for (const key in latestValues) {
        if (Object.prototype.hasOwnProperty.call(originalObject, key)) {
          originalObject[key] = latestValues[key].value;
          originalObject.timestamp_ms = latestValues[key].timestamp_ms; // for checking in the console.
          latestIndex.push(key); // get the latest updated channel index
        }
      }

      const returnData = {};
      returnData.latestIndex = latestIndex;
      returnData.originalObject = originalObject;

      return returnData;
    }
    // get the sample which has the latest timestamp in (A) when (B) is emptied (at the beginning)
    const latestObj = getObjectWithLatestTimestamp(data);
    return { latestObj };
  }
  return false;
};

export default handleLatestDataObj;
