import React, { useEffect, useState, memo } from 'react';
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
} from '@chakra-ui/react';
import CustomSwitch from '../../../../../components/custom-switch/custom-switch.component';

const LedsModal = ({ isOpen, onClose, LED, handleUpdateLEDsConfig }) => {
  const [LEDsConfig, setLEDsConfig] = useState({});
  const [isButtonLoading, setButtonLoading] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    if (LED) {
      setLEDsConfig(LED);
    }
  }, [LED]);

  const handleLEDsConfig = (prop, value) => {
    const updatedLEDsConfig = structuredClone(LEDsConfig);
    if (typeof value === 'boolean') {
      updatedLEDsConfig[prop] = value ? 'enabled' : 'disabled';
    } else {
      updatedLEDsConfig[prop] = value;
    }
    setLEDsConfig(updatedLEDsConfig);
  };

  const handleConfirmConfig = () => {
    setButtonLoading(true);
    setButtonDisabled(true);
    handleUpdateLEDsConfig(LEDsConfig);
  };

  const handleOnCloseModal = () => {
    setLEDsConfig(LED); // Reset all on cancel;
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
      className="system-modal"
      onCloseComplete={() => {
        setButtonLoading(false);
        setButtonDisabled(false);
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>LEDs</ModalHeader>
        <ModalCloseButton onClick={handleOnCloseModal} />
        <ModalBody p={6} className="dashboard-setting-modal">
          <Box className="adc-modal--box">
            <Flex alignItems="center" mb={2} flexWrap="wrap">
              <h3>Control :</h3>
              <Select
                bg="#535355"
                borderColor="#535355"
                color="white"
                w={200}
                ml={2}
                defaultValue={
                  Object.keys(LEDsConfig).length > 0 && LEDsConfig.control
                }
                onChange={(e) => handleLEDsConfig('control', e.target.value)}
              >
                <option value="dashboard">Dashboard</option>
                <option value="sdk" disabled>
                  SDK
                </option>
              </Select>
              <p>
                Configure <strong>LEDs</strong> either through your EdgePi
                Dashboard or through SDK. Choosing SDK Control will disable
                Dashboard Control.
              </p>
            </Flex>
          </Box>
          <Box className="adc-modal--box">
            <Flex alignItems="center" mb={2} flexWrap="wrap">
              <h3>Data Collection :</h3>
              <CustomSwitch
                id="system-bar"
                variant="lite"
                onSwitchChange={(e) => handleLEDsConfig('data_collection', e)}
                isChecked={
                  Object.keys(LEDsConfig).length > 0 &&
                  LEDsConfig.data_collection === 'enabled'
                }
              />
            </Flex>
            <p>
              Data collection begins the collection of data from this module.
              Its reading will be subsequently shown on your Dashboard.
            </p>
          </Box>
          <Box className="adc-modal--box">
            <Flex alignItems="center" mb={2} flexWrap="wrap">
              <h3>Read Interval :</h3>
              <Select
                bg="#535355"
                borderColor="#535355"
                color="white"
                w={200}
                ml={2}
                defaultValue={
                  Object.keys(LEDsConfig).length > 0 &&
                  LEDsConfig.read_interval_ms
                }
                onChange={(e) =>
                  handleLEDsConfig(
                    'read_interval_ms',
                    parseInt(e.target.value, 10)
                  )
                }
              >
                <option value={1000}>1 second</option>
                <option value={2000}>2 seconds</option>
                <option value={3000}>3 seconds</option>
                <option value={5000}>5 seconds</option>
                <option value={10000}>10 seconds</option>
                <option value={15000}>15 seconds</option>
                <option value={30000}>30 seconds</option>
                <option value={60000}>60 seconds</option>
              </Select>
            </Flex>
            <p>Data collection read interval for LEDs</p>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button
            className="btn-group"
            bg="#535355"
            color="#FFF"
            boxShadow="0px 15px 30px rgba(0, 0, 0, 0.15)"
            variant="solid"
            w={150}
            mr={2}
            onClick={handleOnCloseModal}
            _hover={{ bg: '#242424' }}
            isDisabled={isButtonDisabled}
          >
            Cancel
          </Button>

          <Button
            className="btn-group"
            bg="#DB2F5CCC"
            color="#FFF"
            variant="solid"
            w={150}
            onClick={handleConfirmConfig}
            isLoading={isButtonLoading}
            _hover={{ bg: '#db2f5d99' }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default memo(LedsModal);
