import React from 'react';
import { Box, Image } from '@chakra-ui/react';

const CardHolder = () => (
  <Box className="dashboard-module">
    <div className="card-holder-content">
      <Image
        w="150px"
        objectFit="contain"
        src="/white-cloud.png"
        alt="cloud"
        mt={12}
        mb={6}
      />
      <h2>More Cards Coming Soon</h2>
      <p>
        We’re actively working on improving the EdgePi Portal and developing
        more fun and exciting features for enthusiasts just like yourself.
      </p>
      <h4 className="italic">Stay Curious !</h4>
    </div>
  </Box>
);
export default CardHolder;
