import React, { useEffect, useState, memo } from 'react';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
} from '@chakra-ui/react';
import CustomSwitch from '../../../../../components/custom-switch/custom-switch.component';

const LedChannelModal = ({
  isOpen,
  onClose,
  selectedLed,
  setting,
  handleUpdateConfig,
}) => {
  const [ledConfig, setLedConfig] = useState({});
  const [isButtonLoading, setButtonLoading] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    if (setting) {
      setLedConfig(setting);
    }
  }, [setting]);

  const handleSwichChange = (e) => {
    const newConfig = structuredClone(ledConfig);
    newConfig[selectedLed].data_collection = e ? 'enabled' : 'disabled';
    setLedConfig(newConfig);
  };

  const handleChangeOM = (e) => {
    const newConfig = structuredClone(ledConfig);
    newConfig[selectedLed].mode = e.target.value;
    setLedConfig(newConfig);
  };
  const handleChangeCloudStorage = (e) => {
    const newConfig = structuredClone(ledConfig);
    newConfig[selectedLed].cloud_storage = e ? 'enabled' : 'disabled';
    setLedConfig(newConfig);
  };
  const handleConfirmConfig = () => {
    setButtonLoading(true);
    handleUpdateConfig(ledConfig);
  };
  const handleOnCloseModal = () => {
    setLedConfig(setting); // Reset all on cancel;
    onClose();
  };
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      onCloseComplete={() => {
        setButtonLoading(false);
        setButtonDisabled(false);
      }}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton
          isDisabled={isButtonDisabled}
          onClick={handleOnCloseModal}
        />
        <ModalBody p={6}>
          <h1>LED {selectedLed && selectedLed.toUpperCase().slice(-1)}</h1>
          <Flex mt={4} alignItems="center" flexWrap="wrap">
            <h4>Operating Mode :</h4>
            <Select
              bg="#535355"
              borderColor="#535355"
              color="white"
              w={200}
              ml={2}
              defaultValue={selectedLed && ledConfig[selectedLed].mode}
              onChange={handleChangeOM}
            >
              <option value="manual-on">Manual On</option>
              <option value="manual-off">Manual Off</option>
              <option value="auto-digital-in" disabled>
                Auto Digital In
              </option>
              <option value="auto-digital-out" disabled>
                Auto Digital Out
              </option>
            </Select>
            {setting && setting.data_collection === 'disabled' && (
              <Alert status="warning" mt={2} borderRadius="10px">
                <AlertIcon />
                Data Collection is disabled at the module level.
              </Alert>
            )}
          </Flex>

          <Box className="operating-menu">
            <Box mb={4}>
              <Flex alignItems="center" className="switch-box" flexWrap="wrap">
                <h4>Data Collection:</h4>

                <Flex alignItems="center" className="switch-box">
                  <CustomSwitch
                    id={`led-main${1}`}
                    isChecked={
                      Object.keys(ledConfig).length > 0 &&
                      selectedLed &&
                      ledConfig[selectedLed].data_collection === 'enabled'
                    }
                    onSwitchChange={handleSwichChange}
                  />
                </Flex>
              </Flex>

              <p>
                Data collection begins the collection of data from this port.
                Its reading will be subsequently shown on your Dashboard.
              </p>
            </Box>

            <Flex alignItems="center" className="switch-box" flexWrap="wrap">
              <h4>Cloud Storage: </h4>
              <CustomSwitch
                id={`led${2}`}
                isDisabled
                isChecked={
                  Object.keys(ledConfig).length > 0 &&
                  selectedLed &&
                  ledConfig[selectedLed].cloud_storage === 'enabled'
                }
                onSwitchChange={handleChangeCloudStorage}
                variant="lite"
              />
            </Flex>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button
            className="btn-group"
            bg="#535355"
            color="#FFF"
            boxShadow="0px 15px 30px rgba(0, 0, 0, 0.15)"
            variant="solid"
            w={150}
            mr={2}
            isDisabled={isButtonDisabled}
            onClick={handleOnCloseModal}
            _hover={{ bg: '#242424' }}
          >
            Cancel
          </Button>

          <Button
            className="btn-group"
            bg="#DB2F5CCC"
            color="#FFF"
            variant="solid"
            w={150}
            isLoading={isButtonLoading}
            onClick={handleConfirmConfig}
            _hover={{ bg: '#db2f5d99' }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default memo(LedChannelModal);
