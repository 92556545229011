import { UserActionTypes } from './user.types';

export const setCurrentUser = (user) => ({
  type: UserActionTypes.SET_CURRENT_USER,
  payload: user,
});

export const setUserAccounts = (accounts) => ({
  type: UserActionTypes.SET_USER_ACCOUNTS,
  payload: accounts,
});
export const setCurrentSubscriptions = (subscriptions) => ({
  type: UserActionTypes.SET_CURRENT_SUBSCRIPTIONS,
  payload: subscriptions,
});

export const setActiveDevices = (devices) => ({
  type: UserActionTypes.SET_ACTIVE_DEVICES,
  payload: devices,
});

export const signOutUser = () => ({
  type: UserActionTypes.SIGN_OUT_USER,
});
