/* eslint-disable no-use-before-define */
import React, { useRef, useEffect, useState, useCallback, memo } from 'react';
import { connect } from 'react-redux';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Container,
  Flex,
  Image,
  Icon,
  SimpleGrid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';
import { Auth } from 'aws-amplify';
import { FaPlus, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { signOutUser } from '../../../redux/user/user.actions';
import { refreshProductData } from '../../../redux/product/product.actions';
import SystemPanel from './system-panel/system-panel.component';
import LedsModule from './leds/leds.component';
import Thermocouple from './thermocouple/thermocouple.component';
import AUX from './aux/aux.component';
import AUXDeviceView from './aux/aux-device-view/aux-device-view.component';
import Relay from './relay/relay.component';
import Outputs from './outputs/outputs.component';
import Inputs from './inputs/inputs.component';
import CardHolder from '../../../components/card-placeholder/card-placeholder.component';
import { ReactComponent as ErrorIcon } from '../../../assets/cloud-error.svg';
import useIdleTimeOut from '../../../api/useIdleTimeOut';
import CloudAnimation from '../../../components/animation/cloud';
import portalHandleRequest from '../../../api/portalHandleRequest';
import validateConfig from '../../../api/validateConfig';
import getAvailableModules from '../../../api/getAvailableModules';
import generateAUXChannelsQueryString from '../../../api/generateAUXChannelsQueryString';
import './db-view.styles.scss';

const PORTAL_API_ENDPOINT = process.env.REACT_APP_PORTAL_API;
const GRAFANA_DOMAIN = process.env.REACT_APP_GRAFANA_DOMAIN;
const IDLE_INTERVAL = process.env.REACT_APP_IDLE_INTERVAL;

const DashBoardView = ({
  token,
  activeDevices,
  grafanaData,
  userAccounts,
  refreshProductData,
  refreshData,
}) => {
  const location = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();

  const [currentActiveDevice, setCurrentActiveDevice] = useState(id);
  const [currentSub, setCurrentSub] = useState('');
  const [socketUrl] = useState(process.env.REACT_APP_SOCKET_URL);
  const [ledsData, setLedsData] = useState([]);
  const [tcData, setTCData] = useState([]);
  const [relayData, setRELAYData] = useState([]);
  const [ADCData, setADCData] = useState([]);
  const [DACData, setDACData] = useState([]);
  const [DINData, setDINData] = useState([]);
  const [DOUTData, setDOUTData] = useState([]);
  const [PWMData, setPWMData] = useState([]);
  const [FTXData, setFTXData] = useState([]);
  const [PDXData, setPDXData] = useState([]);
  const [ModbusRTUData, setModbusRTUData] = useState([]);
  const [systemData, setSystemData] = useState([]);
  const [resetData, setResetData] = useState(false);
  const [dashboardConfig, setDashBoardConfig] = useState({});
  const [dashboardStatus, setDashBoardStatus] = useState(0);
  const [isLoading, setButtonLoading] = useState(false);
  const [isDisabled, setButtonDisabled] = useState(false);
  const [isConfigValid, setConfigValid] = useState(true);
  const [availableModules, setAvailableModules] = useState([]);
  const [updateGrafanaResponseMessage, setUpdateGrafanaResponseMessage] =
    useState('');

  const toast = useToast();

  const connectTimer = useRef(null);
  const secondsRef = useRef(0);
  const retryGetConfigRef = useRef(0);

  const {
    isOpen: isIdleTimerModalOpen,
    onOpen: onIdleTimerModalOpen,
    onClose: onIdleTimerModalClose,
  } = useDisclosure();

  const {
    isOpen: isUpdateGrafanaModalOpen,
    onOpen: onUpdateGrafanaModalOpen,
    onClose: onUpdateGrafanaModalClose,
  } = useDisclosure();

  const { sendMessage, lastMessage, getWebSocket } = useWebSocket(socketUrl, {
    protocols: token,
    onOpen: (event) => {
      if (process.env.REACT_APP_DEV_FEATURES === 'true') {
        console.log(event);
      }
      if (id) {
        sendMessage(`/sub ${id} ${token}`);
      } else {
        sendMessage(`/sub ${currentActiveDevice} ${token}`);
      }
    },
    onMessage: () => {
      if (lastMessage) {
        if (lastMessage.data.charAt(0) === '/') {
          setDashBoardStatus(0);
          setResetData(true);

          setLedsData([]);
          setTCData([]);
          setRELAYData([]);
          setADCData([]);
          setDACData([]);
          setDINData([]);
          setDOUTData([]);
          setPWMData([]);
          setSystemData([]);
          setFTXData([]);
          setPDXData([]);
          setModbusRTUData([]);
        } else {
          setResetData(false);
          const data = JSON.parse(lastMessage.data);
          // console.log(data);
          if (data.edgepi_modules) {
            clearInterval(connectTimer.current); // Stop the initial timer
            secondsRef.current = 0;

            // TODO: SHOULD LOOP THROUGH ALL THE AVAI. MODULES
            setLedsData(data.edgepi_modules[0].led_samples);
            setTCData(data.edgepi_modules[0].tc_samples);
            setRELAYData(data.edgepi_modules[0].relay_samples);
            setADCData(data.edgepi_modules[0].adc_samples);
            setDACData(data.edgepi_modules[0].dac_samples);
            setDINData(data.edgepi_modules[0].digital_in_samples);
            setDOUTData(data.edgepi_modules[0].digital_out_samples);
            setPWMData(data.edgepi_modules[0].pwm_samples);
            setSystemData(data.edgepi_modules[0].system_samples);
            setFTXData(data.edgepi_modules[0].aux_id_ftx_module_map);
            setPDXData(data.edgepi_modules[0].aux_id_pdx_module_map);
            setModbusRTUData(data.edgepi_modules[0].aux_id_generic_device_map);
            startTimer(20); // start timer again to detect if EdgePi is disconnected
            setDashBoardStatus(3);
          } else {
            setDashBoardStatus(1);
          }
        }
      } else {
        // eslint-disable-next-line no-use-before-define
        setDashBoardStatus(0);
        startTimer(60);
      }
    },
    onClose: () => {
      console.log('onClose triggered');
    },
    onError: (err) => {
      console.error(err);
      navigate('/connection-error');
    },
    // eslint-disable-next-line no-unused-vars
    shouldReconnect: (closeEvent) => {
      // Will attempt to reconnect on all close events, such as server shutting down, except when connection timeout
      if (isUserIdle) {
        return false;
      }
      return true;
    },
    retryOnError: true,
    reconnectAttempts: 10,
    reconnectInterval: 3000,
  });

  const startTimer = (seconds) => {
    connectTimer.current = setInterval(() => {
      secondsRef.current += 1;
      if (secondsRef.current === seconds) {
        toast({
          title: 'Failed to get data from EdgePi',
          description:
            'Please make sure your EdgePi is powered up and connected to the Internet',
          status: 'error',
          position: 'top',
          duration: 10000,
          isClosable: true,
        });
        clearInterval(connectTimer.current);
        secondsRef.current = 0;
        setDashBoardStatus(2);
      }
    }, 1000);
  };

  const sideScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      // eslint-disable-next-line no-param-reassign
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };

  const getConfig = useCallback(async () => {
    const obj = {
      method: 'GET',
      url: `${PORTAL_API_ENDPOINT}/shadows/${id || currentActiveDevice}`,
      contentType: 'application/json',
    };
    const config = await portalHandleRequest(obj);
    if (config.status === 200) {
      if (process.env.REACT_APP_DEV_FEATURES === 'true') {
        console.log('Config:', config);
      }

      if (!config.is_valid) {
        console.error(config.validation_errors);
        setConfigValid(false);
        return false;
      }

      setConfigValid(true);

      if (config.message) {
        toast({
          title: 'Could not get the device configuration',
          description: config.message,
          status: 'info',
          position: 'top',
          duration: 5000,
          isClosable: true,
        });
      } else {
        // validate config
        const isConfigValid = validateConfig(config.shadow);
        if (isConfigValid) {
          setDashBoardConfig(config.shadow);
          const modules = getAvailableModules(config.shadow.state.reported);
          setAvailableModules(modules);
          return config.shadow;
        }

        // if validate config failed
        console.error('Validate config failed');
        console.log('Trying to send patch request to the shadow api');

        retryGetConfigRef.current += 1;

        if (retryGetConfigRef.current === 3) {
          toast({
            title: 'Could not get the device configuration',
            description:
              'Invalid EdgePi config. Please try power cycling your EdgePi or contact tech support',
            status: 'error',
            position: 'top',
            duration: 10000,
            isClosable: true,
          });
          retryGetConfigRef.current = 0;
          return false;
        }

        const desired = {};

        const obj = {
          method: 'PATCH',
          url: `${PORTAL_API_ENDPOINT}/shadows/${id}`,
          contentType: 'application/json',
          data: {
            state: {
              desired,
            },
          },
        };
        const res = await portalHandleRequest(obj, navigate);
        if (res.status === 200) {
          getConfig();
        } else {
          toast({
            title: 'Could not get the device configuration',
            description:
              'Invalid EdgePi config. Please try power cycling your EdgePi or contact tech support',
            status: 'error',
            position: 'top',
            duration: 10000,
            isClosable: true,
          });
        }
      }
    } else if (config.status === 404) {
      return 404;
    }
    return false;
  }, [id, currentActiveDevice]);

  useEffect(() => {
    if (id) {
      setCurrentActiveDevice(id);
      // if the url does not have thing_id : use the thing_id from the first available devices
    } else if (!id && activeDevices.length > 0) {
      navigate(`/dashboard/${activeDevices[0].thing_id}`);
    }

    const loadDeviceShadow = async () => {
      const deviceShadow = await getConfig();
      if (!deviceShadow) {
        retryGetConfigRef.current += 1;
        if (retryGetConfigRef.current === 3) {
          // Disable DASHBOARD if FE could not get the config after retrying
          setDashBoardConfig(null);
          retryGetConfigRef.current = 0;
        } else {
          // Try load device shadow again
          loadDeviceShadow();
        }
      } else if (deviceShadow === 404) {
        setDashBoardConfig(404);
      } else {
        retryGetConfigRef.current = 0;

        // Reset timer
        clearInterval(connectTimer.current);
        secondsRef.current = 0;
        startTimer();

        if (currentSub) {
          sendMessage(`/unsub ${currentSub} ${token}`); // unsubscribe the current ws connection
        }

        if (id) {
          if (id !== currentSub) {
            // set new state
            setCurrentSub(id);
          }
          // use thing id from the url
          sendMessage(`/sub ${id} ${token}`);
        } else {
          // use thing id from state. This happens when the url does not have thing id
          navigate(`/dashboard/${currentActiveDevice}`);
          sendMessage(`/sub ${currentActiveDevice} ${token}`);
        }
      }
    };
    if (id || currentActiveDevice) {
      loadDeviceShadow();
    }
  }, [location]);

  useEffect(() => {
    // Clear the interval when the component is about to unmount
    return () => {
      clearInterval(connectTimer.current);
      secondsRef.current = 0;
    };
  }, []);

  const handleIdle = () => {
    // eslint-disable-next-line no-use-before-define
    if (isUserIdle) {
      getWebSocket().close(); // close the current ws connection
      onIdleTimerModalOpen(); // open the modal
    }
  };

  const { isUserIdle } = useIdleTimeOut({
    onIdle: handleIdle,
    idleTime: parseInt(IDLE_INTERVAL, 10), // 1 hour
  });

  const handleStayConnected = () => {
    navigate(0);
  };

  const handleSelectedDevice = (deviceID) => {
    navigate(`/dashboard/${deviceID}`);
  };

  const handleUpdateDashboards = async () => {
    setUpdateGrafanaResponseMessage('');
    setButtonLoading(true);
    setButtonDisabled(true);

    const obj = {
      method: 'PATCH',
      url: `${PORTAL_API_ENDPOINT}/users/${userAccounts.user_email}/dashboards?add-missing=true`,
      contentType: 'application/json',
      data: {},
    };
    try {
      const response = await portalHandleRequest(obj, navigate);
      let responseMessage = '';
      if (response.status === 200) {
        responseMessage =
          'All dashboards have been updated or added. This window will close automatically in 3 seconds.';
        refreshProductData(!refreshData);
        setTimeout(() => {
          onUpdateGrafanaModalClose();
        }, 3000);
      } else if (response.status === 400 || response.status === 404) {
        responseMessage =
          'The provisioning was not found or has not been completed. Please refresh the page and try again. If the issue persists, please contact our technical support team for further assistance.';
      } else {
        responseMessage = `We are experiencing an issue when updating the dashboards (status code: ${response.status}). Please refresh the page and try again. If the problem persists, please contact our technical support team for assistance.`;
      }
      setUpdateGrafanaResponseMessage(responseMessage);
    } catch (error) {
      console.error(error);
      toast({
        title: `Failed to update Grafana dashboards!`,
        status: 'error',
        position: 'top',
        duration: 10000,
        isClosable: true,
      });
      onUpdateGrafanaModalClose();
    } finally {
      setButtonLoading(false);
      setButtonDisabled(false);
    }
  };

  const getEdgePiMainGrafanaURL = ({ PANEL }) => {
    try {
      if (Object.keys(grafanaData).length > 0) {
        const url = `${GRAFANA_DOMAIN}/d/${grafanaData.edgepi_main.dashboard_uid}?var-thing_id=${currentActiveDevice}&viewPanel=${grafanaData.edgepi_main.panel_ids[PANEL]}`;

        if (!grafanaData.edgepi_main.panel_ids[PANEL]) {
          onUpdateGrafanaModalOpen();
        } else {
          window.open(url, '_blank', 'noopener, noreferrer');
        }
      } else {
        toast({
          title: 'Failed to get Grafana data!',
          description:
            'Your device provisioning might be incomplete. Please remove and add device again',
          status: 'error',
          position: 'top',
          duration: 10000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('An error occurred:', error);
      onUpdateGrafanaModalOpen();
    }
  };

  const getAUXGrafanaURL = ({ protocol, deviceID, channels }) => {
    try {
      if (Object.keys(grafanaData).length > 0) {
        let url;
        // base Grafana URL
        url = `${GRAFANA_DOMAIN}/d/${grafanaData[protocol].dashboard_uid}?var-thing_id=${currentActiveDevice}&var-device=${deviceID}`;

        if (channels) {
          const channelsQueryString = generateAUXChannelsQueryString(channels);
          url += `${channelsQueryString}`;
        }

        window.open(url, '_blank', 'noopener, noreferrer');
      } else {
        toast({
          title: 'Failed to get Grafana data!',
          description:
            'Your device provisioning might be incomplete. Please remove and add device again',
          status: 'error',
          position: 'top',
          duration: 10000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('An error occurred:', error);
      onUpdateGrafanaModalOpen();
    }
  };

  const handleResetConfig = async () => {
    setButtonLoading(true);
    setButtonDisabled(true);

    const resetConfigOptions = {
      method: 'PATCH',
      url: `${PORTAL_API_ENDPOINT}/shadows/${id}`,
      contentType: 'application/json',
      data: null,
    };

    const res = await portalHandleRequest(resetConfigOptions);

    if (res.status === 200) {
      const retry = (fn, retriesLeft = 10, interval = 3000) => {
        return new Promise((resolve, reject) => {
          fn()
            .then((response) => {
              if (
                Object.prototype.hasOwnProperty.call(response.state, 'delta')
              ) {
                console.log(
                  `Retry to get new config ... Remaining attempts: ${retriesLeft}`
                );
                setTimeout(() => {
                  if (retriesLeft === 1) {
                    console.log('Maximum retries exceeded');
                    toast({
                      title: 'Failed to reset device configuration completely',
                      description: 'Please power cycle your EdgePi.',
                      status: 'error',
                      position: 'top',
                      duration: 10000,
                      isClosable: true,
                    });
                    setButtonLoading(false);
                    setButtonDisabled(false);
                    return;
                  }
                  retry(fn, retriesLeft - 1, interval).then(resolve, reject);
                }, interval);
              } else {
                toast({
                  title:
                    'Device config has been reset successfully. Please power cycle your EdgePi',
                  status: 'success',
                  position: 'top',
                  duration: 2000,
                  isClosable: true,
                });
                setButtonLoading(false);
                setButtonDisabled(false);
                resolve();
              }
            })
            .catch((error) => {
              setTimeout(() => {
                if (retriesLeft === 1) {
                  // maximum retries exceeded
                  toast({
                    title: 'Failed to reset device configuration completely',
                    description: 'Please power cycle your EdgePi.',
                    status: 'error',
                    position: 'top',
                    duration: 10000,
                    isClosable: true,
                  });
                  reject(error);
                  setButtonLoading(false);
                  setButtonDisabled(false);
                  return;
                }
                retry(fn, retriesLeft - 1, interval).then(resolve, reject);
              }, interval);
            });
        });
      };
      retry(() => getConfig());
    } else {
      toast({
        title: 'Failed to reset device configuration completely',
        description: 'Please power cycle your EdgePi.',
        status: 'error',
        position: 'top',
        duration: 10000,
        isClosable: true,
      });
      setButtonLoading(false);
      setButtonDisabled(false);
    }
    return null;
  };

  const [isOverflowed, setIsOverflowed] = useState(false);

  const contentWrapper = useRef(null);
  useEffect(() => {
    const divElement = contentWrapper.current;
    if (divElement) {
      setIsOverflowed(divElement.scrollWidth > divElement.clientWidth);
    }
  }, []);

  return (
    <>
      <Box
        w="100%"
        className="dashboard-devices-list"
        ref={contentWrapper}
        style={{
          overflowX: 'auto', // Enable horizontal scrolling if overflowed
          whiteSpace: 'nowrap', // Prevent line breaks
          width: '100%', // Set a fixed width for demonstration (you can adjust this)
        }}
      >
        {activeDevices && activeDevices.length > 0
          ? activeDevices.map((device, index) => (
              <Link
                className={`dashboard-device ${
                  currentActiveDevice === device.thing_id ? 'active-device' : ''
                }`}
                key={index}
                to={`/dashboard/${device.thing_id}`}
              >
                <Flex
                  alignItems="center"
                  onClick={() => {
                    handleSelectedDevice(device.thing_id);
                  }}
                >
                  <Image
                    boxSize="100px"
                    objectFit="contain"
                    src="/edgepi.png"
                    alt="settings"
                    mr={6}
                  />
                  <Box>
                    <h3 className="device-name">{device.product_name}</h3>
                    <Tooltip
                      hasArrow
                      label="Serial Number"
                      bg="#242424"
                      color="white"
                      placement="right-start"
                    >
                      <p className="device-sn">{device.serial_number}</p>
                    </Tooltip>
                  </Box>
                </Flex>
              </Link>
            ))
          : ''}
      </Box>
      <Container maxW="full" className="dashboard-page--container">
        <Link to="/add-device">
          <Button
            className="btn-group add-device-btn"
            bg="#535355"
            color="#FFF"
            variant="solid"
            w={200}
            _hover={{ bg: '#DB2F5C' }}
          >
            <Icon as={FaPlus} w={6} h={6} mr={4} /> Add Device
          </Button>
        </Link>
        {isOverflowed && (
          <>
            <div
              className="btn-wrapper-left"
              type="button"
              onClick={() => {
                sideScroll(contentWrapper.current, 25, 100, -10);
              }}
            >
              <Icon
                as={FaChevronLeft}
                color="#FFF"
                className="scroll-btn"
                w={8}
                h={8}
              />
            </div>
            <div
              className="btn-wrapper-right"
              type="button"
              onClick={() => {
                sideScroll(contentWrapper.current, 25, 100, 10);
              }}
            >
              <Icon
                as={FaChevronRight}
                color="#FFF"
                className="scroll-btn"
                w={8}
                h={8}
              />
            </div>
          </>
        )}

        {dashboardConfig && Object.keys(dashboardConfig).length > 0 ? (
          <Box w="100%" className="dashboard-view">
            <Flex
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
              mt={2}
              mb={2}
            >
              <Box mr={2}>
                <Button
                  className="btn-group"
                  bg="#3A3A3C"
                  border="1px solid #FFF"
                  borderRadius="20px"
                  color="#FFF"
                  variant="solid"
                  w={200}
                  mr={3}
                  onClick={() => {
                    const url = `${GRAFANA_DOMAIN}/d/${grafanaData.edgepi_main.dashboard_uid}?var-thing_id=${id}`;
                    window.open(url, '_blank', 'noopener, noreferrer');
                  }}
                  _hover={{ bg: '#242424' }}
                >
                  View Graphs{' '}
                  <Image
                    w="20px"
                    objectFit="contain"
                    src="/grafana.png"
                    ml={2}
                    alt="grafana"
                  />
                </Button>

                <Button
                  className="btn-group"
                  bg="#3A3A3C"
                  color="#FFF"
                  border="1px solid #FFF"
                  borderRadius="20px"
                  variant="solid"
                  w={200}
                  mt={3}
                  mb={3}
                  onClick={() => {
                    navigate(`/device/${id}`);
                  }}
                  _hover={{ bg: '#242424' }}
                >
                  Manage Device{' '}
                  <Image
                    w="20px"
                    objectFit="contain"
                    src="/device.svg"
                    ml={2}
                    alt="grafana"
                  />
                </Button>
              </Box>
              {dashboardStatus === 0 && (
                <Alert status="info" w={300}>
                  <AlertIcon />
                  Status : Initializing ...
                </Alert>
              )}
              {dashboardStatus === 1 && (
                <Alert status="warning" w={300}>
                  <AlertIcon />
                  Status : No data found
                </Alert>
              )}
              {dashboardStatus === 2 && (
                <Alert status="error" w={300}>
                  <AlertIcon />
                  Status : Disconnected
                </Alert>
              )}
              {dashboardStatus === 3 && (
                <Alert status="success" w={300}>
                  <AlertIcon />
                  Status : Connected
                </Alert>
              )}

              {/* <h4>Status : {dashboardStatus}</h4> */}
            </Flex>
            <SystemPanel
              thingID={id || currentActiveDevice}
              data={systemData}
              resetData={resetData}
              config={
                dashboardConfig.state && dashboardConfig.state.reported.SYS
              }
              getConfig={getConfig}
              getGrafanaURL={getEdgePiMainGrafanaURL}
            />

            <SimpleGrid
              className="dashboard-view--grid"
              columns={{ lg: 1, xl: 2 }}
              spacing="20px"
            >
              <Outputs
                thingID={id || currentActiveDevice}
                data={DACData}
                resetData={resetData}
                config={
                  dashboardConfig.state && dashboardConfig.state.reported.DAC
                }
                DOUTConfig={
                  dashboardConfig.state && dashboardConfig.state.reported.DOUT
                }
                DOUTData={DOUTData}
                OUTPUTSSelection={
                  dashboardConfig.state &&
                  dashboardConfig.state.reported.OUTPUTS
                }
                PWMConfig={
                  dashboardConfig.state && dashboardConfig.state.reported.PWM
                }
                PWMData={PWMData}
                getConfig={getConfig}
                getGrafanaURL={getEdgePiMainGrafanaURL}
              />

              <Inputs
                thingID={id || currentActiveDevice}
                data={ADCData}
                resetData={resetData}
                config={
                  dashboardConfig.state && dashboardConfig.state.reported.ADC
                }
                DINConfig={
                  dashboardConfig.state && dashboardConfig.state.reported.DIN
                }
                DINData={DINData}
                INPUTSSelection={
                  dashboardConfig.state && dashboardConfig.state.reported.INPUTS
                }
                getConfig={getConfig}
                getGrafanaURL={getEdgePiMainGrafanaURL}
              />

              <LedsModule
                thingID={id || currentActiveDevice}
                data={ledsData}
                resetData={resetData}
                config={
                  dashboardConfig.state && dashboardConfig.state.reported.LED
                }
                getConfig={getConfig}
                getGrafanaURL={getEdgePiMainGrafanaURL}
              />

              <Thermocouple
                thingID={id || currentActiveDevice}
                data={tcData}
                resetData={resetData}
                config={
                  dashboardConfig.state && dashboardConfig.state.reported.TC
                }
                getConfig={getConfig}
                getGrafanaURL={getEdgePiMainGrafanaURL}
              />

              <Relay
                thingID={id || currentActiveDevice}
                data={relayData}
                resetData={resetData}
                config={
                  dashboardConfig.state && dashboardConfig.state.reported.RLY
                }
                getConfig={getConfig}
                getGrafanaURL={getEdgePiMainGrafanaURL}
              />

              {dashboardConfig.state.reported.AUX &&
                Object.keys(dashboardConfig.state.reported.AUX).length !== 0 &&
                Object.entries(dashboardConfig.state.reported.AUX).map(
                  ([deviceID, deviceConfig]) => (
                    <AUXDeviceView
                      thingID={id || currentActiveDevice}
                      key={deviceID}
                      deviceID={deviceID}
                      config={deviceConfig}
                      data={
                        deviceConfig.protocol === 'modbus_ftx'
                          ? FTXData[deviceID]
                          : deviceConfig.protocol === 'modbus_pdx'
                          ? PDXData[deviceID]
                          : ModbusRTUData[deviceID]
                      }
                      getConfig={getConfig}
                      getGrafanaURL={getAUXGrafanaURL}
                    />
                  )
                )}

              <AUX
                deviceShadow={dashboardConfig}
                thingID={id || currentActiveDevice}
                getConfig={getConfig}
                isAvailable={availableModules.includes('AUX')}
              />

              <CardHolder />
            </SimpleGrid>
          </Box>
        ) : dashboardConfig === null || !isConfigValid ? (
          <Box w="100%" className="dashboard-disabled">
            <div className="error-icon">
              <ErrorIcon />
            </div>

            <Flex
              className="error-detail"
              flexDirection="column"
              alignItems="center"
            >
              <h3>Your device config is invalid</h3>
              <p>
                There is an issue with your EdgePi config. Please power cycle
                your EdgePi and reconnect again. If power cycle did not fix the
                issue, reset your EdgePi config.
              </p>
              <Flex mt={4} flexWrap="wrap">
                <Button
                  className="btn-group"
                  bg="#DB2F5CCC"
                  color="#FFF"
                  variant="solid"
                  mr={2}
                  w={180}
                  onClick={handleStayConnected}
                  _hover={{ bg: '#db2f5d99' }}
                >
                  Reconnect
                </Button>
                <Button
                  className="btn-group"
                  bg="#DB2F5CCC"
                  color="#FFF"
                  variant="solid"
                  w={180}
                  onClick={handleResetConfig}
                  _hover={{ bg: '#db2f5d99' }}
                  isLoading={isLoading}
                  isDisabled={isDisabled}
                >
                  Reset Config
                </Button>
              </Flex>
            </Flex>
          </Box>
        ) : dashboardConfig === 404 ? (
          <Box w="100%" className="dashboard-disabled">
            <div className="error-icon">
              <ErrorIcon />
            </div>

            <Flex
              className="error-detail"
              flexDirection="column"
              alignItems="center"
            >
              <h3>Device Not Found</h3>
              <p>
                Please make sure your device ID is correct. Current device ID:{' '}
                {id}
              </p>
              <Flex mt={4} flexWrap="wrap">
                <Button
                  className="btn-group"
                  bg="#DB2F5CCC"
                  color="#FFF"
                  variant="solid"
                  mr={2}
                  w={180}
                  onClick={() => navigate('/')}
                  _hover={{ bg: '#db2f5d99' }}
                >
                  Refresh
                </Button>
              </Flex>
            </Flex>
          </Box>
        ) : (
          <Flex
            w="100%"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            mt="100px"
          >
            <CloudAnimation />
          </Flex>
        )}
      </Container>

      <Modal
        closeOnOverlayClick={false}
        isOpen={isIdleTimerModalOpen}
        onClose={onIdleTimerModalClose}
        size="4xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className="idle-modal-header">
            <h3>Hmm... Looks like you’ve been idle for a while</h3>
          </ModalHeader>
          <ModalBody className="idle-modal-body">
            <p>
              Your connection from the EdgePi Dashboard has been automatically
              disconnected due to inactivity. Please reconnect to continue.
            </p>
          </ModalBody>

          <ModalFooter>
            <Button
              className="btn-group"
              bg="#565657"
              color="#FFF"
              boxShadow="0px 15px 30px rgba(0, 0, 0, 0.15)"
              variant="solid"
              w={150}
              mr={4}
              onClick={() => {
                signOutUser();
                Auth.signOut();
              }}
              _hover={{ bg: '#2e2e2f' }}
            >
              Logout
            </Button>
            <Button
              className="btn-group"
              bg="#DB2F5CCC"
              color="#FFF"
              variant="solid"
              w={180}
              onClick={handleStayConnected}
              _hover={{ bg: '#db2f5d99' }}
            >
              Reconnect
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        closeOnOverlayClick={false}
        isOpen={isUpdateGrafanaModalOpen}
        onClose={onUpdateGrafanaModalClose}
        onCloseComplete={() => setUpdateGrafanaResponseMessage('')}
        size="4xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className="update-grafana-modal-header">
            <h3>The selected grafana panel data is not available.</h3>
          </ModalHeader>
          <ModalBody className="update-grafana-modal-body">
            {isLoading ? (
              <p>Updating Dashboards ...</p>
            ) : updateGrafanaResponseMessage ? (
              <p>{updateGrafanaResponseMessage}</p>
            ) : (
              <p>
                Your account is not setup with a matching dashboard for this
                card. Please update the dashboard or contact the tech support.
              </p>
            )}
          </ModalBody>

          <ModalFooter>
            {!updateGrafanaResponseMessage ? (
              <Box>
                <Button
                  className="btn-group"
                  bg="#565657"
                  color="#FFF"
                  boxShadow="0px 15px 30px rgba(0, 0, 0, 0.15)"
                  variant="solid"
                  w={150}
                  mr={4}
                  isDisabled={isDisabled}
                  onClick={onUpdateGrafanaModalClose}
                  _hover={{ bg: '#2e2e2f' }}
                >
                  Cancel
                </Button>
                <Button
                  className="btn-group"
                  bg="#DB2F5CCC"
                  color="#FFF"
                  variant="solid"
                  w={180}
                  onClick={handleUpdateDashboards}
                  _hover={{ bg: '#db2f5d99' }}
                  isDisabled={isDisabled}
                  isLoading={isLoading}
                >
                  Update
                </Button>
              </Box>
            ) : (
              <Button
                className="btn-group"
                bg="#DB2F5CCC"
                color="#FFF"
                variant="solid"
                w={180}
                onClick={onUpdateGrafanaModalClose}
                _hover={{ bg: '#db2f5d99' }}
                isDisabled={isDisabled}
                isLoading={isLoading}
              >
                Close
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  grafanaData: state.product.grafanaData,
  userAccounts: state.user.accounts,
  refreshData: state.product.refreshData,
});

const mapDispatchToProps = (dispatch) => ({
  refreshProductData: (bool) => dispatch(refreshProductData(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(DashBoardView));
