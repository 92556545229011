/* eslint-disable no-param-reassign */
function getLatestAttributeValue(latestModbusRTUData, inCommingModbusRTUData) {
  // Iterate over each object in the inCommingModbusRTUData
  inCommingModbusRTUData.forEach((obj) => {
    // Check if the object has the generic_samples property
    if (
      Object.prototype.hasOwnProperty.call(obj, 'generic_samples') &&
      Array.isArray(obj.generic_samples) &&
      obj.generic_samples.length > 0
    ) {
      // Find the latest sample based on timestamp_ms
      const latestSample = obj.generic_samples.reduce((latest, current) => {
        return current.timestamp_ms > latest.timestamp_ms ? current : latest;
      });

      // Remove keys with null values from the latest sample
      const cleanedSample = {};
      for (const key in latestSample) {
        if (latestSample[key] !== null) {
          cleanedSample[key] = latestSample[key];
        }
      }

      // Check if the object has at least one key starting with "value_"
      const hasValueKey = Object.keys(cleanedSample).some((key) =>
        key.startsWith('value')
      );

      if (!hasValueKey) {
        cleanedSample.status = latestSample.status;
        cleanedSample.timestamp_ms = latestSample.timestamp_ms;
        cleanedSample.value = null; // create key `value` if all the value_x values are null
        cleanedSample.isStaledData = latestSample.isStaledData;
      }

      // Replace the generic_samples array with the cleaned sample
      obj.generic_samples = [cleanedSample];
    }
  });

  // Check staled data
  const combinedMap = new Map();

  // Function to update the map with the latest sample based on property_name
  const updateMap = (arr) => {
    arr.forEach((item) => {
      const existingItem = combinedMap.get(item.property_name);
      if (
        !existingItem ||
        existingItem.generic_samples[0].timestamp_ms <
          item.generic_samples[0].timestamp_ms
      ) {
        combinedMap.set(item.property_name, item);
      }
    });
  };

  // Update combinedMap with latestModbusRTUData
  updateMap(latestModbusRTUData);
  // Update combinedMap with inCommingModbusRTUData
  updateMap(inCommingModbusRTUData);

  // Iterate over combinedMap to add isStaledData property if not available
  combinedMap.forEach((item) => {
    const isStaleA = !inCommingModbusRTUData.find(
      (b) => b.property_name === item.property_name
    );
    item.generic_samples.forEach((sample) => {
      sample.isStaledData =
        isStaleA ||
        inCommingModbusRTUData.some(
          (b) =>
            b.property_name === item.property_name &&
            b.generic_samples[0].timestamp_ms < sample.timestamp_ms
        );
    });
  });

  // Convert combinedMap values back to an array
  const result = Array.from(combinedMap.values());
  return result;
}

export default getLatestAttributeValue;
