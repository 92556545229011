import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Flex,
  Image,
  Icon,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  TableContainer,
  Tag,
  TagLabel,
  SimpleGrid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { IoCloseCircle, IoArrowBackCircleOutline } from 'react-icons/io5';
import { BsTruck } from 'react-icons/bs';
import CloudAnimation from '../../components/animation/cloud';
import portalHandleRequest from '../../api/portalHandleRequest';
import mapStatusLabels from '../../api/mapStatusLabels';
import './order.styles.scss';

const PORTAL_API_ENDPOINT = process.env.REACT_APP_PORTAL_API;

const Order = () => {
  const [order, setOrder] = useState({});
  const [orderStatus, setOrderStatus] = useState();
  const [cancelButtonLoading, setCancelButtonLoading] = useState(false);
  const { id } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const toast = useToast();

  const handleCancelOrder = async (orderId) => {
    setCancelButtonLoading(true);

    const cancelRequestObj = {
      method: 'POST',
      url: `${PORTAL_API_ENDPOINT}/events/cancel-order/${orderId}`,
    };

    const orderStatusObj = {
      method: 'GET',
      url: `${PORTAL_API_ENDPOINT}/orders/${orderId}`,
    };

    const cancelOrder = await portalHandleRequest(cancelRequestObj);

    if (cancelOrder.ResponseMetadata.HTTPStatusCode === 200) {
      // Check order status again

      const retry = (fn, retriesLeft = 30, interval = 200) => {
        return new Promise((resolve, reject) => {
          fn()
            .then((response) => {
              if (!response || response.salesorder.status === 'draft') {
                setTimeout(() => {
                  if (retriesLeft === 1) {
                    console.log('maximum retries exceeded');
                    toast({
                      title:
                        'Order cancellation is in process. Please check back later',
                      description: response.message,
                      status: 'info',
                      position: 'top',
                      duration: 5000,
                      isClosable: true,
                    });
                    setCancelButtonLoading(false);
                    return;
                  }
                  retry(fn, retriesLeft - 1, interval).then(resolve, reject);
                }, interval);
              } else {
                if (response.salesorder.status === 'void') {
                  toast({
                    title: 'Order cancellation successful',
                    status: 'success',
                    position: 'top',
                    duration: 5000,
                    isClosable: true,
                  });
                  setCancelButtonLoading(false);
                  setOrderStatus('void');
                } else {
                  toast({
                    title: 'Failed to cancel order',
                    description: 'Please contact us for support',
                    status: 'error',
                    position: 'top',
                    duration: 10000,
                    isClosable: true,
                  });
                  setCancelButtonLoading(false);
                }
                resolve();
              }
            })
            .catch((error) => {
              setTimeout(() => {
                if (retriesLeft === 1) {
                  // maximum retries exceeded
                  reject(error);
                  setCancelButtonLoading(false);
                  return;
                }
                retry(fn, retriesLeft - 1, interval).then(resolve, reject);
              }, interval);
            });
        });
      };

      retry(() => portalHandleRequest(orderStatusObj));
    } else {
      toast({
        title: 'Failed to cancel this order!',
        description: 'Please contact us for support',
        status: 'error',
        position: 'top',
        duration: 10000,
        isClosable: true,
      });
      setCancelButtonLoading(false);
    }
    return null;
  };

  const handleTrackingOrder = (id) => {
    console.log(id);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const obj = {
        method: 'GET',
        url: `${PORTAL_API_ENDPOINT}/orders/${id}`,
      };

      const response = await portalHandleRequest(obj, navigate);
      if (response.status === 200) {
        if (
          response.salesorder &&
          Object.keys(response.salesorder).length !== 0
        ) {
          setOrder(response.salesorder);
          setOrderStatus(response.salesorder.order_status);
        }
      } else {
        navigate('/error', {
          state: {
            name: response.name,
            message: response.message,
          },
        });
      }

      return null;
    };
    fetchUserData();
  }, []);
  return (
    <Box className="order-page">
      {Object.keys(order).length !== 0 ? (
        <Container maxW="full" className="order-page--container">
          <Flex wrap="wrap" justifyContent="space-between">
            <Box w="100%" className="order-container">
              <Flex w="100%" flexWrap="wrap" className="order-number">
                <Icon
                  as={IoArrowBackCircleOutline}
                  w={10}
                  h={10}
                  mr={4}
                  className="order-back-btn"
                  onClick={() => navigate('/orders')}
                />
                Order #{order.salesorder_number}{' '}
                <Flex alignItems="center" mt={2} mb={2}>
                  <Tag
                    className={`order-status ${
                      orderStatus === 'draft'
                        ? 'draft'
                        : orderStatus === 'confirmed'
                        ? 'confirmed'
                        : orderStatus === 'void'
                        ? 'void'
                        : orderStatus === 'closed'
                        ? 'closed'
                        : orderStatus === 'onhold'
                        ? 'onhold'
                        : 'default'
                    }`}
                    size="lg"
                    borderRadius="full"
                    variant="solid"
                  >
                    <TagLabel>{mapStatusLabels(orderStatus)}</TagLabel>
                  </Tag>
                  {order.custom_field_hash.cf_pre_order_unformatted && (
                    <Tag
                      className="order-status pre-order"
                      size="lg"
                      borderRadius="full"
                      variant="solid"
                    >
                      <TagLabel>Pre-Order</TagLabel>
                    </Tag>
                  )}
                </Flex>
              </Flex>
              <h2 className="shipping-heading">Shipping Information</h2>
              <SimpleGrid
                className="order-information"
                columns={{ lg: 1, xl: 2 }}
                spacingY="15px"
              >
                <Box className="order-details">
                  <p>
                    <span className="order-info-heading">Name :</span>
                    {order.shipping_address.attention}
                  </p>

                  <p>
                    <span className="order-info-heading">Phone :</span>
                    {order.shipping_address.phone || 'N/A'}
                  </p>

                  {/* <p>
                    <span className="order-info-heading">Email :</span>
                    {order.contact_person_details &&
                    order.contact_person_details.length > 0
                      ? order.contact_person_details[0].email
                      : order.created_by_email}
                  </p> */}
                  <p>
                    <span className="order-info-heading">Address :</span>
                    {order.shipping_address.address},{' '}
                    {order.shipping_address.city},{' '}
                    {order.shipping_address.state}, {order.shipping_address.zip}
                  </p>
                </Box>
                <Box className="order-details">
                  <p>
                    <span className="order-info-heading">Created :</span>
                    {order.date} at{' '}
                    {new Date(order.created_time).toLocaleTimeString('en', {
                      timeStyle: 'short',
                      hour12: false,
                      timeZone:
                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                    })}
                  </p>

                  <p>
                    <span className="order-info-heading">Shipped :</span>
                    {order.shipment_date || 'N/A'}
                  </p>
                </Box>
              </SimpleGrid>
              <TableContainer className="item-summary">
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Td className="item-name">Product</Td>
                      <Td p={0} className="item-name">
                        Quantity
                      </Td>
                      <Td className="item-name" isNumeric>
                        Price
                      </Td>
                    </Tr>
                  </Thead>
                  <Tbody className="border-top-dark">
                    {order.line_items.map((item) => (
                      <Tr key={item.line_item_id}>
                        <Td>
                          <Flex alignItems="center" flexWrap="wrap">
                            <Image
                              boxSize="120px"
                              objectFit="cover"
                              src={
                                !item.image_name || item.image_name === 'null'
                                  ? '/edgepi.png'
                                  : item.image_name
                              }
                              alt={item.name}
                              marginRight={2}
                              mt={4}
                              mb={4}
                            />
                            <Flex flexDirection="column">
                              <p className="product-name">
                                {JSON.parse(item.description).product_name}
                              </p>
                              <p className="product-modal">
                                {JSON.parse(item.description).product_model}
                              </p>
                              <p className="sku">SKU: {item.sku}</p>
                            </Flex>
                          </Flex>
                        </Td>

                        <Td className="order-quantity">{item.quantity}</Td>
                        <Td isNumeric>
                          {order.currency_symbol +
                            item.item_sub_total.toFixed(2)}
                        </Td>
                      </Tr>
                    ))}
                    <Tr className="extra-row border-top-dark">
                      <Td className="sub-total" />
                      <Td className="sub-total" p={0}>
                        Subtotal
                      </Td>

                      <Td className="sub-total" isNumeric>
                        {order.currency_symbol +
                          order.sub_total_exclusive_of_discount.toFixed(2)}
                      </Td>
                    </Tr>
                    {order.discount_total > 0 ? (
                      <Tr className="extra-row">
                        <Td />
                        <Td p={0}>Discount</Td>

                        <Td isNumeric>
                          {order.currency_symbol +
                            order.discount_total.toFixed(2)}
                        </Td>
                      </Tr>
                    ) : null}

                    <Tr className="extra-row">
                      <Td className="shipping" />
                      <Td className="shipping" p={0}>
                        Shipping
                      </Td>

                      <Td className="shipping" isNumeric>
                        {order.currency_symbol +
                          order.shipping_charge.toFixed(2)}
                      </Td>
                    </Tr>
                    <Tr className="extra-row">
                      <Td className="tax" />
                      <Td className="tax" p={0}>
                        Taxes
                      </Td>

                      <Td className="tax" isNumeric>
                        {order.currency_symbol + order.tax_total.toFixed(2)}
                      </Td>
                    </Tr>
                    <Tr className="extra-row border-top-dark">
                      <Td>
                        {orderStatus !== 'void' && (
                          <Button
                            mt={6}
                            mr={2}
                            className="btn-group"
                            bg="#DB2F5CCC"
                            color="#FFF"
                            leftIcon={<IoCloseCircle />}
                            variant="solid"
                            isLoading={cancelButtonLoading}
                            onClick={onOpen}
                            _hover={{ bg: '#bd214b' }}
                          >
                            Cancel Order
                          </Button>
                        )}
                        {order.shipment_date && (
                          <Button
                            className="btn-group"
                            mr={3}
                            mt={6}
                            bg="#3182ce"
                            color="#FFF"
                            leftIcon={<BsTruck />}
                            variant="solid"
                            _hover={{ bg: '#094a87' }}
                            onClick={() => {
                              handleTrackingOrder(order.salesorder_id);
                            }}
                          >
                            Track Shipment
                          </Button>
                        )}
                      </Td>
                      <Td textAlign="left" className="order-total">
                        Total
                      </Td>
                      <Td isNumeric className="order-total">
                        {`${order.currency_symbol + order.total.toFixed(2)} ${
                          order.currency_code
                        }`}
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>

              <Box className="item-summary-mobile">
                {order.line_items.map((item) => (
                  <Flex
                    alignItems="center"
                    key={item.line_item_id}
                    flexWrap="wrap"
                  >
                    <Image
                      boxSize="120px"
                      objectFit="cover"
                      src={item.image_name ? item.image_name : '/edgepi.png'}
                      alt={item.name}
                      marginRight={6}
                      mt={4}
                      mb={4}
                    />
                    <Flex flexDirection="column">
                      <p className="product-name">{item.name}</p>
                      <p className="sku">SKU: {item.sku}</p>
                      <p className="quantity">Quantity: {item.quantity}</p>
                      <p className="price">
                        Price: {order.currency_symbol + item.item_sub_total}
                      </p>
                    </Flex>
                  </Flex>
                ))}
                <Flex
                  className="order-prices border-top-dark"
                  alignItems="center"
                  justifyContent="space-between"
                  marginTop={6}
                >
                  <p>Subtotal</p>
                  <p>
                    {order.currency_symbol +
                      order.sub_total_exclusive_of_discount}
                  </p>
                </Flex>
                <Flex
                  className="order-prices"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <p>Shipping</p>
                  <p>{order.currency_symbol + order.shipping_charge}</p>
                </Flex>
                <Flex
                  className="order-prices"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <p>Taxes</p>
                  <p>{order.currency_symbol + order.tax_total}</p>
                </Flex>
                <Flex
                  className="order-total border-top-dark"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <h2>{order.currency_symbol + order.total}</h2>
                </Flex>
                <Flex flexWrap="wrap" flexDirection="column">
                  {orderStatus !== 'void' && (
                    <Button
                      mt={6}
                      mr={2}
                      w={200}
                      className="btn-group"
                      bg="#DB2F5CCC"
                      color="#FFF"
                      leftIcon={<IoCloseCircle />}
                      variant="solid"
                      onClick={onOpen}
                      _hover={{ bg: '#bd214b' }}
                    >
                      Cancel Order
                    </Button>
                  )}
                  {order.shipment_date && (
                    <Button
                      mt={6}
                      w={200}
                      className="btn-group"
                      bg="#3182ce"
                      color="#FFF"
                      leftIcon={<BsTruck />}
                      variant="solid"
                      _hover={{ bg: '#094a87' }}
                      onClick={() => {
                        handleTrackingOrder(order.salesorder_id);
                      }}
                    >
                      Track Shipment
                    </Button>
                  )}
                </Flex>
              </Box>
            </Box>
          </Flex>
          <Modal isOpen={isOpen} onClose={onClose} size="lg">
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Cancel the order</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <p>Are you sure to cancel this order?</p>
              </ModalBody>
              <ModalFooter>
                <Button
                  mr={3}
                  onClick={() => {
                    handleCancelOrder(order.salesorder_id);
                    onClose();
                  }}
                >
                  Cancel the order
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Container>
      ) : (
        <Container maxW="full" className="order-page--container">
          <CloudAnimation />
        </Container>
      )}
    </Box>
  );
};

export default Order;
