import React, { useState, useCallback, useEffect, useRef, memo } from 'react';
import {
  Box,
  Flex,
  Icon,
  Image,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import { SiIcloud } from 'react-icons/si';
import getObjectWithLatestTimestamp from '../../../../api/getLatestObjWithTimestamp';
import portalHandleRequest from '../../../../api/portalHandleRequest';
import RelayModal from './relay-modal/relay-modal.component';

const PORTAL_API_ENDPOINT = process.env.REACT_APP_PORTAL_API;

const Relay = ({
  thingID,
  data,
  resetData,
  config,
  getConfig,
  getGrafanaURL,
}) => {
  const [RLYConfig, setRLYConfig] = useState();
  const [latestRELAYData, setLatestRELAYData] = useState();
  const navigate = useNavigate();
  const toast = useToast();
  const updateState = useRef(false);

  useEffect(() => {
    if (config && !updateState.current) {
      setRLYConfig(config);
    }
  }, [config]);

  useEffect(() => {
    if (data.length > 0) {
      if (latestRELAYData) {
        // add the current relay data sample to the upcoming data samples array
        data.push(latestRELAYData);
      }
      // get the sample which has the latest timestamp
      const latestObject = getObjectWithLatestTimestamp(data);
      setLatestRELAYData(latestObject);
    } else {
      setLatestRELAYData();
    }
  }, [data]);

  useEffect(() => {
    if (resetData) {
      setLatestRELAYData();
    }
  }, [resetData]);

  const handleGrafanaURL = (PANEL) => {
    getGrafanaURL({ PANEL });
  };

  const {
    isOpen: isRelayModalOpen,
    onOpen: onRelayModalOpen,
    onClose: onRelayModalClose,
  } = useDisclosure();

  const handleUpdateRelayConfig = useCallback(async (newConfig) => {
    updateState.current = true;

    const obj = {
      method: 'PATCH',
      url: `${PORTAL_API_ENDPOINT}/shadows/${thingID}`,
      contentType: 'application/json',
      data: {
        state: {
          desired: {
            RLY: newConfig,
          },
        },
      },
    };
    const res = await portalHandleRequest(obj, navigate);

    if (res.status === 200) {
      const retry = (fn, retriesLeft = 10, interval = 1000) => {
        return new Promise((resolve, reject) => {
          fn()
            .then((response) => {
              if (!response) {
                // validation failed => invalid config
                toast({
                  title:
                    'Invalid EdgePi config. Please try power cycling your EdgePi or contact tech support',
                  status: 'error',
                  position: 'top',
                  duration: 5000,
                  isClosable: true,
                });
                updateState.current = false;
                onRelayModalClose();
              } else {
                const check2ConfigObj = !isEqual(
                  response.state.desired.RLY,
                  response.state.reported.RLY
                );

                if (check2ConfigObj) {
                  console.log(
                    `Retry to get new reported config ... Remaining attempts: ${retriesLeft}`
                  );
                  setTimeout(() => {
                    if (retriesLeft === 1) {
                      console.log('Maximum retries exceeded');
                      toast({
                        title: 'Failed to setup Relay configuration',
                        description:
                          'Please try again later or contact us for support',
                        status: 'error',
                        position: 'top',
                        duration: 10000,
                        isClosable: true,
                      });
                      updateState.current = false;
                      onRelayModalClose();
                      return;
                    }
                    retry(fn, retriesLeft - 1, interval).then(resolve, reject);
                  }, interval);
                } else {
                  console.log('Updated successfully!');
                  toast({
                    title:
                      'New Relay configuration has been setup successfully',
                    status: 'success',
                    position: 'top',
                    duration: 2000,
                    isClosable: true,
                  });
                  updateState.current = false;
                  onRelayModalClose();
                  resolve();
                }
              }
            })
            .catch((error) => {
              setTimeout(() => {
                if (retriesLeft === 1) {
                  // maximum retries exceeded
                  toast({
                    title: 'Failed to setup Relay configuration',
                    description:
                      'Please try again later or contact us for support',
                    status: 'error',
                    position: 'top',
                    duration: 10000,
                    isClosable: true,
                  });
                  reject(error);
                  updateState.current = false;
                  onRelayModalClose();
                  return;
                }
                retry(fn, retriesLeft - 1, interval).then(resolve, reject);
              }, interval);
            });
        });
      };
      retry(() => getConfig());
    } else {
      toast({
        title: `Failed to update RELAY configuration. Status: ${res.status}`,
        description:
          res.Message || 'Please try again later or contact us for support',
        status: 'error',
        position: 'top',
        duration: 10000,
        isClosable: true,
      });
      onRelayModalClose();
    }
  }, []);
  return (
    <Box className="dashboard-module">
      <div className="dashboard-module--heading">
        <h2>Relay </h2>
        <Icon
          as={SiIcloud}
          color="#dc2f5c"
          className="cloud-status"
          ml={2}
          w={8}
          h={8}
        />
      </div>
      {RLYConfig ? (
        <>
          {RLYConfig.data_collection === 'enabled' ? (
            <div className="dashboard-module--content">
              <Image
                w="300px"
                mb={4}
                mt="80px"
                objectFit="contain"
                src={
                  latestRELAYData && latestRELAYData.is_relay_closed
                    ? '/relay-switch-on.png'
                    : '/relay-switch-off.png'
                }
                alt="settings"
                data-testid="test-switch-img"
              />
              <h4 className="relay-state">
                {latestRELAYData && latestRELAYData.is_relay_closed
                  ? 'Closed'
                  : 'Open'}
              </h4>
            </div>
          ) : (
            <div className="dashboard-module--content">
              <Image
                h="150px"
                mt="80px"
                objectFit="contain"
                src="/disconnection.png"
                alt="settings"
              />
              <h4 className="relay-state">N/A</h4>
            </div>
          )}

          <Flex className="dashboard-module--footer">
            <Flex p={3} w="100%" alignItems="center" justifyContent="flex-end">
              <Image
                className="setting-btn"
                boxSize="30px"
                objectFit="contain"
                src="/gear.png"
                alt="settings"
                mr={2}
                ml={2}
                onClick={() => onRelayModalOpen()}
              />
              <Image
                className="chart-btn"
                boxSize="25px"
                objectFit="contain"
                src="/grafana.png"
                alt="grafana-chart"
                onClick={() => handleGrafanaURL('RLY')}
              />
            </Flex>
          </Flex>
        </>
      ) : (
        <Flex className="dashboard-module--na" mt={8} mb={8}>
          <h4>Module is not available</h4>
        </Flex>
      )}

      <RelayModal
        isOpen={isRelayModalOpen}
        onClose={onRelayModalClose}
        setting={RLYConfig || ''}
        handleUpdateConfig={handleUpdateRelayConfig}
      />
    </Box>
  );
};

export default memo(Relay);
