import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  useToast,
} from '@chakra-ui/react';
import portalHandleRequest from '../../api/portalHandleRequest';
import './account-migration.styles.scss';

const PORTAL_API_ENDPOINT = process.env.REACT_APP_PORTAL_API;

const AccountMigration = () => {
  const [isMergeBtnLoading, setMergeButtonLoading] = useState(false);
  const [isNonMergeBtnLoading, setNonMergeButtonLoading] = useState(false);
  const [isMergeBtnDisabled, setMergeButtonDisabled] = useState(false);
  const [isNonMergeDisabled, setNonMergeButtonDisabled] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    if (!location.state.allowMigration) {
      navigate('/');
    }
  }, []);
  const handleCreateAccount = async (migrateAccount) => {
    if (migrateAccount) {
      setMergeButtonLoading(true);
      setMergeButtonDisabled(true);
    } else {
      setNonMergeButtonLoading(true);
      setNonMergeButtonDisabled(true);
    }

    const obj = {
      method: 'POST',
      url: `${PORTAL_API_ENDPOINT}/users`,
      contentType: 'application/json',
      data: { migrate_account: migrateAccount },
    };
    const res = await portalHandleRequest(obj, navigate);
    if (migrateAccount && res.success) {
      toast({
        title: 'Your account has been migrated',
        description: 'You will be redirected to the homepage shortly',
        status: 'success',
        onCloseComplete: () => {
          navigate('/');
        },
        position: 'top',
        duration: 3000,
        isClosable: true,
      });
    } else if (!migrateAccount && res.success) {
      // Create account without merging
      toast({
        title: 'Account Created Successful',
        description: 'You will be redirected to the homepage shortly',
        status: 'success',
        onCloseComplete: () => {
          navigate('/');
        },
        position: 'top',
        duration: 3000,
        isClosable: true,
      });
    } else {
      toast({
        title: 'Failed to migrate your account',
        description: 'Please try again later or contact us for support',
        status: 'error',
        position: 'top',
        duration: 10000,
        isClosable: true,
      });
    }
    setMergeButtonLoading(false);
    setNonMergeButtonLoading(false);
  };

  return (
    <Box className="migration-page">
      <Container maxW="full" className="migration-container">
        <h3>Before we log you in to your EdgePi Portal ...</h3>
        <p>
          We’ve noticed that the email you’ve used for your account creation is
          linked to the following guest account(s).
        </p>

        <TableContainer mt={10} mb={10} className="accounts-table">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Email</Th>
                <Th>Account ID</Th>
                <Th>Created On</Th>
              </Tr>
            </Thead>
            <Tbody>
              {location.state.accounts &&
              location.state.accounts.accounts.length > 0
                ? location.state.accounts.accounts.map((account) => (
                    <Tr key={account.account_id}>
                      <Td>{account.stripe_email || ''}</Td>
                      <Td>{account.account_id || ''}</Td>
                      <Td>{account.created_on || ''}</Td>
                    </Tr>
                  ))
                : ''}
            </Tbody>
          </Table>
        </TableContainer>

        <h4>Would you like to merge these guest accounts.</h4>
        <p>
          Once merged, purchases and subscription orders from these guest
          accounts will be shown through your personal account.
        </p>

        <Flex justifyContent="center" flexWrap="wrap" mt={6}>
          <Button
            mt={6}
            mr={8}
            className="btn-group"
            bg="#3A3A3C"
            color="#FFF"
            variant="solid"
            w={250}
            isLoading={isNonMergeBtnLoading}
            isDisabled={isNonMergeDisabled}
            onClick={() => {
              handleCreateAccount(false);
            }}
            _hover={{ bg: '#3A3A3C99' }}
          >
            Continue without merge
          </Button>
          <Button
            mt={6}
            mr={8}
            className="btn-group"
            bg="#DB2F5CCC"
            color="#FFF"
            variant="solid"
            w={250}
            isLoading={isMergeBtnLoading}
            isDisabled={isMergeBtnDisabled}
            onClick={() => {
              handleCreateAccount(true);
            }}
            _hover={{ bg: '#db2f5d99' }}
          >
            Merge Guest Accounts
          </Button>
        </Flex>
      </Container>
    </Box>
  );
};

export default AccountMigration;
