import { extendTheme } from '@chakra-ui/react';

const config = {
  initialColorMode:
    localStorage.getItem('colorMode') &&
    localStorage.getItem('colorMode') === 'light'
      ? 'light'
      : 'dark',
  useSystemColorMode: false,
};

const theme = extendTheme({ config });

export default theme;
