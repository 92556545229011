const mapModbusRTUStatus = (statusCode) => {
  let status;
  switch (statusCode) {
    case 0:
      status = 'OK';
      break;
    case 1:
      status = 'COM ERROR';
      break;
    case 2:
      status = 'READ ERROR';
      break;
    case 'NO DATA':
      status = 'NO DATA';
      break;
    case 'BAD ADDRESS':
      status = 'BAD ADDRESS';
      break;
    case 'N/A':
      status = 'N/A';
      break;
    default:
      status = 'INVALID';
  }
  return status;
};

export default mapModbusRTUStatus;
