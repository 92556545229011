/* eslint-disable no-use-before-define */
import React, { useEffect, useState, useRef, memo } from 'react';
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import payloadObj from '../../../../../api/createPayloadObject';
import CustomRadioComponent from '../../../../../components/custom-radio-btn/custom-radio-btn.component';
import CustomSwitch from '../../../../../components/custom-switch/custom-switch.component';
import useADCModal from './useADCModal';
import './adc-modal.styles.scss';

const ADCModal = ({
  isOpen,
  onClose,
  ADC,
  DIN,
  INPUTS,
  selectedModalMeasurement,
  handleUpdateModuleConfig,
}) => {
  const [DINConfig, setDINConfig] = useState({});
  const [selectedMeasurement, setSelectedMeasurement] = useState('General');
  const [selectedDiff, setSelectedDiff] = useState('1');
  const [INPUTSConfig, setINPUTSConfig] = useState({});
  const [initialINPUTSConfig, setInitialINPUTSConfig] = useState({});
  const [isButtonLoading, setButtonLoading] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const updateState = useRef(false);

  async function updateInfoCallback() {
    handleConfirmConfig();
  }

  const { onStart, onChange, onSubmit, onResetAll, values } = useADCModal(
    updateInfoCallback,
    ADC
  );

  useEffect(() => {
    setTabIndex(1);
    if (selectedModalMeasurement.num) {
      setSelectedDiff(selectedModalMeasurement.num);
    }
    setSelectedMeasurement(selectedModalMeasurement.mode);
  }, [selectedModalMeasurement]);

  useEffect(() => {
    if (ADC && !updateState.current) {
      onStart(ADC);
    }
  }, [ADC]);

  useEffect(() => {
    if (DIN && !updateState.current) {
      setDINConfig(DIN);
    }
  }, [DIN]);

  useEffect(() => {
    setINPUTSConfig(structuredClone(INPUTS));
    if (Object.keys(initialINPUTSConfig).length === 0 && INPUTS) {
      setInitialINPUTSConfig(structuredClone(INPUTS));
    }
  }, [INPUTS]);

  const handleINPUTSSetting = (prop, value) => {
    const updatedINPUTSConfig = structuredClone(INPUTSConfig);
    if (typeof value === 'boolean') {
      updatedINPUTSConfig[prop] = value ? 'enabled' : 'disabled';
    } else {
      updatedINPUTSConfig[prop] = value;
    }

    setINPUTSConfig(updatedINPUTSConfig);
  };

  const handleDINSetting = (prop, value) => {
    const newDINConfig = structuredClone(DINConfig);
    if (typeof value === 'boolean') {
      newDINConfig[prop] = value ? 'enabled' : 'disabled';
    } else {
      newDINConfig[prop] = value;
    }
    setDINConfig(newDINConfig);
  };

  const handleChangeMeasurement = (e) => {
    setSelectedMeasurement(e);
  };

  const handleOnCloseModal = () => {
    onClose();
    onResetAll(ADC);
    setDINConfig(DIN);
  };

  const handleConfirmConfig = () => {
    updateState.current = true;
    setButtonLoading(true);
    setButtonDisabled(true);

    const newADCConfig = structuredClone(values);
    const newDINConfig = structuredClone(DINConfig);
    const newINPUTSConfig = structuredClone(INPUTSConfig);

    // IF using DIFF, change equivalent ports to 'ain' before turning on the equivalent DIFF
    if (values.diff_1.data_collection === 'enabled') {
      newINPUTSConfig.in_1 = 'ain';
      newINPUTSConfig.in_2 = 'ain';
      newADCConfig.ch_1.data_collection = 'disabled';
      newADCConfig.ch_2.data_collection = 'disabled';
    } else {
      newADCConfig.ch_1.data_collection = 'enabled';
      newADCConfig.ch_2.data_collection = 'enabled';
    }

    if (values.diff_2.data_collection === 'enabled') {
      newINPUTSConfig.in_3 = 'ain';
      newINPUTSConfig.in_4 = 'ain';
      newADCConfig.ch_3.data_collection = 'disabled';
      newADCConfig.ch_4.data_collection = 'disabled';
    } else {
      newADCConfig.ch_3.data_collection = 'enabled';
      newADCConfig.ch_4.data_collection = 'enabled';
    }

    if (values.diff_3.data_collection === 'enabled') {
      newINPUTSConfig.in_5 = 'ain';
      newINPUTSConfig.in_6 = 'ain';
      newADCConfig.ch_5.data_collection = 'disabled';
      newADCConfig.ch_6.data_collection = 'disabled';
    }

    if (values.diff_4.data_collection === 'enabled') {
      newINPUTSConfig.in_7 = 'ain';
      newINPUTSConfig.in_8 = 'ain';
      newADCConfig.ch_7.data_collection = 'disabled';
      newADCConfig.ch_8.data_collection = 'disabled';
    }

    if (values.rtd.data_collection === 'enabled') {
      // change all ports (5-8) to 'ain' before turning on RTD
      newINPUTSConfig.in_5 = 'ain';
      newINPUTSConfig.in_6 = 'ain';
      newINPUTSConfig.in_7 = 'ain';
      newINPUTSConfig.in_8 = 'ain';

      // disable data_collection for channel 5, 6, 7 and 8 if RTD is enabled
      newADCConfig.ch_5.data_collection = 'disabled';
      newADCConfig.ch_6.data_collection = 'disabled';
      newADCConfig.ch_7.data_collection = 'disabled';
      newADCConfig.ch_8.data_collection = 'disabled';
    } else {
      // turn on channels if RTD & DIFF 3 & DIFF 4 are disabled
      if (values.diff_3.data_collection === 'disabled') {
        newADCConfig.ch_5.data_collection = 'enabled';
        newADCConfig.ch_6.data_collection = 'enabled';
      }
      if (values.diff_4.data_collection === 'disabled') {
        newADCConfig.ch_7.data_collection = 'enabled';
        newADCConfig.ch_8.data_collection = 'enabled';
      }
    }

    const ADCPayload = payloadObj(values, newADCConfig); // update the ADC config with new values
    handleUpdateModuleConfig(ADCPayload, newDINConfig, newINPUTSConfig);
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      onCloseComplete={() => {
        updateState.current = false;
        setButtonLoading(false);
        setButtonDisabled(false);
      }}
      size="2xl"
      className="adc-modal"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Inputs</ModalHeader>
        <ModalCloseButton
          onClick={handleOnCloseModal}
          isDisabled={isButtonDisabled}
        />
        <ModalBody p={6}>
          {isButtonLoading && <div className="modal-disabled" />}
          <Tabs
            isLazy
            isFitted
            index={tabIndex}
            onChange={(index) => setTabIndex(index)}
          >
            <TabList>
              <Tab>
                <h2>General</h2>
              </Tab>
              <Tab isDisabled={INPUTSConfig.control === 'sdk'}>
                <h2>Analog Input</h2>
              </Tab>
              <Tab isDisabled={INPUTSConfig.control === 'sdk'}>
                <h2>Digital Input</h2>
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <Box>
                  <Box pl={0} className="adc-modal--item">
                    <Flex alignItems="center" mb={2} flexWrap="wrap">
                      <h4>Control :</h4>
                      <Select
                        bg="#535355"
                        borderColor="#535355"
                        color="white"
                        w={200}
                        ml={2}
                        value={
                          INPUTSConfig.control || (INPUTS && INPUTS.control)
                        }
                        onChange={(e) =>
                          handleINPUTSSetting('control', e.target.value)
                        }
                      >
                        <option value="dashboard">Dashboard</option>
                        <option value="sdk" disabled>
                          SDK
                        </option>
                      </Select>
                    </Flex>
                    <p>
                      Configure{' '}
                      <strong>Analog Inputs and Digital Inputs</strong> either
                      through your EdgePi Dashboard or through SDK. Choosing SDK
                      Control will disable Dashboard Control.
                    </p>
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel>
                <CustomRadioComponent
                  options={['General', 'RTD', 'Differentials']}
                  value={selectedMeasurement || selectedModalMeasurement.mode}
                  onChange={handleChangeMeasurement}
                />

                {selectedMeasurement === 'General' && (
                  <Box>
                    <Box className="adc-modal--dashboard">
                      <Box pl={0} className="adc-modal--item">
                        <Flex alignItems="center" mb={2} flexWrap="wrap">
                          <h4 className="adc-modal--title">
                            Data Collection :
                          </h4>
                          <CustomSwitch
                            id="general-data-toggle"
                            onSwitchChange={onChange(null, 'data_collection')}
                            variant="lite"
                            isChecked={values.data_collection === 'enabled'}
                          />
                        </Flex>
                        <p>
                          Data collection controls the collection of data from
                          this module, subsequently overriding data collection
                          on a port level.
                        </p>
                      </Box>

                      <Box className="adc-modal--box">
                        <h4>Data Rate :</h4>
                        <p>Samples taken per second for ADC 1 and ADC 2</p>
                        <Flex justifyContent="space-between" flexWrap="wrap">
                          <Flex alignItems="center" mt={2}>
                            <h4 className="data-rate">ADC 1 : </h4>
                            <Select
                              bg="#535355"
                              borderColor="#535355"
                              color="white"
                              w={140}
                              ml={2}
                              onChange={onChange(null, 'adc_1_data_rate')}
                              value={values.adc_1_data_rate}
                            >
                              <option value={2.5}>2.5 S/s</option>
                              <option value={5}>5 S/s</option>
                              <option value={10}>10 S/s</option>
                              <option value={16.6}>16.6 S/s</option>
                              <option value={20}>20 S/s</option>
                              <option value={50}>50 S/s</option>
                              <option value={60}>60 S/s</option>
                              <option value={100}>100 S/s</option>
                              <option value={400}>400 S/s</option>
                              <option value={1200}>1200 S/s</option>
                              <option value={4800}>4800 S/s</option>
                              <option value={7200}>7200 S/s</option>
                              <option value={14400}>14400 S/s</option>
                              <option value={19200}>19200 S/s</option>
                              <option value={38400}>38400 S/s</option>
                            </Select>
                          </Flex>
                          <Flex alignItems="center" mt={2} flexWrap="wrap">
                            <h4 className="data-rate">ADC 2 : </h4>
                            <Select
                              bg="#535355"
                              borderColor="#535355"
                              color="white"
                              w={120}
                              ml={2}
                              value={values.adc_2_data_rate}
                              onChange={onChange(null, 'adc_2_data_rate')}
                            >
                              <option value={10}>10 S/s</option>
                              <option value={100}>100 S/s</option>
                              <option value={400}>400 S/s</option>
                              <option value={800}>800 S/s</option>
                            </Select>
                          </Flex>
                        </Flex>
                      </Box>

                      <Box className="adc-modal--box">
                        <h4>Read Interval :</h4>
                        <p>
                          Data collection read interval for{' '}
                          <strong>Analog Inputs</strong>.
                        </p>
                        <Select
                          bg="#535355"
                          borderColor="#535355"
                          color="white"
                          w={200}
                          mt={2}
                          value={values.read_interval_ms}
                          onChange={onChange(null, 'read_interval_ms')}
                        >
                          <option value={1000}>1 second</option>
                          <option value={2000}>2 seconds</option>
                          <option value={3000}>3 seconds</option>
                          <option value={5000}>5 seconds</option>
                          <option value={10000}>10 seconds</option>
                          <option value={15000}>15 seconds</option>
                          <option value={30000}>30 seconds</option>
                          <option value={60000}>60 seconds</option>
                        </Select>
                      </Box>
                    </Box>
                  </Box>
                )}
                {selectedMeasurement === 'RTD' && (
                  <Box>
                    <Box className="adc-modal--box">
                      <Flex mb={2}>
                        <h4 className="type">RTD :</h4>
                        <div className="port-number">21</div>
                        <div className="port-number">24</div>
                        <div className="port-number">25</div>
                      </Flex>
                      <p>
                        <span>NOTE :</span> When RTD is enabled, ports 21, 24
                        and 25 will be assigned for RTD measurements and will
                        not be available for regular ADC measurements. Port 22
                        will also be disabled.
                      </p>
                    </Box>

                    <Box className="adc-modal--box">
                      <Box mb={2}>
                        <h4 className="adc-modal--title">Data Collection :</h4>
                        <p>
                          Data collection begins the collection of data from
                          these ports. Its reading will be subsequently shown on
                          your Dashboard.
                        </p>
                      </Box>
                      <CustomSwitch
                        id="rtd-data-toggle"
                        onSwitchChange={onChange('rtd', 'data_collection')}
                        variant="lite"
                        isChecked={values.rtd.data_collection === 'enabled'}
                      />
                    </Box>

                    <Box className="adc-modal--box">
                      <h4>ADC :</h4>
                      <p>RTD can only be read from ADC 2. </p>
                      <Select
                        bg="#535355"
                        borderColor="#535355"
                        color="white"
                        w={200}
                        mt={2}
                        disabled
                      >
                        <option value="ADC-2"> ADC 2</option>
                      </Select>
                    </Box>

                    <Box className="adc-modal--box">
                      <h4>Cloud Storage :</h4>
                      <p>
                        Cloud Storage will begin the storing of data. This data
                        will be made available to you through our data
                        visualization tool.
                      </p>
                      <CustomSwitch
                        isDisabled
                        id="rtd-cloud-toggle"
                        variant="lite"
                        onSwitchChange={onChange('rtd', 'cloud_storage')}
                        isChecked={values.rtd.cloud_storage === 'enabled'}
                      />
                    </Box>
                  </Box>
                )}

                {selectedMeasurement === 'Differentials' && (
                  <Box>
                    <Box className="adc-modal--box">
                      <Flex alignItems="center" flexWrap="wrap">
                        <h4>Differential :</h4>
                        <Flex
                          pl={6}
                          mt={1}
                          spacing={1}
                          className="diff-checkbox"
                        >
                          <CustomRadioComponent
                            options={['1', '2', '3', '4']}
                            value={selectedDiff || selectedModalMeasurement.num}
                            onChange={(e) => setSelectedDiff(e)}
                          />
                        </Flex>
                      </Flex>
                    </Box>
                    <Box className="adc-modal--box">
                      <Flex alignItems="center" mb={2} flexWrap="wrap">
                        {selectedDiff === '1' && (
                          <>
                            <Flex mr={2}>
                              <h4 className="type">DIFF 1 :</h4>
                              <div className="port-number">16</div>
                              <div className="port-number">17</div>
                            </Flex>

                            <CustomSwitch
                              id="diff1-toggle"
                              variant="lite"
                              onSwitchChange={onChange(
                                'diff_1',
                                'data_collection'
                              )}
                              isChecked={
                                values.diff_1.data_collection === 'enabled'
                              }
                            />

                            <Box className="adc-modal--box">
                              <h4>ADC :</h4>
                              <p>
                                Select measuring ADC for this differential.{' '}
                              </p>
                              <Select
                                bg="#535355"
                                borderColor="#535355"
                                color="white"
                                w={200}
                                mt={2}
                                value={values.diff_1.adc}
                                onChange={onChange('diff_1', 'adc')}
                              >
                                <option value="adc_1">ADC 1</option>
                                <option value="adc_2">ADC 2</option>
                              </Select>
                            </Box>

                            <Box className="adc-modal--box">
                              <h4>Cloud Storage :</h4>
                              <p>
                                Cloud Storage will begin the storing of data.
                                This data will be made available to you through
                                our data visualization tool.
                              </p>

                              <CustomSwitch
                                isDisabled
                                id="diff1-cloudstorage-toggle"
                                variant="lite"
                                onSwitchChange={onChange(
                                  'diff_1',
                                  'cloud_storage'
                                )}
                                isChecked={
                                  values.diff_1.cloud_storage === 'enabled'
                                }
                              />
                            </Box>
                          </>
                        )}

                        {selectedDiff === '2' && (
                          <>
                            <Flex mr={2}>
                              <h4 className="type">DIFF 2 :</h4>
                              <div className="port-number">19</div>
                              <div className="port-number">20</div>
                            </Flex>

                            <CustomSwitch
                              id="diff2-toggle"
                              variant="lite"
                              onSwitchChange={onChange(
                                'diff_2',
                                'data_collection'
                              )}
                              isChecked={
                                values.diff_2.data_collection === 'enabled'
                              }
                            />
                            <Box className="adc-modal--box">
                              <h4>ADC :</h4>
                              <p>
                                Select measuring ADC for this differential.{' '}
                              </p>
                              <Select
                                bg="#535355"
                                borderColor="#535355"
                                color="white"
                                w={200}
                                mt={2}
                                value={values.diff_2.adc}
                                onChange={onChange('diff_2', 'adc')}
                              >
                                <option value="adc_1">ADC 1</option>
                                <option value="adc_2">ADC 2</option>
                              </Select>
                            </Box>

                            <Box className="adc-modal--box">
                              <h4>Cloud Storage :</h4>
                              <p>
                                Cloud Storage will begin the storing of data.
                                This data will be made available to you through
                                our data visualization tool.
                              </p>

                              <CustomSwitch
                                isDisabled
                                id="diff2-cloudstorage-toggle"
                                variant="lite"
                                onSwitchChange={onChange(
                                  'diff_2',
                                  'cloud_storage'
                                )}
                                isChecked={
                                  values.diff_2.cloud_storage === 'enabled'
                                }
                              />
                            </Box>
                          </>
                        )}

                        {selectedDiff === '3' && (
                          <>
                            <Flex mr={2}>
                              <h4 className="type">DIFF 3 :</h4>
                              <div className="port-number">21</div>
                              <div className="port-number">22</div>
                            </Flex>

                            <CustomSwitch
                              id="diff3-toggle"
                              variant="lite"
                              onSwitchChange={onChange(
                                'diff_3',
                                'data_collection'
                              )}
                              isChecked={
                                values.diff_3.data_collection === 'enabled'
                              }
                            />
                            <Box className="adc-modal--box">
                              <h4>ADC :</h4>
                              <p>
                                Select measuring ADC for this differential.{' '}
                              </p>
                              <Select
                                bg="#535355"
                                borderColor="#535355"
                                color="white"
                                w={200}
                                mt={2}
                                value={values.diff_3.adc}
                                onChange={onChange('diff_3', 'adc')}
                              >
                                <option value="adc_1">ADC 1</option>
                                <option value="adc_2">ADC 2</option>
                              </Select>
                            </Box>

                            <Box className="adc-modal--box">
                              <h4>Cloud Storage :</h4>
                              <p>
                                Cloud Storage will begin the storing of data.
                                This data will be made available to you through
                                our data visualization tool.
                              </p>

                              <CustomSwitch
                                isDisabled
                                id="diff3-cloudstorage-toggle"
                                variant="lite"
                                onSwitchChange={onChange(
                                  'diff_3',
                                  'cloud_storage'
                                )}
                                isChecked={
                                  values.diff_3.cloud_storage === 'enabled'
                                }
                              />
                            </Box>
                          </>
                        )}

                        {selectedDiff === '4' && (
                          <>
                            <Flex mr={2}>
                              <h4 className="type">DIFF 4 :</h4>
                              <div className="port-number">24</div>
                              <div className="port-number">25</div>
                            </Flex>

                            <CustomSwitch
                              id="diff4-toggle"
                              variant="lite"
                              onSwitchChange={onChange(
                                'diff_4',
                                'data_collection'
                              )}
                              isChecked={
                                values.diff_4.data_collection === 'enabled'
                              }
                            />
                            <Box className="adc-modal--box">
                              <h4>ADC :</h4>
                              <p>
                                Select measuring ADC for this differential.{' '}
                              </p>
                              <Select
                                bg="#535355"
                                borderColor="#535355"
                                color="white"
                                w={200}
                                mt={2}
                                value={values.diff_4.adc}
                                onChange={onChange('diff_4', 'adc')}
                              >
                                <option value="adc_1">ADC 1</option>
                                <option value="adc_2">ADC 2</option>
                              </Select>
                            </Box>

                            <Box className="adc-modal--box">
                              <h4>Cloud Storage :</h4>
                              <p>
                                Cloud Storage will begin the storing of data.
                                This data will be made available to you through
                                our data visualization tool.
                              </p>

                              <CustomSwitch
                                isDisabled
                                id="diff4-cloudstorage-toggle"
                                variant="lite"
                                onSwitchChange={onChange(
                                  'diff_4',
                                  'cloud_storage'
                                )}
                                isChecked={
                                  values.diff_4.cloud_storage === 'enabled'
                                }
                              />
                            </Box>
                          </>
                        )}
                      </Flex>
                      <p>
                        <span>NOTE :</span> When switching two ports to
                        differential mode, if either ports are not connected,
                        the ADC will provide a measurement regardless. However
                        the data may be invalid.
                      </p>
                    </Box>
                  </Box>
                )}
              </TabPanel>
              <TabPanel>
                <Box>
                  <Box className="adc-modal--box">
                    <Flex alignItems="center" flexWrap="wrap">
                      <h4 className="adc-modal--title">Data Collection :</h4>
                      <CustomSwitch
                        id="din-data-collection"
                        onSwitchChange={(e) =>
                          handleDINSetting('data_collection', e)
                        }
                        variant="lite"
                        isChecked={
                          DINConfig && DINConfig.data_collection === 'enabled'
                        }
                        isDisabled={
                          Object.keys(DINConfig).length > 0 &&
                          DINConfig.control === 'sdk'
                        }
                      />
                    </Flex>
                    <p>
                      Data collection begins the collection of data from this
                      module. Its reading will be subsequently shown on your
                      Dashboard.
                    </p>
                  </Box>

                  <Box className="adc-modal--box">
                    <h4>Read Interval :</h4>
                    <p>
                      Data collection read interval for{' '}
                      <strong>Digital Inputs</strong>.
                    </p>
                    <Select
                      bg="#535355"
                      borderColor="#535355"
                      color="white"
                      w={200}
                      mt={2}
                      defaultValue={DIN && DIN.read_interval_ms}
                      onChange={(e) =>
                        handleDINSetting(
                          'read_interval_ms',
                          parseInt(e.target.value, 10)
                        )
                      }
                      isDisabled={
                        Object.keys(DINConfig).length > 0 &&
                        DINConfig.control === 'sdk'
                      }
                    >
                      <option value={1000}>1 second</option>
                      <option value={2000}>2 seconds</option>
                      <option value={3000}>3 seconds</option>
                      <option value={3000}>3 seconds</option>
                      <option value={5000}>5 seconds</option>
                      <option value={10000}>10 seconds</option>
                      <option value={15000}>15 seconds</option>
                      <option value={30000}>30 seconds</option>
                      <option value={60000}>60 seconds</option>
                    </Select>
                  </Box>
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn-group"
            bg="#535355"
            color="#FFF"
            boxShadow="0px 15px 30px rgba(0, 0, 0, 0.15)"
            variant="solid"
            w={150}
            mr={2}
            isDisabled={isButtonDisabled}
            onClick={handleOnCloseModal}
            _hover={{ bg: '#242424' }}
          >
            Cancel
          </Button>

          <Button
            className="btn-group"
            bg="#DB2F5CCC"
            color="#FFF"
            variant="solid"
            w={150}
            isLoading={isButtonLoading}
            onClick={onSubmit}
            _hover={{ bg: '#db2f5d99' }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default memo(ADCModal);
