import React, { useEffect, memo } from 'react';
import {
  Alert,
  AlertIcon,
  Box,
  Flex,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react';
import useForm from '../../../../../api/useForm';

const FTXMappingList = ({ list, handleUpdateList, selectedModel }) => {
  const { onStart, onChange, values } = useForm(null, {});

  useEffect(() => {
    let listOptions = {};
    if (selectedModel === '1_ch') {
      listOptions = {
        CH_A: 'CH_A',
      };
    } else if (selectedModel === '3_ch') {
      listOptions = {
        CH_A: 'CH_A',
        CH_B: 'CH_B',
        CH_C: 'CH_C',
      };
    } else if (selectedModel === '9_ch') {
      listOptions = {
        CH_A: 'CH_A',
        CH_B: 'CH_B',
        CH_C: 'CH_C',
        CH_D: 'CH_D',
        CH_E: 'CH_E',
        CH_F: 'CH_F',
        CH_G: 'CH_G',
        CH_H: 'CH_H',
        CH_I: 'CH_I',
      };
    }
    for (const key in list) {
      if (
        Object.prototype.hasOwnProperty.call(list, key) &&
        Object.prototype.hasOwnProperty.call(listOptions, key) &&
        list[key] !== ''
      ) {
        listOptions[key] = list[key];
      }
    }
    onStart(listOptions);
  }, [selectedModel]);

  useEffect(() => {
    handleUpdateList(values);
  }, [values]);

  return (
    <>
      <Flex flexWrap="wrap" alignItems="flex-end" />
      <Box className="mapping-container">
        <Box>
          <TableContainer className="mapping-list">
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th w={40}>Channel</Th>
                  <Th>Name</Th>
                </Tr>
              </Thead>

              <Tbody>
                {Object.keys(values).length !== 0 ? (
                  Object.entries(values).map(([channel, name]) => (
                    <Tr key={channel}>
                      <Td w={40}>{channel}</Td>
                      <Td>
                        <Input
                          width={320}
                          mt={1}
                          type="text"
                          isInvalid={!name}
                          maxLength={20}
                          placeholder="e.g. Living Room"
                          value={name}
                          onChange={onChange(channel)}
                        />
                      </Td>
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <Td colSpan={2}>
                      <Alert status="warning" mt={2} borderRadius="10px">
                        <AlertIcon />
                        Please select your FTX Device Model first
                      </Alert>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
};
export default memo(FTXMappingList);
