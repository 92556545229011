function getKeysWithEmptyValues(obj) {
  const emptyKeys = [];

  function checkEmpty(value) {
    return value === '' || value === undefined || Number.isNaN(value);
  }

  function checkObject(obj) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const value = obj[key];
        if (Array.isArray(value) && value.length === 0) {
          emptyKeys.push(key);
        } else if (typeof value === 'object') {
          checkObject(value);
        } else if (checkEmpty(value)) {
          emptyKeys.push(key);
        }
      }
    }
  }
  checkObject(obj);
  return emptyKeys;
}

function validateReadBlocks(readBlocks, formatList) {
  const attributeNames = {}; // Object to store attribute names

  for (const key in readBlocks) {
    // Check if the key is a property of the object and the value is not null
    if (
      Object.prototype.hasOwnProperty.call(readBlocks, key) &&
      readBlocks[key] !== null
    ) {
      const block = readBlocks[key]; // Reference to the current block

      // CHECK if read_attributes is empty
      if (Object.keys(block.read_attributes).length === 0) {
        return {
          isValid: false,
          message: `Read block ${block.block_name} has no read attributes. It should have at least one.`,
        };
      }

      // Iterate over each attribute in read_attributes
      for (const attributeKey in block.read_attributes) {
        if (
          Object.prototype.hasOwnProperty.call(
            block.read_attributes,
            attributeKey
          )
        ) {
          const attribute = block.read_attributes[attributeKey]; // Reference to the current attribute

          // CHECK if any attribute properties are missing
          if (
            !attribute.attribute_name ||
            !attribute.unit ||
            !attribute.format_id ||
            !attribute.address ||
            !attribute.reg_count
          ) {
            return {
              isValid: false,
              message: `Missing values found for attribute ${attribute.attribute_name} of read block ${block.block_name}`,
            };
          }

          // CHECK for duplicate attribute names
          if (attributeNames[attribute.attribute_name]) {
            return {
              isValid: false,
              message: `Invalid values found for attribute: ${attribute.attribute_name}. The read attribute name must be unique in their read block AND across all read blocks`,
            };
          }

          // Store the attribute name
          attributeNames[attribute.attribute_name] = true;

          // Retrieve format data type
          const formatDataType = formatList[attribute.format_id].data_type;

          // CHECK register count and format data type
          if (
            attribute.reg_count === 1 &&
            !['int_16', 'uint_16', 'bool', 'ascii_16'].includes(formatDataType)
          ) {
            return {
              isValid: false,
              message: `Invalid values found for attribute: ${attribute.attribute_name}. The format data type must specify a 16-bit data type or Boolean if the register count of attribute is 1`,
            };
          }
          if (
            attribute.reg_count === 2 &&
            !['int_32', 'uint_32', 'float_32', 'ascii_32'].includes(
              formatDataType
            )
          ) {
            return {
              isValid: false,
              message: `Invalid values found for attribute: ${attribute.attribute_name}. The format data type must specify a 32-bit data type if the register count of attribute is 2`,
            };
          }
        }
      }
    }
  }

  // If all validations pass, return a valid message
  return {
    isValid: true,
    message: 'The Modbus RTU config is valid',
  };
}

const validateModbusRTUConfig = (config) => {
  // Validation: check any empty values
  const emptyKeys = getKeysWithEmptyValues(config);

  if (emptyKeys.length > 0) {
    console.error('Config object has empty values in keys:', emptyKeys);
    return {
      isValid: false,
      message: `Some values are missing. Please check and try again.`,
    };
  }

  // Validation: check Modbus RTU params
  if (
    Number.isInteger(config.modbus_params.modbus_id) &&
    config.modbus_params.modbus_id >= 1 &&
    config.modbus_params.modbus_id <= 247
  ) {
    console.log('Modbus ID is valid!');
  } else {
    return {
      isValid: false,
      message: 'Invalid Modbus ID. Valid Range: 1 to 247.',
    };
  }

  if (
    Number.isInteger(config.modbus_params.baud_rate) &&
    config.modbus_params.baud_rate > 0
  ) {
    console.log('Baud Rate is valid!');
  } else {
    return {
      isValid: false,
      message: 'Invalid Baud Rate. Valid range: greater than 0',
    };
  }
  // Validation: check Modbus RTU read blocks
  return validateReadBlocks(config.read_blocks, config.formats);
};

export default validateModbusRTUConfig;
