import { ShopActionTypes } from './shop.types';

export const retrieveProductsData = (shop) => ({
  type: ShopActionTypes.RETRIEVE_PRODUCTS_DATA,
  payload: shop,
});
export const retrievePlansData = (shop) => ({
  type: ShopActionTypes.RETRIEVE_PLANS_DATA,
  payload: shop,
});
