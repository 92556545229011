import React, { useState, useEffect, memo } from 'react';
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  RadioGroup,
  Radio,
  Stack,
  Select,
} from '@chakra-ui/react';

import CustomSwitch from '../../../../../components/custom-switch/custom-switch.component';

const ThermocoupleModal = ({
  isOpen,
  onClose,
  setting,
  handleUpdateConfig,
}) => {
  const [TCConfig, setTCConfig] = useState({});
  const [isButtonLoading, setButtonLoading] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    if (setting) {
      setTCConfig(setting);
    }
  }, [setting]);

  const handleTCConfig = (prop, value) => {
    const updatedTCConfig = structuredClone(TCConfig);
    if (typeof value === 'boolean') {
      updatedTCConfig[prop] = value ? 'enabled' : 'disabled';
    } else {
      updatedTCConfig[prop] = value;
    }
    setTCConfig(updatedTCConfig);
  };

  const handleOnCloseModal = () => {
    onClose();
    setTCConfig(setting); // reset config
  };

  const handleConfirmConfig = () => {
    setButtonLoading(true);
    setButtonDisabled(true);
    handleUpdateConfig(TCConfig);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
      className="thermocouple-modal"
      closeOnOverlayClick={false}
      onCloseComplete={() => {
        setButtonLoading(false);
        setButtonDisabled(false);
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Thermocouple</ModalHeader>
        <ModalCloseButton
          isDisabled={isButtonLoading}
          onClick={handleOnCloseModal}
        />

        <ModalBody p={6}>
          <Box className="operating-menu">
            <Box mb={4}>
              <Flex alignItems="center" className="switch-box" flexWrap="wrap">
                <h4>Data Collection : </h4>

                <CustomSwitch
                  id="tc-data-collection"
                  onSwitchChange={(e) => handleTCConfig('data_collection', e)}
                  variant="lite"
                  isChecked={
                    Object.keys(TCConfig).length > 0 &&
                    TCConfig.data_collection === 'enabled'
                  }
                  isDisabled={isButtonLoading}
                />
              </Flex>
              <p>
                Data collection begins the collection of data from this module.
                Its reading will be subsequently shown on your Dashboard.
              </p>
            </Box>
            <Box mb={4}>
              <Flex alignItems="center" className="switch-box" flexWrap="wrap">
                <h4>Cloud Storage : </h4>
                <CustomSwitch
                  id="tc-cloud-storage"
                  onSwitchChange={(e) => handleTCConfig('cloud_storage', e)}
                  variant="lite"
                  isChecked={
                    Object.keys(TCConfig).length > 0 &&
                    TCConfig.cloud_storage === 'enabled'
                  }
                  isDisabled
                />
              </Flex>
              <p>
                Cloud Storage will begin the storing of data. This data will be
                made available to you through our data visualization tool.
              </p>
            </Box>

            <h4>Type</h4>
            <RadioGroup
              mt={4}
              value={Object.keys(TCConfig).length > 0 && TCConfig.probe_type}
              onChange={(e) => {
                handleTCConfig('probe_type', e);
              }}
              isDisabled={isButtonLoading}
            >
              <Flex justifyContent="space-between">
                <Stack spacing={[1, 2]} direction={['column']}>
                  <Radio value="B">B : 250°C to 1820°C</Radio>
                  <Radio value="E">E : -200°C to 1000°C</Radio>
                  <Radio value="J">J : -210°C to 1200°C</Radio>
                  <Radio value="K">K : -200°C to 1372°C</Radio>
                </Stack>
                <Stack spacing={[1, 2]} direction={['column']}>
                  <Radio value="N">N : -200°C to 1300°C</Radio>
                  <Radio value="R">R : -50°C to 1768°C</Radio>
                  <Radio value="S">S : -50°C to 1768°C</Radio>
                  <Radio value="T">T : -200°C to 400°C</Radio>
                </Stack>
              </Flex>
            </RadioGroup>
            <Flex mt={4} mb={4} alignItems="center" flexWrap="wrap">
              <h4>Sample per Average :</h4>
              <Select
                bg="#535355"
                borderColor="#535355"
                color="white"
                w="90px"
                ml={2}
                onChange={(e) => {
                  handleTCConfig(
                    'average_samples',
                    parseInt(e.target.value, 10)
                  );
                }}
                defaultValue={
                  Object.keys(TCConfig).length > 0 && TCConfig.average_samples
                }
                isDisabled={isButtonLoading}
              >
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
              </Select>
            </Flex>

            <Box mb={4}>
              <h4>Read Interval :</h4>
              <p>
                Data collection read interval for <strong>Thermocouple</strong>.
              </p>
              <Select
                bg="#535355"
                borderColor="#535355"
                color="white"
                w={200}
                mt={2}
                defaultValue={
                  Object.keys(TCConfig).length > 0 && TCConfig.read_interval_ms
                }
                onChange={(e) =>
                  handleTCConfig(
                    'read_interval_ms',
                    parseInt(e.target.value, 10)
                  )
                }
                isDisabled={isButtonLoading}
              >
                <option value={1000}>1 second</option>
                <option value={2000}>2 seconds</option>
                <option value={3000}>3 seconds</option>
                <option value={5000}>5 seconds</option>
                <option value={10000}>10 seconds</option>
                <option value={15000}>15 seconds</option>
                <option value={30000}>30 seconds</option>
                <option value={60000}>60 seconds</option>
              </Select>
            </Box>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button
            className="btn-group"
            bg="#535355"
            color="#FFF"
            boxShadow="0px 15px 30px rgba(0, 0, 0, 0.15)"
            variant="solid"
            w={150}
            mr={2}
            isDisabled={isButtonDisabled}
            onClick={handleOnCloseModal}
            _hover={{ bg: '#242424' }}
          >
            Cancel
          </Button>

          <Button
            className="btn-group"
            bg="#DB2F5CCC"
            color="#FFF"
            variant="solid"
            w={150}
            isLoading={isButtonLoading}
            isDisabled={isButtonDisabled}
            onClick={handleConfirmConfig}
            _hover={{ bg: '#db2f5d99' }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default memo(ThermocoupleModal);
