/* eslint-disable no-use-before-define */
import React, { useEffect, useState, useRef, memo } from 'react';
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import CustomSwitch from '../../../../../components/custom-switch/custom-switch.component';
import useDACModal from './useDACModal';

const DACModal = ({
  isOpen,
  onClose,
  DAC,
  DOUT,
  OUTPUTS,
  handleUpdateModuleConfig,
}) => {
  const [DOUTConfig, setDOUTConfig] = useState({});
  const [OUTPUTSConfig, setOUTPUTSConfig] = useState({});
  const [initialOUTPUTSConfig, setInitialOUTPUTSConfig] = useState({});
  const [isButtonLoading, setButtonLoading] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const updateState = useRef(false);
  async function updateInfoCallback() {
    handleConfirmConfig();
  }

  const { onStart, onChange, onSubmit, onResetAll, values } = useDACModal(
    updateInfoCallback,
    DAC
  );

  useEffect(() => {
    if (DAC && !updateState.current) {
      onStart(DAC);
    }
  }, [DAC]);

  useEffect(() => {
    if (DOUT && !updateState.current) {
      setDOUTConfig(DOUT);
    }
  }, [DOUT]);

  useEffect(() => {
    setOUTPUTSConfig(structuredClone(OUTPUTS));
    if (Object.keys(initialOUTPUTSConfig).length === 0 && OUTPUTS) {
      setInitialOUTPUTSConfig(structuredClone(OUTPUTS));
    }
  }, [OUTPUTS]);

  const handleOUTPUTSSetting = (prop, value) => {
    const updatedOUTPUTSConfig = structuredClone(OUTPUTSConfig);
    if (typeof value === 'boolean') {
      if (prop === 'gain') {
        updatedOUTPUTSConfig[prop] = value;
      } else {
        updatedOUTPUTSConfig[prop] = value ? 'enabled' : 'disabled';
      }
    } else {
      updatedOUTPUTSConfig[prop] = value;
    }
    setOUTPUTSConfig(updatedOUTPUTSConfig);
  };

  const handleDOUTSetting = (prop, value) => {
    const updatedDOUTConfig = structuredClone(DOUTConfig);
    if (typeof value === 'boolean') {
      updatedDOUTConfig[prop] = value ? 'enabled' : 'disabled';
    } else {
      updatedDOUTConfig[prop] = value;
    }
    setDOUTConfig(updatedDOUTConfig);
  };

  const handleConfirmConfig = () => {
    updateState.current = true;
    setButtonLoading(true);
    setButtonDisabled(true);

    handleUpdateModuleConfig(values, DOUTConfig, OUTPUTSConfig);
  };

  const handleOnCloseModal = () => {
    onClose();
    onResetAll(DAC);
    setDOUTConfig(DOUT);
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      onCloseComplete={() => {
        updateState.current = false;
        setButtonLoading(false);
        setButtonDisabled(false);
      }}
      size="3xl"
      className="adc-modal"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Outputs</ModalHeader>
        <ModalCloseButton
          isDisabled={isButtonDisabled}
          onClick={handleOnCloseModal}
        />
        <ModalBody p={6}>
          {isButtonLoading && <div className="modal-disabled" />}
          <Tabs
            isLazy
            isFitted
            index={tabIndex}
            onChange={(index) => setTabIndex(index)}
          >
            <TabList>
              <Tab className="tab-title">
                <h2>General</h2>
              </Tab>
              <Tab
                className="tab-title"
                isDisabled={OUTPUTSConfig.control === 'sdk'}
              >
                <h2>Analog Output</h2>
              </Tab>
              <Tab
                className="tab-title"
                isDisabled={OUTPUTSConfig.control === 'sdk'}
              >
                <h2>Digital Output</h2>
              </Tab>
              {/* <Tab>
                <h2>PWM</h2>
              </Tab> */}
            </TabList>

            <TabPanels>
              <TabPanel>
                <Box>
                  <Box className="adc-modal--item">
                    <Flex alignItems="center" mb={2} flexWrap="wrap">
                      <h4>Control :</h4>
                      <Select
                        bg="#535355"
                        borderColor="#535355"
                        color="white"
                        w={200}
                        ml={2}
                        defaultValue={OUTPUTS && OUTPUTS.control}
                        onChange={(e) =>
                          handleOUTPUTSSetting('control', e.target.value)
                        }
                      >
                        <option value="dashboard">Dashboard</option>
                        <option value="sdk" disabled>
                          SDK
                        </option>
                      </Select>
                    </Flex>
                    <p>
                      Configure{' '}
                      <strong>Analog Outputs, Digital Outputs, and PWM</strong>{' '}
                      either through your EdgePi Dashboard or through SDK.
                      Choosing SDK Control will disable Dashboard Control.
                    </p>
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel>
                <Box>
                  <Box className="adc-modal--dashboard">
                    <Box className="adc-modal--box">
                      <Flex alignItems="center" mb={2} flexWrap="wrap">
                        <h4 className="adc-modal--title">
                          Internal DAC Gain :
                        </h4>
                        <CustomSwitch
                          id="dac-gain-toggle"
                          onSwitchChange={onChange(null, 'gain')}
                          variant="lite"
                          isChecked={values.gain}
                        />
                      </Flex>
                      <p>
                        Internal DAC gain amplifies the output voltage by a
                        multiple of 2. This increases the{' '}
                        <strong>Analog Output Range</strong> from 0-5V to 0-10V.
                      </p>
                    </Box>

                    <Box className="adc-modal--item">
                      <Flex alignItems="center" mb={2} flexWrap="wrap">
                        <h4 className="adc-modal--title">Data Collection :</h4>
                        <CustomSwitch
                          id="general-data-toggle"
                          variant="lite"
                          onSwitchChange={onChange(null, 'data_collection')}
                          isChecked={values.data_collection === 'enabled'}
                        />
                      </Flex>
                      <p>
                        Data collection begins the collection of data from this
                        module. Its reading will be subsequently shown on your
                        Dashboard.
                      </p>
                    </Box>

                    <Box className="adc-modal--box">
                      <h4 className="adc-modal--title">Read Interval :</h4>
                      <p>
                        Data collection read interval for{' '}
                        <strong>Analog Outputs</strong>.
                      </p>
                      <Select
                        bg="#535355"
                        borderColor="#535355"
                        color="white"
                        w={200}
                        mt={2}
                        onChange={onChange(null, 'read_interval_ms')}
                        value={values.read_interval_ms}
                      >
                        <option value={1000}>1 second</option>
                        <option value={2000}>2 seconds</option>
                        <option value={3000}>3 seconds</option>
                        <option value={5000}>5 seconds</option>
                        <option value={10000}>10 seconds</option>
                        <option value={15000}>15 seconds</option>
                        <option value={30000}>30 seconds</option>
                        <option value={60000}>60 seconds</option>
                      </Select>
                    </Box>
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel>
                <Box>
                  <Box className="adc-modal--item">
                    <Flex alignItems="center" mb={2} flexWrap="wrap">
                      <h4 className="adc-modal--title">Data Collection :</h4>
                      <CustomSwitch
                        id="digital-output-data-toggle"
                        variant="lite"
                        onSwitchChange={(e) =>
                          handleDOUTSetting('data_collection', e)
                        }
                        isChecked={
                          DOUTConfig && DOUTConfig.data_collection === 'enabled'
                        }
                      />
                    </Flex>
                    <p>
                      Data collection begins the collection of data from this
                      module. Its reading will be subsequently shown on your
                      Dashboard.
                    </p>
                  </Box>

                  <Box className="adc-modal--box">
                    <h4 className="adc-modal--title">Read Interval :</h4>
                    <p>
                      Data collection read interval for{' '}
                      <strong>Digital Outputs.</strong>.
                    </p>
                    <Select
                      bg="#535355"
                      borderColor="#535355"
                      color="white"
                      w={200}
                      mt={2}
                      defaultValue={DOUT && DOUT.read_interval_ms}
                      onChange={(e) =>
                        handleDOUTSetting(
                          'read_interval_ms',
                          parseInt(e.target.value, 10)
                        )
                      }
                    >
                      <option value={1000}>1 second</option>
                      <option value={2000}>2 seconds</option>
                      <option value={3000}>3 seconds</option>
                      <option value={5000}>5 seconds</option>
                      <option value={10000}>10 seconds</option>
                      <option value={15000}>15 seconds</option>
                      <option value={30000}>30 seconds</option>
                      <option value={60000}>60 seconds</option>
                    </Select>
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel>
                <Box>
                  <Box className="adc-modal--item">
                    <Flex alignItems="center" mb={2} flexWrap="wrap">
                      <h4>Data Collection :</h4>
                      <CustomSwitch
                        id="pwm-data-toggle"
                        // onSwitchChange={(e) =>
                        //   handleDOUTSetting('data_collection', e)
                        // }
                        variant="lite"
                        isDisabled={DAC.control === 'sdk'}
                        // isChecked={DOUT.data_collection === 'enabled'}
                      />
                    </Flex>
                    <p>
                      Data collection begins the collection of data from this
                      module. Its reading will be subsequently shown on your
                      Dashboard.
                    </p>
                  </Box>

                  <Box className="adc-modal--box">
                    <h4>Read Interval :</h4>
                    <p>
                      Data collection read interval for <strong>PWM</strong>.
                    </p>
                    <Select
                      bg="#535355"
                      borderColor="#535355"
                      color="white"
                      w={200}
                      mt={2}
                      // defaultValue={DOUT && DOUT.read_interval_ms}
                      // onChange={(e) =>
                      //   handleDOUTSetting(
                      //     'read_interval_ms',
                      //     parseInt(e.target.value, 10)
                      //   )
                      // }
                    >
                      <option value={1000}>1 second</option>
                      <option value={2000}>2 seconds</option>
                      <option value={3000}>3 seconds</option>
                      <option value={5000}>5 seconds</option>
                      <option value={10000}>10 seconds</option>
                      <option value={15000}>15 seconds</option>
                      <option value={30000}>30 seconds</option>
                      <option value={60000}>60 seconds</option>
                    </Select>
                  </Box>
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn-group"
            bg="#535355"
            color="#FFF"
            boxShadow="0px 15px 30px rgba(0, 0, 0, 0.15)"
            variant="solid"
            w={150}
            mr={2}
            isDisabled={isButtonDisabled}
            onClick={handleOnCloseModal}
            _hover={{ bg: '#242424' }}
          >
            Cancel
          </Button>

          <Button
            className="btn-group"
            bg="#DB2F5CCC"
            color="#FFF"
            variant="solid"
            w={150}
            isLoading={isButtonLoading}
            isDisabled={tabIndex === 0}
            onClick={onSubmit}
            _hover={{ bg: '#db2f5d99' }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default memo(DACModal);
