import React, { useState, useCallback, useEffect, memo } from 'react';
import {
  Box,
  Flex,
  Heading,
  Image,
  Icon,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import { SiIcloud } from 'react-icons/si';
import ADCModal from './adc-modal/adc-modal.component';
import InputChannelModal from './input-channel-modal/input-channel-modal.component';
import portalHandleRequest from '../../../../api/portalHandleRequest';
import handleLatestDataObj from '../../../../api/handleLatestDataObj';
import './inputs.styles.scss';

const PORTAL_API_ENDPOINT = process.env.REACT_APP_PORTAL_API;

const Inputs = ({
  thingID,
  data,
  resetData,
  config,
  DINConfig,
  DINData,
  INPUTSSelection,
  getConfig,
  getGrafanaURL,
}) => {
  const [latestADCData, setLatestADCData] = useState({});
  const [latestDINData, setLatestDINData] = useState({});
  const [updatedChannels, setUpdatedChannels] = useState([]);
  const [updatedDINChannels, setUpdatedDINChannels] = useState([]);
  const [showDIFF1, setShowDIFF1] = useState(false);
  const [showDIFF2, setShowDIFF2] = useState(false);
  const [showDIFF3, setShowDIFF3] = useState(false);
  const [showDIFF4, setShowDIFF4] = useState(false);
  const [showRTD, setShowRTD] = useState(false);
  const [ADCConfig, setADCConfig] = useState({});
  const [selectedModalMeasurement, setSelectedModalMeasurement] = useState({
    mode: 'General',
  });
  const [selectedChannel, setSelectedChannel] = useState();
  const navigate = useNavigate();
  const toast = useToast();

  const {
    isOpen: isADCModalOpen,
    onOpen: onADCModalOpen,
    onClose: onADCModalClose,
  } = useDisclosure();

  const {
    isOpen: isInputChannelModalOpen,
    onOpen: onInputChannelModalOpen,
    onClose: onInputChannelModalClose,
  } = useDisclosure();

  useEffect(() => {
    if (config) {
      setADCConfig(config);
      setShowDIFF1(
        config.diff_1 && config.diff_1.data_collection === 'enabled'
      );
      setShowDIFF2(
        config.diff_2 && config.diff_2.data_collection === 'enabled'
      );
      setShowDIFF3(
        config.diff_3 && config.diff_3.data_collection === 'enabled'
      );
      setShowDIFF4(
        config.diff_4 && config.diff_4.data_collection === 'enabled'
      );
      setShowRTD(config.rtd && config.rtd.data_collection === 'enabled');
    }
  }, [config]);

  useEffect(() => {
    if (resetData) {
      setLatestADCData({});
    }
  }, [resetData]);

  useEffect(() => {
    if (data.length > 0) {
      const res = handleLatestDataObj(data, latestADCData);
      if (res) {
        if (res.latestObj) {
          setLatestADCData(res.latestObj);
        } else {
          setUpdatedChannels(res.latestIndex);
          setLatestADCData(res.originalObject);
        }
      }
    } else {
      setLatestADCData({});
    }
  }, [data]);

  useEffect(() => {
    if (DINData.length > 0) {
      const res = handleLatestDataObj(DINData, latestDINData);
      if (res) {
        if (res.latestObj) {
          setLatestDINData(res.latestObj);
        } else {
          setUpdatedDINChannels(res.latestIndex);
          setLatestDINData(res.originalObject);
        }
      } else {
        setUpdatedDINChannels([]);
      }
    } else {
      setLatestDINData({});
    }
  }, [DINData]);

  const handleUpdateConfig = useCallback(
    async (newADCConfig, newDINConfig, newINPUTSConfig) => {
      const desired = {};

      desired.ADC = newADCConfig;
      desired.DIN = newDINConfig;
      desired.INPUTS = newINPUTSConfig;

      const obj = {
        method: 'PATCH',
        url: `${PORTAL_API_ENDPOINT}/shadows/${thingID}`,
        contentType: 'application/json',
        data: {
          state: {
            desired,
          },
        },
      };

      const res = await portalHandleRequest(obj, navigate);
      if (res.status === 200) {
        const retry = (fn, retriesLeft = 10, interval = 1000) => {
          return new Promise((resolve, reject) => {
            fn()
              .then((response) => {
                if (!response) {
                  // validation failed => invalid config
                  toast({
                    title:
                      'Invalid EdgePi config. Please try power cycling your EdgePi or contact tech support',
                    status: 'error',
                    position: 'top',
                    duration: 5000,
                    isClosable: true,
                  });
                  onADCModalClose();
                  onInputChannelModalClose();
                } else {
                  const isINPUTSMatched = isEqual(
                    response.state.desired.INPUTS,
                    response.state.reported.INPUTS
                  );

                  const isADCConfigMatched = isEqual(
                    response.state.desired.ADC,
                    response.state.reported.ADC
                  );
                  const isDINConfigMatched = isEqual(
                    response.state.desired.DIN,
                    response.state.reported.DIN
                  );

                  if (
                    !isADCConfigMatched ||
                    !isDINConfigMatched ||
                    !isINPUTSMatched
                  ) {
                    console.log(
                      `Retry to get new reported config ... Remaining attempts: ${retriesLeft}`
                    );
                    setTimeout(() => {
                      if (retriesLeft === 1) {
                        console.log('Maximum retries exceeded');
                        toast({
                          title: 'Failed to setup Inputs configuration',
                          description:
                            'Please try again later or contact us for support',
                          status: 'error',
                          position: 'top',
                          duration: 10000,
                          isClosable: true,
                        });
                        onInputChannelModalClose();
                        onADCModalClose();

                        return;
                      }
                      retry(fn, retriesLeft - 1, interval).then(
                        resolve,
                        reject
                      );
                    }, interval);
                  } else {
                    console.log('Updated successfully!');
                    toast({
                      title:
                        'New Inputs configuration has been setup successfully',
                      status: 'success',
                      position: 'top',
                      duration: 2000,
                      isClosable: true,
                    });
                    onADCModalClose();
                    onInputChannelModalClose();
                    resolve();
                  }
                }
              })
              .catch((error) => {
                setTimeout(() => {
                  if (retriesLeft === 1) {
                    // maximum retries exceeded
                    toast({
                      title: 'Failed to setup Inputs configuration',
                      description:
                        'Please try again later or contact us for support',
                      status: 'error',
                      position: 'top',
                      duration: 10000,
                      isClosable: true,
                    });
                    reject(error);
                    onADCModalClose();
                    onInputChannelModalClose();
                    return;
                  }
                  retry(fn, retriesLeft - 1, interval).then(resolve, reject);
                }, interval);
              });
          });
        };
        retry(() => getConfig());
      } else {
        toast({
          title: `Failed to update Inputs configuration. Status: ${res.status}`,
          description:
            res.Message || 'Please try again later or contact us for support',
          status: 'error',
          position: 'top',
          duration: 10000,
          isClosable: true,
        });
        onADCModalClose();
        onInputChannelModalClose();
      }
    },
    []
  );

  const handleGrafanaURL = (PANEL) => {
    getGrafanaURL({ PANEL });
  };

  return (
    <Box className="dashboard-module" mt={2}>
      <div className="dashboard-module--heading">
        <h2>Inputs</h2>
      </div>
      {config ? (
        <>
          <Flex className="dashboard-module--adc" mt={8}>
            <Flex className="channel-orders" w={4}>
              <div className="channel">16</div>
              <div className="channel">17</div>
              <div className="channel">19</div>
              <div className="channel">20</div>
            </Flex>
            <Flex className="adc-type" flexDirection="column">
              {showDIFF1 ? (
                <Flex
                  className="adc-type--data"
                  alignItems="center"
                  flexWrap="wrap"
                >
                  <Flex flexDirection="column">
                    <div className="channel-mobile">16</div>
                    <div className="channel-mobile">17</div>
                  </Flex>
                  <Flex
                    className="adc-type--channel"
                    mb={{ sm: 0, md: '23px' }}
                  >
                    <Image
                      className="graph-img"
                      h="55px"
                      objectFit="contain"
                      src="/diff-cons.png"
                      alt="diff"
                      mt="23px"
                    />
                    <Heading
                      as="h2"
                      mt={{ sm: 0, md: '26px' }}
                      ml="3px"
                      size="16px"
                    >
                      <span className="input-mode-diff">DIFF 1</span> :
                      {Object.keys(ADCConfig).length > 0 &&
                      ADCConfig.diff_1.data_collection === 'enabled' ? (
                        <span
                          className={`${
                            updatedChannels.length > 0 &&
                            updatedChannels.includes('diff_1_V')
                              ? 'adc-value-updated'
                              : 'adc-value'
                          }`}
                        >
                          {latestADCData &&
                          latestADCData.diff_1_V !== null &&
                          latestADCData.diff_1_V !== undefined ? (
                            `${latestADCData.diff_1_V.toFixed(4)}V`
                          ) : (
                            <span className="adc-value">--</span>
                          )}
                        </span>
                      ) : (
                        <span className="adc-value">N/A</span>
                      )}
                    </Heading>

                    <Flex mt={{ sm: 0, md: '26px' }} className="adc-status">
                      <Tooltip
                        hasArrow
                        label={
                          Object.keys(ADCConfig).length > 0 &&
                          ADCConfig.diff_1.cloud_storage === 'enabled'
                            ? 'Cloud Storage Enabled'
                            : 'Cloud Storage Disabled'
                        }
                        bg="#242424"
                        color="white"
                      >
                        <Box h={6}>
                          <Icon
                            as={SiIcloud}
                            color={
                              Object.keys(ADCConfig).length > 0 &&
                              ADCConfig.diff_1.cloud_storage === 'enabled'
                                ? '#dc2f5c'
                                : '#6f6f6f'
                            }
                            className="cloud-status"
                            w={6}
                            h={6}
                          />
                        </Box>
                      </Tooltip>
                      <Tooltip
                        hasArrow
                        label="Channel Settings"
                        bg="#242424"
                        color="white"
                      >
                        <Image
                          boxSize="25px"
                          objectFit="contain"
                          src="/gear.png"
                          alt="settings"
                          className="setting-btn"
                          mr={2}
                          ml={2}
                          onClick={() => {
                            setSelectedModalMeasurement({
                              mode: 'Differentials',
                              num: '1',
                            });

                            onADCModalOpen();
                          }}
                        />
                      </Tooltip>
                      <Tooltip
                        hasArrow
                        label="View Graphs"
                        bg="#242424"
                        color="white"
                      >
                        <Image
                          className="chart-btn"
                          boxSize="20px"
                          objectFit="contain"
                          src="/grafana.png"
                          alt="grafana-chart"
                          onClick={() => handleGrafanaURL('ADC.DIFF')}
                        />
                      </Tooltip>
                    </Flex>
                  </Flex>
                </Flex>
              ) : (
                <>
                  <Flex
                    className="adc-type--data"
                    alignItems="center"
                    flexWrap="wrap"
                  >
                    <div className="channel-mobile">16</div>

                    <Flex className="adc-type--channel">
                      {INPUTSSelection && INPUTSSelection.in_1 === 'din' ? (
                        <h2>
                          <span className="channel-number">D-IN 1</span> :
                          {Object.keys(DINConfig).length > 0 &&
                          DINConfig.din_1.data_collection === 'enabled' ? (
                            <span
                              className={`${
                                updatedDINChannels.length > 0 &&
                                updatedDINChannels.includes('digital_in_1')
                                  ? 'adc-value-updated'
                                  : 'adc-value'
                              }`}
                              data-testid="test-din-1"
                            >
                              {Object.keys(latestDINData).length > 0 &&
                              latestDINData.digital_in_1 !== null ? (
                                `${latestDINData.digital_in_1 ? 'HIGH' : 'LOW'}`
                              ) : (
                                <span className="adc-value">--</span>
                              )}
                            </span>
                          ) : (
                            <span className="adc-value">N/A</span>
                          )}
                        </h2>
                      ) : (
                        <h2>
                          <span className="channel-number">A-IN 1</span> :
                          {Object.keys(ADCConfig).length > 0 &&
                          ADCConfig.ch_1.data_collection === 'enabled' ? (
                            <span
                              className={`${
                                updatedChannels.length > 0 &&
                                updatedChannels.includes('ch_1_V')
                                  ? 'adc-value-updated'
                                  : 'adc-value'
                              }`}
                            >
                              {latestADCData &&
                              latestADCData.ch_1_V !== null &&
                              latestADCData.ch_1_V !== undefined ? (
                                `${latestADCData.ch_1_V.toFixed(4)}V`
                              ) : (
                                <span className="adc-value">--</span>
                              )}
                            </span>
                          ) : (
                            <span className="adc-value">N/A</span>
                          )}
                        </h2>
                      )}

                      <Flex className="adc-status">
                        <Tooltip
                          hasArrow
                          label={
                            Object.keys(ADCConfig).length > 0 &&
                            ADCConfig.ch_1.cloud_storage === 'enabled'
                              ? 'Cloud Storage Enabled'
                              : 'Cloud Storage Disabled'
                          }
                          bg="#242424"
                          color="white"
                        >
                          <Box h={6}>
                            <Icon
                              as={SiIcloud}
                              color={
                                Object.keys(ADCConfig).length > 0 &&
                                ADCConfig.ch_1.cloud_storage === 'enabled'
                                  ? '#dc2f5c'
                                  : '#6f6f6f'
                              }
                              className="cloud-status"
                              w={6}
                              h={6}
                            />
                          </Box>
                        </Tooltip>
                        <Tooltip
                          hasArrow
                          label="Channel Settings"
                          bg="#242424"
                          color="white"
                        >
                          <Image
                            boxSize="25px"
                            objectFit="contain"
                            src="/gear.png"
                            alt="settings"
                            className="setting-btn"
                            mr={2}
                            ml={2}
                            onClick={() => {
                              setSelectedChannel('ch_1');
                              onInputChannelModalOpen();
                            }}
                          />
                        </Tooltip>
                        <Tooltip
                          hasArrow
                          label="View Graphs"
                          bg="#242424"
                          color="white"
                        >
                          <Image
                            className="chart-btn"
                            boxSize="20px"
                            objectFit="contain"
                            src="/grafana.png"
                            alt="grafana-chart"
                            onClick={() =>
                              handleGrafanaURL(
                                INPUTSSelection &&
                                  INPUTSSelection.in_1 === 'din'
                                  ? 'DIN'
                                  : 'ADC'
                              )
                            }
                          />
                        </Tooltip>
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex
                    className="adc-type--data"
                    alignItems="center"
                    flexWrap="wrap"
                  >
                    <div className="channel-mobile">17</div>
                    <Flex className="adc-type--channel">
                      {INPUTSSelection && INPUTSSelection.in_2 === 'din' ? (
                        <h2>
                          <span className="channel-number">D-IN 2</span> :
                          {Object.keys(DINConfig).length > 0 &&
                          DINConfig.din_2.data_collection === 'enabled' ? (
                            <span
                              className={`${
                                updatedDINChannels.length > 0 &&
                                updatedDINChannels.includes('digital_in_2')
                                  ? 'adc-value-updated'
                                  : 'adc-value'
                              }`}
                            >
                              {Object.keys(latestDINData).length > 0 &&
                              latestDINData.digital_in_2 !== null ? (
                                `${latestDINData.digital_in_2 ? 'HIGH' : 'LOW'}`
                              ) : (
                                <span className="adc-value">--</span>
                              )}
                            </span>
                          ) : (
                            <span className="adc-value">N/A</span>
                          )}
                        </h2>
                      ) : (
                        <h2>
                          <span className="channel-number">A-IN 2</span> :
                          {Object.keys(ADCConfig).length > 0 &&
                          ADCConfig.ch_2.data_collection === 'enabled' ? (
                            <span
                              className={`${
                                updatedChannels.length > 0 &&
                                updatedChannels.includes('ch_2_V')
                                  ? 'adc-value-updated'
                                  : 'adc-value'
                              }`}
                              data-testid="test-ain-2"
                            >
                              {latestADCData &&
                              latestADCData.ch_2_V !== null &&
                              latestADCData.ch_2_V !== undefined ? (
                                `${latestADCData.ch_2_V.toFixed(4)}V`
                              ) : (
                                <span className="adc-value">--</span>
                              )}
                            </span>
                          ) : (
                            <span className="adc-value">N/A</span>
                          )}
                        </h2>
                      )}

                      <Flex className="adc-status">
                        <Tooltip
                          hasArrow
                          label={
                            Object.keys(ADCConfig).length > 0 &&
                            ADCConfig.ch_2.cloud_storage === 'enabled'
                              ? 'Cloud Storage Enabled'
                              : 'Cloud Storage Disabled'
                          }
                          bg="#242424"
                          color="white"
                        >
                          <Box h={6}>
                            <Icon
                              as={SiIcloud}
                              color={
                                Object.keys(ADCConfig).length > 0 &&
                                ADCConfig.ch_2.cloud_storage === 'enabled'
                                  ? '#dc2f5c'
                                  : '#6f6f6f'
                              }
                              className="cloud-status"
                              w={6}
                              h={6}
                            />
                          </Box>
                        </Tooltip>
                        <Tooltip
                          hasArrow
                          label="Channel Settings"
                          bg="#242424"
                          color="white"
                        >
                          <Image
                            boxSize="25px"
                            objectFit="contain"
                            src="/gear.png"
                            alt="settings"
                            className="setting-btn"
                            mr={2}
                            ml={2}
                            onClick={() => {
                              setSelectedChannel('ch_2');
                              onInputChannelModalOpen();
                            }}
                          />
                        </Tooltip>

                        <Tooltip
                          hasArrow
                          label="View Graphs"
                          bg="#242424"
                          color="white"
                        >
                          <Image
                            className="chart-btn"
                            boxSize="20px"
                            objectFit="contain"
                            src="/grafana.png"
                            alt="grafana-chart"
                            onClick={() =>
                              handleGrafanaURL(
                                INPUTSSelection &&
                                  INPUTSSelection.in_2 === 'din'
                                  ? 'DIN'
                                  : 'ADC'
                              )
                            }
                          />
                        </Tooltip>
                      </Flex>
                    </Flex>
                  </Flex>
                </>
              )}

              {showDIFF2 ? (
                <Flex
                  className="adc-type--data"
                  alignItems="center"
                  flexWrap="wrap"
                >
                  <Flex flexDirection="column">
                    <div className="channel-mobile">19</div>
                    <div className="channel-mobile">20</div>
                  </Flex>
                  <Flex className="adc-type--channel">
                    <Image
                      className="graph-img"
                      h="55px"
                      objectFit="contain"
                      src="/diff-cons.png"
                      alt="diff"
                      mt={22}
                    />

                    <Heading
                      as="h2"
                      mt={{ sm: 0, md: '26px' }}
                      ml="3px"
                      size="16px"
                    >
                      <span className="input-mode-diff">DIFF 2</span> :
                      {Object.keys(ADCConfig).length > 0 &&
                      ADCConfig.diff_2.data_collection === 'enabled' ? (
                        <span
                          className={`${
                            updatedChannels.length > 0 &&
                            updatedChannels.includes('diff_2_V')
                              ? 'adc-value-updated'
                              : 'adc-value'
                          }`}
                          data-testid="test-diff-2"
                        >
                          {latestADCData &&
                          latestADCData.diff_2_V !== null &&
                          latestADCData.diff_2_V !== undefined ? (
                            `${latestADCData.diff_2_V.toFixed(4)}V`
                          ) : (
                            <span className="adc-value">--</span>
                          )}
                        </span>
                      ) : (
                        <span className="adc-value">N/A</span>
                      )}
                    </Heading>
                    <Flex mt={{ sm: 0, md: '26px' }} className="adc-status">
                      <Tooltip
                        hasArrow
                        label={
                          Object.keys(ADCConfig).length > 0 &&
                          ADCConfig.diff_2.cloud_storage === 'enabled'
                            ? 'Cloud Storage Enabled'
                            : 'Cloud Storage Disabled'
                        }
                        bg="#242424"
                        color="white"
                      >
                        <Box h={6}>
                          <Icon
                            as={SiIcloud}
                            color={
                              Object.keys(ADCConfig).length > 0 &&
                              ADCConfig.diff_2.cloud_storage === 'enabled'
                                ? '#dc2f5c'
                                : '#6f6f6f'
                            }
                            className="cloud-status"
                            w={6}
                            h={6}
                          />
                        </Box>
                      </Tooltip>
                      <Tooltip
                        hasArrow
                        label="Channel Settings"
                        bg="#242424"
                        color="white"
                      >
                        <Image
                          boxSize="25px"
                          objectFit="contain"
                          src="/gear.png"
                          alt="settings"
                          className="setting-btn"
                          mr={2}
                          ml={2}
                          onClick={() => {
                            setSelectedModalMeasurement({
                              mode: 'Differentials',
                              num: '2',
                            });
                            onADCModalOpen();
                          }}
                        />
                      </Tooltip>
                      <Tooltip
                        hasArrow
                        label="View Graphs"
                        bg="#242424"
                        color="white"
                      >
                        <Image
                          className="chart-btn"
                          boxSize="20px"
                          objectFit="contain"
                          src="/grafana.png"
                          alt="grafana-chart"
                          onClick={() => handleGrafanaURL('ADC.DIFF')}
                        />
                      </Tooltip>
                    </Flex>
                  </Flex>
                </Flex>
              ) : (
                <>
                  <Flex
                    className="adc-type--data"
                    alignItems="center"
                    flexWrap="wrap"
                  >
                    <div className="channel-mobile">19</div>
                    <Flex className="adc-type--channel">
                      {INPUTSSelection && INPUTSSelection.in_3 === 'din' ? (
                        <h2>
                          <span className="channel-number">D-IN 3</span> :
                          {Object.keys(DINConfig).length > 0 &&
                          DINConfig.din_3.data_collection === 'enabled' ? (
                            <span
                              className={`${
                                updatedDINChannels.length > 0 &&
                                updatedDINChannels.includes('digital_in_3')
                                  ? 'adc-value-updated'
                                  : 'adc-value'
                              }`}
                            >
                              {Object.keys(latestDINData).length > 0 &&
                              latestDINData.digital_in_3 !== null ? (
                                `${latestDINData.digital_in_3 ? 'HIGH' : 'LOW'}`
                              ) : (
                                <span className="adc-value">--</span>
                              )}
                            </span>
                          ) : (
                            <span className="adc-value">N/A</span>
                          )}
                        </h2>
                      ) : (
                        <h2>
                          <span className="channel-number">A-IN 3</span> :
                          {Object.keys(ADCConfig).length > 0 &&
                          ADCConfig.ch_3.data_collection === 'enabled' ? (
                            <span
                              className={`${
                                updatedChannels.length > 0 &&
                                updatedChannels.includes('ch_3_V')
                                  ? 'adc-value-updated'
                                  : 'adc-value'
                              }`}
                            >
                              {latestADCData &&
                              latestADCData.ch_3_V !== null &&
                              latestADCData.ch_3_V !== undefined ? (
                                `${latestADCData.ch_3_V.toFixed(4)}V`
                              ) : (
                                <span className="adc-value">--</span>
                              )}
                            </span>
                          ) : (
                            <span className="adc-value">N/A</span>
                          )}
                        </h2>
                      )}

                      <Flex className="adc-status">
                        <Tooltip
                          hasArrow
                          label={
                            Object.keys(ADCConfig).length > 0 &&
                            ADCConfig.ch_3.cloud_storage === 'enabled'
                              ? 'Cloud Storage Enabled'
                              : 'Cloud Storage Disabled'
                          }
                          bg="#242424"
                          color="white"
                        >
                          <Box h={6}>
                            <Icon
                              as={SiIcloud}
                              color={
                                Object.keys(ADCConfig).length > 0 &&
                                ADCConfig.ch_3.cloud_storage === 'enabled'
                                  ? '#dc2f5c'
                                  : '#6f6f6f'
                              }
                              className="cloud-status"
                              w={6}
                              h={6}
                            />
                          </Box>
                        </Tooltip>
                        <Tooltip
                          hasArrow
                          label="Channel Settings"
                          bg="#242424"
                          color="white"
                        >
                          <Image
                            boxSize="25px"
                            objectFit="contain"
                            src="/gear.png"
                            alt="settings"
                            className="setting-btn"
                            mr={2}
                            ml={2}
                            onClick={() => {
                              setSelectedChannel('ch_3');
                              onInputChannelModalOpen();
                            }}
                          />
                        </Tooltip>
                        <Tooltip
                          hasArrow
                          label="View Graphs"
                          bg="#242424"
                          color="white"
                        >
                          <Image
                            className="chart-btn"
                            boxSize="20px"
                            objectFit="contain"
                            src="/grafana.png"
                            alt="grafana-chart"
                            onClick={() =>
                              handleGrafanaURL(
                                INPUTSSelection &&
                                  INPUTSSelection.in_3 === 'din'
                                  ? 'DIN'
                                  : 'ADC'
                              )
                            }
                          />
                        </Tooltip>
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex
                    className="adc-type--data"
                    alignItems="center"
                    flexWrap="wrap"
                  >
                    <div className="channel-mobile">20</div>
                    <Flex className="adc-type--channel">
                      {INPUTSSelection && INPUTSSelection.in_4 === 'din' ? (
                        <h2>
                          <span className="channel-number">D-IN 4</span> :
                          {Object.keys(DINConfig).length > 0 &&
                          DINConfig.din_4.data_collection === 'enabled' ? (
                            <span
                              className={`${
                                updatedDINChannels.length > 0 &&
                                updatedDINChannels.includes('digital_in_4')
                                  ? 'adc-value-updated'
                                  : 'adc-value'
                              }`}
                            >
                              {Object.keys(latestDINData).length > 0 &&
                              latestDINData.digital_in_4 !== null ? (
                                `${latestDINData.digital_in_4 ? 'HIGH' : 'LOW'}`
                              ) : (
                                <span className="adc-value">--</span>
                              )}
                            </span>
                          ) : (
                            <span className="adc-value">N/A</span>
                          )}
                        </h2>
                      ) : (
                        <h2>
                          <span className="channel-number">A-IN 4</span> :
                          {Object.keys(ADCConfig).length > 0 &&
                          ADCConfig.ch_4.data_collection === 'enabled' ? (
                            <span
                              className={`${
                                updatedChannels.length > 0 &&
                                updatedChannels.includes('ch_4_V')
                                  ? 'adc-value-updated'
                                  : 'adc-value'
                              }`}
                            >
                              {latestADCData &&
                              latestADCData.ch_4_V !== null &&
                              latestADCData.ch_4_V !== undefined ? (
                                `${latestADCData.ch_4_V.toFixed(4)}V`
                              ) : (
                                <span className="adc-value">--</span>
                              )}
                            </span>
                          ) : (
                            <span className="adc-value">N/A</span>
                          )}
                        </h2>
                      )}

                      <Flex className="adc-status">
                        <Tooltip
                          hasArrow
                          label={
                            Object.keys(ADCConfig).length > 0 &&
                            ADCConfig.ch_4.cloud_storage === 'enabled'
                              ? 'Cloud Storage Enabled'
                              : 'Cloud Storage Disabled'
                          }
                          bg="#242424"
                          color="white"
                        >
                          <Box h={6}>
                            <Icon
                              as={SiIcloud}
                              color={
                                Object.keys(ADCConfig).length > 0 &&
                                config.ch_4.cloud_storage === 'enabled'
                                  ? '#dc2f5c'
                                  : '#6f6f6f'
                              }
                              className="cloud-status"
                              w={6}
                              h={6}
                            />
                          </Box>
                        </Tooltip>
                        <Tooltip
                          hasArrow
                          label="Channel Settings"
                          bg="#242424"
                          color="white"
                        >
                          <Image
                            boxSize="25px"
                            objectFit="contain"
                            src="/gear.png"
                            alt="settings"
                            className="setting-btn"
                            mr={2}
                            ml={2}
                            onClick={() => {
                              setSelectedChannel('ch_4');
                              onInputChannelModalOpen();
                            }}
                          />
                        </Tooltip>
                        <Tooltip
                          hasArrow
                          label="View Graphs"
                          bg="#242424"
                          color="white"
                        >
                          <Image
                            className="chart-btn"
                            boxSize="20px"
                            objectFit="contain"
                            src="/grafana.png"
                            alt="grafana-chart"
                            onClick={() =>
                              handleGrafanaURL(
                                INPUTSSelection &&
                                  INPUTSSelection.in_4 === 'din'
                                  ? 'DIN'
                                  : 'ADC'
                              )
                            }
                          />
                        </Tooltip>
                      </Flex>
                    </Flex>
                  </Flex>
                </>
              )}
            </Flex>
          </Flex>

          <Flex className="dashboard-module--adc">
            <Flex className="channel-orders" w={4}>
              <div className="channel">21</div>
              <div className={`channel ${showRTD && 'channel-deactivated'}`}>
                22
              </div>
              <div className="channel">24</div>
              <div className="channel">25</div>
            </Flex>
            <Flex className="adc-type" flexDirection="column">
              {showRTD ? (
                <>
                  <Flex
                    className="adc-type--data"
                    alignItems="center"
                    flexWrap="wrap"
                  >
                    <Flex flexDirection="column">
                      <div className="channel-mobile">21</div>
                      <div className="channel-mobile channel-mobile-deactivated">
                        22
                      </div>
                      <div className="channel-mobile">24</div>
                      <div className="channel-mobile">25</div>
                    </Flex>
                    <Flex className="adc-type--channel" alignItems="center">
                      <Flex alignItems="center">
                        <Image
                          className="graph-img"
                          h="170px"
                          objectFit="fill"
                          src="/rtd-cons.png"
                          alt="rtd"
                          mt="10px"
                        />
                        <Heading as="h2" mt={{ sm: 0, md: '35px' }} size="16px">
                          <span className="input-mode-rtd">RTD</span> :
                          {Object.keys(ADCConfig).length > 0 &&
                          ADCConfig.rtd.data_collection === 'enabled' ? (
                            <span
                              className={`${
                                updatedChannels.length > 0 &&
                                updatedChannels.includes('rtd_C')
                                  ? 'adc-value-updated'
                                  : 'adc-value'
                              }`}
                              data-testid="test-rtd"
                            >
                              {latestADCData &&
                              latestADCData.rtd_C !== null &&
                              latestADCData.rtd_C !== undefined ? (
                                latestADCData.rtd_C > -273.15 &&
                                latestADCData.rtd_C < 2000 ? (
                                  `${latestADCData.rtd_C.toFixed(4)}°C `
                                ) : (
                                  `No Probe`
                                )
                              ) : (
                                <span className="adc-value">--</span>
                              )}
                            </span>
                          ) : (
                            <span className="adc-value">N/A</span>
                          )}
                        </Heading>
                      </Flex>
                      <Box className="adc-status" mt={{ sm: 0, md: 8 }}>
                        <Tooltip
                          hasArrow
                          label={
                            Object.keys(ADCConfig).length > 0 &&
                            ADCConfig.rtd.cloud_storage === 'enabled'
                              ? 'Cloud Storage Enabled'
                              : 'Cloud Storage Disabled'
                          }
                          bg="#242424"
                          color="white"
                        >
                          <Box h={6}>
                            <Icon
                              as={SiIcloud}
                              color={
                                Object.keys(ADCConfig).length > 0 &&
                                ADCConfig.rtd.cloud_storage === 'enabled'
                                  ? '#dc2f5c'
                                  : '#6f6f6f'
                              }
                              className="cloud-status"
                              w={6}
                              h={6}
                            />
                          </Box>
                        </Tooltip>
                        <Tooltip
                          hasArrow
                          label="Channel Settings"
                          bg="#242424"
                          color="white"
                        >
                          <Image
                            boxSize="25px"
                            objectFit="contain"
                            src="/gear.png"
                            alt="settings"
                            className="setting-btn"
                            mr={2}
                            ml={2}
                            onClick={() => {
                              setSelectedModalMeasurement({
                                mode: 'RTD',
                              });
                              onADCModalOpen();
                            }}
                          />
                        </Tooltip>
                        <Tooltip
                          hasArrow
                          label="View Graphs"
                          bg="#242424"
                          color="white"
                        >
                          <Image
                            className="chart-btn"
                            boxSize="20px"
                            objectFit="contain"
                            src="/grafana.png"
                            alt="grafana-chart"
                            onClick={() => handleGrafanaURL('ADC.RTD')}
                          />
                        </Tooltip>
                      </Box>
                    </Flex>
                  </Flex>
                </>
              ) : (
                <>
                  {showDIFF3 ? (
                    <Flex
                      className="adc-type--data"
                      alignItems="center"
                      flexWrap="wrap"
                    >
                      <Flex flexDirection="column">
                        <div className="channel-mobile">21</div>
                        <div className="channel-mobile">22</div>
                      </Flex>
                      <Flex
                        className="adc-type--channel"
                        pt={{ sm: 0, md: '23px' }}
                        mb={{ sm: 0, md: '23px' }}
                      >
                        <Image
                          className="graph-img"
                          h="55px"
                          objectFit="contain"
                          src="/diff-cons.png"
                          alt="diff"
                        />
                        <Heading
                          as="h2"
                          mt={{ sm: 0, md: '4px' }}
                          ml="3px"
                          size="16px"
                        >
                          <span className="input-mode-diff">DIFF 3</span> :
                          {Object.keys(ADCConfig).length > 0 &&
                          ADCConfig.diff_3.data_collection === 'enabled' ? (
                            <span
                              className={`${
                                updatedChannels.length > 0 &&
                                updatedChannels.includes('diff_3_V')
                                  ? 'adc-value-updated'
                                  : 'adc-value'
                              }`}
                            >
                              {latestADCData &&
                              latestADCData.diff_3_V !== null &&
                              latestADCData.diff_3_V !== undefined ? (
                                `${latestADCData.diff_3_V.toFixed(4)}V`
                              ) : (
                                <span className="adc-value">--</span>
                              )}
                            </span>
                          ) : (
                            <span className="adc-value">N/A</span>
                          )}
                        </Heading>

                        <Flex className="adc-status">
                          <Tooltip
                            hasArrow
                            label={
                              Object.keys(ADCConfig).length > 0 &&
                              ADCConfig.diff_3.cloud_storage === 'enabled'
                                ? 'Cloud Storage Enabled'
                                : 'Cloud Storage Disabled'
                            }
                            bg="#242424"
                            color="white"
                          >
                            <Box h={6}>
                              <Icon
                                as={SiIcloud}
                                color={
                                  Object.keys(ADCConfig).length > 0 &&
                                  ADCConfig.diff_3.cloud_storage === 'enabled'
                                    ? '#dc2f5c'
                                    : '#6f6f6f'
                                }
                                className="cloud-status"
                                w={6}
                                h={6}
                              />
                            </Box>
                          </Tooltip>
                          <Tooltip
                            hasArrow
                            label="Channel Settings"
                            bg="#242424"
                            color="white"
                          >
                            <Image
                              boxSize="25px"
                              objectFit="contain"
                              src="/gear.png"
                              alt="settings"
                              className="setting-btn"
                              mr={2}
                              ml={2}
                              onClick={() => {
                                setSelectedModalMeasurement({
                                  mode: 'Differentials',
                                  num: '3',
                                });
                                onADCModalOpen();
                              }}
                            />
                          </Tooltip>
                          <Tooltip
                            hasArrow
                            label="View Graphs"
                            bg="#242424"
                            color="white"
                          >
                            <Image
                              className="chart-btn"
                              boxSize="20px"
                              objectFit="contain"
                              src="/grafana.png"
                              alt="grafana-chart"
                              onClick={() => handleGrafanaURL('ADC.DIFF')}
                            />
                          </Tooltip>
                        </Flex>
                      </Flex>
                    </Flex>
                  ) : (
                    <>
                      <Flex
                        className="adc-type--data"
                        alignItems="center"
                        flexWrap="wrap"
                      >
                        <div className="channel-mobile">21</div>
                        <Flex className="adc-type--channel">
                          {INPUTSSelection && INPUTSSelection.in_5 === 'din' ? (
                            <h2>
                              <span className="channel-number">D-IN 5</span> :
                              {Object.keys(DINConfig).length > 0 &&
                              DINConfig.din_5.data_collection === 'enabled' ? (
                                <span
                                  className={`${
                                    updatedDINChannels.length > 0 &&
                                    updatedDINChannels.includes('digital_in_5')
                                      ? 'adc-value-updated'
                                      : 'adc-value'
                                  }`}
                                >
                                  {Object.keys(latestDINData).length > 0 &&
                                  latestDINData.digital_in_5 !== null ? (
                                    `${
                                      latestDINData.digital_in_5
                                        ? 'HIGH'
                                        : 'LOW'
                                    }`
                                  ) : (
                                    <span className="adc-value">--</span>
                                  )}
                                </span>
                              ) : (
                                <span className="adc-value">N/A</span>
                              )}
                            </h2>
                          ) : (
                            <h2>
                              <span className="channel-number">A-IN 5</span> :
                              {Object.keys(ADCConfig).length > 0 &&
                              ADCConfig.ch_5.data_collection === 'enabled' ? (
                                <span
                                  className={`${
                                    updatedChannels.length > 0 &&
                                    updatedChannels.includes('ch_5_V')
                                      ? 'adc-value-updated'
                                      : 'adc-value'
                                  }`}
                                >
                                  {latestADCData &&
                                  latestADCData.ch_5_V !== null &&
                                  latestADCData.ch_5_V !== undefined ? (
                                    `${latestADCData.ch_5_V.toFixed(4)}V`
                                  ) : (
                                    <span className="adc-value">--</span>
                                  )}
                                </span>
                              ) : (
                                <span className="adc-value">N/A</span>
                              )}
                            </h2>
                          )}

                          <Flex className="adc-status">
                            <Tooltip
                              hasArrow
                              label={
                                Object.keys(ADCConfig).length > 0 &&
                                ADCConfig.ch_5.cloud_storage === 'enabled'
                                  ? 'Cloud Storage Enabled'
                                  : 'Cloud Storage Disabled'
                              }
                              bg="#242424"
                              color="white"
                            >
                              <Box h={6}>
                                <Icon
                                  as={SiIcloud}
                                  color={
                                    Object.keys(ADCConfig).length > 0 &&
                                    ADCConfig.ch_5.cloud_storage === 'enabled'
                                      ? '#dc2f5c'
                                      : '#6f6f6f'
                                  }
                                  className="cloud-status"
                                  w={6}
                                  h={6}
                                />
                              </Box>
                            </Tooltip>
                            <Tooltip
                              hasArrow
                              label="Channel Settings"
                              bg="#242424"
                              color="white"
                            >
                              <Image
                                boxSize="25px"
                                objectFit="contain"
                                src="/gear.png"
                                alt="settings"
                                className="setting-btn"
                                mr={2}
                                ml={2}
                                onClick={() => {
                                  setSelectedChannel('ch_5');
                                  onInputChannelModalOpen();
                                }}
                              />
                            </Tooltip>
                            <Tooltip
                              hasArrow
                              label="View Graphs"
                              bg="#242424"
                              color="white"
                            >
                              <Image
                                className="chart-btn"
                                boxSize="20px"
                                objectFit="contain"
                                src="/grafana.png"
                                alt="grafana-chart"
                                onClick={() =>
                                  handleGrafanaURL(
                                    INPUTSSelection &&
                                      INPUTSSelection.in_5 === 'din'
                                      ? 'DIN'
                                      : 'ADC'
                                  )
                                }
                              />
                            </Tooltip>
                          </Flex>
                        </Flex>
                      </Flex>
                      <Flex
                        className="adc-type--data"
                        alignItems="center"
                        flexWrap="wrap"
                      >
                        <div className="channel-mobile">22</div>
                        <Flex className="adc-type--channel">
                          {INPUTSSelection && INPUTSSelection.in_6 === 'din' ? (
                            <h2>
                              <span className="channel-number">D-IN 6</span> :
                              {Object.keys(DINConfig).length > 0 &&
                              DINConfig.din_6.data_collection === 'enabled' ? (
                                <span
                                  className={`${
                                    updatedDINChannels.length > 0 &&
                                    updatedDINChannels.includes('digital_in_6')
                                      ? 'adc-value-updated'
                                      : 'adc-value'
                                  }`}
                                >
                                  {Object.keys(latestDINData).length > 0 &&
                                  latestDINData.digital_in_6 !== null ? (
                                    `${
                                      latestDINData.digital_in_6
                                        ? 'HIGH'
                                        : 'LOW'
                                    }`
                                  ) : (
                                    <span className="adc-value">--</span>
                                  )}
                                </span>
                              ) : (
                                <span className="adc-value">N/A</span>
                              )}
                            </h2>
                          ) : (
                            <h2>
                              <span className="channel-number">A-IN 6</span> :
                              {Object.keys(ADCConfig).length > 0 &&
                              ADCConfig.ch_6.data_collection === 'enabled' ? (
                                <span
                                  className={`${
                                    updatedChannels.length > 0 &&
                                    updatedChannels.includes('ch_6_V')
                                      ? 'adc-value-updated'
                                      : 'adc-value'
                                  }`}
                                >
                                  {latestADCData &&
                                  latestADCData.ch_6_V !== null &&
                                  latestADCData.ch_6_V !== undefined ? (
                                    `${latestADCData.ch_6_V.toFixed(4)}V`
                                  ) : (
                                    <span className="adc-value">--</span>
                                  )}
                                </span>
                              ) : (
                                <span className="adc-value">N/A</span>
                              )}
                            </h2>
                          )}

                          <Flex className="adc-status">
                            <Tooltip
                              hasArrow
                              label={
                                Object.keys(ADCConfig).length > 0 &&
                                ADCConfig.ch_6.cloud_storage === 'enabled'
                                  ? 'Cloud Storage Enabled'
                                  : 'Cloud Storage Disabled'
                              }
                              bg="#242424"
                              color="white"
                            >
                              <Box h={6}>
                                <Icon
                                  as={SiIcloud}
                                  color={
                                    Object.keys(ADCConfig).length > 0 &&
                                    ADCConfig.ch_6.cloud_storage === 'enabled'
                                      ? '#dc2f5c'
                                      : '#6f6f6f'
                                  }
                                  className="cloud-status"
                                  w={6}
                                  h={6}
                                />
                              </Box>
                            </Tooltip>
                            <Tooltip
                              hasArrow
                              label="Channel Settings"
                              bg="#242424"
                              color="white"
                            >
                              <Image
                                boxSize="25px"
                                objectFit="contain"
                                src="/gear.png"
                                alt="settings"
                                className="setting-btn"
                                mr={2}
                                ml={2}
                                onClick={() => {
                                  setSelectedChannel('ch_6');
                                  onInputChannelModalOpen();
                                }}
                              />
                            </Tooltip>
                            <Tooltip
                              hasArrow
                              label="View Graphs"
                              bg="#242424"
                              color="white"
                            >
                              <Image
                                className="chart-btn"
                                boxSize="20px"
                                objectFit="contain"
                                src="/grafana.png"
                                alt="grafana-chart"
                                onClick={() =>
                                  handleGrafanaURL(
                                    INPUTSSelection &&
                                      INPUTSSelection.in_6 === 'din'
                                      ? 'DIN'
                                      : 'ADC'
                                  )
                                }
                              />
                            </Tooltip>
                          </Flex>
                        </Flex>
                      </Flex>
                    </>
                  )}
                  {showDIFF4 ? (
                    <Flex
                      className="adc-type--data"
                      alignItems="center"
                      flexWrap="wrap"
                    >
                      <Flex flexDirection="column">
                        <div className="channel-mobile">24</div>
                        <div className="channel-mobile">25</div>
                      </Flex>
                      <Flex
                        className="adc-type--channel"
                        pt={{ sm: 0, md: '23px' }}
                      >
                        <Image
                          className="graph-img"
                          h="55px"
                          objectFit="contain"
                          src="/diff-cons.png"
                          alt="diff"
                        />
                        <Heading as="h2" mt="4px" ml="3px" size="16px">
                          <span className="input-mode-diff">DIFF 4</span> :
                          {Object.keys(ADCConfig).length > 0 &&
                          ADCConfig.diff_4.data_collection === 'enabled' ? (
                            <span
                              className={`${
                                updatedChannels.length > 0 &&
                                updatedChannels.includes('diff_4_V')
                                  ? 'adc-value-updated'
                                  : 'adc-value'
                              }`}
                            >
                              {latestADCData &&
                              latestADCData.diff_4_V !== null &&
                              latestADCData.diff_4_V !== undefined ? (
                                `${latestADCData.diff_4_V.toFixed(4)}V`
                              ) : (
                                <span className="adc-value">--</span>
                              )}
                            </span>
                          ) : (
                            <span className="adc-value">N/A</span>
                          )}
                        </Heading>

                        <Flex className="adc-status">
                          <Tooltip
                            hasArrow
                            label={
                              Object.keys(ADCConfig).length > 0 &&
                              ADCConfig.diff_4.cloud_storage === 'enabled'
                                ? 'Cloud Storage Enabled'
                                : 'Cloud Storage Disabled'
                            }
                            bg="#242424"
                            color="white"
                          >
                            <Box h={6}>
                              <Icon
                                as={SiIcloud}
                                color={
                                  Object.keys(ADCConfig).length > 0 &&
                                  ADCConfig.diff_4.cloud_storage === 'enabled'
                                    ? '#dc2f5c'
                                    : '#6f6f6f'
                                }
                                className="cloud-status"
                                w={6}
                                h={6}
                              />
                            </Box>
                          </Tooltip>
                          <Tooltip
                            hasArrow
                            label="Channel Settings"
                            bg="#242424"
                            color="white"
                          >
                            <Image
                              boxSize="25px"
                              objectFit="contain"
                              src="/gear.png"
                              alt="settings"
                              className="setting-btn"
                              mr={2}
                              ml={2}
                              onClick={() => {
                                setSelectedModalMeasurement({
                                  mode: 'Differentials',
                                  num: '4',
                                });
                                onADCModalOpen();
                              }}
                            />
                          </Tooltip>
                          <Tooltip
                            hasArrow
                            label="View Graphs"
                            bg="#242424"
                            color="white"
                          >
                            <Image
                              className="chart-btn"
                              boxSize="20px"
                              objectFit="contain"
                              src="/grafana.png"
                              alt="grafana-chart"
                              onClick={() => handleGrafanaURL('ADC.DIFF')}
                            />
                          </Tooltip>
                        </Flex>
                      </Flex>
                    </Flex>
                  ) : (
                    <>
                      <Flex
                        className="adc-type--data"
                        alignItems="center"
                        flexWrap="wrap"
                      >
                        <div className="channel-mobile">24</div>
                        <Flex className="adc-type--channel">
                          {INPUTSSelection && INPUTSSelection.in_7 === 'din' ? (
                            <h2>
                              <span className="channel-number">D-IN 7</span> :
                              {Object.keys(DINConfig).length > 0 &&
                              DINConfig.din_7.data_collection === 'enabled' ? (
                                <span
                                  className={`${
                                    updatedDINChannels.length > 0 &&
                                    updatedDINChannels.includes('digital_in_7')
                                      ? 'adc-value-updated'
                                      : 'adc-value'
                                  }`}
                                >
                                  {Object.keys(latestDINData).length > 0 &&
                                  latestDINData.digital_in_7 !== null ? (
                                    `${
                                      latestDINData.digital_in_7
                                        ? 'HIGH'
                                        : 'LOW'
                                    }`
                                  ) : (
                                    <span className="adc-value">--</span>
                                  )}
                                </span>
                              ) : (
                                <span className="adc-value">N/A</span>
                              )}
                            </h2>
                          ) : (
                            <h2>
                              <span className="channel-number">A-IN 7</span> :
                              {Object.keys(ADCConfig).length > 0 &&
                              ADCConfig.ch_7.data_collection === 'enabled' ? (
                                <span
                                  className={`${
                                    updatedChannels.length > 0 &&
                                    updatedChannels.includes('ch_7_V')
                                      ? 'adc-value-updated'
                                      : 'adc-value'
                                  }`}
                                >
                                  {latestADCData &&
                                  latestADCData.ch_7_V !== null &&
                                  latestADCData.ch_7_V !== undefined ? (
                                    `${latestADCData.ch_7_V.toFixed(4)}V`
                                  ) : (
                                    <span className="adc-value">--</span>
                                  )}
                                </span>
                              ) : (
                                <span className="adc-value">N/A</span>
                              )}
                            </h2>
                          )}

                          <Flex className="adc-status">
                            <Tooltip
                              hasArrow
                              label={
                                Object.keys(ADCConfig).length > 0 &&
                                ADCConfig.ch_7.cloud_storage === 'enabled'
                                  ? 'Cloud Storage Enabled'
                                  : 'Cloud Storage Disabled'
                              }
                              bg="#242424"
                              color="white"
                            >
                              <Box h={6}>
                                <Icon
                                  as={SiIcloud}
                                  color={
                                    Object.keys(ADCConfig).length > 0 &&
                                    ADCConfig.ch_7.cloud_storage === 'enabled'
                                      ? '#dc2f5c'
                                      : '#6f6f6f'
                                  }
                                  className="cloud-status"
                                  w={6}
                                  h={6}
                                />
                              </Box>
                            </Tooltip>
                            <Tooltip
                              hasArrow
                              label="Channel Settings"
                              bg="#242424"
                              color="white"
                            >
                              <Image
                                boxSize="25px"
                                objectFit="contain"
                                src="/gear.png"
                                alt="settings"
                                className="setting-btn"
                                mr={2}
                                ml={2}
                                onClick={() => {
                                  setSelectedChannel('ch_7');
                                  onInputChannelModalOpen();
                                }}
                              />
                            </Tooltip>
                            <Tooltip
                              hasArrow
                              label="View Graphs"
                              bg="#242424"
                              color="white"
                            >
                              <Image
                                className="chart-btn"
                                boxSize="20px"
                                objectFit="contain"
                                src="/grafana.png"
                                alt="grafana-chart"
                                onClick={() =>
                                  handleGrafanaURL(
                                    INPUTSSelection &&
                                      INPUTSSelection.in_7 === 'din'
                                      ? 'DIN'
                                      : 'ADC'
                                  )
                                }
                              />
                            </Tooltip>
                          </Flex>
                        </Flex>
                      </Flex>
                      <Flex
                        className="adc-type--data"
                        alignItems="center"
                        flexWrap="wrap"
                      >
                        <div className="channel-mobile">25</div>
                        <Flex className="adc-type--channel">
                          {INPUTSSelection && INPUTSSelection.in_8 === 'din' ? (
                            <h2>
                              <span className="channel-number">D-IN 8</span> :
                              {Object.keys(DINConfig).length > 0 &&
                              DINConfig.din_8.data_collection === 'enabled' ? (
                                <span
                                  className={`${
                                    updatedDINChannels.length > 0 &&
                                    updatedDINChannels.includes('digital_in_8')
                                      ? 'adc-value-updated'
                                      : 'adc-value'
                                  }`}
                                >
                                  {Object.keys(latestDINData).length > 0 &&
                                  latestDINData.digital_in_8 !== null ? (
                                    `${
                                      latestDINData.digital_in_8
                                        ? 'HIGH'
                                        : 'LOW'
                                    }`
                                  ) : (
                                    <span className="adc-value">--</span>
                                  )}
                                </span>
                              ) : (
                                <span className="adc-value">N/A</span>
                              )}
                            </h2>
                          ) : (
                            <h2>
                              <span className="channel-number">A-IN 8</span> :
                              {Object.keys(ADCConfig).length > 0 &&
                              ADCConfig.ch_8.data_collection === 'enabled' ? (
                                <span
                                  className={`${
                                    updatedChannels.length > 0 &&
                                    updatedChannels.includes('ch_8_V')
                                      ? 'adc-value-updated'
                                      : 'adc-value'
                                  }`}
                                >
                                  {latestADCData &&
                                  latestADCData.ch_8_V !== null &&
                                  latestADCData.ch_8_V !== undefined ? (
                                    `${latestADCData.ch_8_V.toFixed(4)}V`
                                  ) : (
                                    <span className="adc-value">--</span>
                                  )}
                                </span>
                              ) : (
                                <span className="adc-value">N/A</span>
                              )}
                            </h2>
                          )}

                          <Flex className="adc-status">
                            <Tooltip
                              hasArrow
                              label={
                                Object.keys(ADCConfig).length > 0 &&
                                ADCConfig.ch_8.cloud_storage === 'enabled'
                                  ? 'Cloud Storage Enabled'
                                  : 'Cloud Storage Disabled'
                              }
                              bg="#242424"
                              color="white"
                            >
                              <Box h={6}>
                                <Icon
                                  as={SiIcloud}
                                  color={
                                    Object.keys(ADCConfig).length > 0 &&
                                    ADCConfig.ch_8.cloud_storage === 'enabled'
                                      ? '#dc2f5c'
                                      : '#6f6f6f'
                                  }
                                  className="cloud-status"
                                  w={6}
                                  h={6}
                                />
                              </Box>
                            </Tooltip>
                            <Tooltip
                              hasArrow
                              label="Channel Settings"
                              bg="#242424"
                              color="white"
                            >
                              <Image
                                boxSize="25px"
                                objectFit="contain"
                                src="/gear.png"
                                alt="settings"
                                className="setting-btn"
                                mr={2}
                                ml={2}
                                onClick={() => {
                                  setSelectedChannel('ch_8');
                                  onInputChannelModalOpen();
                                }}
                              />
                            </Tooltip>
                            <Tooltip
                              hasArrow
                              label="View Graphs"
                              bg="#242424"
                              color="white"
                            >
                              <Image
                                className="chart-btn"
                                boxSize="20px"
                                objectFit="contain"
                                src="/grafana.png"
                                alt="grafana-chart"
                                onClick={() =>
                                  handleGrafanaURL(
                                    INPUTSSelection &&
                                      INPUTSSelection.in_8 === 'din'
                                      ? 'DIN'
                                      : 'ADC'
                                  )
                                }
                              />
                            </Tooltip>
                          </Flex>
                        </Flex>
                      </Flex>
                    </>
                  )}
                </>
              )}
            </Flex>
          </Flex>

          <Flex className="dashboard-module--footer" w="100%">
            <Flex p={3} w="100%" alignItems="center" justifyContent="flex-end">
              <Tooltip
                hasArrow
                label="Module Settings"
                bg="#242424"
                color="white"
              >
                <Image
                  boxSize="30px"
                  objectFit="contain"
                  src="/gear.png"
                  alt="settings"
                  className="setting-btn"
                  onClick={() => {
                    onADCModalOpen();
                  }}
                />
              </Tooltip>
            </Flex>
          </Flex>
        </>
      ) : (
        <Flex className="dashboard-module--na" mt={8}>
          <h4>Module is not available</h4>
        </Flex>
      )}

      <ADCModal
        isOpen={isADCModalOpen}
        handleUpdateModuleConfig={handleUpdateConfig}
        onClose={onADCModalClose}
        ADC={config}
        DIN={DINConfig || ''}
        INPUTS={INPUTSSelection || ''}
        selectedModalMeasurement={selectedModalMeasurement}
      />

      <InputChannelModal
        isOpen={isInputChannelModalOpen}
        onClose={onInputChannelModalClose}
        selectedChannel={selectedChannel}
        ADC={config}
        DIN={DINConfig || ''}
        INPUTS={INPUTSSelection || ''}
        handleUpdateChannelConfig={handleUpdateConfig}
      />
    </Box>
  );
};

export default memo(Inputs);
