import React from 'react';
import { Box, Flex, Container } from '@chakra-ui/react';
import { ReactComponent as ErrorIcon } from '../../../assets/cloud-error.svg';

import '../error.styles.scss';

const Page404 = () => {
  return (
    <Box p={4} className="page-404">
      <Container maxW="full" p={0} className="container-404">
        <div className="error-icon">
          <ErrorIcon />
        </div>

        <Flex
          className="error-detail"
          flexDirection="column"
          alignItems="center"
        >
          <h1>OOPS...</h1>
          <h3>We can not find that page...</h3>
        </Flex>
      </Container>
    </Box>
  );
};

export default Page404;
