import React, { useEffect, useState, useRef, memo } from 'react';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
} from '@chakra-ui/react';
import CustomSwitch from '../../../../../components/custom-switch/custom-switch.component';

const InputChannelModal = ({
  isOpen,
  onClose,
  ADC,
  DIN,
  INPUTS,
  selectedChannel,
  handleUpdateChannelConfig,
}) => {
  const [ADCConfig, setADCConfig] = useState({}); // ADC
  const [INPUTSSelection, setINPUTSSelection] = useState({}); // INPUTS
  const [DINConfig, setDINConfig] = useState({}); // DIN
  const [selectedOM, setSelectedOM] = useState('');
  const [isButtonLoading, setButtonLoading] = useState(false);
  const updateState = useRef(false);

  useEffect(() => {
    if (ADC && !updateState.current) {
      setADCConfig(ADC);
    }
  }, [ADC]);

  useEffect(() => {
    if (INPUTS && !updateState.current) {
      setINPUTSSelection(INPUTS);
    }
  }, [INPUTS]);

  useEffect(() => {
    if (DIN && !updateState.current) {
      setDINConfig(DIN);
    }
  }, [DIN]);

  useEffect(() => {
    if (INPUTSSelection && selectedChannel) {
      setSelectedOM(INPUTSSelection[`in_${selectedChannel.at(-1)}`]);
    }
  }, [selectedChannel]);

  const handleChangeOM = (e) => {
    const newDINSelectionConfig = structuredClone(INPUTSSelection);
    newDINSelectionConfig[`in_${selectedChannel.at(-1)}`] = e.target.value;
    setSelectedOM(e.target.value);
    setINPUTSSelection(newDINSelectionConfig);
  };

  const handleUpdateConfig = (name, e) => {
    let newConfig = {};
    if (selectedOM === 'din') {
      newConfig = structuredClone(DINConfig);
      newConfig[`din_${selectedChannel.at(-1)}`][name] = e
        ? 'enabled'
        : 'disabled';

      if (name === 'data_collection' && e) {
        newConfig.data_collection = 'enabled';
      }

      setDINConfig(newConfig);
    } else {
      newConfig = structuredClone(ADCConfig);
      if (name === 'adc') {
        newConfig[selectedChannel][name] = e;
      } else if (name === 'data_collection' && e) {
        newConfig[selectedChannel][name] = e ? 'enabled' : 'disabled';
        newConfig.data_collection = 'enabled';
      } else {
        newConfig[selectedChannel][name] = e ? 'enabled' : 'disabled';
      }
      setADCConfig(newConfig);
    }
  };

  const handleConfirmConfig = () => {
    updateState.current = true;
    setButtonLoading(true);

    if (selectedOM === 'din') {
      handleUpdateChannelConfig(ADCConfig, DINConfig, INPUTSSelection, 'din');
    } else {
      handleUpdateChannelConfig(ADCConfig, DINConfig, INPUTSSelection, 'adc');
    }
  };

  const handleOnCloseModal = () => {
    // revert changes if not applying
    setADCConfig(ADC);
    setINPUTSSelection(INPUTS);
    setDINConfig(DIN);
    setSelectedOM(INPUTS[`in_${selectedChannel.at(-1)}`]);
    onClose();
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      onCloseComplete={() => {
        updateState.current = false;
        setButtonLoading(false);
      }}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Input{' '}
          {selectedChannel &&
            parseInt(selectedChannel[selectedChannel.length - 1], 10)}
        </ModalHeader>
        <ModalCloseButton
          isDisabled={isButtonLoading}
          onClick={handleOnCloseModal}
        />

        <ModalBody pt={0}>
          {isButtonLoading && <div className="modal-disabled" />}
          <Flex alignItems="center" flexWrap="wrap">
            <h4>Operating Mode :</h4>

            <Select
              bg="#535355"
              borderColor="#535355"
              color="white"
              w={200}
              ml={2}
              defaultValue={
                INPUTS &&
                selectedChannel &&
                INPUTS[`in_${selectedChannel.at(-1)}`]
              }
              onChange={handleChangeOM}
              isDisabled={isButtonLoading}
            >
              <option value="ain">Analog Input</option>
              <option value="din">Digital Input</option>
            </Select>

            {((selectedOM === 'ain' && ADC.data_collection === 'disabled') ||
              (selectedOM === 'din' && DIN.data_collection === 'disabled')) && (
              <Alert status="warning" mt={2} borderRadius="10px">
                <AlertIcon />
                Data Collection is disabled at the module level.
              </Alert>
            )}
          </Flex>
          <Box className="operating-menu">
            {selectedOM === 'ain' &&
              Object.keys(ADCConfig).length > 0 &&
              ADCConfig.control === 'sdk' && (
                <h4 className="sdk-enabled">SDK is being enabled</h4>
              )}

            {selectedOM === 'din' &&
              Object.keys(DINConfig).length > 0 &&
              DINConfig.control === 'sdk' && (
                <h4 className="sdk-enabled">SDK is being enabled</h4>
              )}

            <Box mb={4}>
              <Flex alignItems="center" className="switch-box" flexWrap="wrap">
                <h4>Data Collection: </h4>

                {selectedOM === 'ain' && (
                  <CustomSwitch
                    id={`${selectedChannel}-data-collection`}
                    onSwitchChange={(e) =>
                      handleUpdateConfig('data_collection', e)
                    }
                    variant="lite"
                    isChecked={
                      Object.keys(ADCConfig).length > 0 &&
                      selectedChannel &&
                      ADCConfig[selectedChannel].data_collection === 'enabled'
                    }
                    isDisabled={ADCConfig && ADCConfig.control === 'sdk'}
                  />
                )}

                {selectedOM === 'din' && (
                  <CustomSwitch
                    id={`${selectedChannel}-din-data-collection`}
                    onSwitchChange={(e) =>
                      handleUpdateConfig('data_collection', e)
                    }
                    variant="lite"
                    isChecked={
                      Object.keys(DINConfig).length > 0 &&
                      selectedChannel &&
                      DINConfig[`din_${selectedChannel.at(-1)}`]
                        .data_collection === 'enabled'
                    }
                    isDisabled={
                      Object.keys(DINConfig).length > 0 &&
                      DINConfig.control === 'sdk'
                    }
                  />
                )}
              </Flex>
              <p>
                Data collection begins the collection of data from this port.
                Its reading will be subsequently shown on your Dashboard.
              </p>
            </Box>
            <Box mb={4}>
              <Flex alignItems="center" className="switch-box" flexWrap="wrap">
                <h4>Cloud Storage: </h4>

                {selectedOM === 'ain' && (
                  <CustomSwitch
                    id={`${selectedChannel}-cloud-storage`}
                    onSwitchChange={(e) =>
                      handleUpdateConfig('cloud_storage', e)
                    }
                    variant="lite"
                    isChecked={
                      Object.keys(ADCConfig).length > 0 &&
                      selectedChannel &&
                      ADCConfig[selectedChannel].cloud_storage === 'enabled'
                    }
                    isDisabled
                  />
                )}
                {selectedOM === 'din' && (
                  <CustomSwitch
                    id={`${selectedChannel}-cloud-storage`}
                    onSwitchChange={(e) =>
                      handleUpdateConfig('cloud_storage', e)
                    }
                    variant="lite"
                    isChecked={
                      Object.keys(DINConfig).length > 0 &&
                      selectedChannel &&
                      DINConfig[`din_${selectedChannel.at(-1)}`]
                        .cloud_storage === 'enabled'
                    }
                    isDisabled
                  />
                )}
              </Flex>
              <p>
                Cloud Storage will begin the storing of data. This data will be
                made available to you through our data visualization tool.
              </p>
            </Box>
          </Box>

          {selectedOM === 'ain' && (
            <Box className="operating-menu">
              <Flex alignItems="center" className="switch-box">
                <h4>ADC :</h4>
                <Select
                  bg="#535355"
                  borderColor="#535355"
                  color="white"
                  onChange={(e) => handleUpdateConfig('adc', e.target.value)}
                  defaultValue={
                    ADC && selectedChannel && ADC[selectedChannel].adc
                  }
                  isDisabled={
                    Object.keys(ADCConfig).length > 0 &&
                    ADCConfig.control === 'sdk'
                  }
                  w={200}
                >
                  <option value="adc_1">ADC 1</option>
                  <option value="adc_2">ADC 2</option>
                </Select>
              </Flex>
            </Box>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            className="btn-group"
            bg="#535355"
            color="#FFF"
            boxShadow="0px 15px 30px rgba(0, 0, 0, 0.15)"
            variant="solid"
            w={150}
            mr={2}
            onClick={handleOnCloseModal}
            isDisabled={isButtonLoading}
            _hover={{ bg: '#242424' }}
          >
            Cancel
          </Button>

          <Button
            className="btn-group"
            bg="#DB2F5CCC"
            color="#FFF"
            variant="solid"
            w={150}
            onClick={handleConfirmConfig}
            isLoading={isButtonLoading}
            _hover={{ bg: '#db2f5d99' }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default memo(InputChannelModal);
