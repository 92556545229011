import React, { memo } from 'react';
import {
  Box,
  Button,
  Flex,
  Icon,
  Input,
  Select,
  Stack,
  Heading,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/react';
import { GoInfo, GoTrash } from 'react-icons/go';
import CustomSwitch from '../../../../../../../components/custom-switch/custom-switch.component';

const ModbusRTUReadBlock = ({
  readBlockList,
  activeReadBlock,
  handleDeleteReadBlock,
  handleUpdateReadBlock,
}) => (
  <Flex className="adc-modal--item" flexDirection="column">
    <Stack justifyContent="flex-end" direction="row" spacing={4} mb={4}>
      <Popover>
        <PopoverTrigger>
          <Button
            colorScheme="red"
            variant="outline"
            w={200}
            mr={2}
            leftIcon={<GoTrash />}
          >
            Delete Read Block
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>Confirmation!</PopoverHeader>
          <PopoverBody>
            Are you sure you want to delete this read block{' '}
            <span className="item_name">
              {readBlockList[activeReadBlock].block_name}
            </span>
            ?
          </PopoverBody>
          <PopoverFooter>
            <Button colorScheme="blue" onClick={handleDeleteReadBlock}>
              Yes, delete it
            </Button>
          </PopoverFooter>
        </PopoverContent>
      </Popover>
    </Stack>
    <hr />
    <Flex justifyContent="center" flexDirection="column" mt={2}>
      <Flex alignItems="center" mb={4}>
        <Heading as="h4" mr={2}>
          Read Enable :
        </Heading>
        <CustomSwitch
          id={`aux-${readBlockList[activeReadBlock].block_name}`}
          variant="lite"
          onSwitchChange={handleUpdateReadBlock(
            activeReadBlock,
            'read_enabled'
          )}
          isChecked={readBlockList[activeReadBlock].read_enabled}
        />
      </Flex>
      <Stack direction="row" spacing={10} flexWrap="wrap">
        <Flex justifyContent="center" flexDirection="column">
          <h4>Read Block Name :</h4>
          <Input
            width={250}
            mt={2}
            type="text"
            maxLength={20}
            onChange={handleUpdateReadBlock(activeReadBlock, 'block_name')}
            value={readBlockList[activeReadBlock].block_name}
            isInvalid={readBlockList[activeReadBlock].block_name === ''}
          />
        </Flex>
        <Flex flexDirection="column">
          <h4>Read Function Code : </h4>
          <Select
            w={250}
            mt={2}
            variant="filled"
            placeholder="Function Code ..."
            onChange={handleUpdateReadBlock(
              activeReadBlock,
              'read_params',
              'func_code'
            )}
            value={readBlockList[activeReadBlock].read_params.func_code}
          >
            <option value={1}>Read Coils (0x01)</option>
            <option value={2}>Read Discrete Inputs (0x02)</option>
            <option value={3}>Read Holding Registers (0x03)</option>
            <option value={4}>Read Input Registers (0x04)</option>
          </Select>
        </Flex>
      </Stack>
      <Stack direction="row" spacing={10} flexWrap="wrap">
        <Flex justifyContent="center" flexDirection="column" mt={4}>
          <h4>Starting Address :</h4>
          <p>Where to begin reading</p>
          <Input
            width={250}
            mt={2}
            type="text"
            onChange={handleUpdateReadBlock(
              activeReadBlock,
              'read_params',
              'address'
            )}
            value={readBlockList[activeReadBlock].read_params.address}
          />
        </Flex>
        <Flex justifyContent="center" flexDirection="column" mt={4}>
          <Flex alignItems="center">
            <h4>Register Count</h4>

            <Box h={6} className="fc-tooltip">
              <Icon as={GoInfo} ml={2} w={6} h={6} />
              <div className="fc-tooltip-box">
                <Flex justifyContent="space-between" alignItems="center">
                  <p>FC-01</p>
                  <p>max. 10,000</p>
                </Flex>
                <hr />
                <Flex justifyContent="space-between" alignItems="center">
                  <p>FC-02</p>
                  <p>max. 10,000</p>
                </Flex>
                <hr />
                <Flex justifyContent="space-between" alignItems="center">
                  <p>FC-03</p>
                  <p>max. 10,000</p>
                </Flex>
                <hr />
                <Flex justifyContent="space-between" alignItems="center">
                  <p>FC-04</p>
                  <p>max. 10,000</p>
                </Flex>
              </div>
            </Box>
          </Flex>
          <p>How many registers to be read</p>
          <Input
            width={250}
            mt={2}
            type="number"
            onChange={handleUpdateReadBlock(
              activeReadBlock,
              'read_params',
              'reg_count'
            )}
            value={readBlockList[activeReadBlock].read_params.reg_count}
          />
        </Flex>
      </Stack>
    </Flex>
  </Flex>
);

export default memo(ModbusRTUReadBlock);
